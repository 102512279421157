import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import share_cz from '../../assets/share_cz.png';
import share_en from '../../assets/share_en.png';
import share_sk from '../../assets/share_sk.png';
import share_ua from '../../assets/share_ua.png';
import { messages } from '../../intl';
import { Context } from '../../Proctoring';

const images = {
  cz: share_cz,
  en: share_en,
  sk: share_sk,
  ua: share_ua,
};

const ShareScreen = ({ isActive }) => {
  const { locale } = useContext(Context);

  const { allowContinue, setAllowContinue } = useContext(Context);

  useEffect(() => {
    if (isActive && !allowContinue) setAllowContinue(true);
  }, [allowContinue, isActive, setAllowContinue]);

  return (
    <div className="introCheck shareScreen">
      <h2 className="header2Semibold">
        <FormattedMessage {...messages.shareScreenHeading} />
      </h2>
      <div className="info textXsMedium">
        <FormattedMessage {...messages.shareScreenPrompt} />
        <br />
        <span className="textMdNormal">
          <FormattedMessage {...messages.shareScreenPromptDesc} />
        </span>
      </div>
      <div className="displayFlex">
        <img alt="" src={images[locale]} />
      </div>
    </div>
  );
};

export default ShareScreen;
