const DisableCopyPaste = {
  add() {
    document.addEventListener('copy', (e) => {
      e.preventDefault();
    });

    document.addEventListener('paste', (e) => {
      e.preventDefault();
    });
  },
};

export default DisableCopyPaste;
