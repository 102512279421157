import { equals, isEmpty } from 'ramda';
import { useCallback, useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CHECK, SECTIONS } from '../consts';
import { arrowLeft, buttonLoaderIcon } from '../icons';
import { messages } from '../intl';
import { Context } from '../Proctoring';
import { prepareScreenStream } from '../utils/stream';
import usePrevious from '../utils/usePrevious';
import base64Image from '../../utils/images';
import ReactTooltip from 'react-tooltip';

const scrollMore = () => {
  const scrollAmount = window.innerHeight * 0.3;
  if (scrollAmount > 0) window.scrollBy(0, window.scrollY + scrollAmount);
};

const ScrollMoreButton = () => {
  const intl = useIntl();

  return (
    <button type="button" id={'scrollMore'} className="btn">
      <div className="test-continues-polygon" />
      <div className="test-continues-overlay" />
      <div className="arrow-down-toggle">
        <img
          src={base64Image('arrow-scroll-more')}
          alt=""
          className="arrow-down"
          onClick={() => scrollMore()}
        />
        <img
          src={base64Image('arrow-scroll-more-active')}
          alt=""
          className="arrow-down-active"
          onClick={() => scrollMore()}
          data-tip={intl.formatMessage(messages.textContinueDownward)}
          data-for="tooltip-scroll-more"
        />
      </div>
      <ReactTooltip
        id="tooltip-scroll-more"
        effect="solid"
        arrowColor="transparent"
      />
    </button>
  );
};

const Footer = () => {
  const {
    activeCheckId,
    allowContinue,
    definitionId,
    examId,
    setActiveCheckId,
    setSection,
    socket,
    checks,
    setNotification,
    screenStream,
    setScreenStream,
    approvalsTexts,
  } = useContext(Context);

  // Last chcek is the exam itself, that's why -1
  const isLastStep = activeCheckId === checks.length - 1;
  const isShareScreen = checks[activeCheckId]?.isShareScreen;
  const activeCheck = checks[activeCheckId];
  const showHelper = activeCheck?.isGdpr || checks[activeCheckId]?.isExamRules;
  const checkpointType = activeCheck.runCheck;
  const isLoading = isEmpty(approvalsTexts);
  const prevCheckpointType = usePrevious(checkpointType);
  const [isAllContentVisible, setIsAllContentVisible] = useState(false);

  // Go to next check
  const next = useCallback(
    () => setActiveCheckId(activeCheckId + 1),
    [activeCheckId, setActiveCheckId]
  );

  // Go to exam
  const exam = () => {
    setSection(SECTIONS.EXAM);
    socket.emit('checkpointFinished', {
      examId,
      definitionId: definitionId,
      checkpointType: CHECK.ROOM,
    });
  };

  // Share screen
  const shareScreen = async () =>
    await prepareScreenStream({ setNotification, setScreenStream });

  // Go to next check automaticaly when screen sharing gets permitted by user
  useEffect(() => {
    if (screenStream && isShareScreen) next();
  }, [next, screenStream, isShareScreen]);

  // Handle checkpointStarted / checkpointFinished, room check suppose to be finished after all checks have passed
  useEffect(() => {
    if (checkpointType && !equals(prevCheckpointType, checkpointType)) {
      if (prevCheckpointType && prevCheckpointType !== CHECK.ROOM) {
        socket.emit('checkpointFinished', {
          examId,
          definitionId,
          checkpointType: prevCheckpointType,
        });
      }
      socket.emit('checkpointStarted', {
        examId,
        definitionId,
        checkpointType,
      });
    }
  }, [checkpointType, definitionId, examId, prevCheckpointType, socket]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setIsAllContentVisible(
        window.scrollY + window.innerHeight + 50 >=
          document.documentElement.scrollHeight
      );
    });
  }, []);

  return (
    <footer className="footer">
      {showHelper && !isAllContentVisible && <ScrollMoreButton />}
      <button
        disabled={!allowContinue || isLoading}
        onClick={isShareScreen ? shareScreen : isLastStep ? exam : next}
        className={`next ${
          !allowContinue || isLoading ? 'bgDisabled' : 'bgActive'
        }`}
      >
        {isLoading ? (
          buttonLoaderIcon
        ) : (
          <span>
            <FormattedMessage {...messages[isLastStep ? 'exam' : 'continue']} />
            {arrowLeft}
          </span>
        )}
      </button>
    </footer>
  );
};

export default Footer;
