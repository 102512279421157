import { useEffect } from 'react';
import draggable from '../utils/draggable';
import modalOverlapping from '../utils/modalOverlapping';
import { ResourceManager } from '../resources/ResourceManager';
import InstructionLegend from '../components/InstructionLegend';
import InstructionHtml from '../components/InstructionHtml';
import base64Image from '../utils/images';

const TestInstructionModal = (props) => {
  const { show, top, left, height } = props.modalState;
  const instructionId = props.test.instructionId;
  const items = props.items;
  const legendEnabled = props.legendEnabled;

  useEffect(() => {
    if (show) {
      draggable(
        document.getElementById('idTestInstructionsModal'),
        document.getElementById('idTestInstructionsHeader'),
        document.getElementById('sidebar')
      );
      modalOverlapping('idTestInstructionsModal', '.sidebar-modal');
    }
  }, [show]);

  if (show) {
    // instructions button top position + button's height + gap by design
    const modalTopPosition = top + height + 12;

    return (
      <div
        id="idTestInstructionsModal"
        className="sidebar-modal instruction-modal"
        style={{ top: modalTopPosition, left: left }}
      >
        <div id="idTestInstructionsHeader" className="modal-sidebar-draggable">
          <div className="modal-title">
            {ResourceManager('instructionsLegend')}
          </div>
          <button
            onClick={props.toggleInstructions}
            className="btn-close"
            aria-label={ResourceManager('cancel')}
          >
            <img src={base64Image('x-close-gray700')} alt="" />
          </button>
        </div>
        <div className="instructions-container sidebar-modal-content scrollable">
          {legendEnabled && <InstructionLegend />}
          {instructionId && (
            <InstructionHtml
              renderedInstruction={items[instructionId].renderedOutput}
            />
          )}
        </div>
      </div>
    );
  }

  return null;
};

export default TestInstructionModal;
