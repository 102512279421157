import BaseOperator from "./BaseOperator";

export default class FactorialOperator extends BaseOperator {

    getOperator() {
        return '!';
    }


    toFullString() {
        return this.getOperator() +" ";
    }
}