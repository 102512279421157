export default function modalOverlapping(idActiveModal, modalsClassSelector) {
  const toggleTopModal = () => {
    const modals = document.querySelectorAll(modalsClassSelector);
    modals.forEach((modal) => {
      modal.classList.remove('top-modal');
    });

    const toggleActiveModal = document.getElementById(idActiveModal);
    toggleActiveModal.classList.add('top-modal');
  };

  const activeModal = document.getElementById(idActiveModal);

  activeModal.removeEventListener('click', toggleTopModal);
  activeModal.addEventListener('click', toggleTopModal);

  toggleTopModal();
}
