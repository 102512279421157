const blurImage = (imgBase64, canvasElementId, setIdSnapshotSrc) => {
  const canvas = document.getElementById(canvasElementId);

  if (!canvas) return null;

  const ctx = canvas.getContext('2d', { willReadFrequently: true });
  const rect = {};
  let drag = false;
  let firstRerender = true;
  let imageObj = null;
  let imgData;
  let rw, rh;
  let info = canvas.getClientRects()[0];

  imageObj = new Image();
  imageObj.onload = () =>
    ctx.drawImage(imageObj, 0, 0, info.width, info.height);
  imageObj.src = imgBase64;

  canvas.addEventListener(
    'mousedown',
    (e) => {
      // Recalculate in case the user scrolled up/down and changed position of canvas relative to viewport
      info = canvas.getClientRects()[0];
      rect.startX = e.pageX - info.left;
      // clientY does not account for vertical scroll
      // We want this because neither does the top of the snapshot canvas, it calculates top based on viewport
      rect.startY = e.clientY - info.top;
      drag = true;
    },
    false
  );

  canvas.addEventListener(
    'mouseup',
    () => {
      drag = false;
      setIdSnapshotSrc(canvas.toDataURL());
    },
    false
  );

  canvas.addEventListener(
    'mousemove',
    (e) => {
      if (firstRerender) {
        setIdSnapshotSrc(canvas.toDataURL());
        firstRerender = false;
      }

      if (drag) {
        try {
          ctx.filter = 'blur(10px)';
          ctx.drawImage(imageObj, 0, 0, info.width, info.height);

          rect.w = e.pageX - info.left - rect.startX;
          rect.h = e.clientY - info.top - rect.startY;

          if (rect.w === 0) rect.w = 1;
          if (rect.h === 0) rect.h = 1;

          ctx.strokeStyle = '#EBECEC';
          ctx.lineWidth = 1;

          imgData = ctx.getImageData(rect.startX, rect.startY, rect.w, rect.h);
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.filter = 'none';
          ctx.drawImage(imageObj, 0, 0, info.width, info.height);

          if (rect.w < 0) rw = rect.startX + rect.w;
          else rw = rect.startX;

          if (rect.h < 0) rh = rect.startY + rect.h;
          else rh = rect.startY;

          ctx.putImageData(imgData, rw, rh);

          ctx.strokeRect(rect.startX, rect.startY, rect.w, rect.h);
        } catch (error) {
          console.log(error);
        }
      }
    },
    false
  );
};

export default blurImage;
