export default class TimerHelper {
  remainingTime = 0;

  timerZeroCallback;

  timerZeroParameters;

  setStateRemainingSecondsCallback;

  intervalId;

  startTimer = (
    remainingTime,
    setStateRemainingSecondsCallback,
    timerZeroCallback,
    timerZeroParameters = null
  ) => {
    this.remainingTime = remainingTime;
    this.timerZeroCallback = timerZeroCallback;
    this.timerZeroParameters = timerZeroParameters;
    this.setStateRemainingSecondsCallback = setStateRemainingSecondsCallback;
    this.intervalId = setInterval(this.countDown, 1000);
  };

  countDown = () => {
    let seconds = this.remainingTime;

    if (seconds > 0) {
      seconds -= 1;
      this.remainingTime = seconds;
      this.setStateRemainingSecondsCallback(seconds);
    } else {
      this.clearTimer();
      this.timerZeroCallback(this.timerZeroParameters);
    }
  };

  clearTimer = () => {
    clearInterval(this.intervalId);
  };

  secondsToTime = (secs) => {
    const divisorForMinutes = secs % 60;
    const minutes = Math.floor(secs / 60);

    const divisorForSeconds = divisorForMinutes % 60;
    const seconds = Math.ceil(divisorForSeconds);

    const obj = {
      minutes,
      seconds,
    };
    return obj;
  };

  formatTime = (seconds) => {
    const timeObj = this.secondsToTime(seconds);

    let timerString = '';
    timerString += String(timeObj.minutes).padStart(2, 0);
    timerString += ':';
    timerString += String(timeObj.seconds).padStart(2, 0);

    return timerString;
  };
}
