import { createIntl, createIntlCache } from 'react-intl';
import messages from './messages';
import translation from './translation';

export { messages };
export { translation };

createIntl(
  {
    locale: 'en',
    messages,
  },
  createIntlCache()
);
