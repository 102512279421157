import React, { useContext, useEffect } from 'react';
import { Context } from '../../Proctoring';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../intl';

const IdCheckIntro = ({ isActive }) => {
  const { locale, setAllowContinue } = useContext(Context);

  useEffect(() => {
    if (isActive) setTimeout(() => setAllowContinue(true), 500);
  }, [isActive, setAllowContinue]);

  return (
    <div className="introCheck idCheckIntro">
      <h2 className="header2Semibold">
        <FormattedMessage {...messages.howPhotoshootId} />
      </h2>

      <div className="textMdNormal width682">
        <FormattedMessage {...messages.introIdDesc} />
      </div>
      <br />
      <br />

      <div className="images textSmMedium">
        <p>
          <img alt="" src={`/proctoring/idCutoff-${locale}.png`} />
          <FormattedMessage {...messages.idCheckShowAllDetails} />
        </p>
        <p>
          <img alt="" src={`/proctoring/idBlur-${locale}.png`} />
          <FormattedMessage {...messages.idCheckAllMustBeClear} />
        </p>
        <p>
          <img alt="" src={`/proctoring/idGood-${locale}.png`} />
          <FormattedMessage {...messages.idCheckShouldLookLike} />
        </p>
        <p>
          <img alt="" src={`/proctoring/idHand-${locale}.png`} />
          <FormattedMessage {...messages.idCheckBackWithHand} />
        </p>
      </div>
    </div>
  );
};

export default IdCheckIntro;
