//https://www.esimovmiras.cc/articles/03-build-math-ast-parser/

import types from "./types";

const config = {
    rules: [
        {
            key: "sin|cos|tg|tan|ln|ctg|log|√|neg",
            data: {
                type: types.NAMED_FUNCTION,
                args: 1,
                precedence: 4
            }
        },
        {
            key: "sqrt",
            data: {
                type: types.NAMED_FUNCTION,
                args: 2,
                precedence: 4
            }
        },
        {
            key: "e|π",
            data: {
                type: types.CONSTANT
            }
        },
        {
            key: "[\\^|E]",
            data: {
                type: types.OPERATOR,
                args: 2,
                precedence: 3
            }
        },
        {
            key: "[\\x\\/]",
            data: {
                type: types.OPERATOR,
                args: 2,
                precedence: 2
            }
        },
        {
            key: "[\\+\\-]",
            data: {
                type: types.OPERATOR,
                args: 2,
                precedence: 1
            }
        },
        { key: "[(\\[]", data: { type: types.LEFT_PARENTHESIS } },
        { key: "[)\\]]", data: { type: types.RIGHT_PARENTHESIS } },
        { key: "[0-9.,!]+", data: { type: types.NUMBER } }
        
    ]
};

export default config;
