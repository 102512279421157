import base64ToArrayBuffer from './stringUtils';

const importSecretKey = (rawKey) => {
  return window.crypto.subtle.importKey('raw', rawKey, 'AES-GCM', true, [
    'decrypt',
  ]);
};

const decrypt = async (encrypted, nonce, key) => {
  const decrypted = await crypto.subtle.decrypt(
    { name: 'AES-GCM', iv: nonce },
    key,
    encrypted
  );
  const decoded = new TextDecoder().decode(decrypted);
  return decoded;
};

export default async function decryptResponse(
  encryptedResponse,
  keyString,
  nonceString
) {
  const encryptedBuffer = await encryptedResponse.arrayBuffer();
  const key = base64ToArrayBuffer(keyString);
  const nonce = base64ToArrayBuffer(nonceString);

  const algKey = await importSecretKey(key);
  const result = await decrypt(encryptedBuffer, nonce, algKey);
  return result;
}
