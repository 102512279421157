/**
 * some dom operations about HighlightRange
 */

import { CAMEL_DATASET_IGNORE_NODE } from '@src/util/const';
import type { DomMeta, DomNode } from '@src/types';

/**
 * text total length in all predecessors (text nodes) in the root node
 * (without offset in current node)
 */
const getTextPreOffset = ($root: Node, $text: Node): number => {
    const nodeStack: Node[] = [$root];

    let $curNode: Node = null;
    let offset = 0;

    while (($curNode = nodeStack.pop())) {
        const children = $curNode.childNodes;

        for (let i = children.length - 1; i >= 0; i--) {
            const child = children[i] as HTMLElement;

            if (!child.dataset?.[CAMEL_DATASET_IGNORE_NODE]) nodeStack.push(children[i]);
        }

        if ($curNode.nodeType === 3 && $curNode !== $text) {
            offset += $curNode.textContent.length;
        } else if ($curNode.nodeType === 3) {
            break;
        }
    }

    return offset;
};

export const getSelectionText = ($root: Node, start: DomNode, end: DomNode): string => {
    const nodeStack: Node[] = [$root];

    let $curNode: Node = null;

    if (start.$node === end.$node && start.$node.nodeType === 3) {
        return start.$node.textContent.slice(start.offset, end.offset);
    }

    let text = '';
    let withinSelectedRange = false;

    while (($curNode = nodeStack.pop())) {
        const children = $curNode.childNodes;

        for (let i = children.length - 1; i >= 0; i--) {
            const child = children[i] as HTMLElement;

            if (!child.dataset?.[CAMEL_DATASET_IGNORE_NODE]) nodeStack.push(children[i]);
        }

        if ($curNode === start.$node) {
            text += $curNode.textContent.slice(start.offset);
            // meet the start-node (begin to traverse)
            withinSelectedRange = true;
        } else if ($curNode === end.$node) {
            text += $curNode.textContent.slice(0, end.offset);
            break;
        } else if ($curNode.nodeType === 3 && withinSelectedRange) {
            text += $curNode.textContent;
        }
    }

    return text;
};

export const getDomMeta = ($node: HTMLElement | Text, offset: number, $root: Document | HTMLElement): DomMeta => {
    const preNodeOffset = getTextPreOffset($root, $node);

    return {
        textOffset: preNodeOffset + offset,
    };
};

export const formatDomNode = (n: DomNode): DomNode => {
    if (
        // Text
        n.$node.nodeType === 3 ||
        // CDATASection
        n.$node.nodeType === 4 ||
        // Comment
        n.$node.nodeType === 8
    ) {
        return n;
    }

    return {
        $node: n.$node.childNodes[n.offset],
        offset: 0,
    };
};
