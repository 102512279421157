import React, { Component } from 'react';
import { ResourceManager } from '../resources/ResourceManager';
import finishInProgress from '../utils/finishInProgress';
import log from '../utils/logging/DefaultLogger';
import {
  serializeAnswersAsync,
  saveAnswersAsync,
  serializeDisplayItemLogAsync,
  saveDisplayItemLogAsync,
  cleanUpAnswersAsync,
  cleanUpDisplayItemLogAsync,
  cleanTokens,
  redirectToProfile,
} from '../utils/finishHelpers';
import FinishForm from './FinishForm';

export default class Finish extends Component {
  constructor() {
    super();
    this.state = {
      error: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    finishInProgress.start(this.props.tokenObject.AnswersId);
    this.finishTest();
  }

  // because of retry button
  componentDidUpdate() {
    this.finishTest();
  }

  async finishTest() {
    if (this.state.error) return;

    const { db, tokenObject, token, finishEventType } = this.props;
    log.info('Finishing test', tokenObject);

    try {
      const { serializedAnswers } = await serializeAnswersAsync(
        tokenObject,
        db
      );

      await saveAnswersAsync(
        serializedAnswers,
        finishEventType,
        tokenObject,
        token
      );

      const serializedDisplayItemLog = await serializeDisplayItemLogAsync(
        tokenObject,
        db
      );

      await saveDisplayItemLogAsync(
        serializedDisplayItemLog,
        tokenObject,
        token
      );

      await cleanUpAnswersAsync(tokenObject, db);

      await cleanUpDisplayItemLogAsync(tokenObject, db);

      finishInProgress.cleanup(tokenObject.AnswersId);

      if (tokenObject.ExamSettings.IsFinalSession) {
        cleanTokens();
        redirectToProfile(tokenObject);
      } else {
        this.startNextTest();
      }
    } catch (error) {
      this.handleError(error.statusText || error.message);
    }
  }

  startNextTest = () => {
    log.info('Finish completed - starting next test', this.props.tokenObject);
    this.props.startNextTestFunc();
  };

  handleError = (message) => {
    const errorMessage = `${ResourceManager(
      'errUnableToFinishTest'
    )} ${message}`;
    log.debug(errorMessage);
    this.setState({ error: true, errorMessage });
  };

  retry = () => {
    this.setState({ error: false, errorMessage: '' });
  };

  render() {
    const { token, tokenObject } = this.props;
    const { error, errorMessage } = this.state;

    return (
      <FinishForm
        token={token}
        tokenObject={tokenObject}
        error={error}
        errorMessage={errorMessage}
        retryFunc={this.retry}
      />
    );
  }
}
