const DisableSearching = {
  add() {
    window.addEventListener('keydown', (e) => {
      if (e.keyCode === 114 || (e.ctrlKey && e.keyCode === 70)) {
        e.preventDefault();
      }
    });
  },
};

export default DisableSearching;
