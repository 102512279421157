import React, { useContext, useEffect } from 'react';
import { Context } from '../../Proctoring';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../intl';

const FaceCheckIntro = ({ isActive }) => {
  const { setAllowContinue } = useContext(Context);

  useEffect(() => {
    if (isActive) setTimeout(() => setAllowContinue(true), 500);
  }, [isActive, setAllowContinue]);

  return (
    <div className="introCheck faceCheckIntro">
      <h2 className="header2Semibold">
        <FormattedMessage {...messages.howPhotoshootFace} />
      </h2>

      <div className="textMdNormal width682">
        <FormattedMessage {...messages.introFaceDesc} />
      </div>
      <br />
      <br />

      <div className="images textSmMedium">
        <p>
          <img alt="" src={`/proctoring/faceCutoff.png`} />
          <FormattedMessage {...messages.faceCheckShowWholeFace} />
        </p>
        <p>
          <img alt="" src={`/proctoring/faceBlur.png`} />
          <FormattedMessage {...messages.faceCheckAllMustBeClear} />
        </p>
        <p>
          <img alt="" src={`/proctoring/faceGlare.png`} />
          <FormattedMessage {...messages.faceCheckMoveFromLight} />
        </p>
        <p>
          <img alt="" src={`/proctoring/faceGood.png`} />
          <FormattedMessage {...messages.faceCheckShouldLookLike} />
        </p>
      </div>
    </div>
  );
};

export default FaceCheckIntro;
