module.exports = {
  stringsSK: {
    errHandlerPageTitle: 'Niečo sa nepodarilo...',
    errHandlerTitle: 'Nastala chyba',
    errHandlerDesc:
      'Skúste to znova. Ak sa chyba zopakuje, vráťte sa do zoznamu testov a kliknite na tlačidlo Pokračovať.',
    errTokenMissing: 'Chýba autorizačný token!',
    errFetchToken: 'Nemožno načítať token.',
    errInitIndexedDb: 'Chyba inicializácie databázy prehliadača.',
    errUnknown: 'Nešpecifikovaná chyba servera.',
    errUnableToLoadTest: 'Nemožno načítať test! ',
    errUnableToStartTest: 'Nemožno spustiť test! ',
    errUnableToFinishTest: 'Nepodarilo sa ukončiť test! ',
    errInvalidToken: 'Neplatný token',
    errInit: 'Chyba pri inicializácii.',
    errReturnLink: 'Zoznam skúšok',
    errResilienceNoItems:
      'V tomto zariadení nie sú žiadne odpovede na odosielanie. Uistite sa, že ste v zariadení a prehliadači, v ktorom ste riešili túto skúšku. V takom prípade môžete kliknúť na tlačidlo Ukončiť skúšku. Ak používate iné zariadenie alebo webový prehliadač, kliknite na tlačidlo Zoznam skúšok a prihláste sa do ScioLink zo správneho zariadenia.',
    errRetryAdvice: 'Skúste opakovať akciu',
    err404: '404',
    err404Title: 'Stránka neexistuje',
    err404Desc:
      'Zdá sa, že stránka, ktorú hľadáte, neexistuje, prejdite na domovskú stránku.',
    err404Btn: 'Prejsť na domovskú stránku',
    loadingData: 'nahrávam dáta...',
    savingData: 'nahrávam dáta...',
    testContinuesDownwards: 'Test pokračuje smerom dole',
    testTotalQuestions: 'Počet otázok:',
    testTimeLimit: 'Časový limit:',
    testRemainingTime: 'Zostávajúci čas:',
    testNoTimelimit: 'Bez časového limitu.',
    btnTestStart: 'Spustiť test',
    btnPartStart: 'Spustiť oddiel',
    btnTryAgain: 'Skúsiť znovu',
    btnReturn: 'Zoznam skúšok',
    btnNavigationPrevious: 'Predchádzajúca',
    btnNavigationNext: 'Ďalšia',
    btnNavigationFinishTest: 'Ukončiť test',
    btnNavigationFinishPart: 'Ukončiť oddiel',
    itemQuestion: 'Úloha',
    itemQuestions: 'úloh',
    sharedItem: 'Spoločné zadanie',
    sharedItemIndexOne: 'pre úlohu',
    sharedItemIndexN: 'pre úlohy',
    implicitInstruction: 'Inštrukcia',
    implicitInstructionIndexOne: 'pre úlohu',
    implicitInstructionIndexN: 'pre úlohy',
    insert: 'Vložit',
    instructionCollapse: 'collapse instruction',
    instructionExpand: 'expand instruction',
    downloadContentTitle: 'Pomocné materiály',
    calculatorTitle: 'Kalkulačka',
    cancel: 'Zrušit',
    timer_estimatedTimeTest: 'Zostávajúci čas: ',
    timer_estimatedTimePart: 'Zostávajúci čas: ',
    timerTimeoutAlertTitle: 'Čas vypršal',
    timerTimeoutAlertTextTest:
      'Časový limit na vypracovanie vypršal. Test bude ukončený.',
    timerTimeoutAlertTextPart:
      'Časový limit na vypracovanie vypršal. Oddiel bude ukončený.',
    timerTimeoutAlertButton: 'OK',
    timerTimeoutConfirmTitleTest: 'Naozaj chcete skončiť?',
    timerTimeoutConfirmTitlePart: 'Naozaj chcete skončiť?',
    timerTimeoutConfirmTextTest:
      '<b>Ukončenie testu</b> je nevratné. Po ukončení sa už nebudete môcť vrátiť k jednotlivým úlohám.',
    timerTimeoutConfirmTextPart:
      '<b>Ukončenie oddielu</b> je nevratné. Po ukončení sa už nebudete môcť vrátiť k jednotlivým úlohám. ',
    timerTimeoutConfirmButtonConfirm: 'Áno, ukončiť',
    timerTimeoutConfirmButtonCancel: 'Nie, vrátiť sa späť',
    timer_hideTime_arialabel: 'Skryť čas',
    timer_hideTime_tooltip:
      'Skryť čas. 10 minút pred koncom sa vám čas automaticky zobrazí.',
    timer_showTime_tooltip: 'Zobrazit zostávajúci čas.',
    timer_showTime: 'Zobraziť čas',
    timer_minutes: ['minúta', 'minúty', 'minút'],
    timer_snackbar: 'Máte posledných 10 minút testu',
    timerScreenReaders: `Zostávajúci čas:`,
    highlighterSnackbar: 'Vypnúť zvýrazňovač',
    instructionsTitle: 'Pokyny ku skúške',
    instructionsLegend: 'Legenda',
    instructionTitleItem: 'Úloha',
    instructionTitleItems: 'Úlohy',
    instructionTimerInfo: 'Zostávajúci čas na prečítanie zadania: ',
    legendUreaded: 'Neprečítaná úloha',
    legendAnswered: 'Zodpovedená úloha',
    legendActive: 'Aktívna úloha, na ktorej sa práve nachádzate',
    legendFlagged: 'Označená úloha',
    calcDegrees: 'stupne',
    calcRadians: 'radiány',
    calcError: 'Chybná operácia',
    calcDivideByZero: 'Delenie nulou',
    calcQqrtNegative:
      'Párna odmocnina zo záporného čísla nie je v R definovaná',
    calcMissingOperand: 'Chýba časť výrazu',
    calcInvalidToken: 'Neplatný výraz',
    calcUnknownError: 'Neznáma chyba',
    flagTitle: 'Označiť',
    flagTitleRemove: 'Odznačiť',
    keyboardTitle: 'Pomocná klávesnica',
    keyboardInstruction:
      'Po stlačení príslušného písmena sa zobrazí ponuka špeciálnych znakov, napr. pre é stlačte e.',
    showSupportMaterials: 'Zobraziť pomocný materiál',
    hideSupportMaterials: 'Skryť pomocný materiál',
    sidebarCalculator: 'Kalkulačka',
    sidebarSupportMaterials: 'Materiály',
    sidebarNotepad: 'Poznámky',
    sidebarHighlighter: 'Zvýrazňovač',
    sidebarSupport: 'Podpora',
    supportCenter: 'Zákaznícke centrum',
    supportCenterText:
      'Centrum služieb zákazníkom. Od pondelka do štvrtka od 9:00 do 17:00, v piatok od 9:00 do 16:00. V deň konania Národných porovnávacích skúšok sme k dispozícii vždy počas celého skúšania',
    supportCenterEmailTitle: 'E-mail',
    supportCenterEmailSubtitle: 'Sme tu, aby sme vám pomohli',
    supportCenterEmail: 'scio@scio.cz',
    supportCenterPhoneTitle: 'Telefón',
    supportCenterPhoneSubtitle: 'Neváhajte nás kontaktovať',
    supportCenterPhone: '+420 234 705 555',
    tooltipInstructions: 'Inštrukcie a pokyny ku skúške',
    tooltipSupportMaterials: 'Pomocné materiály dostupné k testu',
    tooltipCalculator: 'Môžete používať zabudovanú kalkulačku',
    tooltipHighlighter: 'Zapnúť zvýrazňovač',
    tooltipHighlighterOn: 'Vypnúť zvýrazňovač',
    tooltipNotepad: 'Poznámkový blok',
    tooltipDisabled: 'Načítava sa ďalšia stránka',
    proctoringTitle: 'Nastavenie a kontrola údajov',
    playMedia: 'Prehrať',
    playingMediaNewPageConfirmTitle: 'Upozornenie počas prehrávania',
    playingMediaNewPageConfirmMessage:
      'Prechodom na inú stránku vyčerpáte jednu možnosť prehrania mediálneho obsahu. Chcete prejsť na inú stránku?',
    playingMediaNewPageConfirmMessageAutoreplay:
      'Prechodom na inú stránku vyčerpáte všetky možnosti prehrania mediálneho obsahu. Chcete prejsť na inú stránku?',
    playingMediaNewPageButtonConfirm: 'Potvrdzujem',
    playingMediaNewPageButtonCancel: 'Zrušiť',
    maximumAttemptsTitle: 'Upozornenie na prehrávanie',
    maximumAttemptsMessage: 'Bol prekročený maximálny počet prehratí.',
    maximumAttemptsButton: 'Ok',
    mediaMessage1: 'Táto ukážka má obmedzený počet prehratí. ',
    mediaMessage21: 'Možno ju prehrať ',
    mediaMessage22: 'x. ',
    mediaMessage23: 'Prehraje sa celkom ',
    mediaMessage24: 'x. ',
    mediaMessage3: 'Po spustení už posluch nie je možné zastaviť. ',
    mediaMessage4: 'Opakované prehrávanie sa spúšťa automaticky. ',
    mediaMessage51: 'Máte k dispozícii ešte ',
    mediaMessage52: ' prehranie.',
    mediaMessage6: ' Už nemáte k dispozícii žiadne prehranie.',
    resilienceModeTitle: 'Obnovenie odpovedí',
    resilienceModeTitleError: 'Ukončenie skúšky',
    resilienceConfirmText:
      'Odpovede uložené pri prechode testom budú nahrané na naše servery.',
    resilienceConfirmButton: 'Nahrať odpovede',
    resilienceErrorButtonFinish: 'Ukončiť skúšku',
    resilienceErrorButtonBack: 'Zoznam skúšok',
    percent: ' %',
    itemLoaderTextInit: 'Úloha sa načíta...',
    sessionNonActiveModalTitle: 'Kontrola prebiehajúcich skúšok',
    sessionNonActiveModalMessage:
      'Táto skúška už prebieha v inom okne! Zatvorte toto okno a vráťte sa do pôvodného.',
    sessionNonActiveModalButton: 'Skontrolovať znova',
    sessionActiveModalTitle: 'Kontrola prebiehajúcich skúšok',
    sessionActiveModalMessage:
      'Táto skúška bola práve otvorená v inom okne! Zatvorte všetky ostatné okná so skúškou a potom kliknite na tlačidlo Pokračovať v skúške.',
    sessionActiveModalButton: 'Pokračovať v skúške',
    notepadTitle: 'Vaše poznámky',
    notepadPlaceholder: 'Priestor pre vaše poznámky k úlohám',
    notepadSave: 'Uložiť a zavrieť',
    ChoiceInstructions_MinOrMax_Both_Same_Low_1: 'Zaškrtnite práve ',
    ChoiceInstructions_MinOrMax_Both_Same_Low_2: ' možnosti.',
    ChoiceInstructions_MinOrMax_Both_Same_High_1: 'Zaškrtnite práve ',
    ChoiceInstructions_MinOrMax_Both_Same_High_2: ' možností.',
    ChoiceInstructions_MinOrMax_Both_MaxOne_1:
      'Začiarknite najviac jednu možnosť.',
    ChoiceInstructions_MinOrMax_Both_Different_Low_1: 'Začiarknite ',
    ChoiceInstructions_MinOrMax_Both_Different_Low_2: ' až ',
    ChoiceInstructions_MinOrMax_Both_Different_Low_3: ' možnosti.',
    ChoiceInstructions_MinOrMax_Both_Different_High_1: 'Začiarknite ',
    ChoiceInstructions_MinOrMax_Both_Different_High_2: ' až ',
    ChoiceInstructions_MinOrMax_Both_Different_High_3: ' možností.',
    ChoiceInstructions_MinOrMax_Max_One_1: 'Začiarknite najviac jednu možnosť.',
    ChoiceInstructions_MinOrMax_Max_Low_1: 'Začiarknite najviac ',
    ChoiceInstructions_MinOrMax_Max_Low_2: ' možnosti.',
    ChoiceInstructions_MinOrMax_Max_High_1: 'Zaškrtnite najviac ',
    ChoiceInstructions_MinOrMax_Max_High_2: ' možností.',
    ChoiceInstructions_MinOrMax_Min_One_1: 'Začiarknite aspoň 1 možnosť.',
    ChoiceInstructions_MinOrMax_Min_Low_1: 'Zaškrtnite aspoň ',
    ChoiceInstructions_MinOrMax_Min_Low_2: ' možnosti.',
    ChoiceInstructions_MinOrMax_Min_High_1: 'Zaškrtnite aspoň ',
    ChoiceInstructions_MinOrMax_Min_High_2: ' možností.',
    ChoiceInstructions_NotSet_Low_1: 'Začiarknite 0 až ',
    ChoiceInstructions_NotSet_Low_2: ' možnosti.',
    ChoiceInstructions_NotSet_High_1: 'Začiarknite 0 až ',
    ChoiceInstructions_NotSet_High_2: ' možností.',
    Score: 'Správna odpoveď: ',
    ScoreNegative: 'Nesprávna odpoveď: ',
    ScoreOnePoint: ' bod',
    ScoreTwo2FourPoints: ' body',
    ScoreFive2NPoints: ' bodov',
    ScoreLessThenPoint: ' bodu',
  },
  // eslint-disable-next-line space-before-function-paren
  getResource: function (key) {
    return this.stringsSK[key];
  },
};
