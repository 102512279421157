import React, { useContext, useEffect, useRef, useState } from 'react';
import { EXAM, NOTIF_KEY } from '../../consts';
import { Context } from '../../Proctoring';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../intl';
import Scanner from 'react-webcam';
import mirrorImage from '../../utils/mirrorImage';
import useInterval from '../../utils/useInterval';
import LSM from '../../utils/localStorageManager';
import { camMirror, frameCorner, photoIcon, warning } from '../../icons';
import usePrevious from '../../utils/usePrevious';
import { uploadImageToBlobStorage } from '../../api';

const timerInit = 4;

const FaceCheck = ({ isActive, headline, instructionsText }) => {
  const {
    definitionId,
    examId,
    externalExamId,
    setAllowContinue,
    notifications,
  } = useContext(Context);
  const [isSnapshotMirrored, setIsSnapshotMirrored] = useState(false);
  const [snapshot, setSnapshot] = useState(null);
  const [timer, setTimer] = useState(timerInit);
  const isCountingDown = timer !== 4;
  const idSnapshot = useRef(null);
  const prevIsActive = usePrevious(isActive);
  const isCamOk = !notifications[NOTIF_KEY.CAM];

  // Send ID snapshot
  useEffect(() => {
    if (prevIsActive && !isActive && snapshot) {
      uploadImageToBlobStorage({
        blobName: `${definitionId}/${examId}/${examId}_image_face.png`,
        snapshot,
      });

      setSnapshot(null);
    }
  }, [definitionId, examId, isActive, prevIsActive, snapshot]);

  // Allow continue
  useEffect(() => {
    if (isActive) setAllowContinue(!!snapshot);
  }, [isActive, setAllowContinue, snapshot]);

  // Countdown interval
  useInterval(
    () => {
      if (timer === 0) {
        setSnapshot(idSnapshot.current.getScreenshot());
        setTimer(timerInit);
      } else if (timer !== 0) setTimer(timer - 1);
    },
    1000,
    timer !== timerInit
  );

  const handleMirrorImage = () => {
    if (snapshot) setSnapshot(mirrorImage(snapshot));
    setIsSnapshotMirrored(!isSnapshotMirrored);
  };

  const handleTakingShot = () => {
    if (snapshot) setSnapshot(null);
    setTimeout(() => setTimer(timerInit - 1), 1000);
  };

  return (
    <div className="introCheck faceCheck">
      <div className="beside">
        <div>
          <h2 className="header2Semibold">{headline}</h2>
          <div
            className="textMdNormal"
            dangerouslySetInnerHTML={{
              __html: instructionsText ?? '',
            }}
          ></div>
        </div>
        <div>
          {isActive && (
            <div className="snapshot">
              {isCamOk &&
                (!snapshot || isCountingDown ? (
                  <Scanner
                    className={isSnapshotMirrored ? 'mirror-snapshot' : ''}
                    mirrored={isSnapshotMirrored}
                    audio={false}
                    ref={idSnapshot}
                    screenshotFormat="image/png"
                    minScreenshotWidth={1280}
                    videoConstraints={
                      !!LSM.getExamProperty(externalExamId, EXAM.WEBCAM_ID)
                        ? {
                            deviceId: {
                              exact: LSM.getExamProperty(
                                externalExamId,
                                EXAM.WEBCAM_ID
                              ),
                            },
                          }
                        : {}
                    }
                  />
                ) : (
                  <img alt="" src={snapshot} />
                ))}

              {isCamOk && isCountingDown && (
                <div className="countdown">
                  <span className={timer === 3 ? 'active' : ''}>3</span>
                  <span className={timer === 2 ? 'active' : ''}>2</span>
                  <span className={timer === 1 ? 'active' : ''}>1</span>
                  <span className={timer === 0 ? 'active' : ''}>
                    {photoIcon}
                  </span>
                </div>
              )}

              {isCamOk && !isCountingDown && !snapshot && (
                <div className="frame">
                  <div className="topBounding">
                    <div className="cornerLeftTop">{frameCorner}</div>
                    <div className="text">
                      <FormattedMessage {...messages.zoomIn} />
                    </div>
                    <div className="cornerRightTop">{frameCorner}</div>
                  </div>
                  <div className="bottomBounding">
                    <div className="cornerLeftBottom">{frameCorner}</div>
                    <div className="text">
                      <FormattedMessage {...messages.face} />
                    </div>
                    <div className="cornerRightBottom">{frameCorner}</div>
                  </div>
                </div>
              )}
            </div>
          )}

          <div className="controls">
            {/* Mirror */}
            <button onClick={handleMirrorImage}>{camMirror}</button>

            {/* Take shot */}
            {!isCountingDown && (
              <div>
                <button
                  className={!snapshot ? 'bgActive' : 'bgActiveLight'}
                  onClick={handleTakingShot}
                >
                  <FormattedMessage
                    {...messages[!snapshot ? 'takeShot' : 'takeShotAgain']}
                  />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Instructions */}
      {snapshot && (
        <div className="warning withIcon textMdNormal">
          {warning}
          <FormattedMessage {...messages.continueIfPhotoOk} />
        </div>
      )}
    </div>
  );
};

export default FaceCheck;
