import BaseOperator from "./BaseOperator";

export default class ParenthesisRightOperator extends BaseOperator {

    getOperator() {
        return ')';
    }

    toFullString() {
        return this.getOperator() +" ";
    }
}