import _ from "lodash";
import BaseNumber from "./numbers/BaseNumber";
import ResultNumber from "./numbers/ResultNumber";
import OperatorsFactory from "./OperatorsFactory";
import BaseException from "./exceptions/BaseException";
import Constants from "./Constants";
import { ResourceManager } from '../../resources/ResourceManager';
import build from "../index";
import { evalStr } from "../evaluate";

export default class Calculator {

    _lastException = "";
    processed = false;
    debug = false;
    total = 0;
    history = [];
    unit = Constants.getUnitDefault();

    operatorFactory = new OperatorsFactory();

    controls = {
        "clear": this.clear.bind(this),
        "equals": this._execute.bind(this),
    };

    constructor(initConfig) {
        if (initConfig) {
            this.unit = initConfig.unit || Constants.getUnitDefault();
            this.debug = initConfig.debug || false;
        }
    }

    setUnit(unit) {
        this.unit = unit;
    }

    clear() {
        this.history = [];
        this._lastException = "";
        this.total = 0;
    }

    _handleControl(v) {
        if (this.controls[v]) {
            (this.controls[v])();
            return true;
        }
        return false;
    }

    _execute() {
        // Calculate result
        try {
            let formula = this._getHistory();
            if (formula) {
                const tree = build(formula);

                let result = this._roundNumber(evalStr(tree, 0, this.unit), 10);

                // Add total to queue
                this.history.push(new ResultNumber(result));

                this.processed = true;
                this.total = result;
            }
        } catch (e) {
            // Handle exception e.g. divide by zero
            this._lastException = e instanceof BaseException ? e.getMessage() : e;
        }
    }

    /**
     * Get history
     *
     * @private
     */
    _getHistory() {
        // Normalise operators with inverted string order
        let history = [];
        this.history.forEach((item) => {
            // Add item
            history.push(item.toFullString());

            let index = history.length - 1;
            if (_.isObject(item)) {
                if (item.getStringOrder() < 0 && index > 0) {
                    // Swap with previous item
                    let v = history[index - 1];
                    history[index - 1] = item.toFullString();

                    // Add brackets, if necessary
                    history[index] = v;
                } 
            }
        });

        //getStringNoSpacing
        return history.join('').replace('  ', ' ');
    }

    getResult() {
        return {
            processed: this.processed,
            total: this._lastException ? ResourceManager("calcError") : this.total + "",
            history: this._getHistory(),
            exception: this._lastException,
        };
    }

    _isNumber(item) {
        return /^[0-9.]+/.test(item);
    }

    _roundNumber(num, scale) {
        if (!("" + num).includes(".") && !("" + num).includes("e")) {
            return num; // normalni cele cislo 123456789
        } else {
            if (!("" + num).includes("e")) { // 1234.56789
                return +(Math.round(num + "e+" + scale) + "e-" + scale);

            } else if (!("" + num).includes(".")) { //1233456e-10
                return num;

            } else { // 1.23456789e10
                var arr = ("" + num).split("e");
                var sig = ""
                if (+arr[1] + scale > 0) {
                    sig = "+";
                }
                return +(Math.round(+arr[0] + "e" + scale) / +("1e" + scale)) + "e" + sig + (+arr[1]);
                //return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) / +("1e"+ sig + scale));
            }
        }
    }

    copy() {
    }

    paste() {
        navigator.clipboard.readText().then(
            clipText => {
                if (this._isNumber(clipText)) {
                    document.querySelector(".component-display-history").innerText += clipText;
                    this.calculate(clipText);
                }
            }
        );
    }

    calculate(buttonName) {
        // Reset process, if new calculation starts
        if (this.processed) {
            this.processed = false;
            this.history = [];
            if (this.total !== 0) { // Compute with last result
                this.history.push(new BaseNumber(this.total));
            }
        }
        this._lastException = "";

        try {
            if (buttonName === "undo") {
                if (this.history.length > 0) {
                    var value = this.history[this.history.length - 1].strValue;
                    if (this._isNumber(value)) {
                        var newUndoValue = value.substr(0, value.length - 1);
                        if (newUndoValue.length > 0) {
                            this.history[this.history.length - 1] = new BaseNumber(Number(newUndoValue), newUndoValue);
                        } else {
                            this.history.pop();
                        }
                    } else {
                        this.history.pop();
                    }
                } else {
                    this.history.pop();
                }

                
            } else if (this.operatorFactory.isOperator(buttonName)) {
                // Process operator
                this.history.push(this.operatorFactory.getOperator(buttonName, this.unit));

            } else if (this._handleControl(buttonName)) {
                // Processed clear or reset
            } else if (this._isNumber(buttonName)) {
                if (this.history.length > 0) {
                    var previousValue = this.history[this.history.length - 1].strValue;
                    if (this._isNumber(previousValue)) {
                        var newValue = previousValue + buttonName;
                        this.history[this.history.length - 1] = new BaseNumber(Number(newValue), newValue);
                    } else {
                        this.history.push(new BaseNumber(Number(buttonName), buttonName));
                    }
                } else {
                    this.history.push(new BaseNumber(Number(buttonName), buttonName));
                }
                
                
            }
        } catch (e) {
            // Handle exception e.g. divide by zero
            this._lastException = e instanceof BaseException ? e.getMessage() : e.message;
        }
        // Return result
        return this.getResult();
    }
}