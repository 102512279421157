import React, { useEffect, useRef, useState } from 'react';
import Keyboard from 'react-simple-keyboard';
import { KEYBOARD } from '../consts/KEYBOARD';
import { ResourceManager } from '../resources/ResourceManager';

import 'react-simple-keyboard/build/css/index.css';

const SimpleKeyboard = (props) => {
  const [layoutName, setLayoutName] = useState('DEFAULT');
  const keyboard = useRef();

  useEffect(() => {
    keyboard.current.setInput(props.inputValue);
  }, [props.inputValue]);

  const onChange = (key) => {
    props.setInputState((prevState) => ({
      ...prevState,
      value: key,
    }));
    keyboard.current.setInput(key);
  };

  const handleCaps = () => {
    const newLayoutName = layoutName === 'DEFAULT' ? 'SHIFT' : 'DEFAULT';
    setLayoutName(newLayoutName);
  };

  const onKeyPress = (button) => {
    if (button === '{lock}' || button === '{shift}') handleCaps();
  };

  return (
    <div className="keyboard">
      <div className="keyboard-instructions">
        {ResourceManager('keyboardInstruction')}
      </div>
      <Keyboard
        keyboardRef={(r) => (keyboard.current = r)}
        layout={KEYBOARD.LAYOUT}
        layoutName={layoutName}
        display={KEYBOARD.DISPLAY}
        mergeDisplay={true}
        onChange={onChange}
        onKeyPress={onKeyPress}
        newLineOnEnter={true}
        physicalKeyboardHighlight={true}
        physicalKeyboardHighlightBgColor={'#d0d5dd'} // $gray-300
        preventMouseDownDefault={true}
        layoutCandidates={
          layoutName === 'DEFAULT'
            ? KEYBOARD.LAYOUT_CANDIDATES.LOWERCASE
            : KEYBOARD.LAYOUT_CANDIDATES.UPPERCASE
        }
      />
    </div>
  );
};

export default SimpleKeyboard;
