import { Tooltip } from '@mui/material';
import { OpenInFull, CloseFullscreen } from '@mui/icons-material';
import * as Sentry from '@sentry/browser';
import { equals, pluck, uniq, values } from 'ramda';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  CHECK,
  EXAM_WAS_OPENED,
  EXAM,
  EXAM_WAS_FINISHED,
  NOTIF_KEY,
} from '../consts';
import { messages } from '../intl';
import { Context } from '../Proctoring';
import draggable from '../utils/draggable';
import LSM from '../utils/localStorageManager';
import useInterval from '../utils/useInterval';
import { useSnackbar } from 'notistack';

const getViolationTime = () => {
  const date = new Date();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return ('0' + hours).slice(-2) + ':' + ('0' + minutes).slice(-2);
};

const getViolations = (aiFeedback, violations) => {
  const feedback =
    typeof aiFeedback[0] === 'string' ? aiFeedback[0] : aiFeedback[0][0];

  if (feedback !== '' && feedback !== 'OK') {
    return uniq([...violations, { time: getViolationTime(), text: feedback }]);
  } else {
    return violations;
  }
};

const ExamWatcher = () => {
  const cam = useRef();
  const [violations, setViolations] = useState([]);
  const [showProcessAnalysisError, setShowProcessAnalysisError] =
    useState(false);
  const [countdown, setCountdown] = useState(0);
  const [playbackDisplay, setPlaybackDisplay] = useState(true);
  const {
    aiFeedbackApp,
    aiFeedbackCam,
    aiFeedbackScreen,
    camStream,
    checks,
    definitionId,
    examId,
    notifications,
    externalExamId,
    isProcessAnalysis,
    monitorAppLink,
    setIsRec,
    setProcessAnalysisCheck,
    socket,
    touchRecorder,
    tryReconnectSocket,
    showDesktopNotification,
    setNotification,
  } = useContext(Context);
  const showOkMessage = countdown === 0;
  const { closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (pluck('runCheck', checks).includes(CHECK.PROCESS_APP)) {
      setShowProcessAnalysisError(true);
    }
  }, [checks]);

  useEffect(() => {
    setProcessAnalysisCheck(Date.now());
  }, [setProcessAnalysisCheck]);

  useEffect(() => {
    if (!LSM.getExamProperty(externalExamId, EXAM_WAS_FINISHED)) {
      // The exam was not yet finished and refresh happened, begin recording again
      setIsRec(true);
    }
  }, [externalExamId, setIsRec]);

  // Init playback
  useEffect(() => {
    if (camStream) {
      cam.current.srcObject = camStream;
      cam.current.controls = false;
    }
  }, [camStream]);

  // Start during_exam - once only
  useEffect(() => {
    setNotification({ [NOTIF_KEY.CHECKS_TIMER]: null });
    closeSnackbar(NOTIF_KEY.CHECKS_TIMER);
    LSM.setExamProperty(externalExamId, EXAM_WAS_OPENED, 'true');
    setTimeout(() => {
      socket.emit('checkpointStarted', {
        examId,
        definitionId,
        checkpointType: CHECK.EXAM,
      });
    }, 5000);
    draggable(document.getElementById('exam-watcher'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Process AI feedback
  useEffect(() => {
    const updatedViolations = getViolations(aiFeedbackCam, violations);

    if (!equals(updatedViolations, violations))
      setViolations(updatedViolations);
  }, [aiFeedbackCam, violations]);

  useEffect(() => {
    const updatedViolations = getViolations(aiFeedbackScreen, violations);

    if (!equals(updatedViolations, violations))
      setViolations(updatedViolations);
  }, [aiFeedbackScreen, violations]);

  useEffect(() => {
    const updatedViolations = getViolations(aiFeedbackApp, violations);

    if (!equals(updatedViolations, violations))
      setViolations(updatedViolations);
  }, [aiFeedbackApp, violations]);

  // Reset AI message if any new violation
  useEffect(() => {
    setCountdown(30);
  }, [aiFeedbackCam, aiFeedbackScreen, aiFeedbackApp]);

  useEffect(() => {
    touchRecorder({ event: 'start' });
  }, [touchRecorder]);

  // Adjusts the mouse-scroll speed
  useEffect(() => {
    const violationList = document.getElementById('violation-list');

    const handleScroll = (e) => {
      e.preventDefault();

      const scrollY = 0.2 * e.deltaY;
      violationList.scrollTop += scrollY;
    };

    violationList?.addEventListener('mousewheel', handleScroll, false);

    return () => {
      violationList?.removeEventListener('mousewheel', handleScroll);
    };
  }, []);

  // Show All OK message after 30 secs. if no new violation
  useInterval(
    () => {
      setCountdown(countdown - 1);
    },
    1000,
    !showOkMessage
  );

  useEffect(() => {
    Sentry.setTag('currentUiSection', 'Exam');
  }, []);

  const noInternet = notifications[NOTIF_KEY.NO_INTERNET];

  return (
    <div
      id="exam-watcher"
      className={noInternet || violations.length > 0 ? 'roomStateError' : ''}
    >
      {values(notifications)
        .filter((notification) => notification?.intl)
        .map(({ intl, variant }) => (
          <div className={variant} key={intl}>
            <FormattedMessage {...messages[intl]} />
          </div>
        ))}

      {/* Display checkProcess notification only if the socket connection works, otherwise, the user lost connection to the internet. */}
      {/* The notification is enabled only based on the exam definition because SCIO's external app refreshed our app. This caused the notification to be shown between parts of the exam (oddíly). */}
      {showDesktopNotification &&
        showProcessAnalysisError &&
        !isProcessAnalysis &&
        !tryReconnectSocket && (
          <div className="error">
            <FormattedMessage {...messages.runProcApp} />
            <span
              className="textButton"
              onClick={() => {
                window.open(monitorAppLink);
                setProcessAnalysisCheck(Date.now() + 5000);
              }}
            >
              <FormattedMessage {...messages.runApp} />
            </span>
          </div>
        )}
      <video
        className={!playbackDisplay ? 'hidePlayback' : ''}
        ref={cam}
        id={`exam-watcher-${EXAM.CAPTURED_CHUNK_CAM}`}
        muted
        autoPlay
      />
      <div className="header">
        <FormattedMessage {...messages.roomState} />
        <div>
          <Tooltip
            key={playbackDisplay + 'resetTooltipPlayback'}
            title={
              playbackDisplay ? (
                <FormattedMessage {...messages.playbackHide} />
              ) : (
                <FormattedMessage {...messages.playbackDisplay} />
              )
            }
          >
            <span
              tabIndex={0}
              role="button"
              className="playbackDisplayBtn"
              onClick={() => setPlaybackDisplay(!playbackDisplay)}
            >
              {playbackDisplay ? <CloseFullscreen /> : <OpenInFull />}
            </span>
          </Tooltip>
        </div>
      </div>
      <div
        id="violation-list"
        className={`violation-list scrollbar ${
          showOkMessage ? 'showOkMessage' : ''
        }`}
      >
        {(showOkMessage ? [] : [...violations].reverse()).map(
          (violation, i) => (
            <p key={`violation-${i}`}>
              <span>{violation.time}</span>
              <span>
                <strong>{violation.text}</strong>
              </span>
            </p>
          )
        )}
      </div>
    </div>
  );
};

export default ExamWatcher;
