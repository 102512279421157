import React, { useContext } from 'react';
import { Context } from '../Proctoring';
import { checkPassedIcon } from '../icons';

const labelSubchecks = (checks) => {
  let labelCount = 0;
  let subcheckIndex = 0;
  let isCheckFirstWithLabel = true;

  const labelFrequencies = {};

  for (const [index, check] of Object.entries(checks)) {
    if (check.label !== checks[index - 1]?.label) {
      labelCount++;
      subcheckIndex = 0;
      isCheckFirstWithLabel = true;
    } else {
      subcheckIndex++;
      isCheckFirstWithLabel = false;
    }
    checks[index] = {
      ...check,
      label: labelCount,
      subcheckIndex,
      isCheckFirstWithLabel,
    };

    labelFrequencies[check.label] = (labelFrequencies[check.label] ?? 0) + 1;
  }

  for (const check of checks) {
    check.checksWithSameLabel = labelFrequencies[check.label];
  }

  return checks;
};

const ProgressNav = () => {
  const { activeCheckId, checks, section } = useContext(Context);

  // Includes only the first check with the respective label, checks with the same label are then displayed as 'subchecks' by moving the indicator line
  const labeledChecks = labelSubchecks(checks).filter(
    (check) => check.isCheckFirstWithLabel
  );

  return (
    <ul className="progressNav">
      {section &&
        labeledChecks.map((check, i) => {
          const done = checks[activeCheckId].label > check.label;
          const current = checks[activeCheckId].label === check.label;
          const ariaCurrent = current ? { 'aria-current': 'step' } : {};

          return (
            <li
              {...ariaCurrent}
              key={`check-${i}`}
              className={`check ${done ? 'passed' : current ? 'current' : ''}`}
            >
              <div className="indicator">
                {/* Display subcheck indicator only if the currently displayed tab corresponds to one of those subchecks */}
                {check.checksWithSameLabel > 1 &&
                  checks[activeCheckId].label === check.label && (
                    <div
                      className="subcheck-indicator"
                      style={{
                        width: `${
                          (checks[activeCheckId].subcheckIndex /
                            checks[activeCheckId].checksWithSameLabel) *
                          100
                        }%`,
                      }}
                    />
                  )}
              </div>
              <span className="label">{checkPassedIcon}</span>
              <span className="name">{check.name}</span>
            </li>
          );
        })}
    </ul>
  );
};

export default ProgressNav;
