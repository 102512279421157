import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { okIcon, warning } from '../../icons';
import { messages } from '../../intl';
import { Context } from '../../Proctoring';

const Outro = ({ isActive }) => {
  const { setAllowContinue } = useContext(Context);

  useEffect(() => {
    if (isActive) setTimeout(() => setAllowContinue(true), 500);
  }, [isActive, setAllowContinue]);

  return (
    <div className="introCheck outro">
      {okIcon}
      <br />
      <div className="textLgMedium">
        <FormattedMessage {...messages.everythingIsSet} />
      </div>
      <div className="warning withIcon textSmNormal">
        {warning}
        <FormattedMessage {...messages.initialChecksCompleted} />
      </div>
    </div>
  );
};

Outro.propTypes = { isActive: PropTypes.bool };

export default Outro;
