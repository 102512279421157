import React, { Component } from 'react';
import RawHtml from './RawHtml';
import { ResourceManager } from '../resources/ResourceManager';

export default class SharedItem extends Component {
  render() {
    let indexText;
    if (this.props.firstItemIndex === this.props.lastItemIndex) {
      indexText = `${ResourceManager('sharedItemIndexOne')} ${
        this.props.firstItemIndex
      }`;
    } else {
      indexText = `${ResourceManager('sharedItemIndexN')} ${
        this.props.firstItemIndex
      }–${this.props.lastItemIndex}`;
    }

    return (
      <div className="item shared-item">
        <div className="item-number">
          {ResourceManager('sharedItem')} {indexText}
        </div>
        <RawHtml content={this.props.renderedItem.renderedOutput} />
      </div>
    );
  }
}
