import React, { Component } from 'react';
import ReactTooltip from 'react-tooltip';
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack';
import { ResourceManager } from '../resources/ResourceManager';
import TimerHelper from '../utils/TimerHelper';
import base64Image from '../utils/images';

export default class TimerTest extends Component {
  constructor() {
    super();
    this.state = {
      remainingTime: 0,
      show: true,
      snackbarFlag: false,
      screenReadersAlert: false,
      screenReadersTimeFlag: 0,
      timerCheckpoints: [60, 120, 300, 600, 900], // 1, 2, 5, 10, 15 minutes
      nextCheckpoint: 0,
    };
    this.timer = new TimerHelper();
  }

  componentDidMount() {
    const totalSeconds = this.props.timeLimit;
    this.setStateRemainingSeconds(totalSeconds);

    if (this.props.startTimer === true) {
      this.timer.startTimer(
        totalSeconds,
        this.setStateRemainingSeconds,
        this.props.finish,
        'timer'
      );
      const updatedTimerCheckpoints = this.state.timerCheckpoints.filter(
        (time) => time < totalSeconds
      );
      const nextCheckpoint =
        updatedTimerCheckpoints[updatedTimerCheckpoints.length - 1];
      this.setState({
        timerCheckpoints: updatedTimerCheckpoints,
        nextCheckpoint: nextCheckpoint,
      });
    }
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();

    const handleTimeAlerts = (rest = {}) => {
      this.setState((prevState) => ({
        timerCheckpoints: prevState.timerCheckpoints.slice(0, -1),
        screenReadersAlert: true,
        screenReadersTimeFlag: this.state.remainingTime,
        ...rest,
      }));
    };

    /*
      - it's NOT welcome page
      - time to end test match nextCheckpoint
    */
    if (
      !this.props.isWelcomePage &&
      this.state.remainingTime === this.state.nextCheckpoint
    ) {
      /*
        - time to end test in seconds = 10 minutes
        - snackbar hasn't been fired
      */
      if (this.state.remainingTime === 600 && !this.state.snackbarFlag) {
        enqueueSnackbar(ResourceManager('timer_snackbar'), {
          autoHideDuration: 20000, // hide the snackbar after 20 seconds
          action: () => (
            <button
              className="timer-snackbar-close"
              onClick={() => closeSnackbar()}
            >
              <img src={base64Image('x-close')} alt="" />
            </button>
          ),
          className: 'timer-snackbar-custom',
        });
        if (!this.state.show) this.setState({ show: true });
        handleTimeAlerts({ snackbarFlag: true }); // prevent to run twice
        // any other than 10 minute mark
      } else {
        if (!this.state.screenReadersAlert) {
          handleTimeAlerts();
        }
      }
    }

    // if screenReadersAlert is shown and is active for less than 10 seconds
    if (
      this.state.screenReadersAlert &&
      this.state.screenReadersTimeFlag - this.state.remainingTime > 9
    ) {
      this.setState({
        screenReadersAlert: false,
        screenReadersTimeFlag: 0,
        nextCheckpoint:
          this.state.timerCheckpoints[this.state.timerCheckpoints.length - 1],
      });
    }
  }

  componentWillUnmount() {
    this.timer.clearTimer();
  }

  setStateRemainingSeconds = (remainingTime) => {
    this.setState({ remainingTime });
  };

  toggleShowTime = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  screenReadersComponent = () => {
    const remainingMinutes = this.state.screenReadersTimeFlag / 60;

    return (
      <div id="screen-readers-alert" role="alert" aria-live="assertive">
        {`${ResourceManager(
          'timerScreenReaders'
        )} ${remainingMinutes} ${ResourceManager(
          'timer_minutes',
          remainingMinutes
        )}`}
      </div>
    );
  };

  render() {
    const showTime = this.state.show;
    const timerMessage =
      this.props.isFinalSession === true
        ? ResourceManager('timer_estimatedTimeTest')
        : ResourceManager('timer_estimatedTimePart');

    return (
      <>
        <div className="counter">
          <img src={base64Image('timer')} alt="" />
          <span className="timer-message">{timerMessage}</span>
          <span className="timer-time">
            {showTime ? this.timer.formatTime(this.state.remainingTime) : null}
            <span
              className={showTime ? 'hide-time' : 'show-time'}
              onClick={this.toggleShowTime}
              data-for="timertooltip"
              data-tip={
                showTime
                  ? ResourceManager('timer_hideTime_tooltip')
                  : ResourceManager('timer_showTime_tooltip')
              }
              tabIndex="0"
              role="button"
              aria-label={ResourceManager('timer_hideTime_arialabel')}
            >
              {showTime ? null : ResourceManager('timer_showTime')}
              <img
                src={showTime ? base64Image('eye-off') : base64Image('eye-on')}
                alt=""
              />
            </span>
          </span>
          <ReactTooltip
            id="timertooltip"
            effect="solid"
            getContent={[
              () =>
                showTime
                  ? ResourceManager('timer_hideTime_tooltip')
                  : ResourceManager('timer_showTime_tooltip'),
            ]}
          />
        </div>
        <div id="timer-snackbar">
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          />
        </div>
        {this.state.screenReadersAlert ? this.screenReadersComponent() : null}
      </>
    );
  }
}
