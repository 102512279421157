import BaseOperator from "./BaseOperator";

export default class TanOperator extends BaseOperator {

    getOperator() {
        return 'tan';
    }

    toFullString() {
        return this.getOperator() + " ";
    }
}