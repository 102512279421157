export const ResourceManager = (key, number) => {
  const language = sessionStorage.getItem('lang');
  let resource;
  switch (language) {
    case 'CZ':
      resource = require('./Strings-CZ');
      break;
    case 'SK':
      resource = require('./Strings-SK');
      break;
    case 'EN':
      resource = require('./Strings-EN');
      break;
    case 'UA':
      resource = require('./Strings-UA');
      break;
    default:
      sessionStorage.setItem('lang', 'CZ');
      resource = require('./Strings-CZ');
  }

  let str = resource.getResource(key);
  if (str == null) {
    str = `key nenalezen (${key})`;
  }

  if (Array.isArray(str)) {
    if (number === 1) {
      return str[0];
    }
    if (number === 2 || number === 3 || number === 4) {
      return str[1];
    }

    return str[2];
  }

  return str;
};
