import React, { createContext, useEffect, useState } from 'react';
import ItemGroup from './ItemGroup';

export const QuestionContext = createContext();

const KeyboardProvider = ({ children }) => {
  const [keyboardId, setKeyboardId] = useState('');
  const [textareaMap, setTextareaMap] = useState({});

  return (
    <QuestionContext.Provider
      value={{ keyboardId, setKeyboardId, textareaMap, setTextareaMap }}
    >
      {children}
    </QuestionContext.Provider>
  );
};

const Page = (props) => {
  const [isImplicitInstructionOpen, setIsImplicitInstructionOpen] = useState(
    new Array(props.test.items.length).fill(true)
  );
  const currentPage = props.test.pages[props.currentPage];

  const setInstructionState = (index, value) => {
    const updatedArray = [...isImplicitInstructionOpen];
    updatedArray[index] = value;
    setIsImplicitInstructionOpen(updatedArray);
  };

  useEffect(() => {
    const mainElement = document.getElementById('TestApp');
    if (mainElement) {
      mainElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
    window.ItemManager.InitItemsForInteractivity(
      props.db,
      props.token,
      props.tokenObject.AnswersId,
      props.items,
      props.connectionId,
      process.env.REACT_APP_TESTCORE_API_URL
    );
    if (window.ItemManager.UpdateNavigationItems) {
      window.ItemManager.UpdateNavigationItems(props.tokenObject.AnswersId);
    }
  }, [
    currentPage,
    props.db,
    props.token,
    props.tokenObject.AnswersId,
    props.items,
    props.connectionId,
  ]);

  const itemGroups = currentPage.itemGroups.map((itemGroup, index) => (
    <ItemGroup
      key={`page${props.currentPage}_itemGroup${index}`}
      currentItem={props.currentItem}
      answersId={props.tokenObject.AnswersId}
      itemGroup={itemGroup}
      groupIdex={index}
      items={props.items}
      testModeSettings={props.testModeSettings}
      pageLayout={currentPage.pageLayout}
      pageIndex={props.currentPage}
      isImplicitInstructionOpen={isImplicitInstructionOpen}
      setInstructionState={setInstructionState}
      hideDistractorKeys={props.tokenObject.ExamSettings.HideDistractorKeys}
      showItemScore={props.tokenObject.ExamSettings.ShowItemScore}
      showItemNegativeScore={
        props.tokenObject.ExamSettings.ShowItemNegativeScore
      }
      showItemTheme={props.tokenObject.ExamSettings.ShowItemTheme}
    />
  ));

  return (
    <main className={`test-page container layout-${currentPage.pageLayout}`}>
      <KeyboardProvider>{itemGroups}</KeyboardProvider>
    </main>
  );
};

export default Page;
