import BaseOperator from "./BaseOperator";

export default class ReciprocalOperator extends BaseOperator {

    getOperator() {
        return '1 / ';
    }
    
    getStringOrder() {
        return -1;
    }

    toFullString() {
        return this.getOperator();
    }
}