import BaseOperator from "./BaseOperator";

export default class SinOperator extends BaseOperator {

    getOperator() {
        return 'sin';
    }

    toFullString() {
        return this.getOperator() + " ";
    }
}