module.exports = {
  stringsEN: {
    errHandlerPageTitle: 'Something went wrong...',
    errHandlerTitle: 'An error occurred',
    errHandlerDesc:
      'Try again, please. If the error occurs again, return to the Exam list and click on the Continue button.',
    errTokenMissing: 'Authorisation token missing!',
    errFetchToken: 'Unable to read token.',
    errInitIndexedDb: 'Error initializing browser database.',
    errUnknown: 'Unspecified server error.',
    errUnableToLoadTest: 'Unable to load test! ',
    errUnableToStartTest: 'Unable to start test! ',
    errUnableToFinishTest: 'Unable to finish test! ',
    errInvalidToken: 'Invalid token',
    errInit: 'Initialization error.',
    errReturnLink: 'Exam List',
    errResilienceNoItems:
      'There are no answers to upload from this device. Make sure you are on the device and in a browser where you took this test. If this is the case, you can click the Finish exam button. If you are on a different device or in a different web browser, click the Exams overview button and log into ScioLink from the correct device.',
    errRetryAdvice: 'Try again',
    err404: '404',
    err404Title: 'The page does not exist',
    err404Desc:
      'The page you are looking for does not seem to exist, please go to the home page.',
    err404Btn: 'Go to the home page',
    loadingData: 'loading data...',
    savingData: 'loading data...',
    testContinuesDownwards: 'The test continues downwards',
    testTotalQuestions: 'Number of questions:',
    testTimeLimit: 'Time limit:',
    testRemainingTime: 'Remaining time:',
    testNoTimelimit: 'No time limit.',
    btnTestStart: 'Start test',
    btnPartStart: 'Start section',
    btnTryAgain: 'Try again',
    btnReturn: 'Exam list',
    btnNavigationPrevious: 'Previous',
    btnNavigationNext: 'Next',
    btnNavigationFinishTest: 'Finish test',
    btnNavigationFinishPart: 'Finish section',
    itemQuestion: 'Question',
    itemQuestions: 'questions',
    sharedItem: 'Common assignment',
    sharedItemIndexOne: 'for question',
    sharedItemIndexN: 'for questions',
    implicitInstruction: 'Instructions',
    implicitInstructionIndexOne: 'for question',
    implicitInstructionIndexN: 'for questions',
    insert: 'Vložit',
    instructionCollapse: 'Collapse instruction',
    instructionExpand: 'Expand instruction',
    downloadContentTitle: 'Aid tools',
    calculatorTitle: 'Calculator',
    cancel: 'Cancel',
    timer_estimatedTimeTest: 'Remaining time: ',
    timer_estimatedTimePart: 'Remaining time: ',
    timerTimeoutAlertTitle: 'Time is up',
    timerTimeoutAlertTextTest: 'Time is up. The test will be closed.',
    timerTimeoutAlertTextPart: 'Time is up. The test section will be closed.',
    timerTimeoutAlertButton: 'OK',
    timerTimeoutConfirmTitleTest: 'Are you sure you want to quit?',
    timerTimeoutConfirmTitlePart: 'Are you sure you want to quit?',
    timerTimeoutConfirmTextTest:
      '<b>Closing the test </b> is irreversible. After closing you will not be able to return to the questions.',
    timerTimeoutConfirmTextPart:
      '<b> Closing the test section </b> is irreversible. After closing you will not be able to return to the questions. ',
    timerTimeoutConfirmButtonConfirm: 'Yes, close',
    timerTimeoutConfirmButtonCancel: 'No, return back',
    timer_hideTime_arialabel: 'Hide time',
    timer_hideTime_tooltip:
      'Hide time. Time will show up automatically 10 minutes before the end.',
    timer_showTime_tooltip: 'Show remaining time.',
    timer_showTime: 'Show time',
    timer_minutes: ['minute', 'minutes', 'minutes'],
    timer_snackbar: 'Last 10 minutes remaining',
    timerScreenReaders: `Remaining time:`,
    highlighterSnackbar: 'Turn off the highlighter',
    instructionsTitle: 'Exam instructions',
    instructionsLegend: 'Legend',
    instructionTitleItem: 'Question',
    instructionTitleItems: 'Questions',
    instructionTimerInfo: 'Remaining time to read the assignment: ',
    legendUreaded: 'Unread question',
    legendAnswered: 'Answered question',
    legendActive: 'Active question currently displayed',
    legendFlagged: 'Flagged question',
    calcDegrees: 'degrees',
    calcRadians: 'radians',
    calcError: 'Invalid operation',
    calcDivideByZero: 'Dividing by zero',
    calcQqrtNegative:
      'The square root of a negative number is not defined among Real Numbers',
    calcMissingOperand: 'Part of the formula is missing',
    calcInvalidToken: 'Invalid formula',
    calcUnknownError: 'Unknown error',
    flagTitle: 'Flag question',
    flagTitleRemove: 'Remove flag',
    keyboardTitle: 'Auxiliary keyboard',
    keyboardInstruction:
      'A menu with special characters appears when you press the corresponding letter, e.g. for é, press e.',
    showSupportMaterials: 'Show aid tool',
    hideSupportMaterials: 'Hide aid tool',
    sidebarCalculator: 'Calculator',
    sidebarSupportMaterials: 'Materials',
    sidebarNotepad: 'Notepad',
    sidebarHighlighter: 'Highlighter',
    sidebarSupport: 'Support',
    supportCenter: 'Customer service',
    supportCenterText:
      'Customer Service Center. Monday to Thursday between 9am and 5pm, Friday between 9am and 4pm. On the day of the National Comparative Exams, we are always available during the whole examination period.',
    supportCenterEmailTitle: 'E-mail',
    supportCenterEmailSubtitle: 'We are here to help you',
    supportCenterEmail: 'scio@scio.cz',
    supportCenterPhoneTitle: 'Phone',
    supportCenterPhoneSubtitle: 'Do not hesitate to contact us',
    supportCenterPhone: '+420 234 705 555',
    tooltipInstructions: 'Exam instructions',
    tooltipSupportMaterials: 'Aid tools available for the test',
    tooltipCalculator: 'You are allowed to use built-in calculator',
    tooltipHighlighter: 'Turn on the highlighter',
    tooltipHighlighterOn: 'Turn off the highlighter',
    tooltipNotepad: 'Notepad',
    tooltipDisabled: 'The next page is loading',
    proctoringTitle: 'Settings and data check',
    playMedia: 'Play',
    playingMediaNewPageConfirmTitle: 'Warning during playback',
    playingMediaNewPageConfirmMessage:
      'By continuing to another page you will lose one media playback. Are you sure you want to continue to another page?',
    playingMediaNewPageConfirmMessageAutoreplay:
      'By continuing to another page you will lose all media playbacks. Are you sure you want to continue to another page?',
    playingMediaNewPageButtonConfirm: 'Agree',
    playingMediaNewPageButtonCancel: 'Cancel',
    maximumAttemptsTitle: 'Playback warning',
    maximumAttemptsMessage: 'Playback limit has been reached.',
    maximumAttemptsButton: 'Ok',
    mediaMessage1: 'This item has a limited number of playbacks. ',
    mediaMessage21: 'You can replay it ',
    mediaMessage22: ' times in total. ',
    mediaMessage23: 'It will be played ',
    mediaMessage24: ' times in total. ',
    mediaMessage3: 'You cannot pause listening after starting. ',
    mediaMessage4: 'Repeat play starts automatically. ',
    mediaMessage51: 'You have ',
    mediaMessage52: ' playbacks left.',
    mediaMessage6: ' No playback left.',
    resilienceModeTitle: 'Answers recovery',
    resilienceModeTitleError: 'Finish exam',
    resilienceConfirmText:
      'Answers saved during the test will be uploaded to our servers.',
    resilienceConfirmButton: 'Upload answers',
    resilienceErrorButtonFinish: 'Finish exam',
    resilienceErrorButtonBack: 'Exams overview',
    percent: '%',
    itemLoaderTextInit: 'Question is loading...',
    sessionNonActiveModalTitle: 'Checking ongoing exams',
    sessionNonActiveModalMessage:
      'This test is already running in another window! Close this window and return to the original window.',
    sessionNonActiveModalButton: 'Check again',
    sessionActiveModalTitle: 'Checking ongoing exams',
    sessionActiveModalMessage:
      'This exam has just been opened in another window! Close all other test windows, then click Continue Exam.',
    sessionActiveModalButton: 'Continue Exam',
    notepadTitle: 'Your Notes',
    notepadPlaceholder: 'Space for your notes',
    notepadSave: 'Save and Close',
    ChoiceInstructions_MinOrMax_Both_Same_Low_1: 'Check exactly ',
    ChoiceInstructions_MinOrMax_Both_Same_Low_2: ' options.',
    ChoiceInstructions_MinOrMax_Both_Same_High_1: 'Check exactly ',
    ChoiceInstructions_MinOrMax_Both_Same_High_2: ' options.',
    ChoiceInstructions_MinOrMax_Both_MaxOne_1: 'Check at most one option.',
    ChoiceInstructions_MinOrMax_Both_Different_Low_1: 'Check from ',
    ChoiceInstructions_MinOrMax_Both_Different_Low_2: ' to ',
    ChoiceInstructions_MinOrMax_Both_Different_Low_3: ' options.',
    ChoiceInstructions_MinOrMax_Both_Different_High_1: 'Check from ',
    ChoiceInstructions_MinOrMax_Both_Different_High_2: ' to ',
    ChoiceInstructions_MinOrMax_Both_Different_High_3: ' options.',
    ChoiceInstructions_MinOrMax_Max_One_1: 'Check at most one option.',
    ChoiceInstructions_MinOrMax_Max_Low_1: 'Check no more than ',
    ChoiceInstructions_MinOrMax_Max_Low_2: ' options.',
    ChoiceInstructions_MinOrMax_Max_High_1: 'Check no more than ',
    ChoiceInstructions_MinOrMax_Max_High_2: ' options.',
    ChoiceInstructions_MinOrMax_Min_One_1: 'Check at least 1 option.',
    ChoiceInstructions_MinOrMax_Min_Low_1: 'Check at least ',
    ChoiceInstructions_MinOrMax_Min_Low_2: ' options.',
    ChoiceInstructions_MinOrMax_Min_High_1: 'Check at least ',
    ChoiceInstructions_MinOrMax_Min_High_2: ' options.',
    ChoiceInstructions_NotSet_Low_1: 'Check 0 to ',
    ChoiceInstructions_NotSet_Low_2: ' options.',
    ChoiceInstructions_NotSet_High_1: 'Check 0 to ',
    ChoiceInstructions_NotSet_High_2: ' options.',
    Score: 'Correct answer: ',
    ScoreNegative: 'Incorrect answer: ',
    ScoreOnePoint: ' point',
    ScoreTwo2FourPoints: ' points',
    ScoreFive2NPoints: ' points',
    ScoreLessThenPoint: ' point',
  },
  // eslint-disable-next-line space-before-function-paren
  getResource: function (key) {
    return this.stringsEN[key];
  },
};
