import React, { useContext, useEffect } from 'react';
import { Context } from '../../Proctoring';
import { messages } from '../../intl';
import { FormattedMessage } from 'react-intl';

const RoomCheckIntro = ({ isActive, instructionsText }) => {
  const { setAllowContinue } = useContext(Context);

  useEffect(() => {
    if (isActive) setTimeout(() => setAllowContinue(true), 500);
  }, [isActive, setAllowContinue]);

  return (
    <div className="introCheck roomCheckIntro">
      <h2 className="header2Semibold">
        <FormattedMessage {...messages.howRecordRoom} />
      </h2>

      <div className="textMdNormal width682">
        <FormattedMessage {...messages.introRoomDesc} />
      </div>
      <br />
      <br />

      <img alt="" src={`/proctoring/roomCheck.png`} />
    </div>
  );
};

export default RoomCheckIntro;
