import React, { Component } from 'react';
import { ResourceManager } from '../resources/ResourceManager';
import InitializedTestApp from './InitializedTestApp';
import GetTestResponseStream from './GetTestResponseStream';
import { ErrorHandler, handlerErrorTC } from './ErrorHandler';
import Loader from './Loader';
import decryptResponse from '../utils/cryptoUtils';
import log from '../utils/logging/DefaultLogger';

export default class TestApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testLoaded: false,
      error: false,
      errorMessage: '',
      reloadLink: '',
      test: {},
      testDefinitionId: this.props.tokenObject.TestDefinitionId,
      testInstance: {},
    };
  }

  componentDidMount() {
    this.initTestApp(true);
  }

  componentDidUpdate() {
    this.initTestApp(false);
  }

  async fetchTestData() {
    const headers = { Authorization: this.props.token };

    const [testResponse, testInstanceResponse] = await window.Promise.all([
      window.fetch(`${process.env.REACT_APP_TESTCORE_API_URL}/Api/Test`, {
        headers,
      }),
      window.fetch(
        `${process.env.REACT_APP_TESTCORE_API_URL}/Api/TestInstance`,
        { headers }
      ),
    ]);

    if (!testResponse.ok) {
      throw testResponse;
    }
    if (!testInstanceResponse.ok) {
      throw testInstanceResponse;
    }

    const data = await window.Promise.all([
      testResponse.json(),
      testInstanceResponse.json(),
    ]);

    const testData = data[0];
    let test;
    // test straight from database
    if (testData.definitionUrl) {
      const testResponseStream = await GetTestResponseStream(
        testData.definitionUrl
      );
      // encrypted test
      if (testData.aesKey && testData.aesNonce) {
        const decryptedResponse = await decryptResponse(
          testResponseStream,
          testData.aesKey,
          testData.aesNonce
        );
        test = JSON.parse(decryptedResponse);
      } else {
        test = await testResponseStream.json();
      }
    } else if (testData.definitionJson) {
      // TODO  odebrat vrámci tasku OZ-1282, zohlednit v API ANPreviewModul, který Json definici používá pro náhled k testu
      test = JSON.parse(testData.definitionJson);
    } else {
      throw new Error(`Missing definitionUrl for test ${testData.testId}`);
    }

    return { test, testInstance: data[1] };
  }

  initTestApp(firstInit) {
    if (!this.state.error) {
      if (
        this.state.testDefinitionId !==
          this.props.tokenObject.TestDefinitionId ||
        firstInit
      ) {
        if (this.state.testLoaded) {
          this.setState({ testLoaded: false });
        } else {
          this.loadTest();
        }
      }
    }
  }

  loadTest() {
    log.info(
      `Loading test. TestDefinitionId: ${this.props.tokenObject.TestDefinitionId}`,
      this.props.tokenObject
    );
    this.fetchTestData()
      .then((response) => {
        log.info(
          `Test loaded. TestDefinitionId: ${this.props.tokenObject.TestDefinitionId}`,
          this.props.tokenObject
        );
        this.setState({
          testLoaded: true,
          test: response.test,
          testInstance: response.testInstance,
          testDefinitionId: this.props.tokenObject.TestDefinitionId,
        });
      })
      .catch((error) => {
        handlerErrorTC(
          this,
          ResourceManager('errUnableToLoadTest'),
          error,
          true,
          ResourceManager('btnTryAgain'),
          this.props.tokenObject
        );
      });
  }

  render() {
    const { error, testLoaded } = this.state;

    if (error) {
      return (
        <ErrorHandler
          message={this.state.errorMessage}
          reloadLink={this.state.reloadLink}
          showReturnLink={this.state.showReturnLink}
        />
      );
    }

    if (testLoaded) {
      return (
        <InitializedTestApp
          test={this.state.test}
          testInstance={this.state.testInstance}
          db={this.props.db}
          token={this.props.token}
          tokenObject={this.props.tokenObject}
          onFinish={this.props.onFinish}
          connectionId={this.props.connectionId}
        />
      );
    }
    return <Loader message={ResourceManager('loadingData')} isProgress />;
  }
}
