import BaseOperator from "./BaseOperator";

export default class CosOperator extends BaseOperator {

    getOperator() {
        return 'cos';
    }

    toFullString() {
        return this.getOperator() +" ";
    }
}