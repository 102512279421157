import BaseOperator from "./BaseOperator";

export default class SquareRootOperator extends BaseOperator {

    getOperator() {
        return '√';
    }

    getStringOrder() {
        return -1;
    }

    toFullString() {
        return this.getOperator();
    }
}