import BaseOperator from "./BaseOperator";

export default class PowYOperator extends BaseOperator {

    getOperator() {
        return '^';
    }

    toFullString() {
        return this.getOperator();
    }

}