class LSM {
  constructor(storageName) {
    this.storageName = storageName;
  }

  getExamData() {
    return JSON.parse(localStorage.getItem(this.storageName)) || {};
  }

  setExamData(examData) {
    localStorage.setItem(this.storageName, JSON.stringify(examData));
  }

  getExternalExamIds() {
    return Object.keys(this.getExamData());
  }

  examExists(externalExamId) {
    const allExams = this.getExamData();
    return !!allExams[externalExamId];
  }

  addNewExam(externalExamId, definitionId) {
    const allExams = this.getExamData();
    allExams[externalExamId] = { definitionId };
    this.setExamData(allExams);
  }

  deleteExam(externalExamId) {
    const allExams = this.getExamData();
    delete allExams[externalExamId];
    this.setExamData(allExams);
  }

  getExamProperty(externalExamId, propName) {
    const allExams = this.getExamData();
    return allExams?.[externalExamId]?.[propName] || '';
  }

  setExamProperty(externalExamId, propName, propValue) {
    const allExams = this.getExamData();
    if (allExams[externalExamId])
      allExams[externalExamId][propName] = propValue;
    this.setExamData(allExams);
  }

  deleteExamProperty(externalExamId, propName) {
    const allExams = this.getExamData();
    if (allExams[externalExamId]) delete allExams[externalExamId][propName];
    this.setExamData(allExams);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new LSM('examData');
