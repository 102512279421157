import React, { Component } from 'react';
import { ResourceManager } from '../resources/ResourceManager';
import TestHeader from './TestHeader';
import beforeUnloadHelper from '../utils/BeforeUnloadHelper';
import log from '../utils/logging/DefaultLogger';
import base64Image from '../utils/images';

export class ErrorHandler extends Component {
  componentDidMount() {
    beforeUnloadHelper.remove();
  }

  render() {
    const { showRetryButton } = this.props.retryFunc !== undefined;
    const { showReturnLink } = this.props;
    const headerProps = {
      tokenObject: this.props.tokenObject,
    };

    const buttons = () => {
      return showRetryButton || showReturnLink ? (
        <div className="row error-buttons">
          {showRetryButton ? (
            <button
              onClick={this.props.retryFunc}
              type="button"
              className="btn btn-primary"
            >
              {ResourceManager('btnTryAgain')}
            </button>
          ) : (
            ''
          )}
          {showReturnLink ? (
            <button
              onClick={() => {
                window.location.href = process.env.REACT_APP_PROFILE_URL;
              }}
              type="button"
              className="btn btn-secondary"
            >
              {ResourceManager('btnReturn')}
            </button>
          ) : (
            ''
          )}
        </div>
      ) : null;
    };

    const message =
      this.props.message.length !== 0 ? (
        <p className="text-description">{this.props.message}</p>
      ) : (
        ''
      );

    return (
      <div
        id="TestApp"
        data-env-build={process.env.REACT_APP_ENV}
        data-env-running={process.env.NODE_ENV}
      >
        <TestHeader headerProps={headerProps} />
        <div className="error-panel mt-5">
          <img className="error" src={base64Image('x-close')} alt="" />
          <div>
            <h2>{ResourceManager('errHandlerTitle')}</h2>
            <p className="text-description">
              {ResourceManager('errHandlerDesc')}
            </p>
            {message}
          </div>
          {buttons()}
        </div>
      </div>
    );
  }
}

const readErrorMessageTC = async (error) => {
  if (!error) return '';
  try {
    const jsonError = await error.json();
    return jsonError.message;
  } catch (e) {
    return error.statusText || error.message;
  }
};

export const handlerErrorTC = (
  errorSource,
  baseMessage,
  error,
  showReturnLink = false,
  reloadLink,
  tokenObject,
  expectedError = false
) => {
  readErrorMessageTC(error).then((detailErrorMessage) => {
    let errorMessage = baseMessage;
    if (errorSource) {
      // pokud se handleErrorTC pouzije na nereacti komponente
      errorSource.setState({
        error: true,
        errorMessage,
        reloadLink,
        showReturnLink,
      });
    }
    errorMessage = `${errorMessage} Detail message: ${
      detailErrorMessage || 'N/A'
    }`;
    if (expectedError) {
      log.debug(errorMessage, tokenObject);
    } else {
      log.warn(errorMessage, tokenObject);
    }
  });
};
