import BaseOperator from "./BaseOperator";

export default class Pow2Operator extends BaseOperator {

    getOperator() {
        return '^2';
    }

    toFullString() {
        return this.getOperator() + " ";
    }
}