import React, { Component } from 'react';
import Loader from './Loader';
import { ErrorHandler } from './ErrorHandler';
import { ResourceManager } from '../resources/ResourceManager';
import { FINISH_FORM_ID } from '../utils/finishHelpers';

export default class FinishForm extends Component {
  render() {
    const { token, tokenObject, error, errorMessage, retryFunc } = this.props;

    if (error) {
      return (
        <ErrorHandler
          message={errorMessage}
          retryFunc={retryFunc}
          showReturnLink={true}
        />
      );
    }

    return (
      <>
        <form id={FINISH_FORM_ID} action={tokenObject.ReturnUrl} method="post">
          <input type="hidden" name="token" value={token} />
        </form>
        <Loader message={ResourceManager('savingData')} />
      </>
    );
  }
}
