import type { DomNode } from '@src//types';
import { CAMEL_DATASET_IGNORE_NODE } from '@src/util/const';

/**
 * Because of supporting highlighting a same area (range overlapping),
 * Highlighter will calculate which text-node and how much offset it actually be,
 * based on the origin website dom node and the text offset.
 *
 * @param {Node} $parent element node in the origin website dom tree
 * @param {number} offset text offset in the origin website dom tree
 * @return {DomNode} DOM a dom info object
 */
export const getTextChildByOffset = ($parent: Node, offset: number): DomNode => {
    const nodeStack: Node[] = [$parent];

    let $curNode: Node = null;
    let curOffset = 0;
    let startOffset = 0;

    while (($curNode = nodeStack.pop())) {
        const children = $curNode.childNodes;

        for (let i = children.length - 1; i >= 0; i--) {
            const child = children[i] as HTMLElement;

            // 忽略该节点
            if (!child.dataset?.[CAMEL_DATASET_IGNORE_NODE]) nodeStack.push(children[i]);
        }

        if ($curNode.nodeType === 3) {
            startOffset = offset - curOffset;
            curOffset += $curNode.textContent.length;

            if (curOffset >= offset) {
                break;
            }
        }
    }

    if (!$curNode) {
        $curNode = $parent;
    }

    return {
        $node: $curNode,
        offset: startOffset,
    };
};
