import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { messages } from '../../intl';
import { Context } from '../../Proctoring';
import usePrevious from '../../utils/usePrevious';

const Gdpr = ({ isActive }) => {
  const { approvalsTexts, examId, setAllowContinue, socket } =
    useContext(Context);

  const [isGdprApproved, setIsGdprApproved] = useState(false);
  const [aiDevApproval, setAiDevApproval] = useState(false);
  const prevIsActive = usePrevious(isActive);

  const reading = useRef();

  useEffect(() => {
    if (isGdprApproved) reading.current.scrollTop = 0;
  }, [isGdprApproved]);

  useEffect(() => {
    if (isActive) setAllowContinue(isGdprApproved);
  }, [isActive, isGdprApproved, setAllowContinue]);

  useEffect(() => {
    if (isGdprApproved && prevIsActive && !isActive) {
      socket?.emit('gdprApproved', { examId, aiDevApproval });
    }
  }, [aiDevApproval, examId, isActive, isGdprApproved, prevIsActive, socket]);

  return (
    <div className="introCheck">
      <div
        className="reading textMdNormal"
        ref={reading}
        dangerouslySetInnerHTML={{
          __html: approvalsTexts?.gdpr ?? '',
        }}
      ></div>

      <div className="rulesCheckboxContainer">
        <FormControlLabel
          className="floatLeft alignLeft"
          control={
            <Checkbox
              size="medium"
              color="secondary"
              checked={isGdprApproved}
              onChange={() => setIsGdprApproved(!isGdprApproved)}
            />
          }
          label={<FormattedMessage {...messages.haveReadAndAgreeWGDPR} />}
        />
        <FormControlLabel
          className="floatLeft alignLeft"
          control={
            <Checkbox
              size="medium"
              color="secondary"
              checked={aiDevApproval}
              onChange={() => setAiDevApproval(!aiDevApproval)}
            />
          }
          label={<FormattedMessage {...messages.agreeWithAiDev} />}
        />
      </div>
    </div>
  );
};

export default Gdpr;
