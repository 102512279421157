export default async function getTestResponseStream(testDefinitionUrl) {
  const testDefinitionResponse = await window.fetch(testDefinitionUrl);

  const contentLength = testDefinitionResponse.headers.get('content-length');
  const total = parseInt(contentLength, 10);
  let loaded = 0;

  const responseStream = new Response(
    new ReadableStream({
      async start(controller) {
        const reader = testDefinitionResponse.body.getReader();
        while (true) {
          // eslint-disable-next-line no-await-in-loop
          const { done, value } = await reader.read();
          if (done) break;

          loaded += value.byteLength;
          const eventPayload = { detail: { loaded, total } };
          const onProgress = new CustomEvent('fetch-progress', eventPayload);
          window.dispatchEvent(onProgress);

          controller.enqueue(value);
        }
        controller.close();
      },
    })
  );
  return responseStream;
}
