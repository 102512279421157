import React, { useContext, useEffect, useRef, useState } from 'react';
import { DEFAULT_CAM_ASPECT_RATIO, EXAM, NOTIF_KEY } from '../../consts';
import { Context } from '../../Proctoring';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from '../../intl';
import Scanner from 'react-webcam';
import mirrorImage from '../../utils/mirrorImage';
import useInterval from '../../utils/useInterval';
import LSM from '../../utils/localStorageManager';
import blurImage from '../../utils/blurImage';
import { camMirror, frameCorner, info, photoIcon, warning } from '../../icons';
import usePrevious from '../../utils/usePrevious';
import { uploadImageToBlobStorage } from '../../api';

let snapshotBase64Original = null;
const timerInit = 4;

const IdCheck = ({ isActive, headline, instructionsText }) => {
  const intl = useIntl();
  const {
    definitionId,
    examId,
    externalExamId,
    setAllowContinue,
    touchRecorder,
    notifications,
  } = useContext(Context);
  const [isSnapshotMirrored, setIsSnapshotMirrored] = useState(false);
  const [snapshot, setSnapshot] = useState(null);
  const [isAnonymizeId, setIsAnonymizeId] = useState(false);
  const [timer, setTimer] = useState(timerInit);
  const isCountingDown = timer !== 4;
  const idSnapshot = useRef(null);
  const imageCanvasId = 'imageCanvasId';
  const prevIsActive = usePrevious(isActive);
  const isCamOk = !notifications[NOTIF_KEY.CAM];

  useEffect(() => {
    if (prevIsActive && !isActive && snapshot) {
      // Send ID snapshot
      uploadImageToBlobStorage({
        blobName: `${definitionId}/${examId}/${examId}_image_id.png`,
        snapshot,
      });

      setSnapshot(null);

      // Start recording again, after id check's finished
      touchRecorder({ event: 'start' });
    } else if (!prevIsActive && isActive) {
      // Don't record id check !
      touchRecorder({ event: 'stop' });
    }
  }, [definitionId, examId, isActive, prevIsActive, snapshot, touchRecorder]);

  // Allow continue
  useEffect(() => {
    if (isActive) setAllowContinue(!!snapshot);
  }, [isActive, setAllowContinue, snapshot]);

  // Prepare to anonymize
  useEffect(() => {
    if (isAnonymizeId) {
      setTimeout(() => blurImage(snapshot, imageCanvasId, setSnapshot), 5);
    }
  }, [isAnonymizeId, setSnapshot, snapshot]);

  // Countdown interval
  useInterval(
    () => {
      if (timer === 0) {
        setSnapshot(idSnapshot.current.getScreenshot());
        setTimer(timerInit);
      } else if (timer !== 0) setTimer(timer - 1);
    },
    1000,
    timer !== timerInit
  );

  const handleAnonymize = () => {
    if (isAnonymizeId) setSnapshot(snapshotBase64Original);
    else snapshotBase64Original = snapshot;

    setIsAnonymizeId(!isAnonymizeId);
  };

  const handleMirrorImage = () => {
    if (snapshot) setSnapshot(mirrorImage(snapshot));
    setIsSnapshotMirrored(!isSnapshotMirrored);
  };

  const handleTakingShot = () => {
    if (snapshot) {
      setSnapshot(null);
      setIsAnonymizeId(false);
      snapshotBase64Original = null;
    }

    setTimeout(() => setTimer(timerInit - 1), 1000);
  };

  const snapshotHeight = `${
    document.querySelector('.snapshot')
      ? document.querySelector('.snapshot').clientWidth /
        Number(localStorage.getItem('aspectRatio') || DEFAULT_CAM_ASPECT_RATIO)
      : 0
  }px`;

  return (
    <div className="introCheck idCheck">
      <div className="beside">
        <div>
          <h2 className="header2Semibold">{headline}</h2>
          <div
            className="textMdNormal"
            dangerouslySetInnerHTML={{
              __html: instructionsText ?? '',
            }}
          ></div>
          <br />
          <br />
        </div>
        <div>
          {isActive && (
            <div className="snapshot" style={{ height: snapshotHeight }}>
              {isCamOk &&
                (!snapshot || isCountingDown ? (
                  <Scanner
                    className={isSnapshotMirrored ? 'mirror-snapshot' : ''}
                    mirrored={isSnapshotMirrored}
                    audio={false}
                    ref={idSnapshot}
                    screenshotFormat="image/png"
                    minScreenshotWidth={1280}
                    videoConstraints={
                      !!LSM.getExamProperty(externalExamId, EXAM.WEBCAM_ID)
                        ? {
                            deviceId: {
                              exact: LSM.getExamProperty(
                                externalExamId,
                                EXAM.WEBCAM_ID
                              ),
                            },
                          }
                        : {}
                    }
                  />
                ) : isAnonymizeId ? (
                  <canvas
                    id={imageCanvasId}
                    width={document.querySelector('.snapshot').clientWidth}
                    height={document.querySelector('.snapshot').clientHeight}
                  />
                ) : (
                  <img alt="" src={snapshot} />
                ))}

              {isCamOk && isCountingDown && (
                <div className="countdown">
                  <span className={timer === 3 ? 'active' : ''}>3</span>
                  <span className={timer === 2 ? 'active' : ''}>2</span>
                  <span className={timer === 1 ? 'active' : ''}>1</span>
                  <span className={timer === 0 ? 'active' : ''}>
                    {photoIcon}
                  </span>
                </div>
              )}

              {isCamOk && !isCountingDown && !snapshot && (
                <div className="frame">
                  <div className="topBounding">
                    <div className="cornerLeftTop">{frameCorner}</div>
                    <div className="text">
                      <FormattedMessage {...messages.zoomInId} />
                    </div>
                    <div className="cornerRightTop">{frameCorner}</div>
                  </div>
                  <div className="bottomBounding">
                    <div className="cornerLeftBottom">{frameCorner}</div>
                    <div className="text">
                      <FormattedMessage {...messages.onFrontPage} />
                    </div>
                    <div className="cornerRightBottom">{frameCorner}</div>
                  </div>
                </div>
              )}
            </div>
          )}

          {!isCountingDown && (
            <div className="controls flexReverse">
              {/* Take shot */}
              <div>
                {/* Anonymize */}
                {snapshot && (
                  <button
                    onClick={handleAnonymize}
                    className={isAnonymizeId ? 'bgError' : ''}
                  >
                    <FormattedMessage
                      {...messages[
                        isAnonymizeId ? 'cancelAnonymization' : 'anonymizeId'
                      ]}
                    />
                  </button>
                )}
                {/* Mirror */}
                <button
                  aria-label={intl.formatMessage(messages.mirrorTurn)}
                  onClick={handleMirrorImage}
                >
                  {camMirror}
                </button>
              </div>
              <button
                className={!snapshot ? 'bgActive' : 'bgActiveLight'}
                onClick={handleTakingShot}
              >
                <FormattedMessage
                  {...messages[!snapshot ? 'takeShot' : 'takeShotAgain']}
                />
              </button>
            </div>
          )}
        </div>
      </div>
      {/* Instructions */}
      {snapshot && (
        <div
          className={`${
            isAnonymizeId ? 'info' : 'warning'
          } textMdNormal withIcon justifyContentCenter`}
        >
          {isAnonymizeId ? info : warning}
          <span>
            {isAnonymizeId && (
              <FormattedMessage {...messages.anonymizeIdDesc} />
            )}{' '}
            <span className="textMdSemibold">
              <FormattedMessage
                {...messages[
                  isAnonymizeId
                    ? 'anonymizeIdDescItems'
                    : 'continueIfDataReadable'
                ]}
              />
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

export default IdCheck;
