const addButtonToInput = (htmlString) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  let elements = [];

  const inputs = doc.querySelectorAll('input[usekeyboard="true"]');
  const textareas = doc.querySelectorAll('textarea[usekeyboard="true"]');

  if (inputs.length > 0) elements = inputs;
  if (textareas.length > 0) elements = textareas;

  elements.forEach((el) => {
    const btn = doc.createElement('button');
    btn.type = 'button';
    btn.className = `keyboard-button${
      textareas.length > 0 ? ' keyboard-textarea' : ''
    }`;
    el.parentNode.insertBefore(btn, inputs.length > 0 ? el.nextSibling : el);
  });

  const newHtmlString = doc.body.innerHTML;

  return newHtmlString;
};

export default addButtonToInput;
