import BaseOperator from "./BaseOperator";

export default class YRootOperator extends BaseOperator {

    getOperator() {
        return 'sqrt';
    }

    toFullString() {
        return " " + this.getOperator() + " ";
    }
}