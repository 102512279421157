const beforeUnloadHelper = {
  add() {
    window.onbeforeunload = () => false;
  },

  remove() {
    window.onbeforeunload = null;
  },
};

export default beforeUnloadHelper;
