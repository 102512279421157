import React, { useState, useEffect, useRef } from 'react';
import { ResourceManager } from '../resources/ResourceManager';
import TimerHelper from '../utils/TimerHelper';
import base64Image from '../utils/images';

const TimerInstruction = (props) => {
  const [remainingTime, setRemainingTime] = useState(0);
  const timer = useRef(new TimerHelper());

  useEffect(() => {
    const currentTimer = timer.current;
    setRemainingTime(props.remainingTime);
    currentTimer.startTimer(
      props.remainingTime,
      setRemainingTime,
      props.startTest
    );

    return () => {
      currentTimer.clearTimer();
    };
  }, [props.remainingTime, props.startTest]);

  return (
    <div className="instruction-counter">
      <img src={base64Image('timer')} alt="" />
      <span className="timer-message">
        {ResourceManager('instructionTimerInfo')}
      </span>
      <span className="timer-time">
        {timer.current.formatTime(remainingTime)}
      </span>
    </div>
  );
};

export default TimerInstruction;
