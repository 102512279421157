import { useContext, useEffect, useRef } from 'react';
import SimpleKeyboard from '../components/SimpleKeyboard';
import { ResourceManager } from '../resources/ResourceManager';
import draggable from '../utils/draggable';
import modalOverlapping from '../utils/modalOverlapping';
import base64Image from '../utils/images';
import { QuestionContext } from '../components/Page';

const KeyboardModal = (props) => {
  const modalRef = useRef(null);
  const { value, show, left, bottom } = props.inputState;
  const { setKeyboardId } = useContext(QuestionContext);
  const modalHeight = 386;

  useEffect(() => {
    if (show) {
      draggable(
        document.getElementById('idKeyboardModal'),
        document.getElementById('idKeyboardHeader')
      );

      modalOverlapping('idKeyboardModal', '.sidebar-modal');
    }
  }, [show]);

  const closeModal = () => {
    setKeyboardId('');
  };

  if (show) {
    const modalTopPosition =
      bottom + modalHeight < window.innerHeight
        ? bottom + 16
        : window.innerHeight - modalHeight + 16;

    return (
      <div
        ref={modalRef}
        className="sidebar-modal keyboard-modal"
        id="idKeyboardModal"
        style={{ left, top: modalTopPosition }}
      >
        <div id="idKeyboardHeader" className="modal-sidebar-draggable">
          <div className="modal-title">{ResourceManager('keyboardTitle')}</div>
          <button
            onClick={() => closeModal()}
            className="btn-close"
            aria-label={ResourceManager('cancel')}
          >
            <img src={base64Image('x-close-gray700')} alt="" />
          </button>
        </div>
        <div
          className="sidebar-modal-content scrollable"
          id="idKeyboardScrollable"
        >
          <SimpleKeyboard
            inputValue={value}
            setInputState={props.setInputState}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default KeyboardModal;
