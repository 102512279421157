import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { ConfirmAlert } from '../modals/ConfirmAlertModal';
import { ResourceManager } from '../resources/ResourceManager';
import beforeUnloadHelper from './BeforeUnloadHelper';
import log from './logging/DefaultLogger';

export default async function initSessionHubAsync(
  externalExamId,
  sessionHubStateErrorCallback
) {
  log.debug(`Signal R - initializing, externalExamId: ${externalExamId}`);

  const connection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_TESTCORE_API_URL}/SessionHub`)
    .withAutomaticReconnect([1000, 5000, 10000, 30000])
    .configureLogging(process.env.REACT_APP_LOG_LEVEL)
    .build();

  let isReconnect = false;

  const start = async () => {
    console.log('Start Signal R connection....');
    try {
      if (connection.state === HubConnectionState.Disconnected) {
        await connection.start();
        console.log('Signal R Connected.');

        if (isReconnect) {
          console.log('Signal R Client ReConnected.');
          connection.invoke('ClientReconnected', externalExamId);
        }
      }
    } catch (err) {
      console.log(err);
      const nextReconnect = Math.round(60000 + Math.random() * 5000); // manual reconnect after 1 minute with random interval 5 seconds (prevent reconnect storm)
      console.log(`Signal R - next reconnect in ${nextReconnect}ms.`);
      isReconnect = true;
      setTimeout(start, nextReconnect);
    }
  };

  connection.on('IsActiveSessionResult', (data) => {
    log.debug(
      `Signal R - received isActiveSessionResult: ${data.isActiveSessionResult}`
    );
    if (data.isActiveSessionResult === false) {
      ConfirmAlert({
        title: ResourceManager('sessionNonActiveModalTitle'),
        message: ResourceManager('sessionNonActiveModalMessage'),
        buttons: [
          {
            label: ResourceManager('sessionNonActiveModalButton'),
            onClick: () => {
              beforeUnloadHelper.remove();
              connection.invoke('IsActiveSession', externalExamId);
            },
            className: 'btn btn-primary',
          },
        ],
      });

      sessionHubStateErrorCallback(true, '');
    } else {
      sessionHubStateErrorCallback(false, null);
    }
  });

  connection.on('ActiveSessionMessage', (data) => {
    log.debug(`Signal R - received ActiveSessionMessage - data: ${data}`);
    ConfirmAlert({
      title: ResourceManager('sessionActiveModalTitle'),
      message: ResourceManager('sessionActiveModalMessage'),
      buttons: [
        {
          label: ResourceManager('sessionActiveModalButton'),
          onClick: () => {},
          className: 'btn btn-primary',
        },
      ],
    });
  });

  connection.on('TestActiveSession', (data) => {
    log.debug(`Signal R - received TestActiveSession - data: ${data}`);
    if (connection.state === HubConnectionState.Connected) {
      log.debug(`Signal R - Session connected`);
      connection.invoke(
        'TestActiveSessionResult',
        connection.connectionId,
        externalExamId
      );
    }
  });

  connection.onreconnecting((error) => {
    log.warning('Singnal R - reconnecting...');
    if (error) {
      log.error(error);
    }
  });

  connection.onreconnected((connectionId) => {
    log.info(`Signal R - reconnected! ${connectionId}`);
    log.info(`Signal R - connection state: ${connection.state}`);

    console.log('Signal R Client Auto ReConnected.');
    connection.invoke('ClientReconnected', externalExamId);

    isReconnect = false;
  });

  connection.onclose(async (error) => {
    log.info('Signal R - connection closed!');
    if (error) {
      log.error(error);
    }
    await connection.stop();

    isReconnect = true;
    await start();
  });

  try {
    log.debug(
      `Signal R - starting session, connectionId: ${connection.connectionId}`
    );
    await start();

    log.info(`Signal R - connection state: ${connection.state}`);

    log.debug(`Signal R - invoking IsActiveSession ${externalExamId}`);
    await connection.invoke('IsActiveSession', externalExamId);
  } catch (error) {
    log.error(`Singnal R - connection error. Message: ${error.message}`, {
      externalExamId,
    });
  }

  return connection;
}
