// Base64 images
// import scss file so there is only one source file to edit
import images from '../styles/utils/_images.scss';

const base64Image = (name) => {
  const image = images[name];
  if (image) {
    const quotesRemoved = images[name].slice(1, -1);
    const imageUrl = `data:image/svg+xml;base64,${quotesRemoved}`;
    return imageUrl;
  }
  return;
};

export default base64Image;
