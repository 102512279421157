import React from 'react';

export const photoIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 554.07 436.09">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Capa_1" data-name="Capa 1">
        <path d="M353.87,166.23A108.28,108.28,0,1,0,200.73,319.36a110.58,110.58,0,0,0,76.57,31.73,108.41,108.41,0,0,0,76.57-184.86Z" />
        <path d="M535.64,67.94A59.58,59.58,0,0,0,491.92,49.5H404.49v-1c0-13.17-5.26-25.81-14.22-34.23A48.14,48.14,0,0,0,356,0H198a48.55,48.55,0,0,0-49,48.45v1H62.15A59.55,59.55,0,0,0,18.44,67.94,62.62,62.62,0,0,0,0,111.65V373.94a59.55,59.55,0,0,0,18.44,43.71,62.62,62.62,0,0,0,43.71,18.44H491.92a59.58,59.58,0,0,0,43.72-18.44,62.61,62.61,0,0,0,18.43-43.71V111.65A59.58,59.58,0,0,0,535.64,67.94Zm-150,283.42a152.61,152.61,0,0,1-108.56,45,156.71,156.71,0,0,1-108.56-45,153.55,153.55,0,1,1,217.12,0Z" />
      </g>
    </g>
  </svg>
);
export const okIcon = (
  <svg
    width="114"
    height="114"
    viewBox="0 0 114 114"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="9" y="9" width="96" height="96" rx="48" fill="#D1FADF" />
    <rect
      x="9"
      y="9"
      width="96"
      height="96"
      rx="48"
      stroke="#ECFDF3"
      strokeWidth="17.1429"
      fill="#D1FADF"
    />
    <path
      d="M73.0002 45L51.0002 67L41.0002 57"
      stroke="#039855"
      strokeWidth="3.42857"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const notOkIcon = (
  <svg
    width="114"
    height="114"
    viewBox="0 0 114 114"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="9" y="9" width="96" height="96" rx="48" fill="#FEE4E2" />
    <path
      d="M69 45L45 69M45 45L69 69"
      stroke="#D92D20"
      stroke-width="3.42857"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <rect
      x="9"
      y="9"
      width="96"
      height="96"
      rx="48"
      stroke="#FEF3F2"
      stroke-width="17.1429"
    />
  </svg>
);
export const violationDesktop = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 1.83 1.624"
  >
    <defs>
      <style type="text/css">
        {`.str0-violationDesktop {stroke:#898989;stroke-width:0.221;stroke-miterlimit:22.9256} .str1-violationDesktop {stroke:#898989;stroke-width:0.074;stroke-miterlimit:22.9256} .fil0-violationDesktop {fill:none} .fil1-violationDesktop {fill:#898989}`}
      </style>
    </defs>
    <g>
      <path
        className="fil0-violationDesktop str0-violationDesktop"
        d="M0.206 0.111l1.417 0c0.053,0 0.096,0.049 0.096,0.108l0 0.957c0,0.06 -0.043,0.109 -0.096,0.109l-1.417 0c-0.052,0 -0.095,-0.049 -0.095,-0.109l0 -0.957c0,-0.059 0.043,-0.108 0.095,-0.108z"
      />
      <polygon
        className="fil1-violationDesktop"
        points="0.782,1.42 1.047,1.42 1.047,1.502 0.782,1.502 "
      />
      <path
        className="fil1-violationDesktop"
        d="M0.671 1.502l0.488 0c0.027,0 0.05,0.023 0.05,0.051l0 0.021c0,0.028 -0.023,0.05 -0.05,0.05l-0.488 0c-0.028,0 -0.05,-0.022 -0.05,-0.05l0 -0.021c0,-0.028 0.022,-0.051 0.05,-0.051z"
      />
      <rect
        className="fil1-violationDesktop str1-violationDesktop"
        x="0.465"
        y="0.662"
        width="0.908"
        height="0.335"
      />
      <rect
        className="fil1-violationDesktop str1-violationDesktop"
        x="0.465"
        y="0.411"
        width="0.908"
        height="0.123"
      />
    </g>
  </svg>
);
export const violationScreen = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 1.552 1.378"
  >
    <defs>
      <style type="text/css">{`.str0-violationScreen {stroke:#898989;stroke-width:0.188;stroke-miterlimit:22.9256} .fil0-violationScreen {fill:none} .fil1-violationScreen {fill:#898989} .fil2-violationScreen {fill:#898989;fill-rule:nonzero}`}</style>
    </defs>
    <g>
      <path
        className="fil0-violationScreen str0-violationScreen"
        d="M0.175 0.094l1.202 0c0.045,0 0.081,0.041 0.081,0.092l0 0.812c0,0.051 -0.036,0.092 -0.081,0.092l-1.202 0c-0.045,0 -0.081,-0.041 -0.081,-0.092l0 -0.812c0,-0.051 0.036,-0.092 0.081,-0.092z"
      />
      <polygon
        className="fil1-violationScreen"
        points="0.664,1.205 0.889,1.205 0.889,1.275 0.664,1.275 "
      />
      <path
        className="fil1-violationScreen"
        d="M0.569 1.275l0.414 0c0.024,0 0.043,0.019 0.043,0.042l0 0.019c0,0.023 -0.019,0.042 -0.043,0.042l-0.414 0c-0.023,0 -0.042,-0.019 -0.042,-0.042l0 -0.019c0,-0.023 0.019,-0.042 0.042,-0.042z"
      />
      <path
        className="fil2-violationScreen"
        d="M0.697 0.935l0.25 -0.25 0.129 0.129 -0.008 -0.508 -0.507 -0.007 0.129 0.128 -0.25 0.25 0.257 0.258zm0.125 -0.125m0.189 -0.061m0.061 -0.189m-0.258 -0.258m-0.189 0.061m-0.06 0.189m0.004 0.254"
      />
    </g>
  </svg>
);
export const violationVideo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 2.199 1.774"
  >
    <defs>
      <style type="text/css">{`.fil-violationVideo {fill:#898989;fill-rule:nonzero}`}</style>
    </defs>
    <g>
      <g>
        <path
          className="fil-violationVideo"
          d="M1.772 0.222l-0.164 0 -0.222 -0.222 -0.591 0 -0.222 0.222 -0.143 0c-0.145,0 -0.263,0.118 -0.263,0.263l0 1.026c0,0.146 0.118,0.263 0.263,0.263l1.342 0 0 -1.552zm-0.681 1.294c-0.295,0 -0.536,-0.241 -0.536,-0.536 0,-0.296 0.241,-0.536 0.536,-0.536 0.296,0 0.536,0.24 0.536,0.536 0,0.295 -0.24,0.536 -0.536,0.536zm0.681 -1.294c0.141,0.004 0.254,0.12 0.254,0.263l0 1.027c0,0.142 -0.113,0.258 -0.254,0.262l0 -1.552zm-0.682 0.388c0.202,0 0.366,0.164 0.366,0.366 0,0.202 -0.164,0.366 -0.366,0.366 -0.202,0 -0.365,-0.164 -0.365,-0.366 0,-0.202 0.163,-0.366 0.365,-0.366z"
        />
        <path
          className="fil-violationVideo"
          d="M1.945 0.222l-0.06 0 0 1.552 0.06 0 0 -1.552zm0 0c0.141,0.004 0.254,0.12 0.254,0.263l0 1.027c0,0.142 -0.113,0.258 -0.254,0.262l0 -1.552zm-0.06 0l-0.186 0 0 1.552 0.186 0 0 -1.552z"
        />
        <path
          className="fil-violationVideo"
          d="M0.255 0.222l0.06 0 0 1.552 -0.06 0 0 -1.552zm0 0c-0.142,0.004 -0.255,0.12 -0.255,0.263l0 1.027c0,0.142 0.113,0.258 0.255,0.262l0 -1.552zm0.06 0l0.185 0 0 1.552 -0.185 0 0 -1.552z"
        />
      </g>
    </g>
  </svg>
);
export const camDisabledIcon = (
  <svg
    width="102"
    height="102"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M94.2194 39.6747C93.3467 38.1233 91.3105 37.5415 89.6621 38.5112L78.6083 44.9107L75.7964 46.5591V61.8793L78.4144 63.4307C78.5114 63.4307 78.5114 63.5277 78.6083 63.5277L89.4682 69.8303C89.953 70.2182 90.6318 70.4121 91.3105 70.4121C93.1528 70.4121 94.6073 68.9576 94.6073 67.1153V54.1222L94.7042 41.3231C94.7042 40.7413 94.6073 40.1595 94.2194 39.6747Z"
      fill="#D0D5DD"
    />
    <path
      d="M29.6423 34.73C38.2721 34.73 45.2534 27.7486 45.2534 19.1189C45.2534 10.4892 38.2721 3.50781 29.6423 3.50781C21.0126 3.50781 14.0313 10.4892 14.0313 19.1189C13.9343 27.7486 21.0126 34.73 29.6423 34.73ZM29.6423 12.7193C33.23 12.7193 36.0419 15.6282 36.0419 19.1189C36.0419 22.7065 33.133 25.5185 29.6423 25.5185C26.1517 25.5185 23.2428 22.6096 23.2428 19.1189C23.1458 15.5313 26.0547 12.7193 29.6423 12.7193Z"
      fill="#D0D5DD"
    />
    <path
      d="M69.202 35.6024C66.6809 36.863 63.869 37.6387 60.8631 37.6387C57.1785 37.6387 53.6878 36.5721 50.7789 34.6328H39.7251C36.8162 36.5721 33.3255 37.6387 29.6409 37.6387C26.6351 37.6387 23.8231 36.9599 21.3021 35.6024C19.0719 36.863 17.5205 39.384 17.5205 42.099V65.758C17.5205 70.1214 21.1082 73.612 25.3745 73.612H65.2265C69.5898 73.612 73.0805 70.0244 73.0805 65.758V42.099C72.9835 39.384 71.4321 36.863 69.202 35.6024Z"
      fill="#D0D5DD"
    />
    <path
      d="M60.8635 34.73C69.4933 34.73 76.4746 27.7486 76.4746 19.1189C76.4746 10.4892 69.4933 3.50781 60.8635 3.50781C52.2338 3.50781 45.2524 10.4892 45.2524 19.1189C45.2524 27.7486 52.2338 34.73 60.8635 34.73ZM60.8635 12.7193C64.4512 12.7193 67.2631 15.6282 67.2631 19.1189C67.2631 22.7065 64.3542 25.5185 60.8635 25.5185C57.3729 25.5185 54.464 22.6096 54.464 19.1189C54.464 15.5313 57.2759 12.7193 60.8635 12.7193Z"
      fill="#D0D5DD"
    />
    <rect
      x="8.76904"
      y="92.8477"
      width="119.257"
      height="12.2765"
      rx="1"
      transform="rotate(-44.831 8.76904 92.8477)"
      fill="#D0D5DD"
    />
    <rect
      y="84.0781"
      width="119.257"
      height="12.2765"
      rx="1"
      transform="rotate(-44.831 0 84.0781)"
      fill="#F2F4F7"
    />
  </svg>
);
export const micIcon = (
  <svg
    width="45"
    height="64"
    viewBox="0 0 45 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.8669 44.0419C25.2317 44.0419 28.1113 42.8439 30.507 40.4491C32.9023 38.0543 34.1001 35.1733 34.1001 31.8087V12.2345C34.1001 8.86991 32.9033 5.99009 30.507 3.59408C28.1113 1.19861 25.2317 0 21.8669 0C18.5025 0 15.6225 1.19861 13.2266 3.59408C10.8306 5.98969 9.63281 8.86991 9.63281 12.2345V31.8087C9.63281 35.1731 10.8312 38.0543 13.2266 40.4491C15.6221 42.8439 18.5025 44.0419 21.8669 44.0419Z"
      fill="#D0D5DD"
    />
    <path
      d="M43.3159 25.5772C42.8331 25.0928 42.2583 24.8506 41.5956 24.8506C40.9336 24.8506 40.3602 25.0928 39.8753 25.5772C39.3915 26.0614 39.1494 26.6348 39.1494 27.2972V32.1911C39.1494 36.9066 37.4731 40.9397 34.1214 44.2913C30.7708 47.643 26.7371 49.3189 22.0215 49.3189C17.3061 49.3189 13.2728 47.643 9.92096 44.2913C6.56951 40.9407 4.89393 36.9067 4.89393 32.1911V27.2972C4.89393 26.6348 4.65169 26.0614 4.16761 25.5772C3.68327 25.0928 3.11041 24.8506 2.4473 24.8506C1.78419 24.8506 1.21066 25.0928 0.726584 25.5772C0.242106 26.0614 0 26.6348 0 27.2972V32.1911C0 37.824 1.88006 42.7242 5.63912 46.891C9.39831 51.0579 14.0434 53.4472 19.5746 54.0585V59.1057H9.78745C9.12474 59.1057 8.55135 59.3482 8.06714 59.8325C7.58279 60.3164 7.34055 60.8901 7.34055 61.5528C7.34055 62.2145 7.58279 62.7892 8.06714 63.2731C8.55135 63.7571 9.12474 64 9.78745 64H34.2549C34.9175 64 35.4917 63.7572 35.9752 63.2731C36.4601 62.7893 36.7025 62.2146 36.7025 61.5528C36.7025 60.8902 36.4601 60.3166 35.9752 59.8325C35.4918 59.3482 34.9176 59.1057 34.2549 59.1057H24.469V54.0585C29.9992 53.4472 34.6439 51.0579 38.4035 46.891C42.1631 42.7242 44.0437 37.824 44.0437 32.1911V27.2972C44.0437 26.6349 43.8009 26.0619 43.3159 25.5772Z"
      fill="#D0D5DD"
    />
  </svg>
);
export const micDisabledIcon = (
  <svg
    width="54"
    height="64"
    viewBox="0 0 54 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.92225 36.3454C9.53752 34.8071 9.34528 33.3586 9.34528 31.9996V27.0759C9.34528 26.4095 9.10159 25.8327 8.61461 25.3453C8.12736 24.8582 7.55067 24.6146 6.88398 24.6146C6.21689 24.6146 5.63993 24.8583 5.15281 25.3453C4.66557 25.8325 4.42188 26.4095 4.42188 27.0759V31.9992C4.42188 34.8455 4.96058 37.5889 6.03746 40.2308L9.92225 36.3454Z"
      fill="#D0D5DD"
    />
    <path
      d="M26.5757 44.3059C29.9605 44.3059 32.8584 43.1008 35.2674 40.6916C37.6775 38.2825 38.8826 35.3842 38.8826 31.9994V27.0761L52.7671 13.192C53.0234 12.9357 53.1516 12.6407 53.1516 12.3074C53.1516 11.9741 53.0234 11.6791 52.7671 11.4227L49.614 8.26877C49.3567 8.01201 49.0621 7.88403 48.7289 7.88403C48.3959 7.88403 48.101 8.01201 47.8447 8.26877L0.384195 55.7295C0.127841 55.9857 0 56.2809 0 56.6136C0 56.9474 0.127841 57.2425 0.384195 57.4987L3.53791 60.6524C3.7944 60.9087 4.08955 61.0364 4.42255 61.0364C4.75596 61.0364 5.05098 60.9089 5.3072 60.6524L15.0763 50.8833C17.8962 52.6277 20.9091 53.6659 24.1144 53.9988V59.0761H14.2686C13.6019 59.0761 13.0245 59.3201 12.5379 59.8073C12.0505 60.2941 11.8065 60.8713 11.8065 61.538C11.8065 62.2036 12.0505 62.7818 12.5379 63.2686C13.0245 63.7554 13.6019 63.9998 14.2686 63.9998H38.8826C39.5492 63.9998 40.1263 63.7556 40.6133 63.2686C41.1009 62.7819 41.3445 62.2037 41.3445 61.538C41.3445 60.8714 41.1009 60.2942 40.6133 59.8073C40.1264 59.3201 39.5493 59.0761 38.8826 59.0761H29.037V53.9988C34.6009 53.3838 39.2734 50.9802 43.056 46.7882C46.8376 42.5964 48.7287 37.6668 48.7287 32.0002V27.076C48.7287 26.4096 48.4851 25.8328 47.9984 25.3456C47.5106 24.8584 46.9339 24.6147 46.2672 24.6147C45.6011 24.6147 45.024 24.8584 44.5366 25.3456C44.0493 25.8328 43.8059 26.4096 43.8059 27.076V31.9993C43.8059 36.743 42.1195 40.8003 38.7482 44.1716C35.3769 47.5429 31.3188 49.2292 26.5755 49.2292C23.8062 49.2292 21.1783 48.576 18.691 47.2677L22.3831 43.5765C23.7686 44.0634 25.1659 44.3059 26.5757 44.3059Z"
      fill="#D0D5DD"
    />
    <path
      d="M33.6709 2.26974C31.5553 0.756805 29.1899 0 26.5746 0C23.19 0 20.2924 1.2058 17.8825 3.61564C15.4726 6.02561 14.2676 8.9231 14.2676 12.3078V31.9995L38.1514 8.11551C37.2791 5.731 35.7864 3.78227 33.6709 2.26974Z"
      fill="#D0D5DD"
    />
  </svg>
);
export const bar = (
  <svg
    width="27"
    height="35"
    viewBox="0 0 27 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.301392" width="26.3542" height="34.7748" rx="4" fill="#EAECF0" />
  </svg>
);
export const activeBar = (
  <svg
    width="27"
    height="35"
    viewBox="0 0 27 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.301392" width="26.3542" height="34.7748" rx="4" fill="#0BA5EC" />
  </svg>
);

export const arrowLeft = (
  <svg
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.66675 6.99935H13.3334M13.3334 6.99935L7.50008 1.16602M13.3334 6.99935L7.50008 12.8327"
      stroke="white"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const disk = (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="40" height="40" rx="20" fill="#E0F2FE" />
    <path
      d="M15.0832 23.0007H30.9165M17.9998 26.334H21.3332M20.472 16.334H25.5277C26.4251 16.334 26.8738 16.334 27.2699 16.4706C27.6202 16.5915 27.9393 16.7886 28.204 17.0479C28.5034 17.3411 28.7041 17.7425 29.1054 18.5451L30.9109 22.1561C31.0684 22.4711 31.1471 22.6286 31.2027 22.7936C31.252 22.9402 31.2876 23.091 31.309 23.2442C31.3332 23.4167 31.3332 23.5928 31.3332 23.9449V25.6673C31.3332 27.0674 31.3332 27.7675 31.0607 28.3023C30.821 28.7727 30.4386 29.1552 29.9681 29.3948C29.4334 29.6673 28.7333 29.6673 27.3332 29.6673H18.6665C17.2664 29.6673 16.5663 29.6673 16.0315 29.3948C15.5611 29.1552 15.1787 28.7727 14.939 28.3023C14.6665 27.7675 14.6665 27.0674 14.6665 25.6673V23.9449C14.6665 23.5928 14.6665 23.4167 14.6906 23.2442C14.7121 23.091 14.7477 22.9402 14.797 22.7936C14.8526 22.6286 14.9313 22.4711 15.0888 22.1561L16.8943 18.5451C17.2956 17.7425 17.4963 17.3411 17.7956 17.0479C18.0604 16.7886 18.3794 16.5915 18.7297 16.4706C19.1259 16.334 19.5746 16.334 20.472 16.334Z"
      stroke="#0086C9"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="3"
      y="3"
      width="40"
      height="40"
      rx="20"
      stroke="#F0F9FF"
      strokeWidth="6"
    />
  </svg>
);

export const cube = (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="40" height="40" rx="20" fill="#E0F2FE" />
    <path
      d="M30.0833 19.0653L23 23.0005M23 23.0005L15.9166 19.0653M23 23.0005L23 30.9171M30.5 26.3826V19.6183C30.5 19.3328 30.5 19.19 30.4579 19.0627C30.4207 18.9501 30.3599 18.8467 30.2795 18.7594C30.1886 18.6608 30.0638 18.5915 29.8142 18.4528L23.6475 15.0269C23.4112 14.8956 23.293 14.8299 23.1679 14.8042C23.0571 14.7814 22.9429 14.7814 22.8321 14.8042C22.707 14.8299 22.5888 14.8956 22.3525 15.0269L16.1858 18.4528C15.9362 18.5915 15.8114 18.6608 15.7205 18.7594C15.6401 18.8467 15.5793 18.9501 15.5421 19.0627C15.5 19.19 15.5 19.3328 15.5 19.6183V26.3826C15.5 26.6681 15.5 26.8109 15.5421 26.9382C15.5793 27.0509 15.6401 27.1543 15.7205 27.2415C15.8114 27.3401 15.9362 27.4095 16.1858 27.5481L22.3525 30.9741C22.5888 31.1054 22.707 31.171 22.8321 31.1968C22.9429 31.2195 23.0571 31.2195 23.1679 31.1968C23.293 31.171 23.4112 31.1054 23.6475 30.9741L29.8142 27.5481C30.0638 27.4095 30.1886 27.3401 30.2795 27.2415C30.3599 27.1543 30.4207 27.0509 30.4579 26.9382C30.5 26.8109 30.5 26.6681 30.5 26.3826Z"
      stroke="#0086C9"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="3"
      y="3"
      width="40"
      height="40"
      rx="20"
      stroke="#F0F9FF"
      strokeWidth="6"
    />
  </svg>
);

export const cam = (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="40" height="40" rx="20" fill="#E0F2FE" />
    <path
      d="M31.3332 20.4422C31.3332 19.9373 31.3332 19.6849 31.2333 19.568C31.1467 19.4666 31.0168 19.4128 30.8838 19.4232C30.7306 19.4353 30.5521 19.6138 30.1951 19.9708L27.1665 22.9993L30.1951 26.0279C30.5521 26.3849 30.7306 26.5634 30.8838 26.5755C31.0168 26.5859 31.1467 26.5321 31.2333 26.4307C31.3332 26.3138 31.3332 26.0614 31.3332 25.5565V20.4422Z"
      stroke="#0086C9"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.6665 21.166C14.6665 19.7659 14.6665 19.0658 14.939 18.531C15.1787 18.0606 15.5611 17.6782 16.0315 17.4385C16.5663 17.166 17.2664 17.166 18.6665 17.166H23.1665C24.5666 17.166 25.2667 17.166 25.8015 17.4385C26.2719 17.6782 26.6543 18.0606 26.894 18.531C27.1665 19.0658 27.1665 19.7659 27.1665 21.166V24.8327C27.1665 26.2328 27.1665 26.9329 26.894 27.4677C26.6543 27.9381 26.2719 28.3205 25.8015 28.5602C25.2667 28.8327 24.5666 28.8327 23.1665 28.8327H18.6665C17.2664 28.8327 16.5663 28.8327 16.0315 28.5602C15.5611 28.3205 15.1787 27.9381 14.939 27.4677C14.6665 26.9329 14.6665 26.2328 14.6665 24.8327V21.166Z"
      stroke="#0086C9"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="3"
      y="3"
      width="40"
      height="40"
      rx="20"
      stroke="#F0F9FF"
      strokeWidth="6"
    />
  </svg>
);

export const id = (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="40" height="40" rx="20" fill="#E0F2FE" />
    <g clipPath="url(#clip0_3551_99462)">
      <path
        d="M16.3332 31.1805C16.8353 31.3327 17.5136 31.3327 18.6665 31.3327H27.3332C28.4861 31.3327 29.1643 31.3327 29.6665 31.1805M16.3332 31.1805C16.2255 31.1479 16.1259 31.1083 16.0315 31.0602C15.5611 30.8205 15.1787 30.4381 14.939 29.9677C14.6665 29.4329 14.6665 28.7328 14.6665 27.3327V18.666C14.6665 17.2659 14.6665 16.5658 14.939 16.031C15.1787 15.5606 15.5611 15.1782 16.0315 14.9385C16.5663 14.666 17.2664 14.666 18.6665 14.666H27.3332C28.7333 14.666 29.4334 14.666 29.9681 14.9385C30.4386 15.1782 30.821 15.5606 31.0607 16.031C31.3332 16.5658 31.3332 17.2659 31.3332 18.666V27.3327C31.3332 28.7328 31.3332 29.4329 31.0607 29.9677C30.821 30.4381 30.4386 30.8205 29.9681 31.0602C29.8737 31.1083 29.7742 31.1479 29.6665 31.1805M16.3332 31.1805C16.3335 30.5061 16.3375 30.1492 16.3972 29.849C16.6602 28.5267 17.6939 27.4931 19.0162 27.2301C19.3382 27.166 19.7254 27.166 20.4998 27.166H25.4998C26.2743 27.166 26.6615 27.166 26.9835 27.2301C28.3058 27.4931 29.3394 28.5267 29.6025 29.849C29.6622 30.1492 29.6662 30.5061 29.6665 31.1805M26.3332 20.916C26.3332 22.757 24.8408 24.2493 22.9998 24.2493C21.1589 24.2493 19.6665 22.757 19.6665 20.916C19.6665 19.0751 21.1589 17.5827 22.9998 17.5827C24.8408 17.5827 26.3332 19.0751 26.3332 20.916Z"
        stroke="#0086C9"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <rect
      x="3"
      y="3"
      width="40"
      height="40"
      rx="20"
      stroke="#F0F9FF"
      strokeWidth="6"
    />
    <defs>
      <clipPath id="clip0_3551_99462">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(13 13)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const frameCorner = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="55"
    height="54"
    viewBox="0 0 55 54"
    fill="none"
  >
    <path
      d="M55 46.2846V7.71538C55 5.66913 54.1721 3.7067 52.6984 2.25978C51.2247 0.812868 49.2259 0 47.1418 0L7.85825 0C5.77411 0 3.77534 0.812868 2.30163 2.25978C0.82792 3.7067 0 5.66913 0 7.71538C0 9.76163 0.82792 11.7241 2.30163 13.171C3.77534 14.6179 5.77411 15.4308 7.85825 15.4308H39.2835V46.2846C39.2835 48.3309 40.1114 50.2933 41.5851 51.7402C43.0588 53.1871 45.0576 54 47.1418 54C49.2259 54 51.2247 53.1871 52.6984 51.7402C54.1721 50.2933 55 48.3309 55 46.2846Z"
      fill="white"
    />
  </svg>
);

export const camMirror = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M17 13L16 14L15 13"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.8922 13.892C15.9582 13.604 16.0002 13.308 16.0002 13C16.0002 10.791 14.2092 9 12.0002 9C11.1512 9 10.3672 9.268 9.71924 9.719"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 9V18C21 19.105 20.105 20 19 20H5C3.895 20 3 19.105 3 18V9C3 7.895 3.895 7 5 7H7L8.462 4.496C8.641 4.189 8.97 4 9.326 4H14.63C14.981 4 15.306 4.184 15.487 4.484L17 7H19C20.105 7 21 7.895 21 9Z"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 13L8 12L9 13"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.108 12.1074C8.042 12.3954 8 12.6914 8 12.9994C8 15.2084 9.791 16.9994 12 16.9994C12.849 16.9994 13.633 16.7314 14.281 16.2804"
      stroke="#323232"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const checkPassedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0965 7.38967L9.9365 14.2997L8.0365 12.2697C7.6865 11.9397 7.1365 11.9197 6.7365 12.1997C6.3465 12.4897 6.2365 12.9997 6.4765 13.4097L8.7265 17.0697C8.9465 17.4097 9.3265 17.6197 9.7565 17.6197C10.1665 17.6197 10.5565 17.4097 10.7765 17.0697C11.1365 16.5997 18.0065 8.40967 18.0065 8.40967C18.9065 7.48967 17.8165 6.67967 17.0965 7.37967V7.38967Z"
      fill="white"
    />
  </svg>
);

export const arrowDown = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
  >
    <path
      d="M8.50016 3.33398V12.6673M8.50016 12.6673L13.1668 8.00065M8.50016 12.6673L3.8335 8.00065"
      stroke="#344054"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const warning = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_4759_23213)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7679 0.767389C10.7916 -0.208922 9.20865 -0.208921 8.23234 0.76739L0.767877 8.23185C-0.208434 9.20817 -0.208432 10.7911 0.767878 11.7674L8.23234 19.2319C9.20865 20.2082 10.7916 20.2082 11.7679 19.2319L19.2323 11.7674C20.2087 10.7911 20.2087 9.20816 19.2323 8.23185L11.7679 0.767389ZM9.00005 5.99962C9.00005 5.44734 9.44776 4.99962 10 4.99962C10.5523 4.99962 11 5.44734 11 5.99962V9.99962C11 10.5519 10.5523 10.9996 10 10.9996C9.44776 10.9996 9.00005 10.5519 9.00005 9.99962V5.99962ZM11 13.9996C11 14.5519 10.5523 14.9996 10 14.9996C9.44776 14.9996 9.00005 14.5519 9.00005 13.9996C9.00005 13.4473 9.44776 12.9996 10 12.9996C10.5523 12.9996 11 13.4473 11 13.9996Z"
        fill="#F79009"
      />
    </g>
    <defs>
      <clipPath id="clip0_4759_23213">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const info = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <g clipPath="url(#clip0_4759_23753)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.514 20 20 15.514 20 10C20 4.486 15.514 -4.82049e-07 10 0C4.486 4.82049e-07 -4.82049e-07 4.486 0 10C4.82049e-07 15.514 4.486 20 10 20ZM11 14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V10C9 9.44772 9.44771 9 10 9C10.5523 9 11 9.44772 11 10V14ZM10 5C9.44771 5 9 5.44771 9 6C9 6.55229 9.44771 7 10 7C10.5523 7 11 6.55229 11 6C11 5.44771 10.5523 5 10 5Z"
        fill="#0BA5EC"
      />
    </g>
    <defs>
      <clipPath id="clip0_4759_23753">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const stopRecordingIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M2.5 6.5C2.5 5.09987 2.5 4.3998 2.77248 3.86502C3.01217 3.39462 3.39462 3.01217 3.86502 2.77248C4.3998 2.5 5.09987 2.5 6.5 2.5H13.5C14.9001 2.5 15.6002 2.5 16.135 2.77248C16.6054 3.01217 16.9878 3.39462 17.2275 3.86502C17.5 4.3998 17.5 5.09987 17.5 6.5V13.5C17.5 14.9001 17.5 15.6002 17.2275 16.135C16.9878 16.6054 16.6054 16.9878 16.135 17.2275C15.6002 17.5 14.9001 17.5 13.5 17.5H6.5C5.09987 17.5 4.3998 17.5 3.86502 17.2275C3.39462 16.9878 3.01217 16.6054 2.77248 16.135C2.5 15.6002 2.5 14.9001 2.5 13.5V6.5Z"
      fill="#F04438"
      stroke="#F04438"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const playRecordingIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M20.9576 13.1338L6.33269 22.321C5.75128 22.6863 5.00506 22.4747 4.66596 21.8484C4.55727 21.6477 4.5 21.4195 4.5 21.1871V2.81267C4.5 2.0877 5.04563 1.5 5.71871 1.5C5.93445 1.5 6.14633 1.56169 6.33269 1.67876L20.9576 10.866C21.539 11.2312 21.7354 12.035 21.3963 12.6612C21.2904 12.8569 21.1392 13.0197 20.9576 13.1338Z"
      fill="#F7F9FA"
    />
  </svg>
);

export const playRecordingAgainIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M1.66669 8.33333C1.66669 8.33333 3.3375 6.05685 4.69488 4.69854C6.05226 3.34022 7.92802 2.5 10 2.5C14.1422 2.5 17.5 5.85786 17.5 10C17.5 14.1421 14.1422 17.5 10 17.5C6.58078 17.5 3.69595 15.2119 2.79316 12.0833M1.66669 8.33333V3.33333M1.66669 8.33333H6.66669"
      stroke="white"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const buttonLoaderIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M22.5 12C22.5 13.3789 22.2284 14.7443 21.7007 16.0182C21.1731 17.2921 20.3996 18.4496 19.4246 19.4246C18.4496 20.3996 17.2921 21.1731 16.0182 21.7007C14.7443 22.2284 13.3789 22.5 12 22.5C10.6211 22.5 9.25574 22.2284 7.98182 21.7007C6.7079 21.1731 5.55039 20.3996 4.57538 19.4246C3.60036 18.4496 2.82694 17.2921 2.29926 16.0182C1.77159 14.7443 1.5 13.3789 1.5 12C1.5 10.6211 1.77159 9.25574 2.29927 7.98182C2.82694 6.7079 3.60037 5.55039 4.57538 4.57538C5.5504 3.60036 6.70791 2.82694 7.98183 2.29926C9.25575 1.77159 10.6211 1.5 12 1.5C13.3789 1.5 14.7443 1.77159 16.0182 2.29927C17.2921 2.82694 18.4496 3.60037 19.4246 4.57538C20.3996 5.5504 21.1731 6.70791 21.7007 7.98183C22.2284 9.25575 22.5 10.6211 22.5 12L22.5 12Z"
      stroke="#D0D5DD"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 1.5C13.3789 1.5 14.7443 1.77159 16.0182 2.29927C17.2921 2.82694 18.4496 3.60036 19.4246 4.57538C20.3996 5.55039 21.1731 6.70791 21.7007 7.98183C22.2284 9.25574 22.5 10.6211 22.5 12"
      stroke="#0086C9"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const download = (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="40" height="40" rx="20" fill="#E0F2FE" />
    <path
      d="M30.5 30.5H15.5M28 22.1667L23 27.1667M23 27.1667L18 22.1667M23 27.1667V15.5"
      stroke="#0086C9"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="3"
      y="3"
      width="40"
      height="40"
      rx="20"
      stroke="#F0F9FF"
      strokeWidth="6"
    />
  </svg>
);

export const laptop = (
  <svg
    width="46"
    height="46"
    viewBox="0 0 46 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="3" y="3" width="40" height="40" rx="20" fill="#E0F2FE" />
    <path
      d="M15.4998 26.334V19.0007C15.4998 18.0672 15.4998 17.6005 15.6815 17.244C15.8413 16.9304 16.0962 16.6754 16.4099 16.5156C16.7664 16.334 17.2331 16.334 18.1665 16.334H27.8332C28.7666 16.334 29.2333 16.334 29.5898 16.5156C29.9034 16.6754 30.1584 16.9304 30.3182 17.244C30.4998 17.6005 30.4998 18.0672 30.4998 19.0007V26.334H26.0521C25.8483 26.334 25.7464 26.334 25.6505 26.357C25.5654 26.3774 25.4842 26.4111 25.4096 26.4568C25.3255 26.5083 25.2534 26.5804 25.1093 26.7245L25.057 26.7768C24.9129 26.9209 24.8408 26.993 24.7567 27.0445C24.6822 27.0902 24.6009 27.1239 24.5159 27.1443C24.42 27.1673 24.318 27.1673 24.1142 27.1673H21.8855C21.6816 27.1673 21.5797 27.1673 21.4838 27.1443C21.3988 27.1239 21.3175 27.0902 21.2429 27.0445C21.1588 26.993 21.0868 26.9209 20.9426 26.7768L20.8904 26.7245C20.7462 26.5804 20.6742 26.5083 20.5901 26.4568C20.5155 26.4111 20.4342 26.3774 20.3492 26.357C20.2533 26.334 20.1514 26.334 19.9476 26.334H15.4998ZM15.4998 26.334C15.0396 26.334 14.6665 26.7071 14.6665 27.1673V27.4451C14.6665 27.9617 14.6665 28.2201 14.7233 28.432C14.8774 29.0072 15.3267 29.4564 15.9018 29.6105C16.1137 29.6673 16.3721 29.6673 16.8887 29.6673H29.1109C29.6276 29.6673 29.8859 29.6673 30.0979 29.6105C30.673 29.4564 31.1223 29.0072 31.2764 28.432C31.3332 28.2201 31.3332 27.9617 31.3332 27.4451C31.3332 27.1868 31.3332 27.0576 31.3048 26.9516C31.2277 26.6641 31.0031 26.4394 30.7155 26.3624C30.6095 26.334 30.4804 26.334 30.2221 26.334H29.6665"
      stroke="#0086C9"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="3"
      y="3"
      width="40"
      height="40"
      rx="20"
      stroke="#F0F9FF"
      strokeWidth="6"
    />
  </svg>
);
