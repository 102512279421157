import * as Sentry from '@sentry/browser';
import React, { cloneElement, useContext, useEffect } from 'react';
import { SECTIONS } from '../../consts';
import { Context } from '../../Proctoring';
import SwipeableViews from '../SwipeableViews';
import ProgressNav from '../ProgressNav';
import Sidebar from '../../../components/Sidebar';

const Checks = () => {
  const { activeCheckId, checks, setSection } = useContext(Context);

  useEffect(() => {
    Sentry.setTag(
      'currentUiSection',
      checks?.[activeCheckId]?.name?.props?.defaultMessage
    );
  }, [activeCheckId, checks]);

  // scenario with all BE checks turned off
  if (!checks[activeCheckId]) {
    setSection(SECTIONS.EXAM);
    return null;
  }

  return (
    <main className="checksBounding">
      <ProgressNav />
      <SwipeableViews index={activeCheckId}>
        {checks.map((check, i) => (
          <div
            className={`${i === activeCheckId && 'activeCheck'}`}
            key={'introStepContent' + i}
          >
            {cloneElement(check.content, {
              isActive: i === activeCheckId,
              ...check,
            })}
          </div>
        ))}
      </SwipeableViews>
      <Sidebar />
    </main>
  );
};

export default Checks;
