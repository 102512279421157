import React, { Component } from 'react';
import RawHtml from './RawHtml';
import { ResourceManager } from '../resources/ResourceManager';
import base64Image from '../utils/images';

export default class ImplicitInstruction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.isImplicitInstructionOpen[this.props.itemIndex],
    };
  }

  toggleOpen = () => {
    this.props.setInstructionState(this.props.itemIndex, !this.state.isOpen);
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  render() {
    let indexText;
    const { isOpen } = this.state;
    const toggleButtonTitle = ResourceManager(
      isOpen ? 'instructionCollapse' : 'instructionExpand'
    );
    if (this.props.firstItemIndex === this.props.lastItemIndex) {
      indexText = `${ResourceManager('implicitInstructionIndexOne')} ${
        this.props.firstItemIndex
      }`;
    } else {
      indexText = `${ResourceManager('implicitInstructionIndexN')} ${
        this.props.firstItemIndex
      }–${this.props.lastItemIndex}`;
    }

    return (
      <div className="item implicit-instruction">
        <h2 className="item-number">
          {ResourceManager('implicitInstruction')} {indexText}
        </h2>
        <button
          className="toggle-button"
          onClick={this.toggleOpen}
          type="button"
          aria-label={toggleButtonTitle}
          aria-expanded={isOpen}
          aria-controls={this.props.renderedItem.itemId}
        >
          <img
            src={
              isOpen
                ? base64Image('chevron-up-blue')
                : base64Image('chevron-down-blue')
            }
            alt=""
          />
        </button>
        {isOpen && <RawHtml content={this.props.renderedItem.renderedOutput} />}
      </div>
    );
  }
}
