import Camera from '../components/checks/Camera';
import Microphone from '../components/checks/Microphone';
import Storage from '../components/checks/Storage';
import Connection from '../components/checks/Connection';
import Gdpr from '../components/checks/Gdpr';
import ExamRules from '../components/checks/ExamRules';
import CheckProcess from '../components/checks/CheckProcess';
import IdCheckIntro from '../components/checks/IdCheckIntro';
import { isEmpty, last, prop, sortBy } from 'ramda';
import IdCheck from '../components/checks/IdCheck';
import FaceCheck from '../components/checks/FaceCheck';
import FaceCheckIntro from '../components/checks/FaceCheckIntro';
import Welcome from '../components/checks/Welcome';
import ShareScreen from '../components/checks/ShareScreen';
import { messages } from '../intl';
import { FormattedMessage } from 'react-intl';
import { CHECK } from '../consts';
import RoomCheckIntro from '../components/checks/RoomCheckIntro';
import RoomCheck from '../components/checks/RoomCheck';
import Outro from '../components/checks/Outro';

export const checks = [
  {
    label: 1,
    content: <Welcome />,
    name: <FormattedMessage {...messages.preparation} />,
  },
  { label: 1, content: <Camera /> },
  { label: 1, content: <Microphone /> },
  { label: 1, content: <Storage /> },
  { label: 1, content: <Connection /> },
  {
    label: 2,
    content: <Gdpr />,
    name: <FormattedMessage {...messages.giveApprovals} />,
    isGdpr: true,
  },
  { label: 2, content: <ExamRules />, isExamRules: true },
  { label: 2, content: <ShareScreen />, isShareScreen: true },
];

const checkHeadlines = [];

const prepareChecks = (checksFromApi) => {
  if (!checksFromApi) return checks;

  for (const check of checksFromApi.sort((a, b) => a.position - b.position)) {
    const label = (last(checks)?.label ?? 0) + 1;

    // START - prevent redundant checks
    if (checkHeadlines.includes(check.headline)) continue;
    else if (check.headline && !isEmpty(check.headline)) {
      checkHeadlines.push(check.headline);
    }
    // END - prevent redundant checks

    switch (check.runCheck) {
      case CHECK.ID:
        checks.push({
          ...check,
          content: <IdCheckIntro />,
          label,
          name: <FormattedMessage {...messages.idVerification} />,
        });

        checks.push({ ...check, content: <IdCheck />, label });

        break;
      case CHECK.DETECT_FACE:
        checks.push({
          ...check,
          content: <FaceCheckIntro />,
          label,
          name: <FormattedMessage {...messages.personVerification} />,
        });

        checks.push({ ...check, content: <FaceCheck />, label });

        break;
      case CHECK.ROOM:
        checks.push({
          ...check,
          content: <RoomCheckIntro />,
          label,
          name: <FormattedMessage {...messages.roomCheck} />,
          isRoomChekIntro: true,
        });

        for (const subChecklist of sortBy(
          prop('position'),
          check.subChecklists
        )) {
          checks.push({
            ...subChecklist,
            parentHeadline: check.headline,
            parentInstructionsText: check.instructionsText,
            content: <RoomCheck {...subChecklist} />,
            label,
          });
        }

        break;
      case CHECK.PROCESS_APP:
        checks.push({
          ...check,
          content: <CheckProcess />,
          label,
          name: <FormattedMessage {...messages.deviceCheckup} />,
        });
        break;
      case CHECK.EXAM:
        break;

      default:
        console.error(`runCheck type of '${check.runCheck}' not recognized !`);
    }
  }

  return [
    ...checks,
    {
      label: checks.length,
      content: <Outro />,
      name: <FormattedMessage {...messages.exam} />,
    },
  ];
};

export default prepareChecks;
