import React from 'react';
import base64Image from '../utils/images';
import { ResourceManager } from '../resources/ResourceManager';
import scioLogo from '../assets/logo-scio.svg';

const Err404Page = () => {
  return (
    <div>
      <img src={scioLogo} alt="" className="logo-icon" />
      <div className="error-panel panel-center mt-5">
        <img className="error" src={base64Image('x-close')} alt="" />
        <div>
          <h1 className="h1-lg">{ResourceManager('err404')}</h1>
          <h2>{ResourceManager('err404Title')}</h2>
          <p className="text-description">{ResourceManager('err404Desc')}</p>
        </div>
        <div className="row error-buttons">
          <div className="col-md-12 mt-2 text-center">
            <button
              onClick={() => {
                window.location.href = process.env.REACT_APP_PROFILE_URL;
              }}
              type="button"
              className="btn btn-primary"
            >
              {ResourceManager('err404Btn')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Err404Page;
