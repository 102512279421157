const translation = {
  cz: {
    'global.agreeWithAiDev':
      'Souhlasím s využitím záznamu ze zkoušky pro účely dalšího vývoje proctoringu v aplikaci ScioLink (nepovinné).',
    'global.examPreparingDontRestartBrowser':
      'Stránka se načítá. Nevypínejte ani neobnovujte webový prohlížeč.',
    'global.desktopControl': 'Kontrola procesů',
    'global.haveReadAndAgreeWGDPR':
      'Přečetl/a jsem si a souhlasím s výše uvedenými zásadami zpracování osobních údajů.',
    'global.haveReadAndAgreeWExamRules':
      'Přečetl/a jsem si a souhlasím s výše uvedenými podmínkami zkoušky.',
    'global.continue': 'Pokračovat',
    'global.stopRecording': 'Zastavit nahrávání',
    'global.exam': 'Přejít k testu',
    'global.startRecording': 'Zahájit záznam',
    'global.recordAgain': 'Zaznamenat znovu',
    'global.downloadApp': 'Stáhnout aplikaci',
    'global.startProctoringDesktop': 'Spustit Proctoring Desktop',
    'global.downloadProctoringDesktop': 'Stáhnout Proctoring Desktop',
    'global.startApplication': 'Spustit aplikaci',
    'global.examWillBeRecorded':
      'Po kliknutí na tlačítko „Pokračovat“ bude zahájeno monitorování pomocí nahrávání videa, zvuku a obrazovky.',
    'global.errorChooseMonitor': 'Povolte sdílení celé obrazovky monitoru.',
    'global.systemDisallowsScreenSharing':
      'Povolte sdílení obrazovky pro aplikaci Chrome v nastavení ochrany osobních údajů v operačním systému.',
    'global.errorDontStopSharing':
      'Ponechte sdílení celé obrazovky monitoru zapnuté po celou dobu zkoušky, jinak může být výsledek zkoušky zablokován.',
    'global.stillUploadingWarning':
      'Nezavírejte stránku. V tomto okamžiku probíhá odesílání dat na naše servery. Tento proces může trvat několik minut.',
    'global.uploadFailedWarning':
      'Odesílání dat trvá déle než obvykle. Postupujte podle následujících instrukcí. Zavřete stránku s testem, vraťte se do seznamu zkoušek a klikněte na tlačítko “Pokračovat”. Aplikace vás upozorní na možnost ztráty dat. Toto upozornění můžete ignorovat. V případě dotazů kontaktujte naši podporu na scio@scio.cz.',
    'global.takeShot': 'Vyfotit',
    'global.takeShotAgain': 'Vyfotit znovu',
    'global.mirrorTurn': 'Zrcadlově otočit',
    'global.warning': 'Varování',
    'global.back': 'Zpět',
    'global.technicalChecks': 'Nastavení',
    'global.checkYourConditions':
      'Zkontrolujte video, zvuk a rychlost připojení',
    'global.makeSureCameraIsConnected':
      'Zkontrolujte připojení kamery k počítači.',
    'global.allowCameraInOs':
      'Povolte použití kamery v operačním systému a obnovte stránku.',
    'global.thenSelectCam':
      'Následně vyberte z nabídky kameru, kterou chcete použít.',
    'global.allowMicrophonePrompt': 'Povolte přístup k mikrofonu',
    'global.allowMicrophonePromptDesc':
      'V nastavení webového prohlížeče povolte použití mikrofonu a vyberte z nabídky mikrofon, který chcete použít. Poté něco řekněte a zkontrolujte, zda je vás slyšet.',
    'global.noCamerasFound': 'Nebyla nalezena žádná kamera.',
    'global.noMicFound': 'Nebyl nalezen žádný mikrofon.',
    'global.connectAndRestart': 'Připojte zařízení a obnovte stránku.',
    'global.microphoneDisallowedPrompt':
      'Povolte webovému prohlížeči použití mikrofonu.',
    'global.selectMicrophoneLabel': 'Audio',
    'global.networkSpeedMeasuring': 'Otestujte rychlost připojení',
    'global.measureSpeed': 'Změřit rychlost',
    'global.networkSpeedMeasuringResultSufficient':
      'Rychlost internetového připojení je dostatečná, můžete pokračovat.',
    'global.giveApprovals': 'Udělení souhlasů',
    'global.shareScreen': 'Sdílení obrazovky',
    'global.shareScreenHeading': 'Nastavení sdílení obrazovky',
    'global.shareScreenPrompt': 'Jak správně sdílet obrazovku',
    'global.shareScreenPromptDesc':
      'Po kliknutí na tlačítko “Pokračovat” se objeví vyskakovací okno, ve kterém nejprve klikněte na "Celá obrazovka", poté vyberte obrazovku a nakonec stiskněte tlačítko "Sdílet". Postup je znázorněn na obrázku níže.',
    'global.authentication': 'Ověření totožnosti',
    'global.anonymizeId': 'Skrýt nepovinné údaje',
    'global.cancelAnonymization': 'Zrušit rozmazání',
    'global.procAppReady':
      'Aplikace Proctoring Desktop je připravena, můžete pokračovat.',
    'global.zoomIn': 'Přibližte',
    'global.recordRoom': 'Naskenujte místnost',
    'global.zoomInId': 'Přibližte doklad',
    'global.face': 'Obličej ',
    'global.onFrontPage': 'Přední stranou',
    'global.continueIfDataReadable':
      'Zkontrolujte, že jsou údaje na fotografii dokladu totožnosti ostré a čitelné. Pokud si přejete uložit jen nezbytné údaje, můžete nepotřebné údaje na dokladu vybrat a skrýt stisknutím tlačítka "Vybrat údaje". V případě, že si přejete uložit fotografii celého dokladu, stiskněte „Pokračovat“.',
    'global.continueIfPhotoOk':
      'Pokud je fotografie ostrá, stiskněte tlačítko „Pokračovat“',
    'global.tryAgainSomethingsWrong': 'Zkuste to znovu.',
    'global.checkIfRecordingOk': 'Zkontrolujte kvalitu záznamu',
    'global.continueIfRecordingOk':
      'Pokud je záznam v pořádku, stiskněte tlačítko „Pokračovat“',
    'global.examIfRecordingOk':
      'Pokud je záznam v pořádku, stiskněte tlačítko „Přejít k testu“',
    'global.howPhotoshootId': 'Jak vyfotit doklad?',
    'global.howPhotoshootFace': 'Jak vyfotit obličej?',
    'global.howPhotoshootFaceDesc':
      'Podívejte se do kamery a vyfoťte váš obličej pro porovnání s vaším dokladem.',
    'global.howRecordRoom': 'Jak správně naskenovat místnost?',
    'global.howRecordRoomDesc':
      'Než zahájíte sken místnosti, připravte si všechny povolené pomůcky ke zkoušce a také vše, co budete pro natočení místnosti potřebovat. Zejména připomínáme zrcátko nebo zrcadlo pro natočení monitoru a klávesnice. Vše natočte pomalu a důkladně. Aplikace vás provede jednotlivými kroky.',
    'global.runProcApp': 'Zapněte aplikaci Proctoring Desktop.',
    'global.everythingsReady': 'Vše je připraveno.',
    'global.somethingWentWrong': 'Kontrola nepovolených aplikací',
    'global.closeDisallowedApps':
      'Zavřete následující aplikace, poté bude možné pokračovat:',
    'global.roomState': 'Probíhá záznam',
    'global.noInternet':
      'Nefunkční připojení k internetu. V řešení zkoušky můžete pokračovat bez připojení.',
    'global.checkInternet': 'Zkontrolujte připojení k internetu.',
    'global.runApp': 'Zapnout aplikaci',
    'global.turnOnWebCamAndRestartBrowser':
      'Zkontrolujte, že kamera je správně připojena k počítači, není využívána jinou aplikací a je povolena v nastavení operačního systému i webového prohlížeče. Poté obnovte stránku.',
    'global.anonymizeIdDesc':
      'Pomocí myši vyberte části dokladu, které si přejete rozmazat.',
    'global.anonymizeIdDescItems':
      'Zkontrolujte, že čitelné zůstaly povinné údaje: jméno, příjmení, datum narození, fotografie.',
    'global.playbackDisplay': 'Zobrazit náhled videa',
    'global.playbackHide': 'Skrýt náhled videa',
    'global.expand': 'Rozbalit',
    'global.shrink': 'Sbalit',
    'global.camera': 'Kamera',
    'global.unknownCamera': 'Neznámá kamera',
    'global.microphone': 'Mikrofon',
    'global.unknownMic': 'Neznámý mikrofon',
    'global.connectionSpeed': 'Rychlost připojení',
    'global.dontLeaveIfNotIntented': 'Stránka se načítá.',
    'global.storageCapacity': 'Kapacita úložiště',
    'global.measureCapacity': 'Změřit kapacitu',
    'global.storageAmountSufficient': 'Kapacita úložiště je dostatečná.',
    'global.storageAmountLow': 'Kapacita úložiště není dostatečná.',
    'global.storageAmountLowDesc':
      'Pro zahájení zkoušky je potřeba uvolnit minimálně 1 GB prostoru v úložišti webového prohlížeče, jinak aplikace nebude fungovat správně.',
    'global.connectionSpeedLow': 'Internetové připojení je příliš pomalé',
    'global.connectionSpeedLowDesc':
      'Zkoušku není možné zahájit z důvodu pomalého připojení k internetu. Pro zahájení zkoušky je potřeba nejméně rychlost 8 Mb/s stahování a 1 Mb/s nahrávání.',
    'global.storageAmountLowerWarning':
      'Velikost dostupného úložiště ve webovém prohlížeči klesla pod 300 MB. Zkontrolujte připojení k internetu.',
    'global.preparation': 'Příprava',
    'global.allowCamAccess': 'Povolte přístup ke kameře',
    'global.saySomethingToContinue':
      'Řekněte něco do mikrofonu, aby bylo možné pokračovat',
    'global.selectMicrophone': 'Vyberte mikrofon',
    'global.storageCheckup': 'Kontrola kapacity úložiště',
    'global.storageCheckupDesc':
      'Zkontrolujte kapacitu dostupného úložiště ve webovém prohlížeči.',
    'global.check': 'Zkontrolovat',
    'global.checkAgain': 'Zkontrolovat znovu',
    'global.checkSuccess': 'Kontrola proběhla úspěšně',
    'global.youCanContinueToNextStep': 'Můžete pokračovat na další krok',
    'global.connectionCheckup': 'Kontrola připojení',
    'global.checkConnectionDesc':
      'Zkontrolujte rychlost internetového připojení.',
    'global.welcomeInExam': 'Vítejte ve zkoušce',
    'global.testIsWatchedBePrepeared':
      'Tato zkouška je monitorována pomocí vzdáleného dohledu. Ujistěte se, že máte připraveno vše potřebné.',
    'global.youGonnaNeedForExam': 'Na zkoušku budete potřebovat',
    'global.idCard': 'Doklad totožnosti',
    'global.webcam': 'Webovou kameru',
    'global.cleanTable': 'Vyklizenou pracovní desku',
    'global.oneGBFreeSpaceonDisk': 'Alespoň 1 GB volného místa v úložišti',
    'global.deviceCheckup': 'Kontrola zařízení',
    'global.idCheckShowAllDetails': 'Všechny důležité údaje musí být viditelné',
    'global.idCheckAllMustBeClear': 'Všechny důležité údaje musí být ostré',
    'global.idCheckBackWithHand':
      'Pro lepší zaostření obrazu je možné doklad podložit',
    'global.idCheckShouldLookLike': 'Takto by to mělo vypadat',
    'global.identityVerification': 'Ověření totožnosti',
    'global.textContinueDownward': 'Text pokračuje směrem dolů',
    'global.support': 'Podpora',
    'global.roomCheck': 'Kontrola místnosti',
    'global.playVideo': 'Přehrát video',
    'global.replayAgain': 'Přehrát znovu',
    'global.playbackReadyWarning': `Předtím, než budete pokračovat, přehrajte video a zkontrolujte, zda je vše v pořádku. Pokud budete s videem spokojeni, stiskněte tlačítko "Pokračovat", čímž se dostanete k dalšímu kroku.`,
    'global.restartRoomCheck': 'Začít znovu kontrolu místnosti',
    'global.personVerification': 'Ověření osoby',
    'global.idVerification': 'Ověření dokladu',
    'global.introIdDesc':
      'Vyfoťte svůj doklad tak, že ho přiložíte ke kameře. Váš doklad by měl vyplnit celou plochu videa. Se zaostřením kamery může pomoci podložení dokladu.',
    'global.introFaceDesc':
      'Podívejte se do kamery a vyfoťte váš obličej pro porovnání s vaším dokladem.',
    'global.introRoomDesc':
      'Než zahájíte sken místnosti, připravte si všechny povolené pomůcky ke zkoušce a také vše, co budete pro natočení místnosti potřebovat. Zejména připomínáme zrcátko nebo zrcadlo pro natočení monitoru a klávesnice. Vše natočte pomalu a důkladně. Aplikace vás provede jednotlivými kroky.',
    'global.everythingIsSet': 'Všechno je nastaveno',
    'global.initialChecksCompleted':
      'Úvodní kontroly byly úspěšně dokončeny. Neopouštějte své pracovní místo. Můžete začít test.',
    'global.faceCheckShowWholeFace': 'Musí být vidět celý obličej',
    'global.faceCheckAllMustBeClear': 'Obličej musí být zaostřený',
    'global.faceCheckMoveFromLight': 'Obličej musí být zřetelný',
    'global.faceCheckShouldLookLike': 'Takto by to mělo vypadat',
    'global.resetRoomCheck': 'Začít znovu kontrolu místnosti',
    'global.resetRoomCheckConfim':
      'Opravdu chcete začít znovu všechny kroky kontroly místnosti?',
    'global.reset': 'Začít znovu',
    'global.goBack': 'Zpět',
    'global.selectCam': 'Vyberte kameru',
    'global.checkStorage': 'Zkontrolovat úložiště',
    'global.checkConnection': 'Zkontrolovat připojení',
    'global.timerDisplayPassed':
      'Test je nutné začít do {limit} minut. Jinak může dojít k zablokování výsledku zkoušky. V případě technického problému se nám ozvěte.',
    'global.timerWarningPassed':
      'Test je nutné začít do {limit} minut. Jinak může dojít k zablokování výsledku zkoušky. V případě technického problému se nám ozvěte.',
    'global.timerLimitPassed':
      'Čas na úvodní kontroly vypršel. Může dojít k zablokování výsledku zkoušky.',
    'global.timeLeft': 'Zbývající čas na úvodní kontroly',
    'global.proctoringNotInstalled': 'Proctoring Desktop ještě nemám',
    'global.proctoringInstalled': 'Proctoring Desktop už mám',
    'global.clickOnThisButton': 'Klikněte na toto tlačítko',
    'global.openInstallationFile':
      'Otevřete instalační soubor a postupujte dle instrukcí.',
    'global.clickButtonWhenFinished':
      'Po dokončení instalace klikněte na toto tlačítko. Klikněte pouze jednou!',
    'global.makeSureAppStarted':
      'Ujistěte se, že se aplikace Proctoring Desktop spustila.',
    'global.waitSuccessfulResult': 'Počkejte na úspěšný výsledek kontroly.',
    'global.returnAndClickContinue':
      'Vraťte se na tuto stránku a klikněte na tlačítko "Pokračovat".',
  },
  sk: {
    'global.agreeWithAiDev':
      'Súhlasím s využitím záznamu zo skúšky na účely ďalšieho vývoja proctoringu v aplikácii ScioLink (nepovinné).',
    'global.examPreparingDontRestartBrowser':
      'Stránka sa načíta. Nevypínajte ani neobnovujte váš prehliadač.',
    'global.desktopControl': 'Kontrola procesov',
    'global.haveReadAndAgreeWGDPR':
      'Prečítal/a som si a súhlasím s vyššie uvedenými zásadami spracovania osobných údajov.',
    'global.haveReadAndAgreeWExamRules':
      'Prečítal/a som si a súhlasím s vyššie uvedenými podmienkami skúšky.',
    'global.continue': 'Pokračovať',
    'global.stopRecording': 'Zastaviť nahrávanie',
    'global.exam': 'Prejsť k testu',
    'global.startRecording': 'Zahájiť záznam',
    'global.recordAgain': 'Zaznamenať znovu',
    'global.downloadApp': 'Stiahnuť aplikáciu',
    'global.startProctoringDesktop': 'Spustiť Proctoring Desktop',
    'global.downloadProctoringDesktop': 'Stiahnuť Proctoring Desktop',
    'global.startApplication': 'Spustiť aplikáciu',
    'global.examWillBeRecorded':
      'Po kliknutí na tlačidlo „Pokračovať“ bude zahájené monitorovanie pomocou nahrávania videa, zvuku a obrazovky.',
    'global.errorChooseMonitor': 'Povoľte zdieľanie celej obrazovky monitoru.',
    'global.systemDisallowsScreenSharing':
      'Povoľte zdieľanie obrazovky pre aplikáciu Chrome v nastaveniach ochrany osobných údajov vo vašom operačnom systéme.',
    'global.errorDontStopSharing':
      'Ponechajte zdieľanie celej obrazovky monitoru zapnuté po celú dobu skúšky, inak môže byť výsledok testu zablokovaný.',
    'global.stillUploadingWarning':
      'Nezatvárajte stránku. V tomto čase sa údaje odosielajú na naše servery. Tento proces môže trvať niekoľko minút.',
    'global.uploadFailedWarning':
      'Odosielanie údajov trvá dlhšie ako zvyčajne. Postupujte podľa týchto pokynov. Zatvorte testovaciu stránku, vráťte sa do zoznamu testov a kliknite na tlačidlo "Pokračovať". Aplikácia vás upozorní na možnosť straty údajov. Toto upozornenie môžete ignorovať. Ak máte akékoľvek otázky, kontaktujte náš tím podpory na adrese scio@scio.cz.',
    'global.takeShot': 'Vyfotiť',
    'global.takeShotAgain': 'Vyfotiť znova',
    'global.mirrorTurn': 'Zrkadlovo otočiť',
    'global.warning': 'Varovanie',
    'global.back': 'Späť',
    'global.technicalChecks': 'Konfigurácia',
    'global.checkYourConditions':
      'Skontrolujte video, zvuk a rýchlosť pripojenia',
    'global.makeSureCameraIsConnected':
      'Uistite sa, že kamera správne pripojená k počítaču.',
    'global.allowCameraInOs':
      'Povoľte použitie kamery v operačnom systéme a načítajte stránku znova.',
    'global.thenSelectCam':
      'Následne vyberte z ponuky kameru, ktorú chcete použiť.',
    'global.allowMicrophonePrompt': 'Povolte prístup k mikrofónu',
    'global.allowMicrophonePromptDesc':
      'V nastavení webového prehliadača povoľte použitie mikrofónu a vyberte z ponuky mikrofón, ktorý chcete použiť. Potom niečo povedzte a skontrolujte, či je vás počuť.',
    'global.noCamerasFound': 'Žiadna kamera nebola nájdená.',
    'global.noMicFound': 'Žiadny mikrofón nebol nájdený.',
    'global.connectAndRestart': 'Pripojte zariadenie a obnovte stránku.',
    'global.microphoneDisallowedPrompt':
      'Povoľte webovému prehliadaču používať mikrofón.',
    'global.selectMicrophoneLabel': 'Audio',
    'global.networkSpeedMeasuring': 'Otestujte rýchlosť pripojenia',
    'global.measureSpeed': 'Zmerať rýchlosť',
    'global.networkSpeedMeasuringResultSufficient':
      'Rýchlosť internetového pripojenia je dostatočná, môžete pokračovať.',
    'global.giveApprovals': 'Udelenie súhlasov',
    'global.shareScreen': 'Zdieľanie obrazovky',
    'global.shareScreenHeading': 'Nastavenie zdieľanie obrazovky',
    'global.shareScreenPrompt': 'Ako správne zdieľať obrazovku',
    'global.shareScreenPromptDesc':
      'Po kliknutí na tlačidlo “Pokračovať” sa zobrazí vyskakovacie okno, v ktorom najprv kliknite na "Celá obrazovka", potom vyberte obrazovku a nakoniec stlačte tlačidlo "Zdieľať". Postup je znázornený na obrázku nižšie.',
    'global.authentication': 'Overenie totožnosti',
    'global.anonymizeId': 'Skryť voliteľné údaje',
    'global.cancelAnonymization': 'Zrušiť rozmazanie',
    'global.procAppReady':
      'Aplikácia Proctoring Desktop je pripravená, môžete pokračovať.',
    'global.zoomIn': 'Priblížte',
    'global.recordRoom': 'Naskenujte miestnosť',
    'global.zoomInId': 'Priblížte doklad',
    'global.face': 'Tvár',
    'global.onFrontPage': 'Prednou stranou',
    'global.continueIfDataReadable':
      'Uistite sa, že údaje na doklade totožnosti sú ostré a čitateľné. Ak chcete uložiť len potrebné údaje, môžete vybrať a skryť nepotrebné údaje na dokumente stlačením tlačidla "Vybrať údaje". Ak chcete uložiť fotografiu celého dokumentu, stlačte tlačidlo "Pokračovať".',
    'global.continueIfPhotoOk':
      'Pokiaľ je fotografia ostrá, kliknite na tlačidlo „Pokračovať“',
    'global.tryAgainSomethingsWrong': 'Skúste to znova.',
    'global.checkIfRecordingOk': 'Skontrolujte kvalitu záznamu',
    'global.continueIfRecordingOk':
      'Pokiaľ je záznam v poriadku, kliknite na tlačidlo „Pokračovať“',
    'global.examIfRecordingOk':
      'Pokiaľ je záznam v poriadku, kliknite na tlačidlo „Prejsť k testu“',
    'global.howPhotoshootId': 'Ako vyfotiť doklad?',
    'global.howPhotoshootFace': 'Ako vyfotiť tvár?',
    'global.howPhotoshootFaceDesc':
      'Pozrite sa do kamery, aby bolo možné vyfotiť vašu tvár pre porovnanie s vašim dokladom.',
    'global.howRecordRoom': 'Ako správne naskenovať miestnosť?',
    'global.howRecordRoomDesc':
      'Než začnete sken miestnosti, pripravte si všetky povolené pomôcky na skúšku a tiež všetko, čo budete pre natočenie miestnosti potrebovať. Najmä pripomíname zrkadielko alebo zrkadlo na natočenie monitora a klávesnice. Všetko natočte pomaly a dôkladne. Aplikácia vás prevedie jednotlivými krokmi.',
    'global.runProcApp': 'Spustite aplikáciu Proctoring Desktop.',
    'global.everythingsReady': 'Všetko je pripravené.',
    'global.somethingWentWrong': 'Kontrola nepovolených aplikácií',
    'global.closeDisallowedApps':
      'Zavrite niektoré aplikácie, aby bolo možné pokračovať v ďalšom kroku:',
    'global.roomState': 'Prebiehajúce nahrávanie',
    'global.noInternet':
      'Nefungujúce internetové pripojenie. V skúške môžete pokračovať aj bez pripojenia.',
    'global.checkInternet': 'Skontrolujte internetové pripojenie.',
    'global.runApp': 'Zapnúť aplikáciu',
    'global.turnOnWebCamAndRestartBrowser':
      'Skontrolujte, či je kamera správne pripojená k počítaču, či ju nepoužíva iná aplikácia a či je povolená v nastaveniach operačného systému a webového prehliadača. Potom obnovte stránku.',
    'global.anonymizeIdDesc':
      'Pomocou myši vyberte časti dokladu, ktoré si prajete rozmazať.',
    'global.anonymizeIdDescItems':
      'Uistite sa, že požadované údaje sú čitateľné: meno, priezvisko, dátum narodenia, fotografia.',
    'global.playbackDisplay': 'Zobraziť náhľad videa',
    'global.playbackHide': 'Skryť náhľad videa',
    'global.expand': 'Rozbaliť',
    'global.shrink': 'Zbaliť',
    'global.camera': 'Kamera',
    'global.unknownCam': 'Neznáma kamera',
    'global.microphone': 'Mikrofón',
    'global.unknownMic': 'Neznámy mikrofón',
    'global.connectionSpeed': 'Rýchlosť pripojenia',
    'global.dontLeaveIfNotIntented': 'Načítanie stránky.',
    'global.storageCapacity': 'Úložná kapacita',
    'global.measureCapacity': 'Zmerať kapacitu',
    'global.storageAmountSufficient':
      'Kapacita úložiska prehliadača je dostatečná.',
    'global.storageAmountLow':
      'Kapacita úložiska prehliadača nie je dostatočná.',
    'global.storageAmountLowDesc':
      'Na spustenie testu je potrebné uvoľniť aspoň 1 GB miesta v úložisku prehliadača, inak aplikácia nebude fungovať správne. Uistite sa, že nie ste v anonymnom režime alebo v profile hosťa.',
    'global.connectionSpeedLow': 'Internetové pripojenie je příliš pomalé',
    'global.connectionSpeedLowDesc':
      'Skúšku nie je možné spustiť z dôvodu pomalého internetového pripojenia. Na začatie skúšky je potrebná minimálna rýchlosť sťahovania 8 Mb/s a odosielania 1 Mb/s.',
    'global.storageAmountLowerWarning':
      'Dostupné miesto v úložisku webového prehliadača kleslo pod 300 MB. Skontrolujte svoje internetové pripojenie.',
    'global.preparation': 'Príprava',
    'global.allowCamAccess': 'Povolte prístup ku kamere',
    'global.saySomethingToContinue':
      'Pre pokračovanie povedzte niečo do mikrofónu',
    'global.selectMicrophone': 'Vyberte mikrofón',
    'global.storageCheckup': 'Kontrola kapacity úložiska',
    'global.storageCheckupDesc':
      'Skontrolujte kapacitu úložiska vo webovom prehliedači.',
    'global.check': 'Skontrolovať',
    'global.checkAgain': 'Skontrolovať znovu',
    'global.checkSuccess': 'Kontrola prebehla úspešne',
    'global.youCanContinueToNextStep': 'Môžete pokračovať na ďalší krok',
    'global.connectionCheckup': 'Kontrola pripojenia',
    'global.checkConnectionDesc':
      'Skontrolujte rýchlosť internetového pripojenia.',
    'global.welcomeInExam': 'Vitajte v skúške',
    'global.testIsWatchedBePrepeared':
      'Táto skúška je monitorovaná. Uistite sa že máte pripravené potrebné veci.',
    'global.youGonnaNeedForExam': 'Na skúšku budete potrebovať',
    'global.idCard': 'Doklad totožnosti',
    'global.webcam': 'Webkameru',
    'global.cleanTable': 'Vypratanú pracovnú dosku',
    'global.oneGBFreeSpaceonDisk': 'Alespoň 1 GB volného miesta v úložišti',
    'global.deviceCheckup': 'Kontrola zariadenia',
    'global.idCheckShowAllDetails': 'Všetky dôležité údaje musia byť viditeľné',
    'global.idCheckAllMustBeClear': 'Všetky dôležité údaje musia byť ostré',
    'global.idCheckBackWithHand':
      'Pre lepšie zaostrenie obrazu je možné doklad podložiť',
    'global.idCheckShouldLookLike': 'Takto by to malo vyzerať',
    'global.identityVerification': 'Overenie totožnosti',
    'global.textContinueDownward': 'Text pokračuje smerom dole',
    'global.support': 'Podpora',
    'global.roomCheck': 'Kontrola miestnosti',
    'global.playVideo': 'Prehrať video',
    'global.replayAgain': 'Prehrať znova',
    'global.playbackReadyWarning': `Predtím, než budete pokračovať prehrajte video a skontrolujte, nech je všetko v poriadku. Ak budete spokojní, stlačte tlačidlo „Pokračovať“, čím sa dostanete do ďalšieho kroku.`,
    'global.restartRoomCheck': 'Začať odznova kontrolu miestností',
    'global.personVerification': 'Overenie osoby',
    'global.idVerification': 'Overenie dokladu',
    'global.introIdDesc':
      'Odfoťte svoj preukaz totožnosti priložením k fotoaparátu. Váš doklad by mal vyplniť celú plochu videa. Pri zaostrovaní kamery môže pomôcť podloženie dokladu.',
    'global.introFaceDesc':
      'Pozrite sa do kamery, aby bolo možné naskenovať Vašu tvár pre porovnanie s Vaším dokladom.',
    'global.introRoomDesc':
      'Než začnete sken miestnosti, pripravte si všetky povolené pomôcky na skúšku a tiež všetko, čo budete pre natočenie miestnosti potrebovať. Najmä pripomíname zrkadielko alebo zrkadlo na natočenie monitora a klávesnice. Všetko natočte pomaly a dôkladne. Aplikácia vás prevedie jednotlivými krokmi.',
    'global.everythingIsSet': 'Všetko je nastavené',
    'global.initialChecksCompleted':
      'Počiatočné kontroly boli úspešne ukončené. Teraz už neopúšťajte svoje pracovné miesto. Môžete spustiť test.',
    'global.faceCheckShowWholeFace': 'Musí byť viditeľná celá tvár',
    'global.faceCheckAllMustBeClear': 'Tvár musí byť zaostrená',
    'global.faceCheckMoveFromLight': 'Tvár musí byť jasná',
    'global.faceCheckShouldLookLike': 'Takto by to malo vyzerať',
    'global.resetRoomCheck': 'Začať odznova kontrolu miestnosti',
    'global.resetRoomCheckConfim':
      'Naozaj chcete začať odznova všetky kroky kontroly miestnosti?',
    'global.reset': 'Resetovať',
    'global.goBack': 'Späť',
    'global.selectCam': 'Vyberte kameru',
    'global.checkStorage': 'Skontrolovať úložisko',
    'global.checkConnection': 'Skontrolovať pripojenie',
    'global.timerDisplayPassed':
      'Test sa musí začať do {limit} minút. V opačnom prípade môže byť výsledok testu zablokovaný. V prípade technických problémov nás kontaktujte.',
    'global.timerWarningPassed':
      'Test sa musí začať do {limit} minút. V opačnom prípade môže byť výsledok testu zablokovaný. V prípade technických problémov nás kontaktujte.',
    'global.timerLimitPassed':
      'Čas na počiatočné kontroly uplynul. Výsledky testov sa môžu oneskoriť.',
    'global.timeLeft': 'Zostávajúci čas na počiatočné kontroly',
    'global.proctoringNotInstalled': 'Ešte nemám aplikáciu Proctoring Desktop',
    'global.proctoringInstalled': 'Už mám aplikáciu Proctoring Desktop',
    'global.clickOnThisButton': 'Kliknite na toto tlačidlo',
    'global.openInstallationFile':
      'Otvorte inštalačný súbor a postupujte podľa pokynov.',
    'global.clickButtonWhenFinished':
      'Po dokončení inštalácie kliknite na toto tlačidlo. Kliknite iba raz!',
    'global.makeSureAppStarted':
      'Uistite sa, že sa aplikácia Proctoring Desktop spustila.',
    'global.waitSuccessfulResult': 'Počkajte na úspešný výsledok kontroly.',
    'global.returnAndClickContinue':
      'Vráťte sa na túto stránku a kliknite na tlačidlo "Pokračovať".',
  },
  ua: {
    'global.agreeWithAiDev':
      'Я даю згоду на використання запису іспиту для подальшої розробки прокторингу в програмі ScioLink (необов’язково).',
    'global.examPreparingDontRestartBrowser':
      'Іспит завантажується. Не вимикайте та не оновлюйте веб-браузер.',
    'global.desktopControl': 'Контроль процесів',
    'global.haveReadAndAgreeWGDPR':
      'Я прочитав/ла і погоджуюся з вищенаведеною політикою конфіденційності.',
    'global.haveReadAndAgreeWExamRules':
      'Я прочитав/ла й погоджуюся з вищенаведеними умовами іспиту.',
    'global.continue': 'Продовжити',
    'global.stopRecording': 'Зупинити завантаження',
    'global.exam': 'Перейти до тесту',
    'global.startRecording': 'Почати запис',
    'global.recordAgain': 'Записати знову',
    'global.downloadApp': 'Завантажте додаток',
    'global.startProctoringDesktop': 'Запустити Proctoring Desktop',
    'global.downloadProctoringDesktop': 'Завантажити Proctoring Desktop',
    'global.startApplication': 'Запустіть програму',
    'global.examWillBeRecorded':
      'Після натискання кнопки «Продовжити» почнеться моніторинг за допомогою запису відео, звуку та зображення екрана.',
    'global.errorChooseMonitor':
      'Будь ласка, увімкніть режим демонстрації екрана на своєму моніторі.',
    'global.systemDisallowsScreenSharing':
      'Будь ласка, увімкніть режим демонстрації екрана для програми Chrome у налаштуваннях конфіденційності вашої операційної системи.',
    'global.errorDontStopSharing':
      'Залиште режим поширення екрана увімкнутим протягом іспиту, інакше вас можуть вилучити з іспиту.',
    'global.stillUploadingWarning':
      'Будь ласка, не закривайте браузер. На цей момент ми досі надсилаємо необхідні дані на наші сервери.',
    'global.uploadFailedWarning':
      'Завантаження даних на наші сервери займає більше часу, ніж зазвичай. Ми надішлемо вам email із інструкціями, як завантажити дані вручну. Якщо ви не отримаєте повідомлення протягом наступних дванадцяти годин, зв’яжіться з нашою службою підтримки за номером ',
    'global.takeShot': 'Сфотографувати',
    'global.takeShotAgain': 'Сфотографувати знову',
    'global.mirrorTurn': 'Розвернути дзеркально',
    'global.warning': 'Застереження',
    'global.back': 'Назад',
    'global.technicalChecks': 'Налаштування',
    'global.checkYourConditions':
      'Перевірте відео, звук та швидкість з’єднання',
    'global.makeSureCameraIsConnected':
      'Переконайтеся, що камеру правильно підключено до комп’ютера.',
    'global.allowCameraInOs':
      'Увімкніть використання камери у своїй операційній системі та перезавантажте сторінку.',
    'global.thenSelectCam':
      'Потім виберіть у меню ту, яку хочете використовувати.',
    'global.allowMicrophonePrompt': 'Дозволити доступ до аудіо',
    'global.allowMicrophonePromptDesc':
      'Увімкніть мікрофон і скажіть щось. Увімкніть використання мікрофона в налаштуваннях браузера та виберіть у меню той, який ви хочете використовувати. Потім скажіть щось і переконайтеся, що вас чують.',
    'global.noCamerasFound': 'Жодної камери не виявлено.',
    'global.noMicFound': 'Мікрофон не знайдено.',
    'global.connectAndRestart':
      "Під'єднайте пристрій і перезапустіть веб-браузер.",
    'global.microphoneDisallowedPrompt':
      'Будь ласка, дозвольте браузеру використовувати ваш мікрофон.',
    'global.selectMicrophoneLabel': 'Аудіо',
    'global.networkSpeedMeasuring': "Перевірте швидкість з'єднання",
    'global.measureSpeed': 'Виміряйте швидкість',
    'global.networkSpeedMeasuringResultSufficient':
      "Швидкість вашого з'єднання достатня, можна продовжувати.",
    'global.giveApprovals': 'Надання згоди',
    'global.shareScreen': 'Спільний доступ до екрана',
    'global.shareScreenHeading': 'Налаштування спільного доступу до екрана',
    'global.shareScreenPrompt': 'Як правильно поділитися екраном',
    'global.shareScreenPromptDesc':
      'Після натискання кнопки «Продовжити» автоматично з’явиться вікно, коли ви вперше натиснете «Повний екран», потім виберіть екран і, нарешті, натисніть кнопку «Поділитися». Процедура показана на малюнку нижче. ',
    'global.authentication': 'Перевірка особи ',
    'global.anonymizeId': "Приховати необов'язкові дані",
    'global.cancelAnonymization': 'Скасувати розмиття',
    'global.procAppReady':
      'Програма Proctoring Desktop готова до роботи, можна продовжувати.',
    'global.zoomIn': 'Наблизьте',
    'global.recordRoom': 'Зіскануйте приміщення ',
    'global.zoomInId': 'Наблизьте документ',
    'global.face': 'Обличчя ',
    'global.onFrontPage': 'Передню сторону',
    'global.continueIfDataReadable':
      'Переконайтеся, що дані чіткі та розбірливі. Якщо ви хочете вибрати лише необхідні дані, натисніть кнопку «Вибрати дані». Якщо ви хочете зберегти зображення всього документа, натисніть «Продовжити».',
    'global.continueIfPhotoOk':
      'Якщо фотографія вийшла чіткою, натисніть «Продовжити»',
    'global.tryAgainSomethingsWrong': 'Спробуйте ще раз, щось не так',
    'global.checkIfRecordingOk': 'Будь ласка, перевірте якість запису',
    'global.continueIfRecordingOk':
      'Якщо запис у порядку, натисніть «Продовжити»',
    'global.examIfRecordingOk':
      'Якщо запис у порядку, натисніть «Перейти до тесту»',
    'global.howPhotoshootId': 'Як сфотографувати документ?',
    'global.howPhotoshootFace': 'Як сфотографувати обличчя?',
    'global.howPhotoshootFaceDesc':
      'Подивіться в камеру, щоб можна було сфотографувати ваше обличчя для порівняння з вашим посвідченням особи.',
    'global.howRecordRoom': 'Як правильно зафільмувати приміщення?',
    'global.howRecordRoomDesc':
      'Зробіть відеозапис приміщення, де відбуватиметься іспит.',
    'global.runProcApp': 'Запустіть програму Proctoring Desktop.',
    'global.everythingsReady': 'Усе готово.',
    'global.somethingWentWrong':
      'Перевірте наявність несанкціонованих додатків',
    'global.closeDisallowedApps':
      'ZЗакрийте, будь ласка, деякі програми, щоб перейти до наступного кроку:',
    'global.roomState': 'Стан приміщення',
    'global.noInternet':
      "Немає інтернет-з'єднання. Можна продовжити складати іспит у режимі офлайн.",
    'global.checkInternet': "Будь ласка, перевірте інтернет-з'єднання.",
    'global.runApp': 'Увімкніть програму',
    'global.turnOnWebCamAndRestartBrowser':
      'Переконайтеся, що камеру належним чином підключено до комп’ютера, не використовується іншою програмою та чи включено в налаштуваннях операційної системи та браузера.  Потім перезавантажте сторінку.',
    'global.anonymizeIdDesc':
      'За допомогою миші виділіть частини документа, які потрібно розмити.',
    'global.anonymizeIdDescItems':
      "Обов'язкові частини документа, які повинні залишатися розбірливими: ім'я, прізвище, дата народження, фотографія.",
    'global.playbackDisplay': 'Попередній вигляд відео',
    'global.playbackHide': 'Приховати попередній вигляд відео',
    'global.expand': 'Розгорнути',
    'global.shrink': 'Згорнути',
    'global.camera': 'Камера',
    'global.unknownCamera': 'Невідома камера',
    'global.microphone': 'Мікрофон',
    'global.unknownMic': 'Невідомий мікрофон',
    'global.connectionSpeed': "Швидкість з'єднання",
    'global.dontLeaveIfNotIntented': 'Не залишайте сайт..',
    'global.storageCapacity': 'Ємність для зберігання',
    'global.measureCapacity': 'Виміряти ємність',
    'global.storageAmountSufficient': 'Обсяг зберігання достатній.',
    'global.storageAmountLow': 'У вас недостатньо місця для зберігання',
    'global.storageAmountLowDesc':
      'Ви повинні звільнити принаймні 1 Гб місця, щоб продовжити, інакше програма не працюватиме належним чином.',
    'global.connectionSpeedLow': 'Ваше інтернет-з’єднання надто повільне',
    'global.connectionSpeedLowDesc':
      "Ви не можете скласти іспит через повільне з’єднання з інтернетом. Мінімальна швидкість з'єднання з інтернетом, необхідна для онлайн-іспиту, становить 8 Мбіт/с.",
    'global.storageAmountLowerWarning':
      'Обсяг сховища зменшився за 0,3 ГБ, негайно звільніть місце на диску.',
    'global.preparation': 'Підготовка',
    'global.allowCamAccess': 'Повольте приступити до камери',
    'global.saySomethingToContinue':
      'Ви повинні сказати щось у мікрофон, щоб продовжити',
    'global.selectMicrophone': 'Виберіть мікрофон',
    'global.storageCheckup': 'Перевірка місця для зберігання',
    'global.storageCheckupDesc': 'Перевірте обсяг пам’яті.',
    'global.check': 'Перевірте',
    'global.checkAgain': 'Перевірте ще раз',
    'global.checkSuccess': 'Перевірка пройшла успішно',
    'global.youCanContinueToNextStep':
      'Ви можете продовжити до наступного кроку',
    'global.connectionCheckup': 'Контроль припоєння',
    'global.checkConnectionDesc':
      "Перевірте потужність вашого Інтернет-з'єднання.",
    'global.welcomeInExam': 'Ласкаво просимо на іспит',
    'global.testIsWatchedBePrepeared':
      'Цей іспит контролюється, переконайтеся, що у вас є необхідні речі.',
    'global.youGonnaNeedForExam': 'Він вам знадобиться на іспиті',
    'global.idCard': 'посвідчення особи',
    'global.webcam': 'Веб-камера',
    'global.cleanTable': 'Порядок на стіл',
    'global.oneGBFreeSpaceonDisk': '1 ГБ вільного місця на диску',
    'global.deviceCheckup': 'Перевірка пристрою',
    'global.idCheckShowAllDetails':
      'Показати всі деталі, включаючи 2 нижні рядки',
    'global.idCheckAllMustBeClear': 'Всі деталі повинні бути чіткими',
    'global.idCheckBackWithHand':
      'Для кращого фокусування зображення документ можна підклеїти на підкладку',
    'global.idCheckShouldLookLike': 'Ось як це має виглядати',
    'global.identityVerification': 'Підтвердження особи',
    'global.textContinueDownward': 'Tекст продовжуються вниз',
    'global.support': 'підтримка',
    'global.roomCheck': 'Контроль кімнати',
    'global.playVideo': 'Відтворити відео',
    'global.replayAgain': 'Повторіть ще раз',
    'global.playbackReadyWarning': `Перш ніж продовжити, відтворіть відео, і нехай все буде добре. Якщо ви задоволені, натисніть кнопку «Продовжити», яка переведе вас до наступного кроку - „Звернути простір під вашою стільницею“. Переконайтеся, що дані чіткі та видимі. Якщо ви хочете вибрати лише необхідні дані для збереження, натисніть кнопку „Вибрати дані“. Якщо ви хочете зберегти зображення всього документа, натисніть „Продовжити“.`,
    'global.restartRoomCheck': 'Перезапустити контроль місткості',
    'global.personVerification': 'Перевірка особи',
    'global.idVerification': 'Перевірка ідентифікатора',
    'global.introIdDesc':
      'Сфотографуйте свою квитанцію, піднісши її до камери. Ваш документ повинен заповнювати всю область відео.',
    'global.introFaceDesc':
      'Подивіться на камеру, щоб ваше обличчя можна було відсканувати для порівняння з вашим посвідченням особи.',
    'global.introRoomDesc':
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.',
    'global.everythingIsSet': 'Все встановлено',
    'global.initialChecksCompleted':
      'Початкові перевірки успішно завершено. Не залишайте робоче місце. Можна починати тест.',
    'global.faceCheckShowWholeFace': 'Mає бути видно все обличчя',
    'global.faceCheckAllMustBeClear': 'Фото має бути різким',
    'global.faceCheckMoveFromLight': 'Фото повинно бути без відблисків',
    'global.faceCheckShouldLookLike': 'Це має виглядати так',
    'global.resetRoomCheck': 'Скинути контроль кімнати',
    'global.resetRoomCheckConfim':
      'Ви впевнені, що бажаєте скинути весь процес перевірки кімнати?',
    'global.reset': 'Скинути',
    'global.goBack': 'Повертайся',
    'global.selectCam': 'Виберіть камеру',
    'global.checkStorage': 'Перевірте сховище',
    'global.checkConnection': 'Перевірте з’єднання',
    'global.timerDisplayPassed':
      'Тест необхідно розпочати протягом {limit} хвилин. Інакше ваш результат може бути заблокований.',
    'global.timerWarningPassed':
      'Тест необхідно розпочати протягом {limit} хвилин. Інакше ваш результат може бути заблокований.',
    'global.timerLimitPassed':
      'Час для первинних перевірок закінчився. Ваш результат може бути заблокований.',
    'global.timeLeft': 'Залишився час для первинних перевірок',
    'global.proctoringNotInstalled': 'У мене ще немає Proctoring Desktop',
    'global.proctoringInstalled': 'У мене вже є Proctoring Desktop',
    'global.clickOnThisButton': 'Натисніть на цю кнопку',
    'global.openInstallationFile':
      'Відкрийте інсталяційний файл і дотримуйтесь інструкцій.',
    'global.clickButtonWhenFinished':
      'Коли інсталяція завершиться, натисніть цю кнопку. Натисніть лише один раз!',
    'global.makeSureAppStarted':
      'Переконайтеся, що додаток Proctoring Desktop запущено.',
    'global.waitSuccessfulResult':
      'Дочекайтеся успішного результату перевірки.',
    'global.returnAndClickContinue':
      'Поверніться на цю сторінку і натисніть кнопку Продовжити.',
  },
};

export default translation;
