const DisablePrintScreen = {
  add() {
    document.addEventListener('keyup', (e) => {
      const keyCode = e.keyCode ? e.keyCode : e.which;
      if (keyCode === 44) {
        navigator.clipboard.writeText('');
      }
    });
  },
};

export default DisablePrintScreen;
