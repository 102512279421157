import React, { useCallback, useRef, useState, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import { ResourceManager } from '../resources/ResourceManager';
import base64Image from '../utils/images';

const TestButtons = (props) => {
  const [disabled, setDisabled] = useState(props.nextButtonInitiallyDisabled);
  const [showScrollMore, setShowScrollMore] = useState();
  const timerDebounceRef = useRef();

  const handleScroll = useCallback(() => {
    if (timerDebounceRef.current) {
      clearTimeout(timerDebounceRef.current);
    }
    timerDebounceRef.current = setTimeout(() => {
      calculateScroll(50);
    }, 500);
  }, []);

  useEffect(() => {
    setShowScrollMore(props.testAppHasScrollbar);
  }, [props.testAppHasScrollbar]);

  useEffect(() => {
    let testApp;
    setTimeout(() => {
      testApp = document.getElementById('TestApp');
      if (testApp) {
        testApp.addEventListener('scroll', handleScroll);
      }
    }, 0);
    return () => {
      if (testApp) {
        testApp.removeEventListener('scroll', handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDisabled(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [disabled]);

  const calculateScroll = (scrollMargin = false) => {
    const testApp = document.getElementById('TestApp');
    const testAppScrollHeight = testApp.scrollHeight;
    const testAppVisibleHeight = testApp.clientHeight;
    const currentScroll = window.scrollY || testApp.scrollTop;
    const scrollAmount = scrollMargin || testAppScrollHeight * 0.3;

    let newScrollPosition = currentScroll + scrollAmount;

    if (newScrollPosition > testAppScrollHeight - testAppVisibleHeight) {
      // end of the page
      newScrollPosition = testAppScrollHeight - testAppVisibleHeight;
      setShowScrollMore(false);
    } else {
      setShowScrollMore(true);
    }

    return { testApp, newScrollPosition };
  };

  const scrollMore = () => {
    const { testApp, newScrollPosition } = calculateScroll();
    testApp.scrollTo({
      top: newScrollPosition,
      behavior: 'smooth',
    });
  };

  const leftButton = () => {
    if (props.isFirstItem || props.testModeSettings.disableLeftButton)
      return null;
    return (
      <button
        type="button"
        id="btnPrevious"
        className="btn btn-secondary float-left"
        onClick={() => {
          props.movePrevious();
          props.checkTestAppHasScrollbar();
          setShowScrollMore(true);
        }}
      >
        <img src={base64Image('arrow-left-gray700')} alt="" className="mr8" />
        {ResourceManager('btnNavigationPrevious')}
      </button>
    );
  };

  const scrollMoreButton = () => {
    return (
      <button type="button" id={'scrollMore'} className="btn">
        <div className="test-continues-polygon" />
        <div className="test-continues-overlay" />
        <div className="arrow-down-toggle">
          <img
            src={base64Image('arrow-scroll-more')}
            alt=""
            className="arrow-down"
            onClick={() => scrollMore()}
            tabIndex="-1"
            aria-hidden="true"
          />
          <img
            src={base64Image('arrow-scroll-more-active')}
            alt=""
            className="arrow-down-active"
            onClick={() => scrollMore()}
            data-tip={ResourceManager('testContinuesDownwards')}
            data-for="tooltip-scroll-more"
            tabIndex="-1"
            aria-hidden="true"
          />
        </div>
        <ReactTooltip
          id="tooltip-scroll-more"
          effect="solid"
          arrowColor="transparent"
        />
      </button>
    );
  };

  const rightButton = () => {
    const btnState = props.isLastItem
      ? {
          btnId: 'btnClose',
          btnOnClick: () => props.finish('button'),
          btnResourceName: props.isFinalSession
            ? 'btnNavigationFinishTest'
            : 'btnNavigationFinishPart',
        }
      : {
          btnId: 'btnNext',
          btnOnClick: () => {
            props.moveNext();
            props.checkTestAppHasScrollbar();
            setShowScrollMore(true);
            if (props.nextButtonInitiallyDisabled) setDisabled(true);
          },
          btnResourceName: 'btnNavigationNext',
        };

    return (
      <button
        type="button"
        id={btnState.btnId}
        className="btn btn-primary float-right"
        onClick={btnState.btnOnClick}
        disabled={disabled}
        data-for={'tooltip-disabled'}
        data-tip={ResourceManager('tooltipDisabled')}
      >
        {disabled ? null : ResourceManager(btnState.btnResourceName)}
        <img
          src={base64Image(disabled ? 'loading-circle' : 'arrow-right-white')}
          alt=""
          className={disabled ? '' : 'ml8'}
        />
      </button>
    );
  };

  return (
    <div id="buttons-row">
      {leftButton()}
      {props.testAppHasScrollbar && showScrollMore && scrollMoreButton()}
      {rightButton()}
      {disabled ? (
        <ReactTooltip id="tooltip-disabled" place="left" effect="solid" />
      ) : null}
    </div>
  );
};

export default TestButtons;
