import BaseOperator from "./BaseOperator";

export default class NaturalLogarithmOperator extends BaseOperator {

    getOperator() {
        return 'ln';
    }

    toFullString() {
        return this.getOperator() + " ";
    }

}