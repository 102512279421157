export default function draggable(elmnt, header, extraElement = false) {
  let pos1 = 0;
  let pos2 = 0;
  let pos3 = 0;
  let pos4 = 0;

  const elementDrag = (e) => {
    const event = e || window.event;
    event.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    const positionTop = Math.min(
      Math.max(elmnt.offsetTop - pos2, 70),
      window.innerHeight - 40
    );

    const positionLeft = Math.min(
      Math.max(elmnt.offsetLeft - pos1, 15),
      // prevent the modal from overlapping other element
      extraElement
        ? extraElement.offsetLeft - (elmnt.offsetWidth + 20)
        : window.innerWidth - elmnt.offsetWidth - 60
    );
    elmnt.style.top = `${positionTop}px`;
    elmnt.style.left = `${positionLeft}px`;
    elmnt.style.right = 'unset';
    elmnt.style.bottom = 'unset';
    elmnt.style.width = `${elmnt.innerWidth}px`;
  };

  const closeDragElement = () => {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  };

  const dragMouseDown = (e) => {
    const event = e || window.event;
    event.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  };

  if (header) {
    header.onmousedown = dragMouseDown;
  } else {
    elmnt.onmousedown = dragMouseDown;
  }
}
