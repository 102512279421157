/**
 * Something about the Selection/Range API in browsers.
 * If you want to use Highlighter in some old browsers, you may use a polyfill.
 * https://caniuse.com/#search=selection
 */

import { SelectionDirection } from '../../types';

export const getSelectionDirection = (selection: Selection): SelectionDirection => {
    const position = selection.anchorNode.compareDocumentPosition(selection.focusNode);

    // position == 0 if nodes are the same
    if (
        (!position && selection.anchorOffset > selection.focusOffset) ||
        position === Node.DOCUMENT_POSITION_PRECEDING
    ) {
        return SelectionDirection.Backward;
    }

    return SelectionDirection.Forward;
};

export const getDomRange = (): [Range, SelectionDirection] => {
    const selection = window.getSelection();

    if (selection.isCollapsed) {
        // eslint-disable-next-line no-console
        console.debug('no text selected');

        return null;
    }

    const selectionDirection = getSelectionDirection(selection);

    return [selection.getRangeAt(0), selectionDirection];
};

export const removeSelection = (): void => {
    window.getSelection().removeAllRanges();
};
