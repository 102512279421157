import React, { useState, useEffect, useRef, useContext } from 'react';
import Loader from '../../components/Loader';
import useInterval from '../utils/useInterval';
import debounce from '../utils/debounce';
import { Context } from '../Proctoring';

const debounced = debounce((callback) => callback(), 1000);

const hasParentWithClass = (element, className) => {
  while (element) {
    if (element.classList.contains(className)) return true;
    element = element.parentElement;
  }
  return false;
};

const SwipeableViews = ({ index, children }) => {
  const { isExam } = useContext(Context);
  const [position, setPosition] = useState(0);
  const [opacity, setOpacity] = useState(1);
  const bounding = useRef();
  const boogie = useRef();
  const [maxHeight, setMaxHeight] = useState(500);

  // Fix height of the container in interval. It's bcoz ai notifications can change the height of the container
  useInterval(
    () => {
      setMaxHeight(boogie.current?.children?.[index]?.offsetHeight ?? 500);
    },
    100,
    !isExam
  );

  // Move checks to display the right one
  useEffect(() => {
    setPosition(bounding.current.offsetWidth * index * -1);
    window.proctoringBoogieIndex = index;
  }, [index]);

  // Scroll always on top when new check is displayed
  useEffect(() => {
    setTimeout(() => window.scrollTo({ top: 0 }), 1);
  }, [index]);

  useEffect(() => {
    const focusableElements =
      document
        .querySelector('.checksBounding')
        ?.querySelectorAll('a, button, input, select, textarea, [tabindex]') ??
      [];

    for (const element of focusableElements) {
      element.setAttribute(
        'tabindex',
        hasParentWithClass(element, 'activeCheck') ? '0' : '-1'
      );
    }
  }, [index, children]);

  // On page zooming we've got undesired ui behaviour. To fix it we hide the ui and show it again after zooming is done with a delay 1s
  useEffect(() => {
    window.addEventListener('resize', () => {
      if (bounding?.current?.offsetWidth) {
        setOpacity(0);
        setPosition(
          bounding.current.offsetWidth *
            (window?.proctoringBoogieIndex ?? 0) *
            -1
        );
        debounced(() => setTimeout(() => setOpacity(1), 1000));
      }
    });
  }, []);

  return (
    <div
      className="tabsContent"
      ref={bounding}
      style={{ maxHeight: maxHeight + 'px' }}
    >
      {!opacity && <Loader style={{ height: maxHeight }} />}
      <div
        className="bogie"
        ref={boogie}
        style={{ transform: `translateX(${position}px)`, opacity }}
      >
        {children.map((ch) => ch)}
      </div>
    </div>
  );
};

export default SwipeableViews;
