import types from "../types";
import ASTNode from "../parse/node";
import { ResourceManager } from '../../resources/ResourceManager';
import BaseException from "../logic/exceptions/BaseException";

var operators = {
    "+": [function (a, b) { return b + a }, 2],
    "-": [function (a, b) { return b - a }, 2],
    "x": [function (a, b) { return b * a }, 2],
    "/": [function (a, b) {
        if (a === 0) throw new BaseException(ResourceManager("calcDivideByZero"));
        return b / a
    }, 2],
    "^": [function (a, b) { return Math.pow(b, a) }, 2],
    "exp": [function(a) {
        return Math.pow(Math.E, a);
    }, 2],
    "neg": [function (a) { return -1 * a }, 1],
    "sin": [function (a) { return Math.sin(a) }, 1],
    "cos": [function (a) { return Math.cos(a) }, 1],
    "tan": [function (a) { return Math.tan(a) }, 1],
    "floor": [function (a) { return a | 0 }, 1],
    "ceil": [function (a) { return Math.ceil(a) }, 1],
    "round": [function (a) { return Math.round(a) }, 1],
    "log": [function (a) { return Math.log10(a) }, 1],
    "ln": [function (a) { return Math.log(a) }, 1],
    "sqrt": [function (x, n) {
        if (x < 0 && n % 2 !== 1) throw new BaseException(ResourceManager("calcQqrtNegative")); // Not well defined
        return (x < 0 ? -1 : 1) * Math.pow(Math.abs(x), 1 / n);
         
    }, 2],
    "√": [function (a) { return Math.sqrt(a) }, 1],
    "e": [function () { return Math.E }, 0],
    "pi": [function () { return Math.PI }, 0],
    "π": [function () { return Math.PI }, 0]
}


function computeFactorial(n) {
    var absNum = Math.abs(n);
    var i = 1;
    var factorial = 1;
    while (i <= absNum) {
        factorial *= i;
        i += 1;
    }
    if (absNum % 2 === 1 && n < 0) {
        return -factorial
    }
    return factorial;
}

export function evalStr(rootNode, pResult, unit) {
    var stack = [];

    if (!rootNode) return pResult;
    var leftNode = rootNode.left;
    var rightNode = rootNode.right;

    if (leftNode === undefined) {
        leftNode = new ASTNode({ value: "0", type: types.NUMBER }); // minus na zacatku, doplnim  jako 0 - x
   
    } 

    if (leftNode) {
        stack.push(evalStr(leftNode, pResult, unit));
    } 
    
    if (rightNode) {
        stack.push(evalStr(rightNode, pResult, unit));
    }
    
    if (rootNode.token) {
        switch (rootNode.token.type) {
            case types.NUMBER:
                if (rootNode.token.value.substring(rootNode.token.value.length-1) === "!") {
                    return computeFactorial(parseFloat(rootNode.token.value));
                }
                return parseFloat(rootNode.token.value);

            case types.OPERATOR:
            case types.NAMED_FUNCTION:
            case types.CONSTANT:
                if ((rootNode.token.value === "sin" ||
                    rootNode.token.value === "cos" ||
                    rootNode.token.value === "tan") && unit === "degrees") {
                    stack[0] = stack[0] * Math.PI / 180; // convert to radians
                }


                if (rootNode.token.value in operators) {
                    var numArg = operators[rootNode.token.value][1];
                    if (stack.length < numArg) throw new BaseException(ResourceManager("calcMissingOperand"));
                    return operators[rootNode.token.value][0].apply(null, (function () {
                        var arr = [];
                        for (var j = 0; j < numArg; j++) {
                            arr.push(stack.pop());
                        }
                        return arr;
                    })());
                }
                break;
            default:
                throw new BaseException(ResourceManager("calcInvalidToken"));
        }
    }
    throw new BaseException(ResourceManager("calcUnknownError"));
}

