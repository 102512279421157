import { Checkbox, FormControlLabel } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { warning } from '../../icons';
import { messages } from '../../intl';
import { Context } from '../../Proctoring';
import usePrevious from '../../utils/usePrevious';

const ExamRules = ({ isActive }) => {
  const { approvalsTexts, examId, setAllowContinue, setIsRec, socket } =
    useContext(Context);

  const [areRulesApproved, setAreRulesApproved] = useState(false);
  const prevIsActive = usePrevious(isActive);

  const reading = useRef();

  useEffect(() => {
    if (areRulesApproved) reading.current.scrollTop = 0;
  }, [areRulesApproved]);

  useEffect(() => {
    if (isActive) setAllowContinue(areRulesApproved);
  }, [isActive, areRulesApproved, setAllowContinue]);

  useEffect(() => {
    if (areRulesApproved && prevIsActive && !isActive) {
      socket.emit('rulesApproved', { examId });
      setIsRec(true);
    }
  }, [examId, isActive, areRulesApproved, prevIsActive, setIsRec, socket]);

  return (
    <div className="introCheck">
      <div
        className="reading textMdNormal"
        ref={reading}
        dangerouslySetInnerHTML={{
          __html: approvalsTexts?.examRules ?? '',
        }}
      ></div>

      <div className="warning withIcon textMdNormal">
        {warning}
        <span>
          <div className="textMdSemibold">
            <FormattedMessage {...messages.warning} />
          </div>
          <FormattedMessage {...messages.examWillBeRecorded} />
        </span>
      </div>
      <br />

      <div className="rulesCheckboxContainer">
        <FormControlLabel
          className="floatLeft alignLeft"
          control={
            <Checkbox
              size="medium"
              color="secondary"
              checked={areRulesApproved}
              onChange={() => setAreRulesApproved(!areRulesApproved)}
            />
          }
          label={<FormattedMessage {...messages.haveReadAndAgreeWExamRules} />}
        />
      </div>
    </div>
  );
};

export default ExamRules;
