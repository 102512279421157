import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { EXAM } from '../../consts';
import { activeBar, bar, micIcon, micDisabledIcon } from '../../icons';
import { messages } from '../../intl';
import { Context } from '../../Proctoring';
import LSM from '../../utils/localStorageManager';
import logger from '../../../utils/logging/DefaultLogger';
import { checkAudioStream } from '../../utils/stream';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

// Used for microphone volume detection
// Has to be changed in the global scope in case a different mic is selected
let analyserNode;

const Volume = ({ volume }) => {
  const barsCount = 13;
  const activeBars = Math.round((volume * 10) / barsCount);

  return (
    <div className="volume">
      {[...Array(barsCount)].map((e, index) => (
        <span key={'vol-bar-' + index}>
          {activeBars > index ? activeBar : bar}
        </span>
      ))}
    </div>
  );
};

const Microphone = ({ isActive }) => {
  const intl = useIntl();
  const {
    externalExamId,
    examId,
    microphoneDeviceId,
    setAllowContinue,
    setMicrophoneDeviceId,
    socket,
  } = useContext(Context);

  const [microphonePermissionGranted, setMicrophonePermissionGranted] =
    useState(false);

  const [audioDevices, setAudioDevices] = useState([]);
  const [microphoneVolume, setMicrophoneVolume] = useState(0);
  const [isMicrophoneVolumeSufficient, setIsMicrophoneVolumeSufficient] =
    useState(false);
  const [error, setError] = useState(null);

  const microphoneDeviceIdRef = useRef(microphoneDeviceId);

  const didMicPass =
    microphonePermissionGranted &&
    isMicrophoneVolumeSufficient &&
    audioDevices.length > 0;

  const saveMicrophoneDevice = useCallback(
    (deviceId, devices) => {
      // Check if the deviceId is the same as the current one
      if (deviceId === microphoneDeviceIdRef.current) {
        return;
      }

      const deviceName = devices.find(
        (device) => device.deviceId === deviceId
      )?.label;
      setMicrophoneDeviceId(deviceId);
      LSM.setExamProperty(externalExamId, EXAM.MIC_ID, deviceId);
      if (deviceName?.length > 0)
        socket?.emit('microphoneDeviceChanged', { examId, deviceName });
    },
    [setMicrophoneDeviceId, externalExamId, socket, examId]
  );

  useEffect(() => {
    microphoneDeviceIdRef.current = microphoneDeviceId;
  }, [microphoneDeviceId]);

  useEffect(() => {
    if (!isActive) return;

    navigator.mediaDevices
      .getUserMedia({ audio: true, video: true })
      .then(() => {
        navigator.permissions
          .query({ name: 'microphone' })
          .then((permissionStatus) => {
            if (permissionStatus.state === 'granted') {
              setMicrophonePermissionGranted(true);
            }
            permissionStatus.onchange = (e) => {
              setMicrophonePermissionGranted(
                e.srcElement.state === 'granted' ? true : false
              );
            };
          });
      })
      .catch(() => setMicrophonePermissionGranted(false));
  }, [isActive]);

  useEffect(() => {
    if (!isActive) return;
    if (!microphonePermissionGranted) {
      checkAudioStream({
        // Prevents OverconstrainedError if the deviceId is ''
        microphoneDeviceId: microphoneDeviceId || undefined,
        errorHandler: (error) => {
          logger.warn(`Error while checking audio: ${error}`, {
            examId,
            externalExamId,
            externalExamIdSentry: localStorage.getItem('externalExamId4Sentry'),
            exception: error,
          });

          setError(
            <FormattedMessage {...messages.microphoneDisallowedPrompt} />
          );
          setAllowContinue(false);
        },
      });
    } else {
      setError(null);
    }

    navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
      const audioDevices = mediaDevices.filter(
        ({ kind }) => kind === 'audioinput'
      );
      if (!isEmpty(audioDevices)) {
        setAudioDevices(audioDevices);

        if (!microphoneDeviceId) {
          const defaultDeviceId = audioDevices[0]?.deviceId || '';
          saveMicrophoneDevice(defaultDeviceId, audioDevices);
        }
      }
    });
  }, [
    examId,
    externalExamId,
    isActive,
    microphoneDeviceId,
    microphonePermissionGranted,
    saveMicrophoneDevice,
    setAllowContinue,
  ]);

  useEffect(() => {
    if (!isActive) return;

    const getMicrophoneVolume = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: { exact: microphoneDeviceId || '' } },
        });

        const audioContext = new AudioContext();
        const mediaStreamAudioSourceNode =
          audioContext.createMediaStreamSource(stream);
        analyserNode = audioContext.createAnalyser();
        mediaStreamAudioSourceNode.connect(analyserNode);
        const pcmData = new Float32Array(analyserNode.fftSize);

        const onFrame = () => {
          if (!analyserNode) {
            return;
          }

          analyserNode.getFloatTimeDomainData(pcmData);
          let sumSquares = 0.0;
          for (const amplitude of pcmData) {
            sumSquares += amplitude * amplitude;
          }

          const result = Math.sqrt(sumSquares / pcmData.length) * 100;

          setMicrophoneVolume(result);

          setTimeout(() => {
            window.requestAnimationFrame(onFrame);
          }, 50);
        };

        window.requestAnimationFrame(onFrame);
      } catch (error) {
        logger.warn(`Error while checking microphone volume: ${error}`, {
          examId,
          externalExamId,
          externalExamIdSentry: localStorage.getItem('externalExamId4Sentry'),
          exception: error,
        });

        // No microphone with the given deviceId was found
        // eslint-disable-next-line no-undef
        if (err instanceof OverconstrainedError) {
          analyserNode = null;
        }
      }
    };

    if (microphonePermissionGranted && microphoneDeviceId) {
      getMicrophoneVolume();
    }

    return () => (analyserNode = null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [microphoneDeviceId, microphonePermissionGranted]);

  useEffect(() => {
    if (microphoneVolume > 0.5 && isActive) {
      setIsMicrophoneVolumeSufficient(true);
      setAllowContinue(true);
    }
  }, [isActive, microphoneVolume, setAllowContinue]);

  return (
    <div className="introCheck">
      <h2 className="header2Semibold">
        <FormattedMessage {...messages.allowMicrophonePrompt} />
      </h2>

      <p className="width530 textMdNormal">
        <FormattedMessage {...messages.allowMicrophonePromptDesc} />
      </p>
      {didMicPass ? micIcon : micDisabledIcon}
      <Volume volume={Math.round(microphoneVolume)} />
      <div className="cam-mic-selection-box">
        <FormControl>
          <InputLabel id="label-id-select-microphone">
            <FormattedMessage {...messages.selectMicrophone} />
          </InputLabel>
          <Select
            labelId="label-id-select-microphone"
            label={<FormattedMessage {...messages.selectMicrophone} />}
            value={microphoneDeviceId}
            onChange={(e) => {
              saveMicrophoneDevice(e.target.value, audioDevices);
            }}
            variant="outlined"
            IconComponent={({ className }) =>
              className.includes('iconOpen') ? <ExpandLess /> : <ExpandMore />
            }
          >
            {audioDevices.map((device, key) => (
              <MenuItem
                key={key}
                value={device.deviceId}
                aria-label={`${intl.formatMessage(messages.microphone)} ${
                  device.label || intl.formatMessage(messages.unknownMic)
                }`}
              >
                {device.label || <FormattedMessage {...messages.unknownMic} />}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {audioDevices.length === 0 || error ? (
          <div className="error textXsMedium mt25">
            {error || (
              <>
                <FormattedMessage {...messages.noMicFound} />{' '}
                <FormattedMessage {...messages.connectAndRestart} />
              </>
            )}
          </div>
        ) : !didMicPass ? (
          <div className="info textXsMedium mt25">
            <FormattedMessage {...messages.saySomethingToContinue} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

Microphone.propTypes = {
  isActive: PropTypes.bool,
};

export default Microphone;
