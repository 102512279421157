import { useEffect, useState } from 'react';
import loader from '../assets/loader.png';

const Loader = (props) => {
  const { style, noIcon, message, isProgress, extra } = props;
  const [testDownloadProgress, setTestDownloadProgress] = useState('');

  useEffect(() => {
    window.addEventListener('fetch-progress', (e) => {
      downloadProgress(e.detail);
    });

    return () => {
      window.removeEventListener('fetch-progress', downloadProgress);
    };
  }, []);

  const downloadProgress = (progressEventPayload) => {
    const { loaded, total } = progressEventPayload;
    const progress = `${Math.round((loaded / total) * 100)} %`;
    setTestDownloadProgress(progress);
  };

  return (
    <section className="loader" style={style}>
      {noIcon ? null : <img src={loader} alt="" className="loader-icon" />}
      {message ? <div>{message}</div> : null}
      {isProgress ? <div>{testDownloadProgress}</div> : null}
      {extra ? <div>{extra}</div> : null}
    </section>
  );
};

export default Loader;
