import React, { useContext, useState } from 'react';
import { Context } from '../Proctoring';
import useInterval from '../utils/useInterval';
import { NOTIF_KEY } from '../consts';
import secondsToMMSS from '../utils/secondsToMMSS';
import { FormattedMessage } from 'react-intl';
import { messages } from '../intl';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const msToMm = (ms) => {
  const minutes = Math.floor(ms / 60000);
  return `${minutes}`;
};

const ChecksTimer = () => {
  const { isExam, introChecksTimer, setNotification } = useContext(Context);
  const { timerLimit, timerDisplay, timerWarning } = introChecksTimer || {};
  const [countdown, setCountdown] = useState(null);
  const [timerDisplayPassed, setTimerDisplayPassed] = useState(false);
  const [timerWarningPassed, setTimerWarningPassed] = useState(false);
  const [timerLimitPassed, setTimerLimitPassed] = useState(false);

  useInterval(
    () => {
      const now = new Date();
      setCountdown((timerLimit - now) / 1000);

      if (!timerDisplayPassed && now > timerDisplay) {
        setNotification({ [NOTIF_KEY.CHECKS_TIMER]: null });
        if (timerWarning > now) {
          setNotification({
            [NOTIF_KEY.CHECKS_TIMER]: {
              intl: 'timerDisplayPassed',
              variant: 'information',
              entities: { limit: msToMm(timerLimit - timerDisplay) },
            },
          });
        }
        setTimerDisplayPassed(true);
      }

      if (!timerWarningPassed && now > timerWarning) {
        setNotification({ [NOTIF_KEY.CHECKS_TIMER]: null });
        if (timerLimit > now) {
          setNotification({
            [NOTIF_KEY.CHECKS_TIMER]: {
              intl: 'timerWarningPassed',
              variant: 'error',
              entities: { limit: msToMm(timerLimit - timerWarning) },
            },
          });
        }
        setTimerWarningPassed(true);
      }

      if (!timerLimitPassed && now > timerLimit) {
        setNotification({ [NOTIF_KEY.CHECKS_TIMER]: null });
        setNotification({
          [NOTIF_KEY.CHECKS_TIMER]: {
            intl: 'timerLimitPassed',
            variant: 'error',
          },
        });
        setTimerLimitPassed(true);
      }
    },
    1000,
    introChecksTimer && !isExam && !timerLimitPassed
  );

  return !timerDisplayPassed ? undefined : (
    <div className={`checksTimer ${timerWarningPassed && 'error'}`}>
      <AccessTimeIcon />
      <span>
        <FormattedMessage {...messages.timeLeft} />:
      </span>
      <span>{countdown < 0 ? '00:00' : secondsToMMSS(countdown)}</span>
    </div>
  );
};

export default ChecksTimer;
