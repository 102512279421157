export default class Entity {

    constructor() {
        if (this.constructor === Entity) {
            throw new Error("Abstract classes can't be instantiated.");
        }
    }

    /**
     * Get type
     *
     * @return string
     */
    getType() {
        throw new Error("Method 'getType' must be implemented.");
    }

    /**
     * Get string order, when the calculation is represented by a string
     * 1: right side (default), -1: left side
     *
     * @return {number}
     */
    getStringOrder() {
        return 1;
    }

    /**
     *
     * @return {string}
     */
    toFullString() {
        return this.toString();
    }

}