import seedrandom from 'seedrandom';

export default function shuffleArray(seed, array) {
  // https://www.npmjs.com/package/seedrandom
  // pseudorandom number with seed answersId
  const random = seedrandom(seed);

  // array.sort not working... use custom sorting method
  // https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
  // https://dev.to/codebubb/how-to-shuffle-an-array-in-javascript-2ikj
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
  };

  shuffleArray(array);

  return array;
}
