import BaseOperator from "./BaseOperator";

export default class PiOperator extends BaseOperator {

    getOperator() {
        return "π";
    }

    toFullString() {
        return this.getOperator() + " ";
    }
}