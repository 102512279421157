import { useEffect, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import TimerTest from './TimerTest';

const TestHeader = (props) => {
  const headerProps = props.headerProps;
  const isWelcomePage = props.isWelcomePage;
  const title = headerProps.pageTitle || '';
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current && !isWelcomePage) {
      titleRef.current.focus();
    }
  }, [title, isWelcomePage]);

  const getTimeLimit = () => {
    if (!props.headerProps || !props.headerProps.testInstance) return null;

    if (Number.isInteger(props.headerProps.testInstance.remainingSeconds)) {
      return props.headerProps.testInstance.remainingSeconds;
    }
    return props.headerProps.testInstance.timeLimitSeconds;
  };

  const getInitials = (firstName, lastName) => {
    if (firstName && lastName) {
      return firstName.substring(0, 1) + lastName.substring(0, 1);
    }
    if (firstName) {
      return firstName.substring(0, 1);
    }
    if (lastName) {
      return lastName.substring(0, 1);
    }
    return '';
  };

  const getUserInitialsAndFullName = () => {
    const userSettings =
      props.headerProps &&
      props.headerProps.tokenObject &&
      props.headerProps.tokenObject.UserSettings;
    if (userSettings) {
      const firstName = props.headerProps.tokenObject.UserSettings.FirstName;
      const lastName = props.headerProps.tokenObject.UserSettings.LastName;

      if (firstName || lastName) {
        const fullName = `${firstName ?? ''} ${lastName ?? ''}`;
        const initials = getInitials(firstName, lastName);
        return { fullName, initials };
      }
    }
    return null;
  };

  const userInitialsAndFullName = getUserInitialsAndFullName();

  const timer = () => {
    const timeLimit = getTimeLimit();
    if (Number.isInteger(timeLimit) && props.headerProps) {
      return (
        <TimerTest
          isFinalSession={
            props.headerProps.tokenObject.ExamSettings.IsFinalSession
          }
          timeLimit={timeLimit}
          startTimer={props.headerProps.startTime}
          finish={props.headerProps.finish}
          isWelcomePage={isWelcomePage || false}
        />
      );
    }
    return '';
  };

  const initials = userInitialsAndFullName ? (
    <div
      className="initials header-icon"
      data-tip={userInitialsAndFullName.fullName}
      data-for="tooltip-initials"
    >
      {userInitialsAndFullName.initials}
    </div>
  ) : (
    ''
  );

  return (
    <nav
      id="header"
      className={`navbar ${headerProps.additionalClassName || ''}`}
    >
      <div className="logo">
        <div className="header-timer">{timer()}</div>
      </div>
      <h1 ref={titleRef} className="title" tabIndex="-1">
        {title}
      </h1>
      <div className="icon-box">{initials}</div>
      <ReactTooltip effect="solid" id="tooltip-initials" />
    </nav>
  );
};

export default TestHeader;
