import BaseOperator from "./BaseOperator";

export default class LogarithmOperator extends BaseOperator {

    getOperator() {
        return 'log';
    }

    toFullString() {
        return this.getOperator() + " ";
    }
}