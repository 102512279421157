import BaseOperator from "./BaseOperator";

export default class DivideOperator extends BaseOperator {

    getOperator() {
        return '/';
    }

    toFullString() {
        return " " + this.getOperator() + " ";
    }
}