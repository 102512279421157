export default function fixedElementsResizeEventListener() {
  const sidebarModal = document.getElementsByClassName('sidebar-modal');
  const delay = 50;
  let throttled = false;

  const checkFixedElement = (element) => {
    // check, if element with position fixed is on resize out of screen
    if (element) {
      // reposition instructions modal to avoid sidebar overlap
      const isInstructionsModal = element.id === 'idTestInstructionsModal';
      let condition =
        element.offsetLeft + element.clientWidth > window.innerWidth;
      let elementOffsetLeft = window.innerWidth - element.clientWidth;
      if (isInstructionsModal) {
        const sidebarOffsetLeft = document.getElementById('sidebar').offsetLeft;
        condition =
          element.offsetLeft + element.clientWidth + 20 > sidebarOffsetLeft;
        elementOffsetLeft = sidebarOffsetLeft - (element.clientWidth + 20);
      }
      if (condition) {
        element.style.left = `${elementOffsetLeft}px`;
        element.style.right = 'unset';
      }
      if (element.offsetTop + element.clientHeight > window.innerHeight) {
        element.style.top = `${window.innerHeight - element.clientHeight}px`;
        element.style.bottom = 'unset';
      }
      if (element.offsetLeft < 0) {
        element.style.left = '0px';
      }
      if (element.offsetTop < 70) {
        element.style.top = '70px';
      }
    }
  };

  const checkFixedElements = (elements) => {
    Array.from(elements).forEach((element) => {
      checkFixedElement(element);
    });
  };

  window.addEventListener('resize', () => {
    const examWatcher = document.getElementById('exam-watcher');

    if (!throttled) {
      if (examWatcher) {
        checkFixedElement(examWatcher);
      }
      if (sidebarModal.length > 0) {
        checkFixedElements(sidebarModal);
      }
      throttled = true;
      setTimeout(() => {
        throttled = false;
      }, delay);
    }
  });
}
