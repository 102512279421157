import Instruction from './Instruction';
import Question from './Question';

const Item = ({
  pageItem,
  items,
  answersId,
  pageItemIndex,
  isCurrent,
  testModeSettings,
  hideDistractorKeys,
  showItemScore,
  showItemNegativeScore,
  showItemTheme,
}) => {
  if (pageItem.contextType === 'Question') {
    return (
      <Question
        items={items}
        answersId={answersId}
        pageItem={pageItem}
        pageItemIndex={pageItemIndex}
        isCurrent={isCurrent}
        testModeSettings={testModeSettings}
        hideDistractorKeys={hideDistractorKeys}
        showItemScore={showItemScore}
        showItemNegativeScore={showItemNegativeScore}
        showItemTheme={showItemTheme}
      />
    );
  }
  const item = items[pageItem.itemId];
  return <Instruction renderedItem={item} />;
};

export default Item;
