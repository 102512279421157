import LogLevel from './LogLevel';

class ConsoleLogger {
  log = (logLevel, message, params) => {
    const acceptedParams = params ?? '';
    switch (logLevel) {
      case LogLevel.Debug:
        console.debug(message, acceptedParams);
        break;
      case LogLevel.Warn:
        console.warn(message, acceptedParams);
        break;
      case LogLevel.Error:
        console.error(message, acceptedParams);
        break;
      default:
        console.info(message, acceptedParams);
    }
  };
}

export default ConsoleLogger;
