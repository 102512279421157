import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { IntlProvider } from 'react-intl';
import { LOCALE } from './consts';
import { translation } from './intl';
import Proctoring from './Proctoring';
import reportWebVitals from './reportWebVitals';
import LSM from './utils/localStorageManager';
import Notifications from './components/Notifications';

const App = (props) => {
  const { config } = props;

  // LocalStorage has data for this externalExamId
  const knownExternalExamIds = LSM.getExternalExamIds();
  const [knownExternalExamId, setKnownExternalExamId] = useState(
    config && knownExternalExamIds.includes(config?.externalExamId)
  );

  useEffect(() => {
    // Add exam by externalExamId intl LS
    if (!knownExternalExamId && config) {
      (async () => {
        LSM.addNewExam(config.externalExamId, config.definitionId);
        setKnownExternalExamId(true);
      })();
    }
  }, [config, knownExternalExamId]);

  const langCode = config?.lang ?? LOCALE;

  return config && knownExternalExamId ? (
    <React.StrictMode>
      <IntlProvider
        locale={langCode === 'cz' ? 'cs-CZ' : langCode}
        messages={translation[langCode]}
      >
        <SnackbarProvider
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          preventDuplicate
        >
          <Notifications>
            <Proctoring {...props} config={config} />
          </Notifications>
        </SnackbarProvider>
      </IntlProvider>
    </React.StrictMode>
  ) : null;
};

ReactDOM.render(<App />, document.getElementById('root'));

export default App;

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
