import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { warning } from '../../icons';
import { messages } from '../../intl';
import keyMirror from '../../utils/keyMirror';
import RoomCheckProgress from './RoomCheckProgress';
import RoomCheckVideo from './RoomCheckVideo';
import { findIndex, has } from 'ramda';
import { Context } from '../../Proctoring';

export const STATE = keyMirror({
  INTRO: null,
  RECORDING: null,
  PLAYBACK_READY: null,
  PLAYBACK_WATCHING: null,
  PLAYBACK_WATCHED: null,
});

const RoomCheck = (props) => {
  const { parentInstructionsText, parentHeadline } = props;
  const { checks, setActiveCheckId } = useContext(Context);
  const [state, setState] = useState(STATE.INTRO);
  const [showModal, setShowModal] = useState(false);
  const isPlaybackReady = state === STATE.PLAYBACK_READY;
  const isPlaybackWatched = state === STATE.PLAYBACK_WATCHED;
  const roomCheckIntroId = findIndex(has('isRoomChekIntro'))(checks);

  const handleReset = () => {
    setActiveCheckId(roomCheckIntroId);
    setShowModal(false);
  };

  // Keep focus whitin "reset room modal" if opened
  useEffect(() => {
    const focusableElements = 'button, [tabindex]:not([tabindex="-1"])';
    const modal = document.querySelector('.confimModal');
    if (!modal) return;

    const firstFocusableElement = modal.querySelectorAll(focusableElements)[0];
    const focusableContent = modal.querySelectorAll(focusableElements);
    const lastFocusableElement = focusableContent[focusableContent.length - 1];

    const listener = (e) => {
      const isTabPressed = e.key === 'Tab';
      const isEscPressed = e.key === 'Escape';

      if (isEscPressed) {
        setShowModal(false);
        return;
      }

      if (!isTabPressed) return;

      if (e.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          lastFocusableElement.focus();
          e.preventDefault();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          firstFocusableElement.focus();
          e.preventDefault();
        }
      }
    };

    if (showModal) document.addEventListener('keydown', listener);
    else document.removeEventListener('keydown', listener);

    firstFocusableElement.focus();

    return () => document.removeEventListener('keydown', listener);
  }, [showModal]);

  return (
    <div className="introCheck roomCheck">
      {showModal && (
        <div className="confimModal">
          <div className="textSmNormal">
            <h3 className="textLgMedium">
              <FormattedMessage {...messages.resetRoomCheck} />
            </h3>
            <p tabIndex={0}>
              <FormattedMessage {...messages.resetRoomCheckConfim} />
            </p>
            <div className="displayFlex">
              <button onClick={handleReset} tabIndex={1}>
                <FormattedMessage {...messages.reset} />
              </button>
              <button className="bgActive" onClick={() => setShowModal(false)}>
                <FormattedMessage {...messages.goBack} />
              </button>
            </div>
          </div>
        </div>
      )}

      <h2 className="header2Semibold">{parentHeadline}</h2>
      <div
        className="textMdNormal width682"
        dangerouslySetInnerHTML={{
          __html: parentInstructionsText ?? '',
        }}
      />
      <div className="displayFlex">
        <RoomCheckVideo {...props} state={state} setState={setState} />
        <RoomCheckProgress
          {...props}
          showModal={() => setShowModal(true)}
          roomCheckIntroId={roomCheckIntroId}
        />
      </div>

      {/* Instructions */}
      {(isPlaybackReady || isPlaybackWatched) && (
        <div className="warning withIcon textMdNormal">
          {warning}
          <FormattedMessage {...messages.playbackReadyWarning} />
        </div>
      )}
    </div>
  );
};

export default RoomCheck;
