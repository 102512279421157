import React, { Component } from 'react';
import InitializedApp from './components/InitializedApp';
import Loader from './components/Loader';
import { ErrorHandler, handlerErrorTC } from './components/ErrorHandler';
import { ResourceManager } from './resources/ResourceManager';
import initDatabaseAsync from './utils/initDatabase';
import initSessionHubAsync from './utils/initSessionHub';
import fixedElementsResizeEventListener from './utils/fixedElementsResizeEventListener';
import { tokenParser } from './utils/tokenHelpers';

import './styles/site.scss';
import log from './utils/logging/DefaultLogger';
import Resilience from './components/Resilience';
import Err404Page from './utils/Err404Page';

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appInitialized: false,
      tokens: [],
      tokenIndex: 0,
      connection: null,
      error: false,
      errorMessage: '',
      showReturnLink: false,
      tokenObject: {},
      wrongUrl: false,
    };
  }

  componentDidMount() {
    this.initApp();
  }

  sessionHubStateErrorCallback = (isError, message) => {
    this.setState({
      error: isError,
      errorMessage: message,
    });
  };

  async initApp() {
    if (this.state.error || this.state.appInitialized) return;
    log.debug('Initializing app.');

    // init tokens
    const tokenResults = await this.readTokens();
    if (!tokenResults) return;
    const { tokenObject, tokens, tokenIndex } = tokenResults;

    // init index DB and SignalR SessionHub
    const [db, connection] = await window.Promise.all([
      this.initDb(),
      initSessionHubAsync(
        tokenObject.ExamSettings.ExternalExamId,
        this.sessionHubStateErrorCallback
      ),
    ]);

    if (!db) return;

    // read language setup from token and store it to session storage
    this.setLanguage(tokenObject);

    // global resource manager for access outside React
    window.ResourceManager = ResourceManager;
    fixedElementsResizeEventListener();
    log.debug('App initialized.');

    this.setState({
      appInitialized: true,
      db,
      tokenIndex,
      tokens,
      tokenObject,
      connection,
    });

    // check wrong url
    if (window.location.pathname !== '/') {
      this.setState({
        wrongUrl: true,
      });
    }
  }

  async readTokens() {
    let tokens = window.location.hash;
    if (tokens.length > 1) {
      return await this.initTokens(tokens.substring(1));
    } else {
      // načtení tokenu ze session storage po refreshi
      tokens = sessionStorage.getItem('token');
      if (tokens && tokens.length > 1) {
        return await this.initTokens(tokens);
      } else {
        handlerErrorTC(
          this,
          ResourceManager('errInit') + ResourceManager('errTokenMissing'),
          null,
          true,
          null,
          null,
          true
        );
      }
    }
  }

  async initTokens(tokens) {
    try {
      const newTokensArray = await this.refreshAllTokens(tokens);

      this.storeNewTokensInSessionStorage(newTokensArray);

      const newTokens = this.parseTokens(newTokensArray);
      const lastTokenIndex = await this.getActiveTokenIndex(newTokens);
      const activeToken = newTokens[lastTokenIndex];

      log.info(
        `Init tokens - refreshed tokens to: ${newTokensArray}`,
        activeToken.tokenObject
      );
      return {
        tokenObject: activeToken.tokenObject,
        tokens: newTokens,
        tokenIndex: lastTokenIndex,
      };
    } catch (e) {
      handlerErrorTC(this, ResourceManager('errInit') + e.message, e, true);
      return;
    }
  }

  //store new tokens to session storage
  storeNewTokensInSessionStorage(newTokensArray) {
    const refreshedTokens = newTokensArray.join(';');
    sessionStorage.setItem('token', refreshedTokens);
    window.location.hash = '';
  }

  async refreshAllTokens(tokens) {
    const tokensArray = tokens.split(';');
    const newTokensPromise = Array(tokensArray.length);

    // refresh tokenu přes api
    // aby se volanim nezmenilo poradi testu, vracim novy token na stejne misto pole
    for (let index = 0; index < tokensArray.length; index++) {
      newTokensPromise[index] = this.refreshToken(tokensArray[index]);
    }

    const newTokensArray = await Promise.all(newTokensPromise);
    return newTokensArray;
  }

  parseTokens(newTokensArray) {
    const newTokens = Array(newTokensArray.length);
    for (let index = 0; index < newTokensArray.length; index++) {
      const tokenValue = newTokensArray[index];
      newTokens[index] = {
        token: tokenValue,
        tokenObject: tokenParser(tokenValue),
      };
    }
    return newTokens;
  }

  async refreshToken(token) {
    log.debug(`Refreshing token: ${token}`);
    const response = await window.fetch(
      `${process.env.REACT_APP_TESTCORE_API_URL}/Api/Token`,
      {
        headers: { Authorization: token },
      }
    );
    if (response.ok) {
      const responseData = await response.json();
      if (!responseData.token)
        throw new Error(ResourceManager('errFetchToken'));

      const refreshedToken = responseData.token;
      log.debug(`Token: ${token} \n refreshed to: ${refreshedToken}`);
      return refreshedToken;
    }
    throw new Error(ResourceManager('errFetchToken'));
  }

  async getActiveTokenIndex(tokensArray) {
    const activeTokenObject = await this.checkActiveToken(tokensArray[0].token); // dám mu libovolný autorizační token
    if (activeTokenObject === null) return 0;

    const lastTokenIndex = tokensArray.findIndex(
      (x) => x.tokenObject.AnswersId === activeTokenObject.AnswersId
    );

    if (lastTokenIndex > 0) return lastTokenIndex;
    return 0;
  }

  async checkActiveToken(token) {
    // kvuli refreshi a otevreni prislusneho testu (pokud je více oddílů)
    const activeToken = await this.getActiveTestToken(token);

    if (activeToken) {
      const activeTokenObject = tokenParser(activeToken);
      log.info('Init tokens - active token exists.', activeTokenObject);
      return activeTokenObject;
    }
    return null;
  }

  async getActiveTestToken(token) {
    log.info(`Get ActiveTestToken.`);
    const response = await window.fetch(
      `${process.env.REACT_APP_TESTCORE_API_URL}/Api/ActiveTestToken`,
      {
        method: 'GET',
        headers: {
          Authorization: token,
        },
      }
    );

    if (response.ok) {
      const responseData = await response.json();
      if (!responseData.token)
        throw new Error(ResourceManager('errFetchToken'));

      const activeTestToken = responseData.token;
      log.debug(
        `Token: ${token} \n returned activeTestToken: ${activeTestToken}`
      );
      return activeTestToken;
    }
    throw new Error(ResourceManager('errFetchToken'));
  }

  async initDb() {
    try {
      return await initDatabaseAsync();
    } catch (dbError) {
      handlerErrorTC(
        this,
        ResourceManager('errInit') + ResourceManager('errInitIndexedDb'),
        dbError
      );
    }
  }

  setLanguage(tokenObject) {
    tokenObject.UserSettings.Language = tokenObject.UserSettings.Language
      ? tokenObject.UserSettings.Language
      : 'CZ';

    sessionStorage.setItem('lang', tokenObject.UserSettings.Language);
  }

  render() {
    const {
      tokenObject,
      appInitialized,
      db,
      error,
      errorMessage,
      showReturnLink,
      tokens,
      tokenIndex,
      wrongUrl,
    } = this.state;
    const connectionId = this.state.connection
      ? this.state.connection.connectionId
      : '';

    if (error) {
      return (
        <ErrorHandler
          message={errorMessage}
          tokenObject={tokenObject}
          showReturnLink={showReturnLink}
        />
      );
    }

    if (wrongUrl) {
      return <Err404Page />;
    }

    if (appInitialized) {
      if (tokenObject.IsResilience === true) {
        return <Resilience tokens={tokens} db={db} />;
      }
      return (
        <>
          <InitializedApp
            tokens={tokens}
            tokenIndex={tokenIndex}
            db={db}
            connectionId={connectionId}
          />
        </>
      );
    }

    return <Loader message={ResourceManager('loadingData')} />;
  }
}
