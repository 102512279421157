const finishInProgress = {
  storageKey: '_isFinishing',
  storageValue: '1',

  start(answersId) {
    localStorage.setItem(answersId + this.storageKey, this.storageValue);
  },

  isFinishingActive(answersId) {
    return (
      localStorage.getItem(answersId + this.storageKey) === this.storageValue
    );
  },

  cleanup(answersId) {
    localStorage.removeItem(answersId + this.storageKey);
  },
};

export default finishInProgress;
