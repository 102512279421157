import { ResourceManager } from '../resources/ResourceManager';
import base64Image from '../utils/images';

const ICONS = {
  bookOpen: base64Image('book-open'),
  bookOpenActive: base64Image('book-open-active'),
  calculatorIcon: base64Image('calculator'),
  calculatorIconActive: base64Image('calculator-active'),
  filePlus: base64Image('file-plus'),
  filePlusActive: base64Image('file-plus-active'),
  supportIconActive: base64Image('life-buoy-active'),
  supportIcon: base64Image('life-buoy'),
  pencilLine: base64Image('pencil-line'),
  pencilLineActive: base64Image('pencil-line-active'),
};

const SidebarButton = ({ condition, action, icon, text, extra = '' }) => {
  const defaultIcon = ICONS[icon];
  const activeIconName = `${icon}Active`;
  const activeIcon = ICONS[activeIconName];

  const handleAction = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    action({
      show: !condition,
      top: rect.top,
      left: rect.left,
    });
  };

  return (
    <button
      className={`sidebar-button ${extra} ${condition ? 'active' : ''}`}
      onClick={(e) => handleAction(e)}
    >
      <img src={`${condition ? activeIcon : defaultIcon}`} alt="" />
      <div className="sidebar-label">{ResourceManager(text)}</div>
    </button>
  );
};

export default SidebarButton;
