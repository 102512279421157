import { useCallback, useEffect, useRef } from 'react';
import WebHighlighter from '@yidun/web-highlighter';
import HighlighterLocalStore from '../utils/HighlighterLocalStore';

const Highlighter = ({ isHighlighterActive, answersId, pageIndex }) => {
  const highlighterRef = useRef(null);
  const highlighterStoreRef = useRef(null);

  const createInstances = useCallback(() => {
    highlighterStoreRef.current = new HighlighterLocalStore(
      answersId,
      pageIndex
    );
    highlighterRef.current = new WebHighlighter();

    const root =
      document.getElementsByClassName('test-page')[0] ||
      document.getElementsByClassName('welcome-panel')[0] ||
      document;

    highlighterRef.current.setOption({
      exceptSelectors: [
        '.options',
        'input',
        'textarea',
        'button',
        '.item-number',
        '.media-message',
        '.option-box',
      ],
      style: {
        className: 'highlight-wrapper',
      },
      $root: root,
      verbose: true,
    });

    highlighterRef.current
      .on(WebHighlighter.event.CLICK, (data) => {
        highlighterRef.current.remove(data.id);
        highlighterStoreRef.current.remove(data.id);
      })
      .on(WebHighlighter.event.CREATE, (sources) => {
        cleanUpEmptyNodes(sources.sources[0].id);

        const storeSources = sources.sources.map((hs) => ({ hs }));
        highlighterStoreRef.current.save(storeSources);
      });
  }, [answersId, pageIndex]);

  useEffect(() => {
    createInstances();

    return () => {
      if (highlighterRef.current) {
        highlighterRef.current.dispose();
      }
    };
  }, [createInstances]);

  const initHighlighterSources = useCallback(() => {
    const mediaElementIds = [];
    getMediaIds(mediaElementIds, 'video');
    getMediaIds(mediaElementIds, 'audio');
    const waitCycles = mediaElementIds.length;

    setTimeout(() => {
      const storeInfos = highlighterStoreRef.current.getAll();
      storeInfos.forEach(({ hs }) => {
        highlighterRef.current.fromStore(
          hs.startMeta,
          hs.endMeta,
          hs.text,
          hs.id,
          hs.extra
        );
        cleanUpEmptyNodes(hs.id);
      });
    }, 100 + 300 * waitCycles);
  }, []);

  useEffect(() => {
    if (isHighlighterActive) {
      if (highlighterStoreRef.current) {
        highlighterStoreRef.current.pageIndex = pageIndex;
      }

      initHighlighterSources();

      if (highlighterRef.current) {
        highlighterRef.current.run();
      }
    } else {
      if (highlighterRef.current) {
        highlighterRef.current.stop();
        highlighterRef.current.dispose();
      }
    }
  }, [isHighlighterActive, initHighlighterSources, pageIndex]);

  const getMediaIds = (array, tagName) => {
    const mediaElements = document.getElementsByTagName(tagName);
    if (mediaElements.length > 0) {
      Array.from(mediaElements).forEach((element) => {
        array.push(element.id);
      });
    }
  };

  const cleanUpEmptyNodes = (id) => {
    const spans = highlighterRef.current.getDoms(id);
    if (spans) {
      spans.forEach((span) => {
        if (span.innerText === '') {
          span.remove();
        }
      });
    }
  };
};

export default Highlighter;
