// https://github.com/GA-MO/react-confirm-alert/blob/master/src/index.js

import React, { Component } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';

const createSVGBlurReconfirm = () => {
  // If has svg ignore to create the svg
  const svg = document.getElementById('react-confirm-alert-firm-svg');
  if (svg) return;
  const svgNS = 'http://www.w3.org/2000/svg';
  const feGaussianBlur = document.createElementNS(svgNS, 'feGaussianBlur');
  feGaussianBlur.setAttribute('stdDeviation', '0.3');

  const filter = document.createElementNS(svgNS, 'filter');
  filter.setAttribute('id', 'gaussian-blur');
  filter.appendChild(feGaussianBlur);

  const svgElem = document.createElementNS(svgNS, 'svg');
  svgElem.setAttribute('id', 'react-confirm-alert-firm-svg');
  svgElem.setAttribute('class', 'react-confirm-alert-svg');
  svgElem.appendChild(filter);

  document.body.appendChild(svgElem);
};

const removeSVGBlurReconfirm = () => {
  const svg = document.getElementById('react-confirm-alert-firm-svg');
  if (svg) {
    svg.parentNode.removeChild(svg);
  }
  document.body.children[0].classList.remove('react-confirm-alert-blur');
};

const createElementReconfirm = (properties) => {
  let divTarget = document.getElementById('react-confirm-alert');
  if (divTarget) {
    // Rerender - the mounted ReactConfirmAlert
    render(<ReactConfirmAlert {...properties} />, divTarget);
  } else {
    // Mount the ReactConfirmAlert component
    document.body.children[0].classList.add('react-confirm-alert-blur');
    divTarget = document.createElement('div');
    divTarget.id = 'react-confirm-alert';
    document.body.appendChild(divTarget);
    render(<ReactConfirmAlert {...properties} />, divTarget);
  }
};

const removeElementReconfirm = () => {
  const target = document.getElementById('react-confirm-alert');
  if (target) {
    unmountComponentAtNode(target);
    target.parentNode.removeChild(target);
  }
};

const addBodyClass = () => {
  document.body.classList.add('react-confirm-alert-body-element');
};

const removeBodyClass = () => {
  document.body.classList.remove('react-confirm-alert-body-element');
};
export default class ReactConfirmAlert extends Component {
  handleClickButton = (button) => {
    if (button.onClick) button.onClick();
    this.close();
  };

  close = () => {
    removeBodyClass();
    removeElementReconfirm();
    removeSVGBlurReconfirm();
  };

  render() {
    const { title, message, buttons, overlayClassName } = this.props;

    return (
      <div className={`react-confirm-alert-overlay ${overlayClassName}`}>
        <div
          className="react-confirm-alert"
          role="dialog"
          aria-modal="true"
          aria-labelledby="alert-title"
          aria-describedby="alert-text"
        >
          <div className="react-confirm-alert-body">
            <h1 id="alert-title" className="alert-title">
              {title}
            </h1>
            <div
              dangerouslySetInnerHTML={{ __html: message }}
              id="alert-text"
              className="alert-text"
            />
            <div className="react-confirm-alert-button-group">
              {buttons.map((button, i) => (
                <button
                  key={i}
                  onClick={() => this.handleClickButton(button)}
                  className={button.className}
                >
                  {button.label}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const ConfirmAlert = (properties) => {
  addBodyClass();
  createSVGBlurReconfirm();
  createElementReconfirm(properties);
};
