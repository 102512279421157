export default function draggable(elmnt, header) {
  if (!header) {
    header = elmnt;
  }
  let pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;

  const dragMouseDown = (e) => {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  };

  header.onmousedown = dragMouseDown;

  const elementDrag = (e) => {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    const positionTop = Math.min(
      Math.max(elmnt.offsetTop - pos2, 70),
      window.innerHeight - 15
    );

    const positionLeft = Math.min(
      Math.max(elmnt.offsetLeft - pos1, 10),
      window.innerWidth - 50
    );
    elmnt.style.top = `${positionTop}px`;
    elmnt.style.left = `${positionLeft}px`;
    elmnt.style.right = 'unset';
    elmnt.style.bottom = 'unset';
    elmnt.style.width = `${elmnt.innerWidth}px`;
  };

  const closeDragElement = () => {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  };
}
