import React, { Component, Fragment } from 'react';
import Item from './Item';
import SharedItem from './SharedItem';
import ImplicitInstruction from './ImplicitInstruction';

export default class ItemGroup extends Component {
  componentDidMount() {
    if (this.props.currentItem === -1) {
      window.scrollTo(0, 0);
    }
  }

  componentDidUpdate() {
    this.componentDidMount();
  }

  itemImplicitInstructions = (pageItem) =>
    pageItem.instructions.map((ii) => {
      const rendererInstruction = this.props.items[ii.itemId];
      return (
        <ImplicitInstruction
          key={`page${this.props.pageIndex}_item${ii.itemId}`}
          firstItemIndex={ii.firstItemIndex}
          lastItemIndex={ii.lastItemIndex}
          renderedItem={rendererInstruction}
          pageIndex={this.props.pageIndex}
          itemIndex={pageItem.index}
          isImplicitInstructionOpen={this.props.isImplicitInstructionOpen}
          setInstructionState={this.props.setInstructionState}
        />
      );
    });

  itemSharedItem = (sharedItem) => {
    const renderedSharedItem = this.props.items[sharedItem.itemId];
    return (
      <SharedItem
        key={`page${this.props.pageIndex}_item${sharedItem.itemId}`}
        renderedItem={renderedSharedItem}
        firstItemIndex={this.props.itemGroup.sharedItem.firstItemIndex}
        lastItemIndex={this.props.itemGroup.sharedItem.lastItemIndex}
      />
    );
  };

  renderItem = (instructions, sharedItem, items) => {
    const itemsClass = `item-group-items${
      items.length === 1 ? ' one-item' : ''
    }`;
    return (
      <Fragment key={`renderItemKey-${items[0].key}`}>
        {instructions}
        {sharedItem}
        <div className={itemsClass}>{items}</div>
      </Fragment>
    );
  };

  transformItems = () => {
    const groupItems = this.props.itemGroup.items;
    const items = [];
    let itemInstructionsArray = [];
    let instructionItemsArray = [];
    let sharedItem = '';
    if (this.props.itemGroup.sharedItem) {
      sharedItem = this.itemSharedItem(this.props.itemGroup.sharedItem);
    }

    // cele tohle harakiri je kvuli implicitnim instrukcim u uloh, protoze je potrebuju mit mimo item-group-items
    groupItems.forEach((i, index) => {
      if (i.instructions && i.instructions.length > 0) {
        if (instructionItemsArray.length > 0) {
          items.push(
            this.renderItem(
              itemInstructionsArray,
              sharedItem,
              instructionItemsArray
            )
          );
          instructionItemsArray = [];
          sharedItem = '';
        }
        itemInstructionsArray = this.itemImplicitInstructions(i);
      }

      const item = (
        <Item
          key={`item${i.itemId}`}
          items={this.props.items}
          answersId={this.props.answersId}
          pageItem={i}
          pageItemIndex={index}
          isCurrent={this.props.currentItem === i.itemId}
          testModeSettings={this.props.testModeSettings}
          hideDistractorKeys={this.props.hideDistractorKeys}
          showItemScore={this.props.showItemScore}
          showItemNegativeScore={this.props.showItemNegativeScore}
          showItemTheme={this.props.showItemTheme}
        />
      );

      instructionItemsArray.push(item);
    });

    items.push(
      this.renderItem(itemInstructionsArray, sharedItem, instructionItemsArray)
    );

    return items;
  };

  render() {
    const items = this.transformItems();
    return (
      <div
        className="item-group"
        key={`page${this.props.pageIndex}_itemgroup_${this.props.groupIdex}`}
      >
        {items}
      </div>
    );
  }
}
