import BaseOperator from "./BaseOperator";

export default class PowEulerOperator extends BaseOperator {

    getOperator() {
        return "exp";
    }

    toFullString() {
        return this.getOperator() + " ";
    }
}