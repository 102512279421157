import React, { Component } from 'react';
import { ResourceManager } from '../resources/ResourceManager';

export default class InstructionLegend extends Component {
  render() {
    return (
      <div className="instructions-legend">
        <div className="legend-row">
          {ResourceManager('legendUreaded')}
          <span className="legend-icon">1</span>
        </div>
        <div className="legend-row">
          {ResourceManager('legendAnswered')}
          <span className="legend-icon OK">2</span>
        </div>
        <div className="legend-row">
          {ResourceManager('legendActive')}
          <span className="legend-icon active">3</span>
        </div>
        <div className="legend-row">
          {ResourceManager('legendFlagged')}
          <span className="legend-icon flagged">4</span>
          <span className="legend-icon OK flagged">4</span>
          <span className="legend-icon active flagged">4</span>
        </div>
      </div>
    );
  }
}
