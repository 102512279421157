import React, {
  useContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import RawHtml from './RawHtml';
import { ResourceManager } from '../resources/ResourceManager';
import QuestionFlag from './QuestionFlag';
import shuffleQuestion from '../items/shuffleQuestion';
import KeyboardModal from '../modals/KeyboardModal';
import addButtonToInput from '../utils/addButtonToInput';
import { QuestionContext } from './Page';

const Question = (props) => {
  const itemContentRef = useRef(null);
  const [inputState, setInputState] = useState({
    id: null,
    value: '',
    show: false,
    left: 0,
    bottom: 0,
  });
  const { keyboardId, setKeyboardId, textareaMap, setTextareaMap } =
    useContext(QuestionContext);
  const item = props.items[props.pageItem.itemId];
  const useKeyboard = item.useKeyboard;
  const itemIndex = `${ResourceManager('itemQuestion')} ${
    props.pageItem.index
  }`;
  const hideDistractorKeys = props.hideDistractorKeys;

  const pointRes = (count) => {
    if (count === 1 || count === -1) return ResourceManager('ScoreOnePoint');
    if (2 <= count && count <= 4) return ResourceManager('ScoreTwo2FourPoints');
    if (count > 4) return ResourceManager('ScoreFive2NPoints');
    if (-1 < count && count < 0) return ResourceManager('ScoreLessThenPoint');
  };
  const itemScore =
    props.showItemScore && item.score !== null ? (
      <span className="item-score-partial">
        {ResourceManager('Score')}
        <div className="item-score-val">
          {item.score} {pointRes(item.score)}
        </div>
      </span>
    ) : (
      ''
    );
  const negativeScoreFormat = (val) => {
    if (val === 1) return `-1`;
    return `-1/${val}`;
  };
  const itemNegativeScore =
    props.showItemNegativeScore && item.scoreIncorrectDenominator !== null ? (
      <span className="item-score-partial">
        {ResourceManager('ScoreNegative')}
        <div className="item-score-val">
          {negativeScoreFormat(item.scoreIncorrectDenominator)}{' '}
          {pointRes(item.scoreIncorrect)}
        </div>
      </span>
    ) : (
      ''
    );
  const theme =
    props.showItemTheme && item.theme !== null ? (
      <span className="item-theme">{item.theme}</span>
    ) : (
      ''
    );
  const separator =
    itemScore !== '' && itemNegativeScore !== '' ? (
      <span className="item-score-separator"> | </span>
    ) : (
      ''
    );

  useEffect(() => {
    // wait for the input fields to be populated by values from db
    // something to refactor - it could be done more reliably
    const itemContent = itemContentRef.current;
    const textarea = itemContent?.querySelector(`textarea`);
    if (textarea && textarea.id in textareaMap) {
      const textareaStyle = textareaMap[textarea.id];
      textarea.style.width = textareaStyle.width;
      textarea.style.height = textareaStyle.height;
    }

    if (keyboardId) setKeyboardId('');

    const waitForData = setTimeout(() => {
      const itemContentAfterDelay = itemContentRef.current;
      const input = itemContentAfterDelay?.querySelector(
        `input[usekeyboard='true'], textarea`
      );
      if (input) {
        const rect = input.getBoundingClientRect();
        setInputState({
          id: input.id,
          value: input.value,
          show: false,
          left: rect.left,
          bottom: rect.bottom,
        });
      }
    }, 500);

    return () => {
      clearTimeout(waitForData);
      if (textarea) {
        const style = textarea.style;

        if (style.width || style.height) {
          const id = textarea.id;
          const width = style.width;
          const height = style.height;

          setTextareaMap((prevTextareaMap) => ({
            ...prevTextareaMap,
            [id]: {
              width: width,
              height: height,
            },
          }));
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleInput = useCallback(
    (e) => {
      // some keyboard is open and user clicked into a different input field
      if (e.type === 'click') {
        if (keyboardId && keyboardId !== e.currentTarget.id) {
          setKeyboardId('');
          return;
        }
      }
      // user typed something into the input field = save the value
      const inputValue = e.currentTarget.value;
      setInputState((prevState) => ({
        ...prevState,
        value: inputValue,
      }));
    },
    [keyboardId, setKeyboardId]
  );

  const handleClick = useCallback(() => {
    // some keyboard is open and user clicked on a different .keyboard-button
    if (keyboardId && keyboardId !== inputState.id) {
      setKeyboardId('');
      setTimeout(() => {
        setInputState((prevState) => ({
          ...prevState,
          show: true,
        }));
        setKeyboardId(inputState.id);
      }, 0);
      return;
    }
    // no keyboard is open = open the keyboard modal
    if (!inputState.show && !keyboardId) {
      setInputState((prevState) => ({
        ...prevState,
        show: true,
      }));
      setKeyboardId(inputState.id);
    }
  }, [inputState.show, inputState.id, keyboardId, setKeyboardId]);

  useEffect(() => {
    const itemContent = itemContentRef.current;
    const keyboardInput = itemContent.querySelector(
      `input[usekeyboard='true'], textarea[usekeyboard='true']`
    );
    const keyboardButton = itemContent.querySelector('.keyboard-button');

    keyboardInput?.addEventListener('input', handleInput);
    keyboardInput?.addEventListener('click', handleInput);
    keyboardButton?.addEventListener('click', handleClick);
    return () => {
      keyboardInput?.removeEventListener('input', handleInput);
      keyboardInput?.removeEventListener('click', handleInput);
      keyboardButton?.removeEventListener('click', handleClick);
    };
  }, [handleInput, handleClick]);

  useEffect(() => {
    if (!keyboardId) {
      setInputState((prevState) => ({
        ...prevState,
        show: false,
      }));
    }
  }, [keyboardId]);

  useEffect(() => {
    if (inputState.id) {
      const itemContent = itemContentRef.current;
      const input = itemContent.querySelector(
        `input#${inputState.id}, textarea#${inputState.id}`
      );
      if (input.value !== inputState.value) {
        input.value = inputState.value;
        // value changed, fire event keyboard to save answer
        // look ItemManager bind event keyboard
        input.dispatchEvent(new Event('keyboard', { bubbles: false }));
      }
    }
  }, [inputState]);

  let itemContent = item.renderedOutput;
  if (item.shuffleDistractors) {
    itemContent = shuffleQuestion(
      `${props.answersId}${item.itemId}`,
      item.itemType,
      item.renderedOutput
    );
  }

  if (item.itemType === 'OpenItem') {
    const modifiedContent = addButtonToInput(itemContent);
    itemContent = modifiedContent;
  }

  if (item.itemType === 'MultipleChoiceMultipleAnswer') {
    // localize choice instructions
    const itemContentParts = itemContent.split('##');
    for (let i = 0; i < itemContentParts.length; i++) {
      if (itemContentParts[i].startsWith('ChoiceInstructions')) {
        itemContentParts[i] = ResourceManager(itemContentParts[i]);
      }
    }
    itemContent = itemContentParts.join('');
  }

  let questionFlag = '';
  if (!props.testModeSettings.disableFlag) {
    questionFlag = (
      <QuestionFlag itemId={item.itemId} answersId={props.answersId} />
    );
  }

  return (
    <div className="item">
      <div className="item-number">
        <h2 className="item-index">{itemIndex}</h2>
        {theme}
        <div className="item-number-right-align">
          {props.showItemScore && (
            <div className="item-score">
              {itemScore}
              {separator}
              {itemNegativeScore}
            </div>
          )}
          {questionFlag}
        </div>
      </div>
      <div
        ref={itemContentRef}
        className={
          hideDistractorKeys
            ? 'item-content hide-distractor-keys'
            : 'item-content'
        }
      >
        <RawHtml content={itemContent} />
      </div>
      {useKeyboard && (
        <KeyboardModal inputState={inputState} setInputState={setInputState} />
      )}
    </div>
  );
};

export default Question;
