import React, { Component } from 'react';
import ProctoringApp from '../proctoring';
import TestApp from './TestApp';
import TestHeader from './TestHeader';
import { ResourceManager } from '../resources/ResourceManager';
import disablePrintScreen from '../utils/DisablePrintScreen';
import disableCopyPaste from '../utils/DisableCopyPaste';
import disableSearching from '../utils/DisableSearching';
import finishInProgress from '../utils/finishInProgress';
import Finish from './Finish';
import beforeUnloadHelper from '../utils/BeforeUnloadHelper';
import log from '../utils/logging/DefaultLogger';
import { getProctoringConfiguration } from '../utils/tokenHelpers';

export default class InitializedApp extends Component {
  constructor(props) {
    super(props);
    const { tokens, tokenIndex } = this.props;
    const token = tokens[tokenIndex].token;
    const tokenObject = tokens[tokenIndex].tokenObject;

    this.state = {
      tokenIndex,
      token,
      tokenObject,
      finishEventType: '',
      isFinishing: false,
    };
  }

  componentDidMount() {
    const { tokenObject, isFinishing } = this.state;
    // finishing was already in progress, but canceled (by reload)
    const isFinishInProgress = finishInProgress.isFinishingActive(
      tokenObject.AnswersId
    );
    if (isFinishInProgress) {
      this.setState({
        isFinishing: true,
        finishEventType: 'retry',
      });
      return;
    }

    if (isFinishing === false) {
      beforeUnloadHelper.add();

      if (tokenObject.ExamSettings.DisablePrintScreen) {
        disablePrintScreen.add();
      }

      if (tokenObject.ExamSettings.DisableCopyPaste) {
        disableCopyPaste.add();
      }

      if (tokenObject.ExamSettings.DisableSearching) {
        disableSearching.add();
      }
    }
  }

  // finish on timer timed out or clicking the button
  finish = (finishEventType) => {
    this.setState({
      finishEventType,
      isFinishing: true,
    });
  };

  startNextTest = () => {
    const { tokens } = this.props;
    let activeTestIndex = this.state.tokenIndex;
    if (activeTestIndex < this.props.tokens.length - 1) {
      activeTestIndex++;
      const activeToken = tokens[activeTestIndex].token;
      const activeTokenObject = tokens[activeTestIndex].tokenObject;
      log.info(
        `Starting next test. ActiveTestIndex: ${activeTestIndex}`,
        activeTokenObject
      );
      this.setState({
        token: activeToken,
        tokenObject: activeTokenObject,
        tokenIndex: activeTestIndex,
        isFinishing: false,
      });
    }
  };

  render() {
    const { tokenObject, isFinishing, finishEventType } = this.state;

    // If test ended (reload, timer, button) show Finish, otherwise TestApp
    const testApp =
      isFinishing === true ? (
        <Finish
          type="testApp" // tag for proctoring app
          tokenObject={tokenObject}
          db={this.props.db}
          token={this.state.token}
          finishEventType={finishEventType}
          startNextTestFunc={this.startNextTest}
        />
      ) : (
        <TestApp
          type="testApp" // tag for proctoring app
          token={this.state.token}
          tokenObject={tokenObject}
          onFinish={this.finish}
          db={this.props.db}
          connectionId={this.props.connectionId}
          startTest={this.startNextTest}
        />
      );

    if (tokenObject.ExamSettings.ProctoringConfigurationId != null) {
      // if stopProctoring === true
      // Test is finishing, send signal to Proctoring
      // LS = local storage
      // 1. proctoring writes to LS the test finished, so it knows about it in case the user refreshes the browser
      // 2. proctoring finalizes the upload and does the cleanup
      // 3. after that shows the app again (Finish component)
      // 4. Finish component will write to LS (in it's own part, different from proctoring) about the test finishing
      // 5. cleans up after
      const stopProctoring =
        isFinishing === true &&
        tokenObject.ExamSettings.IsFinalSession === true;

      const proctoringConfig = getProctoringConfiguration(tokenObject);

      const proctoringHeaderProps = {
        tokenObject: this.state.tokenObject,
        startTime: false,
        finish: this.finish,
        pageTitle: ResourceManager('proctoringTitle'),
        additionalClassName: 'headerIntroChecks',
      };

      return (
        <ProctoringApp
          config={proctoringConfig}
          stopProctoring={stopProctoring}
        >
          <>
            <TestHeader headerProps={proctoringHeaderProps} type="pageHeader" />
            {testApp}
          </>
        </ProctoringApp>
      );
    }

    return <>{testApp}</>;
  }
}
