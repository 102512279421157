module.exports = {
  stringsUA: {
    errHandlerPageTitle: 'Щось пішло не так...',
    errHandlerTitle: 'Сталася помилка',
    errHandlerDesc:
      'Спробуйте ще раз, будь ласка. Якщо помилка повториться, поверніться до списку Іспит і натисніть кнопку Продовжити.',
    errTokenMissing: 'Відсутній токен авторизації!',
    errFetchToken: 'Неможливо розпізнати токен.',
    errInitIndexedDb: 'Помилка ініціалізації бази даних браузера.',
    errUnknown: 'Невизначена помилка сервера.',
    errUnableToLoadTest: 'Неможливо завантажити тест! ',
    errUnableToStartTest: 'Неможливо запустити тест! ',
    errUnableToFinishTest: 'Не вдалося закінчити тест! ',
    errInvalidToken: 'Неприпустимий токен',
    errInit: 'Помилка ініціалізації. ',
    errRetryAdvice: 'Спробуйте повторити операцію',
    errReturnLink: 'Список іспитів',
    errResilienceNoItems:
      'Немає відповідей для завантаження з цього пристрою. Переконайтеся, що ви перебуваєте на пристрої та в браузері, де проходили цей тест. Якщо це так, ви можете натиснути кнопку Завершити іспит. Якщо ви перебуваєте на іншому пристрої або в іншому веб-браузері, натисніть кнопку «Огляд іспитів» і увійдіть у ScioLink із потрібного пристрою.',
    err404: '404',
    err404Title: 'Сторінка не існує',
    err404Desc:
      'Сторінка, яку ви шукаєте, здається, не існує, перейдіть на домашню сторінку.',
    err404Btn: 'Перейдіть на головну сторінку',
    loadingData: '...завантажую дані...',
    savingData: '...завантажую дані...',
    testContinuesDownwards: 'Test pokračuje směrem dolů',
    testTotalQuestions: 'Кількість запитань:',
    testTimeLimit: 'Ліміт часу:',
    testRemainingTime: 'Залишилося часу:',
    testNoTimelimit: 'Без обмежень у часі.',
    btnTestStart: 'Запустити тест',
    btnPartStart: 'Запустити розділ',
    btnTryAgain: 'Спробувати знову',
    btnReturn: 'Список екзаменів',
    btnNavigationPrevious: 'Попередній',
    btnNavigationNext: 'Наступний',
    btnNavigationFinishTest: 'Закінчити тест',
    btnNavigationFinishPart: 'Закінчити розділ',
    itemQuestion: 'Задача',
    itemQuestions: 'úloh',
    sharedItem: 'Загальне завдання',
    sharedItemIndexOne: 'для задачі',
    sharedItemIndexN: 'для задач',
    implicitInstruction: 'Інструкція',
    implicitInstructionIndexOne: 'для задачі',
    implicitInstructionIndexN: 'для задач',
    insert: 'Vložit',
    instructionCollapse: 'collapse instruction',
    instructionExpand: 'expand instruction',
    downloadContentTitle: 'Допоміжні матеріали',
    calculatorTitle: 'Калькулятор',
    cancel: 'Zrušit',
    timer_estimatedTimeTest: 'Залишилося часу: ',
    timer_estimatedTimePart: 'Залишилося часу: ',
    timerTimeoutAlertTitle: 'Час сплив',
    timerTimeoutAlertTextTest:
      'Сплив ліміт часу на опрацювання. Тест буде завершено.',
    timerTimeoutAlertTextPart:
      'Сплив ліміт часу на опрацювання. Розділ буде завершено.',
    timerTimeoutAlertButton: 'так',
    timerTimeoutConfirmTitleTest: 'Ви дійсно хочете закінчити?',
    timerTimeoutConfirmTitlePart: 'Ви дійсно хочете закінчити?',
    timerTimeoutConfirmTextTest:
      '<b>Завершення тесту</b>  є незворотною операцією. Після завершення ви не зможете повернутися до окремих завдань.',
    timerTimeoutConfirmTextPart:
      '<b>Завершення розділу</b>  є незворотною операцією. Після завершення ви не зможете повернутися до окремих завдань. ',
    timerTimeoutConfirmButtonConfirm: 'Так, закінчити',
    timerTimeoutConfirmButtonCancel: 'Ні, повернутися',
    timer_hideTime_arialabel: 'Приховати час',
    timer_hideTime_tooltip:
      'Приховати час. Час буде відображено автоматично за 10 хвилин до кінця.',
    timer_showTime_tooltip: 'Показати, скільки залишилося часу.',
    timer_showTime: 'Показати час',
    timer_minutes: ['хвилина', 'хвилини', 'хвилин'],
    timer_snackbar: 'Máte posledních 10 minut testu',
    timerScreenReaders: `Залишився час:`,
    highlighterSnackbar: 'Vypnout zvýrazňovač',
    instructionsTitle: 'Інструкції до тесту',
    instructionsLegend: 'Легенда',
    instructionTitleItem: 'Задача',
    instructionTitleItems: 'Задачі',
    instructionTimerInfo: 'Zbývající čas na přečtení zadání: ',
    legendUreaded: 'Непрочитана задача',
    legendAnswered: "Розв'язана задача",
    legendActive: 'Активна задача, над якою ви зараз працюєте',
    legendFlagged: 'Позначена задача',
    calcDegrees: 'градуси',
    calcRadians: 'радіани',
    calcError: 'Помилкова операція',
    calcDivideByZero: 'Ділення на нуль',
    calcQqrtNegative: "Кореня парного степеня від'ємного числа не існує в R",
    calcMissingOperand: 'Відсутня частина виразу',
    calcInvalidToken: 'Неприпустимий вираз',
    calcUnknownError: 'Невідома помилка',
    flagTitle: 'Позначити задачу',
    flagTitleRemove: 'Видалити позначення',
    keyboardTitle: 'Допоміжна клавіатура',
    keyboardInstruction:
      "Меню спеціальних символів з'являється при натисканні відповідної літери, наприклад, щоб ввести é, натисніть e.",
    showSupportMaterials: 'Показати допоміжний інструмент',
    hideSupportMaterials: 'Приховати допоміжні матеріали',
    sidebarCalculator: 'Kalkulačka',
    sidebarSupportMaterials: 'Materiály',
    sidebarNotepad: 'Poznámky',
    sidebarHighlighter: 'Zvýrazňovač',
    sidebarSupport: 'Podpora',
    supportCenter: 'Zákaznické centrum',
    supportCenterText:
      'Zákaznické centrum. Od pondělí do čtvrtka mezi 9. a 17. hodinou. V den konání Národních srovnávacích zkoušek jsme k dispozici vždy v celém průběhu',
    supportCenterEmailTitle: 'Email',
    supportCenterEmailSubtitle: 'Jsme tu, abychom vám pomohli',
    supportCenterEmail: 'info@sciolink.cz',
    supportCenterPhoneTitle: 'Mobilní telefon',
    supportCenterPhoneSubtitle: 'Neváhejte nás kontaktovat',
    supportCenterPhone: '+1 (555) 000-0000',
    tooltipInstructions: 'Інструкції та настанови до іспиту',
    tooltipSupportMaterials: 'Допоміжні матеріали, дозволені для тесту',
    tooltipCalculator: 'Можна користуватися вбудованим калькулятором',
    tooltipHighlighter: 'Увімкніть хайлайтер',
    tooltipHighlighterOn: 'Вимкніть хайлайтер',
    tooltipNotepad: 'Блокнот',
    tooltipDisabled: 'Завантажується наступна сторінка',
    proctoringTitle: 'Налаштування та контроль даних',
    playMedia: 'Відтворити',
    playingMediaNewPageConfirmTitle: 'Зауваження під час відтворення',
    playingMediaNewPageConfirmMessage:
      'У разі переходу на іншу сторінку ви використаєте одну можливість відтворення медіаконтенту. Бажаєте перейти на іншу сторінку?',
    playingMediaNewPageConfirmMessageAutoreplay:
      'У разі переходу на іншу сторінку ви використаєте всі можливості відтворення медіаконтенту. Бажаєте перейти на іншу сторінку?',
    playingMediaNewPageButtonConfirm: 'Підтверджую',
    playingMediaNewPageButtonCancel: 'Скасувати',
    maximumAttemptsTitle: 'Зауваження щодо відтворення',
    maximumAttemptsMessage: 'Було перевищено максимальну кількість відтворень.',
    maximumAttemptsButton: 'так',
    mediaMessage1: 'Ця демонстрація має обмежену кількість відтворень.', //nastaveno maxNumberOfReplays
    mediaMessage21: ' Можна її відтворити цілком ', //autoReplay = false
    mediaMessage22: 'x. ',
    mediaMessage23: ' Вона відтвориться цілком ', //autoReplay = true
    mediaMessage24: 'x. ',
    mediaMessage3: 'Прослуховування після запуску вже не можна зупинити. ',
    mediaMessage4: 'Повторне відтворення запускається автоматично. ', //autoReplay = true
    mediaMessage51: 'У вас іще залишилося ', // estimated > 0
    mediaMessage52: ' відтворень.',
    mediaMessage6: ' У вас більше не залишилося відтворень.', // estimated == 0
    resilienceModeTitle: 'Актуалізація відповідей',
    resilienceModeTitleError: 'Закінчити іспит',
    resilienceConfirmText:
      'Відповіді, збережені під час тесту, буде завантажено на наші сервери.',
    resilienceConfirmButton: 'Завантажити відповіді',
    resilienceErrorButtonFinish: 'Закінчити іспит',
    resilienceErrorButtonBack: 'Огляд іспитів',
    percent: ' %',
    itemLoaderTextInit: 'Задача завантажується...',
    sessionNonActiveModalTitle: 'Перевірка поточних іспитів',
    sessionNonActiveModalMessage:
      'Цей тест уже виконується в іншому вікні! Закрийте це вікно та поверніться до початкового вікна.',
    sessionNonActiveModalButton: 'Спробуйте ще раз',
    sessionActiveModalTitle: 'Перевірка поточних іспитів',
    sessionActiveModalMessage:
      'Цей іспит щойно відкрився в іншому вікні! Закрийте всі інші вікна тестування, а потім натисніть «Продовжити тест».',
    sessionActiveModalButton: 'Продовжте тест',
    notepadTitle: 'Ваші нотатки',
    notepadPlaceholder: 'Місце для приміток до завдань',
    notepadSave: 'Зберегти та закрити',
    ChoiceInstructions_MinOrMax_Both_Same_Low_1: 'Позначте рівно ',
    ChoiceInstructions_MinOrMax_Both_Same_Low_2: ' варіанти.',
    ChoiceInstructions_MinOrMax_Both_Same_High_1: 'Позначте галочками саме ',
    ChoiceInstructions_MinOrMax_Both_Same_High_2: ' варіантів.',
    ChoiceInstructions_MinOrMax_Both_MaxOne_1:
      'Перевірте щонайбільше один варіант.',
    ChoiceInstructions_MinOrMax_Both_Different_Low_1: 'Позначте від ',
    ChoiceInstructions_MinOrMax_Both_Different_Low_2: ' до ',
    ChoiceInstructions_MinOrMax_Both_Different_Low_3: ' варіантів.',
    ChoiceInstructions_MinOrMax_Both_Different_High_1: 'Позначте від ',
    ChoiceInstructions_MinOrMax_Both_Different_High_2: ' до ',
    ChoiceInstructions_MinOrMax_Both_Different_High_3: ' варіантів.',
    ChoiceInstructions_MinOrMax_Max_One_1:
      'Перевірте щонайбільше один варіант.',
    ChoiceInstructions_MinOrMax_Max_Low_1: 'Поставте галочки не більше ',
    ChoiceInstructions_MinOrMax_Max_Low_2: ' варіантів.',
    ChoiceInstructions_MinOrMax_Max_High_1: 'Перевірте не більше ',
    ChoiceInstructions_MinOrMax_Max_High_2: ' варіантів.',
    ChoiceInstructions_MinOrMax_Min_One_1: 'Позначте принаймні 1 варіант.',
    ChoiceInstructions_MinOrMax_Min_Low_1: 'Перевірте щонайменше ',
    ChoiceInstructions_MinOrMax_Min_Low_2: ' варіанти.',
    ChoiceInstructions_MinOrMax_Min_High_1: 'Позначте принаймні ',
    ChoiceInstructions_MinOrMax_Min_High_2: ' варіантів.',
    ChoiceInstructions_NotSet_Low_1: 'Поставте галочки від 0 до ',
    ChoiceInstructions_NotSet_Low_2: ' варіантів.',
    ChoiceInstructions_NotSet_High_1: 'Позначте від 0 до ',
    ChoiceInstructions_NotSet_High_2: ' варіантів.',
    Score: 'Правильна відповідь: ',
    ScoreNegative: 'Неправильна відповідь: ',
    ScoreOnePoint: ' бал',
    ScoreTwo2FourPoints: ' бали',
    ScoreFive2NPoints: ' балів',
    ScoreLessThenPoint: ' Півбала',
  },
  // eslint-disable-next-line space-before-function-paren
  getResource: function (key) {
    return this.stringsUA[key];
  },
};
