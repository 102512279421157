import React, { Component } from 'react';
import TestHeader from './TestHeader';
import Navigation from './Navigation';
import Page from './Page';
import Sidebar from './Sidebar';
import TestButtons from './TestButtons';

export default class StandardTestMode extends Component {
  render() {
    const testModeSettings = {};

    return (
      <>
        <TestHeader headerProps={this.props.headerProps} />
        <Navigation
          className="container"
          test={this.props.test}
          currentPage={this.props.currentPage}
          switchPage={this.props.move}
          items={this.props.items}
          db={this.props.db}
          tokenObject={this.props.tokenObject}
          testModeSettings={testModeSettings}
          checkTestAppHasScrollbar={this.props.checkTestAppHasScrollbar}
        />
        <Page
          className="container"
          currentPage={this.props.currentPage}
          currentItem={this.props.currentItem}
          test={this.props.test}
          db={this.props.db}
          token={this.props.token}
          tokenObject={this.props.tokenObject}
          items={this.props.items}
          testModeSettings={testModeSettings}
          connectionId={this.props.connectionId}
        />
        <Sidebar sidebarProps={this.props.sidebarProps} />
        <TestButtons
          className="container"
          isFirstItem={this.props.currentPage === 0}
          isLastItem={
            this.props.currentPage === this.props.test.pages.length - 1
          }
          isFinalSession={this.props.tokenObject.ExamSettings.IsFinalSession}
          movePrevious={this.props.movePrevious}
          moveNext={this.props.moveNext}
          finish={this.props.finish}
          testModeSettings={testModeSettings}
          testAppHasScrollbar={this.props.testAppHasScrollbar}
          checkTestAppHasScrollbar={this.props.checkTestAppHasScrollbar}
        />
      </>
    );
  }
}
