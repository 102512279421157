import BaseOperator from "./BaseOperator";


export default class SubtractOperator extends BaseOperator {

    getOperator() {
        return '-';
    }

    toFullString() {
        return " " + this.getOperator() + " ";
    }
}