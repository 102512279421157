// https://github.com/alienzhou/web-highlighter/blob/master/example/local.store.js

class HighlighterLocalStore {
  constructor(answersId, pageIndex) {
    this.answersId = answersId;
    this.pageIndex = pageIndex;
  }

  getKey() {
    return this.answersId
      ? `${this.answersId}_highlighter_${this.pageIndex}`
      : 'highlighter';
  }

  storeToJson() {
    const key = this.getKey();
    const store = localStorage.getItem(key);
    let sources;
    try {
      sources = JSON.parse(store) || [];
    } catch (e) {
      sources = [];
    }
    return sources;
  }

  jsonToStore(stores) {
    localStorage.setItem(this.getKey(), JSON.stringify(stores));
  }

  save(data) {
    const stores = this.storeToJson();
    const map = {};
    let localData = data;
    stores.forEach((store, idx) => {
      map[store.hs.id] = idx;
    });

    if (!Array.isArray(localData)) {
      localData = [localData];
    }

    localData.forEach((store) => {
      // update
      if (map[store.hs.id] !== undefined) {
        stores[map[store.hs.id]] = store;
      }
      // append
      else {
        stores.push(store);
      }
    });

    this.jsonToStore(stores);
  }

  forceSave(store) {
    const stores = this.storeToJson();
    stores.push(store);
    this.jsonToStore(stores);
  }

  remove(id) {
    const stores = this.storeToJson();
    let index = null;
    for (let i = 0; i < stores.length; i++) {
      if (stores[i].hs.id === id) {
        index = i;
        break;
      }
    }
    stores.splice(index, 1);
    this.jsonToStore(stores);
  }

  getAll() {
    return this.storeToJson();
  }

  removeAll() {
    this.jsonToStore([]);
  }
}

export default HighlighterLocalStore;
