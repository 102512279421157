import React, { Component } from 'react';
import { ResourceManager } from '../resources/ResourceManager';
import { ConfirmAlert } from '../modals/ConfirmAlertModal';
import StandardTestMode from './StandardTestMode';
import ForwardTestMode from './ForwardTestMode';

export default class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: this.getStorageValueInt(this.getStorageNamePage()),
      currentItem: this.getStorageValueInt(this.getStorageNameItem()),
      testAppHasScrollbar: false,
    };
    this.move = this.move.bind(this);
  }

  componentDidMount() {
    this.saveItemDisplayLog(
      this.getCurrentPageItemIds(
        this.getStorageValueInt(this.getStorageNamePage())
      ),
      this.getStorageValueInt(this.getStorageNamePage()),
      'init'
    );
    this.checkTestAppHasScrollbar();
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.testAppHasScrollbar !== this.state.testAppHasScrollbar) {
      this.checkTestAppHasScrollbar();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    if (this.resizeTimeout) clearTimeout(this.resizeTimeout);
  }

  getStorageNameItem = () => `${this.props.tokenObject.AnswersId}_lastItem`;

  getStorageNamePage = () => `${this.props.tokenObject.AnswersId}_lastPage`;

  getStorageValueInt = (storageName) => {
    const value = parseInt(localStorage.getItem(storageName));
    return value && !Number.isNaN(value) ? value : 0;
  };

  saveToStorage = (currentPage, currentItem) => {
    localStorage.setItem(this.getStorageNamePage(), currentPage);
    localStorage.setItem(this.getStorageNameItem(), currentItem);
  };

  mediaPlaying = (elementsArray, mediaTag) => {
    const mediaElements = document.getElementsByTagName(mediaTag);
    if (mediaElements.length > 0) {
      Array.from(mediaElements).forEach((element) => {
        const maxReplayCount = parseInt(
          element.getAttribute('maxNumberOfReplays')
        );
        if (
          maxReplayCount &&
          !Number.isNaN(maxReplayCount) &&
          maxReplayCount > 0
        ) {
          if (this.isElementPlaying(element)) {
            elementsArray.push(element);
          }
        }
      });
    }
  };

  isElementPlaying = (element) =>
    !!(
      element.currentTime > 0 &&
      !element.paused &&
      !element.ended &&
      element.readyState > 2
    );

  // set currentItem to first item of first itemGroup in target page
  movePrevious = () => {
    this.move(
      this.state.currentPage - 1,
      this.props.test.pages[this.state.currentPage - 1].itemGroups[0].items[0]
        .itemId,
      'btnPrevious'
    );
  };

  moveNext = () => {
    this.move(
      this.state.currentPage + 1,
      this.props.test.pages[this.state.currentPage + 1].itemGroups[0].items[0]
        .itemId,
      'btnNext'
    );
  };

  move = (newCurrentPage, currentItemId, displayAction) => {
    let currentItemIdHolder = currentItemId;
    const { currentPage } = this.state;
    if (newCurrentPage < 0 || newCurrentPage > this.props.test.pages.length - 1)
      return;

    const itemGroup = this.props.test.pages[newCurrentPage].itemGroups[0];

    // change currentItemIdHolder when page contains shared item or instruction due to scrollTo component
    if (itemGroup) {
      // if page contains instruction, set currentItemIdHolder to instruction
      if (itemGroup.items) {
        const instructionIndex = itemGroup.items.findIndex(
          (i) => i.contextType === 'Instruction'
        );
        const hasFirstItemInstructions =
          itemGroup.items &&
          itemGroup.items[0].instructions &&
          itemGroup.items[0].instructions.length > 0;
        if (instructionIndex >= 0 || hasFirstItemInstructions) {
          currentItemIdHolder = -1; // itemGroup.items[instructionIndex].itemId;
        }
      }

      // if page contains shared item, set currentItemIdHolder to shared item
      if (itemGroup.sharedItem && itemGroup.sharedItem.itemId) {
        currentItemIdHolder = -1; // itemGroup.sharedItem.itemId;
      }
    }

    if (currentPage !== newCurrentPage) {
      const playingMediaElementsArray = [];
      this.mediaPlaying(playingMediaElementsArray, 'audio');
      this.mediaPlaying(playingMediaElementsArray, 'video');

      if (playingMediaElementsArray.length > 0) {
        this.initMediaElements(
          newCurrentPage,
          currentItemIdHolder,
          playingMediaElementsArray,
          displayAction
        );
        return; // stay at current page (cancel from alert)
      }
    }
    this.setNewPage(
      newCurrentPage,
      currentItemIdHolder,
      displayAction,
      currentPage !== newCurrentPage
    );
  };

  setNewPage = (
    newCurrentPage,
    currentItemIdHolder,
    displayAction,
    isDifferentPage
  ) => {
    if (isDifferentPage) {
      this.saveItemDisplayLog(
        this.getCurrentPageItemIds(newCurrentPage),
        newCurrentPage,
        displayAction
      );
    }

    this.saveToStorage(newCurrentPage, currentItemIdHolder);
    this.setState({
      currentPage: newCurrentPage,
      currentItem: currentItemIdHolder,
    });
  };

  initMediaElements = (
    newCurrentPage,
    currentItemIdHolder,
    playingMediaElementsArray,
    displayAction
  ) => {
    let warningMessage = ResourceManager('playingMediaNewPageConfirmMessage');

    const autoreplayElements = playingMediaElementsArray.filter((el) =>
      this.isAutoreplay(el)
    );
    if (autoreplayElements.length > 0) {
      // if any playing media elemnts has autoreplay, use autoreplay warning message
      warningMessage = ResourceManager(
        'playingMediaNewPageConfirmMessageAutoreplay'
      );
    }

    ConfirmAlert({
      title: ResourceManager('playingMediaNewPageConfirmTitle'),
      message: warningMessage,
      buttons: [
        {
          label: ResourceManager('playingMediaNewPageButtonConfirm'),
          onClick: () => {
            // increase replays count

            playingMediaElementsArray.forEach((element) => {
              if (this.isElementPlaying(element)) {
                // sets only when media is still playing when confirm button pressed, otherwise replay attempt is stored by onended method
                let currentReplays = parseInt(
                  atob(
                    localStorage.getItem(btoa(`${element.id}_currentReplays`))
                  )
                );

                if (!currentReplays || Number.isNaN(currentReplays)) {
                  currentReplays = 0;
                }
                if (this.isAutoreplay(element)) {
                  currentReplays = this.getMaxReplays(element); // when autoreplay, lose all replays
                } else {
                  currentReplays++; // lose one attempt
                }
                localStorage.setItem(
                  btoa(`${element.id}_currentReplays`),
                  btoa(currentReplays)
                );
                element.currentTime = 0;
                localStorage.removeItem(btoa(`${element.id}_currentTime`));
                element.pause();
              }
            });

            this.setNewPage(
              newCurrentPage,
              currentItemIdHolder,
              displayAction,
              true
            );
          },
          className: 'btn btn-secondary',
        },
        {
          label: ResourceManager('playingMediaNewPageButtonCancel'),
          className: 'btn btn-primary',
        },
      ],
    });
  };

  finish = (eventType) => {
    if (eventType === 'timer') {
      this.timeoutAlert(eventType);
    } else {
      this.finishConfirm(eventType);
    }
  };

  getMaxReplays = (element) => {
    let maxReplays = 0;
    const maxReplaysAttr = element.attributes.maxNumberOfReplays;
    if (maxReplaysAttr) {
      const intMaxReplays = parseInt(maxReplaysAttr.value);
      if (Number.isInteger(intMaxReplays)) {
        maxReplays = intMaxReplays;
      }
    }
    return maxReplays;
  };

  isAutoreplay = (element) => {
    let autoreplay = false;
    const autoReplayAttr = element.attributes.autoreplay;
    if (autoReplayAttr) {
      autoreplay = autoReplayAttr.value === 'true';
    }
    return autoreplay;
  };

  doFinish = (eventType) => {
    this.props.onFinish(eventType);
  };

  timeoutAlert = (eventType) => {
    const resourceTextName = this.props.tokenObject.ExamSettings.IsFinalSession
      ? 'timerTimeoutAlertTextTest'
      : 'timerTimeoutAlertTextPart';
    ConfirmAlert({
      title: ResourceManager('timerTimeoutAlertTitle'),
      message: ResourceManager(resourceTextName),
      buttons: [
        {
          label: ResourceManager('timerTimeoutAlertButton'),
          onClick: () => {
            this.doFinish(eventType);
          },
          className: 'btn btn-primary',
        },
      ],
    });
  };

  finishConfirm = (eventType) => {
    const resourceTitle = this.props.tokenObject.ExamSettings.IsFinalSession
      ? 'timerTimeoutConfirmTitleTest'
      : 'timerTimeoutConfirmTitlePart';

    const resourceText = this.props.tokenObject.ExamSettings.IsFinalSession
      ? 'timerTimeoutConfirmTextTest'
      : 'timerTimeoutConfirmTextPart';

    ConfirmAlert({
      title: ResourceManager(resourceTitle),
      message: ResourceManager(resourceText),
      buttons: [
        {
          label: ResourceManager('timerTimeoutConfirmButtonCancel'),
          onClick: () => {},
          className: 'btn btn-secondary',
        },
        {
          label: ResourceManager('timerTimeoutConfirmButtonConfirm'),
          onClick: () => {
            this.doFinish(eventType);
          },
          className: 'btn btn-primary',
        },
      ],
    });
  };

  initPageTitle = (page) => {
    const pageItems = this.props.test.pages[page].itemGroups
      .map((g) => g.items.map((i) => i.index))
      .reduce((a, b) => a.concat(b))
      .filter((x) => x > 0); // map to one array, remove shared item index

    const firstItem = pageItems[0];
    const lastItem = pageItems[pageItems.length - 1];
    if (firstItem && lastItem) {
      if (firstItem === lastItem) {
        return `${ResourceManager('instructionTitleItem')} ${firstItem}`;
      }
      return `${ResourceManager(
        'instructionTitleItems'
      )} ${firstItem}–${lastItem}`;
    }
    return '';
  };

  getCurrentPageItemIds = (currentPageIndex) => {
    const pageItems = [];
    const pageItemGroups = this.props.test.pages[currentPageIndex].itemGroups;
    pageItemGroups.forEach((ig) => {
      ig.items.forEach((i) => {
        pageItems.push(i.itemId);
      });
    });

    return pageItems;
  };

  saveItemDisplayLog = (pageItems, pageIndex, displayAction) => {
    const itemDisplayLog = {
      answersId: this.props.tokenObject.AnswersId,
      pageIndex,
      itemIds: pageItems,
      displayTimeUtc: new Date().toISOString(),
      displayAction,
    };

    const tran = this.props.db.transaction('DisplayItemLog', 'readwrite');
    tran.objectStore('DisplayItemLog').add(itemDisplayLog);
  };

  handleResize = () => {
    if (this.resizeTimeout) clearTimeout(this.resizeTimeout);

    this.resizeTimeout = setTimeout(() => {
      this.checkTestAppHasScrollbar();
      this.checkModalsOverflow();
      this.resizeTimeout = null;
    }, 300);
  };

  checkTestAppHasScrollbar = () => {
    setTimeout(() => {
      const testApp = document.getElementById('TestApp');
      const testPage = document.querySelector('.test-page');
      const tolerance = 1;
      if (testApp && testPage) {
        const testPageHeight = testPage.clientHeight;
        const headerHeight = document.getElementById('header').clientHeight;
        const navigationRowHeight =
          document.getElementById('navigation-row').clientHeight;
        const innerHeightCombined =
          headerHeight + navigationRowHeight + testPageHeight;
        const testAppHasScrollbar =
          testApp.clientHeight + tolerance < innerHeightCombined;
        this.changeTestAppHasScrollbar(testAppHasScrollbar);
      }
    }, 0);
  };

  checkModalsOverflow = () => {
    Array.from(document.getElementsByClassName('sidebar-modal')).forEach(
      (m) => {
        if (m.offsetLeft + m.clientWidth > window.innerWidth - 60) {
          m.style.left = `${window.innerWidth - m.clientWidth - 60}px`;
        }
      }
    );
  };

  changeTestAppHasScrollbar = (checkResult) => {
    this.setState({
      testAppHasScrollbar: checkResult,
    });
  };

  resizeTimeout = null;

  render() {
    const { currentPage } = this.state;
    const { currentItem } = this.state;
    const headerProps = {
      tokenObject: this.props.tokenObject,
      testInstance: this.props.testInstance,
      startTime: true,
      finish: this.finish,
      pageTitle: this.initPageTitle(this.state.currentPage),
    };
    const sidebarProps = {
      tokenObject: this.props.tokenObject,
      test: this.props.test,
      items: this.props.items,
      pageIndex: currentPage,
      toggleHighlighterActive: this.props.toggleHighlighterActive,
      isHighlighterActive: this.props.isHighlighterActive,
    };

    if (this.props.tokenObject.TestMode === 2) {
      return (
        <ForwardTestMode
          headerProps={headerProps}
          sidebarProps={sidebarProps}
          test={this.props.test}
          items={this.props.items}
          db={this.props.db}
          tokenObject={this.props.tokenObject}
          token={this.props.token}
          currentPage={currentPage}
          currentItem={currentItem}
          movePrevious={this.movePrevious}
          moveNext={this.moveNext}
          finish={this.finish}
          connectionId={this.props.connectionId}
          testAppHasScrollbar={this.state.testAppHasScrollbar}
          checkTestAppHasScrollbar={this.checkTestAppHasScrollbar}
        />
      );
    }
    return (
      <StandardTestMode
        headerProps={headerProps}
        sidebarProps={sidebarProps}
        test={this.props.test}
        items={this.props.items}
        db={this.props.db}
        tokenObject={this.props.tokenObject}
        token={this.props.token}
        currentPage={currentPage}
        currentItem={currentItem}
        movePrevious={this.movePrevious}
        moveNext={this.moveNext}
        move={this.move}
        finish={this.finish}
        connectionId={this.props.connectionId}
        testAppHasScrollbar={this.state.testAppHasScrollbar}
        checkTestAppHasScrollbar={this.checkTestAppHasScrollbar}
      />
    );
  }
}
