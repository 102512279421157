import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { notOkIcon, okIcon } from '../../icons';
import { messages } from '../../intl';
import { Context } from '../../Proctoring';
import useInterval from '../../utils/useInterval';

const Storage = ({ isActive }) => {
  const { setAllowContinue } = useContext(Context);
  const intl = useIntl();

  const [storageAmount, setStorageAmount] = useState(null);
  const [progressBar, setProgressBar] = useState(0);

  const didStoragePass = storageAmount && storageAmount > 1 && progressBar > 99;

  useInterval(
    () => setProgressBar(progressBar + 20),
    500,
    progressBar > 0 && progressBar < 99
  );

  const measureStorage = async () => {
    const quota = await navigator.storage.estimate();
    // Allocated quota - used space / converting into GB
    setStorageAmount((quota.quota - quota.usage) / 1073741824);
    setProgressBar(1);
  };

  useEffect(() => {
    if (didStoragePass && isActive) {
      setTimeout(() => setAllowContinue(true), 500);
    }
  }, [didStoragePass, isActive, setAllowContinue]);

  return (
    <div className="introCheck">
      <h2 className="header2Semibold">
        <FormattedMessage {...messages.storageCheckup} />
      </h2>

      <p className="width530 textMdNormal">
        <FormattedMessage {...messages.storageCheckupDesc} />
      </p>

      {didStoragePass ? okIcon : progressBar > 99 ? notOkIcon : null}

      <div className="speed-test-box">
        {progressBar < 99 && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={progressBar} />
          </Box>
        )}

        {!didStoragePass && progressBar === 0 && (
          <button
            aria-label={intl.formatMessage(messages.checkStorage)}
            className={`bgActive`}
            onClick={measureStorage}
          >
            <FormattedMessage {...messages.check} />
          </button>
        )}

        {didStoragePass ? (
          <>
            <div className="textLgMedium" tabIndex="0">
              <FormattedMessage {...messages.checkSuccess} />
            </div>
            <div className="textSmNormal" tabIndex="0">
              <FormattedMessage {...messages.youCanContinueToNextStep} />
            </div>
          </>
        ) : (
          progressBar > 99 &&
          storageAmount !== null && (
            <>
              <div className="textLgMedium">
                <FormattedMessage {...messages.storageAmountLow} tabIndex="0" />
              </div>
              <div className="textSmNormal">
                <FormattedMessage
                  {...messages.storageAmountLowDesc}
                  tabIndex="0"
                />
              </div>
            </>
          )
        )}
        {!didStoragePass && progressBar > 99 && (
          <button className={`bgActive mt25`} onClick={measureStorage}>
            <FormattedMessage {...messages.checkAgain} />
          </button>
        )}
      </div>
    </div>
  );
};

Storage.propTypes = {
  isActive: PropTypes.bool,
};

export default Storage;
