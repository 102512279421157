const types = {
  CONSTANT: "CONSTANT",
  NAMED_FUNCTION: "NAMED_FUNCTION",
  OPERATOR: "OPERATOR",
  VARIABLE: "VARIABLE",
  NUMBER: "NUMBER",
  LEFT_PARENTHESIS: "LEFT_PARENTHESIS",
  RIGHT_PARENTHESIS: "RIGHT_PARENTHESIS"
};

export default types;
