import BaseOperator from "./BaseOperator";

export default class PercentageOperator extends BaseOperator {

    getOperator() {
        return '%';
    }

    toFullString() {
        return this.getOperator() +" ";
    }

}