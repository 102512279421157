import React, { Component } from 'react';
import { ResourceManager } from '../resources/ResourceManager';

export default class QuestionFlag extends Component {
  toggleFlag = () => {
    window.ItemManager.ToggleFlag(this.props.itemId, this.props.answersId);
  };

  render() {
    return (
      <button
        id={`flag${this.props.itemId}`}
        className="flag"
        data-remove={ResourceManager('flagTitleRemove')}
        data-title={ResourceManager('flagTitle')}
        onClick={this.toggleFlag}
      >
        {ResourceManager('flagTitle')}
      </button>
    );
  }
}
