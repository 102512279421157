import React, { Component } from 'react';

export default class NavigationItem extends Component {
  changePage = (event) => {
    this.props.switchPage(
      parseInt(event.target.dataset.page),
      parseInt(event.target.dataset.itemid),
      `navigation(${this.props.itemIndex})`
    );
    this.props.checkTestAppHasScrollbar();
  };

  render() {
    const id = `navbutton${this.props.itemId}`;
    let itemNumber = this.props.itemIndex;
    let styleClass = 'item-link';
    let styleActive = '';
    let rest = {};

    if (this.props.isActivePage) {
      styleActive = ' active';
      rest = { 'aria-current': 'step' };
    }

    if (this.props.itemIndex === 0) {
      itemNumber = '';
      styleClass += ' item-instruction';
    }

    return (
      <button
        key={`button_${this.props.itemIndex}`}
        id={id}
        data-page={this.props.pageIndex}
        data-itemidentifier={this.props.itemId}
        data-itemid={this.props.itemId}
        className={styleClass + styleActive}
        onClick={this.props.isActivePage ? () => {} : this.changePage}
        {...rest}
      >
        {itemNumber}
      </button>
    );
  }
}
