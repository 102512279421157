import React, { Component } from 'react';
import Loader from './Loader';
import { ResourceManager } from '../resources/ResourceManager';
import { ConfirmAlert } from '../modals/ConfirmAlertModal';
import ProctoringApp from '../proctoring';
import ResilienceFinish from './ResilienceFinish';
import { getProctoringConfiguration } from '../utils/tokenHelpers';
import TestHeader from './TestHeader';

export default class Resilience extends Component {
  constructor() {
    super();
    this.state = {
      resilienceUnconfirmed: true,
      error: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    if (this.state.resilienceUnconfirmed) {
      this.confirmResilience();
      return;
    }
  }

  confirmResilience = () => {
    ConfirmAlert({
      title: ResourceManager('resilienceModeTitle'),
      message: ResourceManager('resilienceConfirmText'),
      buttons: [
        {
          label: ResourceManager('resilienceConfirmButton'),
          onClick: () => {
            this.setState({ resilienceUnconfirmed: false });
          },
          className: 'btn btn-primary',
        },
      ],
    });
  };

  render() {
    if (this.state.resilienceUnconfirmed)
      return <Loader message={ResourceManager('loadingData')} />;

    const { tokens, db } = this.props;
    const tokenObject = tokens[0].tokenObject;
    const resilienceFinish = (
      <ResilienceFinish tokens={tokens} db={db} type="testApp" />
    );

    if (tokenObject.ExamSettings.ProctoringConfigurationId) {
      const proctoringConfig = getProctoringConfiguration(tokenObject);

      return (
        <ProctoringApp config={proctoringConfig} stopProctoring={true}>
          <>
            <TestHeader />
            {resilienceFinish}
          </>
        </ProctoringApp>
      );
    }

    return <>{resilienceFinish}</>;
  }
}
