import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { MIN_CONNECTION_SPEED } from '../../consts';
import { notOkIcon, okIcon } from '../../icons';
import { messages } from '../../intl';
import { Context } from '../../Proctoring';

const Connection = ({ isActive }) => {
  const { setAllowContinue } = useContext(Context);
  const [isNetworkSpeedSufficient, setIsNetworkSpeedSufficient] =
    useState(false);
  const [progressBar, setProgressBar] = useState(0);
  const isDummyProgressDone = progressBar === 100;
  const didSpeedPass = isNetworkSpeedSufficient && isDummyProgressDone;
  const intl = useIntl();

  useEffect(() => {
    if (didSpeedPass && isActive) setAllowContinue(true);
  }, [didSpeedPass, isActive, setAllowContinue]);

  const measureNetworkSpeed = async () => {
    setIsNetworkSpeedSufficient(false);
    setProgressBar(0);

    const timer = setInterval(() => {
      setProgressBar((oldProgress) => {
        if (oldProgress === 100) clearInterval(timer);
        const diff = Math.random() * 15;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    // Measuring connection speed like this is only an approximation, see:
    // https://stackoverflow.com/questions/5529718/how-to-detect-internet-speed-in-javascript
    const imageAddr = 'https://proctoringapp.azureedge.net/speedCheck.jpg';
    const downloadSize = 2707459;

    const startTime = new Date().getTime();
    const cacheBuster = '?nnn=' + startTime;

    const download = new Image();
    download.onload = () => {
      const endTime = new Date().getTime();
      const duration = (endTime - startTime) / 1000;
      const bitsLoaded = downloadSize * 8;
      const speedBps = (bitsLoaded / duration).toFixed(2);
      const speedKbps = (speedBps / 1024).toFixed(2);
      const speedMbps = (speedKbps / 1024).toFixed(2);

      if (speedMbps > MIN_CONNECTION_SPEED) setIsNetworkSpeedSufficient(true);
    };

    download.src = imageAddr + cacheBuster;
  };

  return (
    <div className="introCheck">
      <h2 className="header2Semibold">
        <FormattedMessage {...messages.connectionCheckup} />
      </h2>

      <p className="width530 textMdNormal">
        <FormattedMessage {...messages.checkConnectionDesc} />
      </p>

      {didSpeedPass ? okIcon : isDummyProgressDone ? notOkIcon : null}

      <div className="speed-test-box">
        {!isDummyProgressDone && (
          <Box sx={{ width: '100%' }}>
            <LinearProgress variant="determinate" value={progressBar} />
          </Box>
        )}

        {!didSpeedPass && progressBar === 0 && (
          <button
            className={`bgActive`}
            onClick={measureNetworkSpeed}
            aria-label={intl.formatMessage(messages.checkConnection)}
          >
            <FormattedMessage {...messages.check} />
          </button>
        )}

        {didSpeedPass ? (
          <>
            <div className="textLgMedium" tabIndex="0">
              <FormattedMessage {...messages.checkSuccess} />
            </div>
            <div className="textSmNormal" tabIndex="0">
              <FormattedMessage {...messages.youCanContinueToNextStep} />
            </div>
          </>
        ) : (
          isDummyProgressDone &&
          !isNetworkSpeedSufficient && (
            <>
              <div className="textLgMedium" tabIndex="0">
                <FormattedMessage {...messages.connectionSpeedLow} />
              </div>
              <div className="textSmNormal" tabIndex="0">
                <FormattedMessage {...messages.connectionSpeedLowDesc} />
              </div>
            </>
          )
        )}
        {isDummyProgressDone && !isNetworkSpeedSufficient && (
          <button className={`bgActive mt25`} onClick={measureNetworkSpeed}>
            <FormattedMessage {...messages.checkAgain} />
          </button>
        )}
      </div>
    </div>
  );
};

Connection.propTypes = {
  isActive: PropTypes.bool,
};

export default Connection;
