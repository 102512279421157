import log from './logging/DefaultLogger';

const createDatabase = (db) => {
  // cleanup původních stores
  const storeNames = [...db.objectStoreNames];
  for (let i = 0; i < storeNames.length; i++) {
    const storeName = storeNames[i].toString();
    db.deleteObjectStore(storeName);
  }

  db.createObjectStore('Answer', { keyPath: 'ItemId' });
  db.createObjectStore('DisplayItemLog', { autoIncrement: true });
};

const initDatabaseAsync = () => {
  return new Promise((resolve, reject) => {
    log.debug('Initializing indexed db.');
    const indexedDB =
      window.indexedDB || // Use the standard DB API
      window.mozIndexedDB || // Or Firefox's early version of it
      window.webkitIndexedDB;

    // cleanup();//#WARNING - USE ONLY FOR DEBUGGING
    const request = indexedDB.open('TestingRepository', 4);

    request.onerror = (event) => {
      reject(event.target.error.message);
    };

    request.onupgradeneeded = (event) => {
      createDatabase(event.target.result);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      log.debug('Indexed db initialized.');
      resolve(db);
    };
  });
};

// #WARNING - USE ONLY FOR DEBUGGING
// function cleanup() {
//    const deleteRequest = indexedDB.deleteDatabase(dbName);
// }

export default initDatabaseAsync;
