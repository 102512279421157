import React, { Component } from 'react';
import InstructionBox from './InstructionBox';

export default class NavigationForward extends Component {
  render() {
    const {
      test,
      itemNumber,
      items,
      percentage,
      testModeSettings,
      totalNumberOfQuestions,
      showProgressBar,
    } = this.props;

    return (
      <div id="navigation-row" className="container forward-stepper">
        <InstructionBox
          items={items}
          test={test}
          testModeSettings={testModeSettings}
        />
        {showProgressBar && (
          <div className="progress">
            <div className="progress-bar" style={{ width: `${percentage}%` }}>
              <div className="progress-thumb" />
              <div className="item-counter">
                <span>{itemNumber}</span>
                <span className="grey-font">{` / ${totalNumberOfQuestions}`}</span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
