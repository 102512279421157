import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { CHECK } from '../../consts';
import { checkPassedIcon } from '../../icons';
import { messages } from '../../intl';
import { Context } from '../../Proctoring';
import secondsToMMSS from '../../utils/secondsToMMSS';

const RoomCheckProgress = (props) => {
  const { position, showModal } = props;
  const { checks } = useContext(Context);
  const roomChecks = checks.filter(
    ({ runCheck, parentChecklist }) =>
      runCheck === CHECK.ROOM && parentChecklist
  );

  return (
    <ul className="progressNav">
      {roomChecks.map(
        ({ headline, durationInSeconds, instructionsText }, i) => (
          <li
            key={`rooom-checks-${i}`}
            className={`check ${
              position - 1 === i ? 'current' : position > i ? 'passed' : ''
            }`}
          >
            <span className="label">{checkPassedIcon}</span>
            <span className="name textXsMedium">
              {headline} ({secondsToMMSS(durationInSeconds)})
              <div
                className="textXsNormal"
                dangerouslySetInnerHTML={{
                  __html: instructionsText ?? '',
                }}
              />
            </span>
          </li>
        )
      )}
      <button onClick={showModal} className="textSmSemibold plain">
        <FormattedMessage {...messages.restartRoomCheck} />
      </button>
    </ul>
  );
};

export default RoomCheckProgress;
