import { defineMessages } from 'react-intl';

export default defineMessages({
  agreeWithAiDev: {
    id: 'global.agreeWithAiDev',
    defaultMessage:
      'I agree to the use of the exam recording for the purpose of further development of proctoring in the ScioLink application (optional).',
  },
  examPreparingDontRestartBrowser: {
    id: 'global.examPreparingDontRestartBrowser',
    defaultMessage:
      'The page is loading. Do not close or reload the web browser.',
  },
  desktopControl: {
    id: 'global.desktopControl',
    defaultMessage: 'Processes',
  },
  errorChooseMonitor: {
    id: 'global.errorChooseMonitor',
    defaultMessage: 'Share the entire monitor screen.',
  },
  systemDisallowsScreenSharing: {
    id: 'global.systemDisallowsScreenSharing',
    defaultMessage:
      'Enable screen sharing for Chrome in the operating system privacy settings.',
  },
  errorDontStopSharing: {
    id: 'global.errorDontStopSharing',
    defaultMessage:
      'Share the screen for the entire exam. The exam result may be blocked otherwise.',
  },
  turnOnWebCamAndRestartBrowser: {
    id: 'global.turnOnWebCamAndRestartBrowser',
    defaultMessage:
      'Make sure the webcam is properly connected, is not used by another application, and is allowed in the operating system and web browser settings. Then reload the page',
  },
  stillUploadingWarning: {
    id: 'global.stillUploadingWarning',
    defaultMessage:
      'Do not close the page. Data is still being uploaded to our servers. This process may take several minutes.',
  },
  uploadFailedWarning: {
    id: 'global.uploadFailedWarning',
    defaultMessage:
      'Uploading the data to our servers takes longer than usual. Proceed according to these instructions. Close the page, return to the exam overview and click "Continue". The application will alert you to the possibility of data loss. You can ignore this warning. If you have any questions, reach out to our support team at scio@scio.cz.',
  },
  continue: {
    id: 'global.continue',
    defaultMessage: 'Continue',
  },
  stopRecording: {
    id: 'global.stopRecording',
    defaultMessage: 'Stop recording',
  },
  exam: {
    id: 'global.exam',
    defaultMessage: 'Go to Test',
  },
  haveReadAndAgreeWGDPR: {
    id: 'global.haveReadAndAgreeWGDPR',
    defaultMessage: 'I have read and I do agree with all the terms above.',
  },
  haveReadAndAgreeWExamRules: {
    id: 'global.haveReadAndAgreeWExamRules',
    defaultMessage: 'I have read and I do agree with all the terms above.',
  },
  startRecording: {
    id: 'global.startRecording',
    defaultMessage: 'Start recording',
  },
  recordAgain: {
    id: 'global.recordAgain',
    defaultMessage: 'Record again',
  },
  downloadApp: {
    id: 'global.downloadApp',
    defaultMessage: 'Download application',
  },
  startProctoringDesktop: {
    id: 'global.startProctoringDesktop',
    defaultMessage: 'Start App',
  },
  downloadProctoringDesktop: {
    id: 'global.downloadProctoringDesktop',
    defaultMessage: 'Download Proctoring Desktop',
  },
  startApplication: {
    id: 'global.startApplication',
    defaultMessage: 'Start application',
  },
  examWillBeRecorded: {
    id: 'global.examWillBeRecorded',
    defaultMessage: 'From this moment, the exam will be recorded.',
  },
  takeShot: {
    id: 'global.takeShot',
    defaultMessage: 'Take a picture',
  },
  takeShotAgain: {
    id: 'global.takeShotAgain',
    defaultMessage: 'Take a picture again',
  },
  mirrorTurn: {
    id: 'global.mirrorTurn',
    defaultMessage: 'Mirror flip',
  },
  warning: {
    id: 'global.warning',
    defaultMessage: 'Warning',
  },
  back: {
    id: 'global.back',
    defaultMessage: 'Back',
  },
  technicalChecks: {
    id: 'global.technicalChecks',
    defaultMessage: 'Settings',
  },
  checkYourConditions: {
    id: 'global.checkYourConditions',
    defaultMessage:
      'Check the video, audio, storage capacity and connection speed',
  },
  makeSureCameraIsConnected: {
    id: 'global.makeSureCameraIsConnected',
    defaultMessage:
      'Make sure the webcam is properly connected to the computer',
  },
  allowCameraInOs: {
    id: 'global.allowCameraInOs',
    defaultMessage:
      'Enable the webcam in the operating system and reload the page.',
  },
  thenSelectCam: {
    id: 'global.thenSelectCam',
    defaultMessage: 'Select the preferred webcam from the menu.',
  },
  allowMicrophonePrompt: {
    id: 'global.allowMicrophonePrompt',
    defaultMessage: 'Allow access to a microphone',
  },
  allowMicrophonePromptDesc: {
    id: 'global.allowMicrophonePromptDesc',
    defaultMessage:
      'Enable the microphone in the web browser settings and select the one you want to use from the menu. Say something and check that you can be heard.',
  },
  noCamerasFound: {
    id: 'global.noCamerasFound',
    defaultMessage: 'No webcam found.',
  },
  noMicFound: {
    id: 'global.noMicFound',
    defaultMessage: 'No microphone found.',
  },
  connectAndRestart: {
    id: 'global.connectAndRestart',
    defaultMessage: 'Connect the device and reload the page.',
  },
  microphoneDisallowedPrompt: {
    id: 'global.microphoneDisallowedPrompt',
    defaultMessage: 'Enable the microphone in the web browser.',
  },
  selectMicrophoneLabel: {
    id: 'global.selectMicrophoneLabel',
    defaultMessage: 'Microphone',
  },
  networkSpeedMeasuring: {
    id: 'global.networkSpeedMeasuring',
    defaultMessage: 'Connection speed',
  },
  measureSpeed: {
    id: 'global.measureSpeed',
    defaultMessage: 'Measure the speed',
  },
  networkSpeedMeasuringResultSufficient: {
    id: 'global.networkSpeedMeasuringResultSufficient',
    defaultMessage: 'The internet speed is sufficient, you can continue.',
  },
  giveApprovals: {
    id: 'global.giveApprovals',
    defaultMessage: 'Approvals',
  },
  shareScreen: {
    id: 'global.shareScreen',
    defaultMessage: 'Screen sharing',
  },
  shareScreenHeading: {
    id: 'global.shareScreenHeading',
    defaultMessage: 'Share screen settings',
  },
  shareScreenPrompt: {
    id: 'global.shareScreenPrompt',
    defaultMessage: 'How to share the screen',
  },
  shareScreenPromptDesc: {
    id: 'global.shareScreenPromptDesc',
    defaultMessage:
      'After clicking the Continue button, a window will automatically pop up. First click on "Entire Screen", then click on the screen thumbnail and finally press the "Share" button. The procedure is shown in the image below.',
  },
  authentication: {
    id: 'global.authentication',
    defaultMessage: 'Authentication',
  },
  anonymizeId: {
    id: 'global.anonymizeId',
    defaultMessage: 'Hide optional data',
  },
  cancelAnonymization: {
    id: 'global.cancelAnonymization',
    defaultMessage: 'Cancel blurring',
  },
  procAppReady: {
    id: 'global.procAppReady',
    defaultMessage: 'Proctoring Desktop is ready, you can continue.',
  },
  zoomIn: {
    id: 'global.zoomIn',
    defaultMessage: 'Zoom in',
  },
  recordRoom: {
    id: 'global.recordRoom',
    defaultMessage: 'Record the room',
  },
  zoomInId: {
    id: 'global.zoomInId',
    defaultMessage: 'Zoom in ID',
  },
  face: {
    id: 'global.face',
    defaultMessage: 'Face',
  },
  onFrontPage: {
    id: 'global.onFrontPage',
    defaultMessage: 'Frontpage',
  },
  continueIfDataReadable: {
    id: 'global.continueIfDataReadable',
    defaultMessage:
      'Make sure that the data on the ID is sharp and legible. If you wish to save only the necessary data on the ID, you can select and hide the unnecessary data by pressing the "Select data" button. If you wish to save an image of the entire document, press "Continue".',
  },
  continueIfPhotoOk: {
    id: 'global.continueIfPhotoOk',
    defaultMessage: 'If the photo is sharp, press the „Continue“ button.',
  },
  tryAgainSomethingsWrong: {
    id: 'global.tryAgainSomethingsWrong',
    defaultMessage: 'Try again.',
  },
  checkIfRecordingOk: {
    id: 'global.checkIfRecordingOk',
    defaultMessage: 'Check the quality of the recording',
  },
  continueIfRecordingOk: {
    id: 'global.continueIfRecordingOk',
    defaultMessage:
      'If the recording looks as required, press the „Continue“ button',
  },
  examIfRecordingOk: {
    id: 'global.examIfRecordingOk',
    defaultMessage:
      'If the recording looks as required, press the „Go to Test“ button',
  },
  howPhotoshootId: {
    id: 'global.howPhotoshootId',
    defaultMessage: 'How to take a picture of an ID?',
  },
  howPhotoshootFace: {
    id: 'global.howPhotoshootFace',
    defaultMessage: 'How to take a picture of your face?',
  },
  howPhotoshootFaceDesc: {
    id: 'global.howPhotoshootFaceDesc',
    defaultMessage:
      'Look at the webcam and take a photo of your face for comparison with your identification document.',
  },
  howRecordRoom: {
    id: 'global.howRecordRoom',
    defaultMessage: 'How to scan a room correctly?',
  },
  howRecordRoomDesc: {
    id: 'global.howRecordRoomDesc',
    defaultMessage:
      'Before you start scanning the room, make sure to prepare all the allowed materials for the exam, as well as everything you’ll need for scanning the room. Specifically, remember to have a small mirror or a regular mirror ready to show the monitor and keyboard. Be sure to scan everything slowly and thoroughly. The application will guide you through each step.',
  },
  runProcApp: {
    id: 'global.runProcApp',
    defaultMessage: 'Start Proctoring Desktop.',
  },
  everythingsReady: {
    id: 'global.everythingsReady',
    defaultMessage: 'Everything is ready',
  },
  somethingWentWrong: {
    id: 'global.somethingWentWrong',
    defaultMessage: 'Disallowed applications check',
  },
  closeDisallowedApps: {
    id: 'global.closeDisallowedApps',
    defaultMessage: 'Close following applications in order to continue:',
  },
  roomState: {
    id: 'global.roomState',
    defaultMessage: 'Recording in progress',
  },
  noInternet: {
    id: 'global.noInternet',
    defaultMessage:
      'No internet connection. You may continue the exam without internet connection.',
  },
  checkInternet: {
    id: 'global.checkInternet',
    defaultMessage: 'Check the internet connection.',
  },
  runApp: {
    id: 'global.runApp',
    defaultMessage: 'Run application',
  },
  anonymizeIdDesc: {
    id: 'global.anonymizeIdDesc',
    defaultMessage:
      'Use the mouse cursor to select the data on the document that you wish to blur.',
  },
  anonymizeIdDescItems: {
    id: 'global.anonymizeIdDescItems',
    defaultMessage:
      'Make sure following data remain readable: first name, last name, date of birth, photograph.',
  },
  playbackDisplay: {
    id: 'global.playbackDisplay',
    defaultMessage: 'Show video playback',
  },
  playbackHide: {
    id: 'global.playbackHide',
    defaultMessage: 'Hide video playback',
  },
  expand: {
    id: 'global.expand',
    defaultMessage: 'Expand',
  },
  shrink: {
    id: 'global.shrink',
    defaultMessage: 'Shrink',
  },
  camera: {
    id: 'global.camera',
    defaultMessage: 'Webcam',
  },
  unknownCam: {
    id: 'global.unknownCam',
    defaultMessage: 'Webcam',
  },
  microphone: {
    id: 'global.microphone',
    defaultMessage: 'Microphone',
  },
  unknownMic: {
    id: 'global.unknownMic',
    defaultMessage: 'Unknown microphone',
  },
  connectionSpeed: {
    id: 'global.connectionSpeed',
    defaultMessage: 'Connection speed',
  },
  dontLeaveIfNotIntented: {
    id: 'global.dontLeaveIfNotIntented',
    defaultMessage: 'Page is loading.',
  },
  storageCapacity: {
    id: 'global.storageCapacity',
    defaultMessage: 'Storage capacity check',
  },
  measureCapacity: {
    id: 'global.measureCapacity',
    defaultMessage: 'Measure capacity',
  },
  storageAmountSufficient: {
    id: 'global.storageAmountSufficient',
    defaultMessage: 'Web browser storage capacity is sufficient.',
  },
  storageAmountLow: {
    id: 'global.storageAmountLow',
    defaultMessage: `Web browser storage capacity is not sufficient.`,
  },
  storageAmountLowDesc: {
    id: 'global.storageAmountLowDesc',
    defaultMessage: `It is necessary to free at least 1GB of capacity in the web browser storage to start the exam, otherwise the application will not work properly. Make sure you are not in incognito mode or guest profile.`,
  },
  connectionSpeedLow: {
    id: 'global.connectionSpeedLow',
    defaultMessage: `Internet connection is slow`,
  },
  connectionSpeedLowDesc: {
    id: 'global.connectionSpeedLowDesc',
    defaultMessage:
      'It is not possible to start the exam due to a slow internet connection. A minimum of 8 Mbps download and 1 Mbps upload speed is required to start the exam.',
  },
  storageAmountLowerWarning: {
    id: 'global.storageAmountLowerWarning',
    defaultMessage:
      'The available storage in the web browser has dropped below 300 MB. Check the internet connection.',
  },
  preparation: {
    id: 'global.preparation',
    defaultMessage: 'Preparation',
  },
  allowCamAccess: {
    id: 'global.allowCamAccess',
    defaultMessage: 'Allow access to the webcam',
  },
  saySomethingToContinue: {
    id: 'global.saySomethingToContinue',
    defaultMessage: 'You must say something into the microphone to continue',
  },
  selectMicrophone: {
    id: 'global.selectMicrophone',
    defaultMessage: 'Select the microphone',
  },
  storageCheckup: {
    id: 'global.storageCheckup',
    defaultMessage: 'Available storage check',
  },
  storageCheckupDesc: {
    id: 'global.storageCheckupDesc',
    defaultMessage: 'Check the storage available in the web browser.',
  },
  check: {
    id: 'global.check',
    defaultMessage: 'Check',
  },
  checkAgain: {
    id: 'global.checkAgain',
    defaultMessage: 'Check again',
  },
  checkSuccess: {
    id: 'global.checkSuccess',
    defaultMessage: 'The check was successful',
  },
  youCanContinueToNextStep: {
    id: 'global.youCanContinueToNextStep',
    defaultMessage: 'You can continue to the next check',
  },
  connectionCheckup: {
    id: 'global.connectionCheckup',
    defaultMessage: 'Internet connection check',
  },
  checkConnectionDesc: {
    id: 'global.checkConnectionDesc',
    defaultMessage: 'Check the internet connection speed.',
  },
  welcomeInExam: {
    id: 'global.welcomeInExam',
    defaultMessage: 'Welcome to the exam',
  },
  testIsWatchedBePrepeared: {
    id: 'global.testIsWatchedBePrepeared',
    defaultMessage:
      'This exam is monitored by remote proctoring. Make sure you have the necessary things ready.',
  },
  youGonnaNeedForExam: {
    id: 'global.youGonnaNeedForExam',
    defaultMessage: 'For the exam, you will need',
  },
  idCard: {
    id: 'global.idCard',
    defaultMessage: 'Identification document',
  },
  webcam: {
    id: 'global.webcam',
    defaultMessage: 'Webcam',
  },
  cleanTable: {
    id: 'global.cleanTable',
    defaultMessage: 'Cleared worktop',
  },
  oneGBFreeSpaceonDisk: {
    id: 'global.oneGBFreeSpaceonDisk',
    defaultMessage: 'At least 1 GB of available storage',
  },
  deviceCheckup: {
    id: 'global.deviceCheckup',
    defaultMessage: 'Device check',
  },
  idCheckShowAllDetails: {
    id: 'global.idCheckShowAllDetails',
    defaultMessage: 'All important details must be visible',
  },
  idCheckAllMustBeClear: {
    id: 'global.idCheckAllMustBeClear',
    defaultMessage: 'All important details must be sharp',
  },
  idCheckBackWithHand: {
    id: 'global.idCheckBackWithHand',
    defaultMessage: 'To achieve better image focus, you can back the document',
  },
  idCheckShouldLookLike: {
    id: 'global.idCheckShouldLookLike',
    defaultMessage: 'This is what it should look like',
  },
  identityVerification: {
    id: 'global.identityVerification',
    defaultMessage: 'Identity verification',
  },
  textContinueDownward: {
    id: 'global.textContinueDownward',
    defaultMessage: 'Text continues downward',
  },
  support: {
    id: 'global.support',
    defaultMessage: 'Support',
  },
  roomCheck: {
    id: 'global.roomCheck',
    defaultMessage: 'Room scan',
  },
  playVideo: {
    id: 'global.playVideo',
    defaultMessage: 'Play video',
  },
  replayAgain: {
    id: 'global.replayAgain',
    defaultMessage: 'Replay video',
  },
  playbackReadyWarning: {
    id: 'global.playbackReadyWarning',
    defaultMessage:
      'Play the video again and check if the video looks as required. If you are satisfied with the video, press "Continue", which will take you to the next step.',
  },
  restartRoomCheck: {
    id: 'global.restartRoomCheck',
    defaultMessage: 'Restart room scan',
  },
  personVerification: {
    id: 'global.personVerification',
    defaultMessage: 'Person verification',
  },
  idVerification: {
    id: 'global.idVerification',
    defaultMessage: 'ID verification',
  },
  introIdDesc: {
    id: 'global.introIdDesc',
    defaultMessage:
      'Take a photo of your identification document by holding it up to the webcam. The card should fill the entire area of the video. Backing the card can help with webcam focus.',
  },
  introFaceDesc: {
    id: 'global.introFaceDesc',
    defaultMessage:
      'Look at the webcam so that your face can be scanned for comparison with your identification document.',
  },
  introRoomDesc: {
    id: 'global.introRoomDesc',
    defaultMessage:
      'Scan the room in which the exam will take place. Scan the room slowly and thoroughly. The app will guide you through the individual steps.',
  },
  everythingIsSet: {
    id: 'global.everythingIsSet',
    defaultMessage: 'Everything is set',
  },
  initialChecksCompleted: {
    id: 'global.initialChecksCompleted',
    defaultMessage:
      'Initial checks have been successfully completed. Do not leave your workplace. You can start the test.',
  },
  faceCheckShowWholeFace: {
    id: 'global.faceCheckShowWholeFace',
    defaultMessage: 'The whole face must be visible',
  },
  faceCheckAllMustBeClear: {
    id: 'global.faceCheckAllMustBeClear',
    defaultMessage: 'The whole face must be clear',
  },
  faceCheckMoveFromLight: {
    id: 'global.faceCheckMoveFromLight',
    defaultMessage: 'The whole face must be unreflected',
  },
  faceCheckShouldLookLike: {
    id: 'global.faceCheckShouldLookLike',
    defaultMessage: 'This is what it should look like',
  },
  resetRoomCheck: {
    id: 'global.resetRoomCheck',
    defaultMessage: 'Reset room scan',
  },
  resetRoomCheckConfim: {
    id: 'global.resetRoomCheckConfim',
    defaultMessage: 'Are you sure you want to reset all room scan steps?',
  },
  reset: {
    id: 'global.reset',
    defaultMessage: 'Reset',
  },
  goBack: {
    id: 'global.goBack',
    defaultMessage: 'Back',
  },
  selectCam: {
    id: 'global.selectCam',
    defaultMessage: 'Select webcam',
  },
  checkStorage: {
    id: 'global.checkStorage',
    defaultMessage: 'Check storage',
  },
  checkConnection: {
    id: 'global.checkConnection',
    defaultMessage: 'Check connection',
  },
  timerDisplayPassed: {
    id: 'global.timerDisplayPassed',
    defaultMessage:
      'The test must be started within {limit} minutes. Otherwise the test result may be blocked. If you are experiencing technical problems, reach out to support.',
  },
  timerWarningPassed: {
    id: 'global.timerWarningPassed',
    defaultMessage:
      'The test must be started within {limit} minutes. Otherwise the test result may be blocked. If you are experiencing technical problems, reach out to support.',
  },
  timerLimitPassed: {
    id: 'global.timerLimitPassed',
    defaultMessage:
      'The time for initial checks passed. The exam result may be blocked.',
  },
  timeLeft: {
    id: 'global.timeLeft',
    defaultMessage: 'Remaining time for initial checks',
  },
  proctoringNotInstalled: {
    id: 'global.proctoringNotInstalled',
    defaultMessage: 'Proctoring Desktop is not installed',
  },
  proctoringInstalled: {
    id: 'global.proctoringInstalled',
    defaultMessage: 'Proctoring Desktop is already installed',
  },
  clickOnThisButton: {
    id: 'global.clickOnThisButton',
    defaultMessage: 'Click on this button',
  },
  openInstallationFile: {
    id: 'global.openInstallationFile',
    defaultMessage: 'Open the installation file and follow the instructions.',
  },
  clickButtonWhenFinished: {
    id: 'global.clickButtonWhenFinished',
    defaultMessage:
      'When the installation is complete, click this button. Click only once!',
  },
  makeSureAppStarted: {
    id: 'global.makeSureAppStarted',
    defaultMessage: 'Make sure the Proctoring Desktop application has started.',
  },
  waitSuccessfulResult: {
    id: 'global.waitSuccessfulResult',
    defaultMessage: 'Wait for the successful result of the check.',
  },
  returnAndClickContinue: {
    id: 'global.returnAndClickContinue',
    defaultMessage: 'Return to this page and click "Continue".',
  },
});
