export const KEYBOARD = {
  LAYOUT: {
    DEFAULT: [
      '\u0060 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      'q w e r t y u i o p [ ] \\',
      "a s d f g h j k l ; ' {enter}",
      'z x c v b n m . - / {shift}',
      '{lock} {space}',
    ],
    SHIFT: [
      '~ ! @ # $ % ^ & * ) ( _ + {bksp}',
      'Q W E R T Y U I O P { } |',
      'A S D F G H J K L : " {enter}',
      'Z X C V B N M < > ? {shift}',
      '{lock} {space}',
    ],
  },
  LAYOUT_CANDIDATES: {
    LOWERCASE: {
      A: 'à á â ä æ',
      C: 'č ç',
      D: 'ď',
      E: 'è é ê ë ě',
      I: 'í î ï',
      N: 'ň ñ',
      O: 'ó ô ö œ',
      R: 'ř',
      S: 'š ß',
      T: 'ť',
      U: 'ù ú û ü ů',
      Y: 'ý',
      Z: 'ž',
    },
    UPPERCASE: {
      A: 'À Á Â Ä Æ',
      C: 'Č Ç',
      D: 'Ď',
      E: 'È É Ê Ë Ě',
      I: 'Í Î Ï',
      N: 'Ň Ñ',
      O: 'Ó Ô Ö Œ',
      R: 'Ř',
      S: 'Š ẞ',
      T: 'Ť',
      U: 'Ù Ú Û Ü Ů',
      Y: 'Ý',
      Z: 'Ž',
    },
  },
  DISPLAY: {
    '{bksp}': '← Backspace',
    '{enter}': '↵ Enter',
    '{shift}': 'Shift',
    '{lock}': 'Caps Lock',
  },
};
