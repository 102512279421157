import React, { Component } from 'react';
import { ResourceManager } from '../resources/ResourceManager';
import finishInProgress from '../utils/finishInProgress';
import log from '../utils/logging/DefaultLogger';
import {
  serializeAnswersAsync,
  saveAnswersAsync,
  serializeDisplayItemLogAsync,
  saveDisplayItemLogAsync,
  cleanUpAnswersAsync,
  cleanUpDisplayItemLogAsync,
  cleanTokens,
  redirectToProfile,
} from '../utils/finishHelpers';
import FinishForm from './FinishForm';
import { ConfirmAlert } from '../modals/ConfirmAlertModal';
import beforeUnloadHelper from '../utils/BeforeUnloadHelper';

export default class ResilienceFinish extends Component {
  constructor() {
    super();
    this.state = {
      noAnswersFoundUnconfirmed: true,
      error: false,
      errorMessage: '',
    };
  }

  componentDidMount() {
    this.finishTest();
  }

  componentDidUpdate() {
    this.finishTest();
  }

  async finishTest() {
    const { error, noAnswersFoundUnconfirmed } = this.state;
    if (error) return;

    const { db, tokens } = this.props;

    try {
      const allAnswers = await this.getAnswers(tokens, db);
      const answersNotFound = allAnswers.every(
        (x) => x.answersNotFound === true
      );

      if (answersNotFound === true && noAnswersFoundUnconfirmed === true) {
        // resilience mode and no answers found => error
        return this.resilienceError(ResourceManager('errResilienceNoItems'));
      }

      log.info('Finishing test(s) - in resilience mode', tokens[0].tokenObject);

      for (let i = 0; i < tokens.length; i++) {
        const tokenObject = tokens[i].tokenObject;
        const token = tokens[i].token;
        const serialzedAnswers = allAnswers[i].serializedAnswers;

        await this.doSaveTestAndCleanup(
          serialzedAnswers,
          token,
          tokenObject,
          db
        );
      }

      cleanTokens();
      redirectToProfile(tokens[0].tokenObject);
    } catch (error) {
      this.handleError(error.statusText || error.message);
    }
  }

  getAnswers = async (tokens, db) => {
    const allAnswers = new Array(tokens.length);
    for (let i = 0; i < tokens.length; i++) {
      const tokenObject = tokens[i].tokenObject;
      allAnswers[i] = await serializeAnswersAsync(tokenObject, db);
    }
    return allAnswers;
  };

  doSaveTestAndCleanup = async (serializedAnswers, token, tokenObject, db) => {
    await saveAnswersAsync(serializedAnswers, 'resilience', tokenObject, token);

    const serializedDisplayItemLog = await serializeDisplayItemLogAsync(
      tokenObject,
      db
    );

    await saveDisplayItemLogAsync(serializedDisplayItemLog, tokenObject, token);

    await cleanUpAnswersAsync(tokenObject, db);

    await cleanUpDisplayItemLogAsync(tokenObject, db);

    finishInProgress.cleanup(tokenObject.AnswersId);
  };

  resilienceError = (message) => {
    beforeUnloadHelper.remove();
    ConfirmAlert({
      title: ResourceManager('resilienceModeTitleError'),
      message,
      buttons: [
        {
          label: ResourceManager('resilienceErrorButtonBack'),
          onClick: () => {
            window.location.href = process.env.REACT_APP_PROFILE_URL;
          },
          className: 'btn btn-secondary',
        },
        {
          label: ResourceManager('resilienceErrorButtonFinish'),
          onClick: () => {
            this.setState({ noAnswersFoundUnconfirmed: false });
          },
          className: 'btn btn-primary',
        },
      ],
    });
  };

  handleError = (message) => {
    const errorMessage = `${ResourceManager(
      'errUnableToFinishTest'
    )} ${message}`;
    log.debug(errorMessage);
    this.setState({ error: true, errorMessage });
  };

  retry = () => {
    this.setState({ error: false, errorMessage: '' });
  };

  render() {
    const { tokens } = this.props;
    const token = tokens[0].token;
    const tokenObject = tokens[0].tokenObject;

    const { error, errorMessage } = this.state;

    return (
      <FinishForm
        token={token}
        tokenObject={tokenObject}
        error={error}
        errorMessage={errorMessage}
        retryFunc={this.retry}
      />
    );
  }
}
