import React, { Component } from 'react';

export default class RawHtml extends Component {
  render() {
    return (
      <div
        className={`text-item-wrapper ${this.props.className || ''}`}
        dangerouslySetInnerHTML={{ __html: this.props.content }}
      />
    );
  }
}
