import React, { useState } from 'react';
import RawHtml from '../components/RawHtml';
import base64Image from '../utils/images';
import { ResourceManager } from '../resources/ResourceManager';

const SupportMaterialsTab = (props) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className="supportMaterials-tab">
      <div
        className={`supportMaterials-header ${isOpened ? 'opened' : 'closed'}`}
        onClick={() => setIsOpened(!isOpened)}
        aria-label={ResourceManager(
          isOpened ? 'hideSupportMaterials' : 'showSupportMaterials'
        )}
      >
        <img
          src={base64Image('play')}
          alt=""
          className="supportMaterials-arrow"
        />
        {props.name}
      </div>
      {isOpened && (
        <div className="supportMaterials-content">
          <RawHtml content={props.item.renderedOutput} />
        </div>
      )}
    </div>
  );
};

export default SupportMaterialsTab;
