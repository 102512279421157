import BaseOperator from "./BaseOperator";

export default class NegativeOperator extends BaseOperator {

    getOperator() {
        return 'neg';
    }

    getStringOrder() {
        return -1;
    }

    toFullString() {
        return this.getOperator() +" ";
    }
}