import { ResourceManager } from '../resources/ResourceManager';

const tokenParser = (token) => {
  try {
    // decode token
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const tokenJson = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join('')
    );

    return JSON.parse(tokenJson);
  } catch (e) {
    throw new Error(ResourceManager('errInvalidToken'));
  }
};

const flattenToken = (token) => {
  const flattened = {};

  const iterateProperties = (obj, prefix = '') => {
    if (!obj) return;
    const keys = Object.keys(obj);
    for (let i = 0; i < keys.length; i += 1) {
      const property = keys[i];
      if (Object.prototype.hasOwnProperty.call(obj, property)) {
        const key = prefix + property;
        /* eslint-disable no-continue */
        if (key === 'UserSettings') continue; // skip user sensitive data
        if (
          typeof obj[property] === 'object' &&
          !Array.isArray(obj[property])
        ) {
          iterateProperties(obj[property], `${key}.`); // Recursive call if the property is an object
        } else {
          flattened[key] = obj[property]; // Assign the value to the flattened object
        }
      }
    }
  };

  iterateProperties(token);

  return flattened;
};

const getProctoringConfiguration = (tokenObject) => {
  const proctoringConfig = {
    externalExamId: tokenObject.ExamSettings.ExternalExamId,
    definitionId: tokenObject.ExamSettings.ProctoringConfigurationId,
    customerId: tokenObject.UserSettings.ExternalCustomerId,
    lang: tokenObject.UserSettings.Language.toLowerCase(),
    firstName: tokenObject.UserSettings.FirstName,
    lastName: tokenObject.UserSettings.LastName,
    birthDate: tokenObject.UserSettings.BirthDate,
    stage: process.env.REACT_APP_PROCTORING_STAGE,
    proctoringBackendUrl:
      tokenObject.ProctoringBackendSettings.ProctoringBackendUrl,
    proctoringDesktopUrlWin:
      tokenObject.ProctoringBackendSettings.ProctoringDesktopUrlWin,
    proctoringDesktopUrlMac:
      tokenObject.ProctoringBackendSettings.ProctoringDesktopUrlMac,
  };
  return proctoringConfig;
};

export { tokenParser, flattenToken, getProctoringConfiguration };
