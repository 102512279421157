import { ResourceManager } from '../resources/ResourceManager';
import base64Image from '../utils/images';

const SupportModal = (props) => {
  const { show, toggleSupport } = props;
  const boxesData = [
    {
      icon: base64Image('mail'),
      title: 'supportCenterEmailTitle',
      subtitle: 'supportCenterEmailSubtitle',
      text: 'supportCenterEmail',
    },
    {
      icon: base64Image('phone'),
      title: 'supportCenterPhoneTitle',
      subtitle: 'supportCenterPhoneSubtitle',
      text: 'supportCenterPhone',
    },
  ];

  const ContactBox = (boxProps) => {
    return (
      <div className="contact-box">
        <img src={boxProps.icon} alt="" />
        <div className="box-title">{ResourceManager(boxProps.title)}</div>
        <div className="subtitle">{ResourceManager(boxProps.subtitle)}</div>
        <span className="text">{ResourceManager(boxProps.text)}</span>
      </div>
    );
  };

  if (show) {
    return (
      <div className="modal-container">
        <div className="support-modal">
          <div className="modal-sidebar-header">
            <div className="modal-title">
              {ResourceManager('supportCenter')}
            </div>
            <button
              onClick={toggleSupport}
              className="btn-close"
              aria-label={ResourceManager('cancel')}
            >
              <img src={base64Image('x-close-gray700')} alt="" />
            </button>
          </div>
          <div className="support-modal-content">
            <p>{ResourceManager('supportCenterText')}</p>
            {boxesData.map((data, index) => {
              return <ContactBox key={index} {...data} />;
            })}
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default SupportModal;
