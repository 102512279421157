import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import LogLevel from './LogLevel';
import { flattenToken } from '../tokenHelpers';

const convertLogLevel = (logLevel) => {
  switch (logLevel) {
    case LogLevel.Debug:
      return 'debug';
    case LogLevel.Info:
      return 'info';
    case LogLevel.Warn:
      return 'warning';
    case LogLevel.Error:
      return 'error';
    default:
      return 'info';
  }
};
class SentryLogger {
  constructor() {
    const version = `T:${window.appVersion}`;

    Sentry.init({
      dsn: 'https://b41f27a282e84487bdd181332fe7c691@o1118847.ingest.sentry.io/6153428',
      integrations: [new Integrations.BrowserTracing()],
      environment: process.env.REACT_APP_ENV,
      release: version,
    });

    Sentry.setTag('appVersion', window.appVersion);
  }

  log = (logLevel, message, params) => {
    if (logLevel < LogLevel.Warn) return;
    const tags = flattenToken(params);
    const sentryLevel = convertLogLevel(logLevel);
    Sentry.captureMessage(message, {
      level: sentryLevel,
      tags: {
        ...tags,
        timestamp: new Date().toISOString(),
      },
    });
  };
}

export default SentryLogger;
