import React, { Component } from 'react';
import { ResourceManager } from '../resources/ResourceManager';
import RawHtml from './RawHtml';

export default class InstructionHtml extends Component {
  render() {
    return (
      <div className="instructions-padding">
        <p className="instructions-title">
          {ResourceManager('instructionsTitle')}
        </p>
        <RawHtml
          className="testInstructions"
          content={this.props.renderedInstruction}
        />
      </div>
    );
  }
}
