import LogLevel from './LogLevel';
import ConsoleLogger from './ConsoleLogger';
import SentryLogger from './SentryLogger';
import MultipleLogger from './MultipleLogger';
import AppInsightsLogger from './AppInsightsLogger';

const initLogger = () => {
  const logLevel = LogLevel[process.env.REACT_APP_LOG_LEVEL];
  const loggers = [];
  if (process.env.REACT_APP_ENV !== 'Production') {
    loggers.push(new ConsoleLogger());
  }
  if (process.env.NODE_ENV !== 'productionANSEC') {
    loggers.push(new SentryLogger());
    loggers.push(new AppInsightsLogger());
  }
  return new MultipleLogger(logLevel, loggers);
};

const log = initLogger();

export default log;
