import RawHtml from './RawHtml';
import { ResourceManager } from '../resources/ResourceManager';
import TestHeader from './TestHeader';
import TimerInstruction from './TimerInstruction';
import TimerHelper from '../utils/TimerHelper';

const Welcome = ({
  testInstance,
  test,
  items,
  startTest,
  tokenObject,
  toggleHighlighterActive,
  isHighlighterActive,
}) => {
  const headerProps = {
    tokenObject,
    testInstance,
    startTime: false,
    test,
    items,
    pageTitle: ResourceManager('instructionsTitle'),
    pageIndex: -1,
    toggleHighlighterActive,
    isHighlighterActive,
  };

  const timer = new TimerHelper();

  const WelcomeTable = ({ text, number }) => {
    return (
      <div className="welcome-table">
        <div className="text">{ResourceManager(text)}</div>
        <div className="number">{number}</div>
      </div>
    );
  };

  return (
    <>
      <TestHeader headerProps={headerProps} isWelcomePage={true} />
      <div className="welcome-panel container">
        {testInstance && testInstance.instructionLimitSeconds > 0 && (
          <TimerInstruction
            timeLimit={testInstance.instructionLimitSeconds}
            remainingTime={
              testInstance.instructionStarted
                ? testInstance.instructionRemainingSeconds
                : testInstance.instructionLimitSeconds
            }
            startTest={startTest}
          />
        )}
        <h3 className="test-name">{test.name}</h3>
        <WelcomeTable
          text={'testTotalQuestions'}
          number={`${test.totalQuestions} ${ResourceManager('itemQuestions')}`}
        />
        {testInstance?.timeLimitSeconds > 0 && (
          <WelcomeTable
            text={'testTimeLimit'}
            number={timer.formatTime(testInstance.timeLimitSeconds)}
          />
        )}
        {test.instructionId && (
          <div className="welcome-instructions">
            <h4>{ResourceManager('instructionsTitle')}</h4>
            <RawHtml content={items[test.instructionId].renderedOutput} />
          </div>
        )}
      </div>
      <div id="buttons-row">
        <button type="button" onClick={startTest} className="btn btn-primary">
          {tokenObject.ExamSettings.TestOrder === 1
            ? ResourceManager('btnTestStart')
            : ResourceManager('btnPartStart')}
        </button>
      </div>
    </>
  );
};

export default Welcome;
