import React, { Component } from 'react';
import TestHeader from './TestHeader';
import NavigationForward from './NavigationForward';
import Page from './Page';
import Sidebar from './Sidebar';
import TestButtons from './TestButtons';
import { ResourceManager } from '../resources/ResourceManager';

export default class ForwardTestMode extends Component {
  getCurrentItemsString = () => {
    const indexes = this.getCurrentItemPosition().flat();
    const indexesLength = indexes.length;
    if (indexesLength > 0) {
      if (indexesLength > 1) {
        return `${indexes[0].toString()} - ${indexes[
          indexesLength - 1
        ].toString()}`;
      }
      return indexes[0].toString();
    }
    return undefined;
  };

  getCurrentItemPosition = () =>
    this.props.test.pages[this.props.currentPage].itemGroups.map(
      (itemGroup) => {
        const itemArray = [];
        itemGroup.items.forEach((item) => {
          if (item.contextType === 'Question') {
            return itemArray.push(item.index);
          }
          if (item.contextType === 'Instruction') {
            return itemArray.push(ResourceManager('implicitInstruction'));
          }
        });
        return itemArray;
      }
    );

  render() {
    const testModeSettings = {
      disableLeftButton: true,
      disableNavigation: true,
      disableFlag: true,
      disableLegend: true,
      enableItemsCounter: true,
    };
    const itemNumber = this.getCurrentItemsString();

    const currentQuestionsArray = this.getCurrentItemPosition().flat();
    const currentQuestionsArrayLength = currentQuestionsArray.length;
    const currentQuestion = Number(currentQuestionsArray[0]);
    const totalNumberOfQuestions = this.props.test.totalQuestions;
    let percentage = (currentQuestion / totalNumberOfQuestions) * 100;

    if (
      currentQuestion >
      totalNumberOfQuestions - currentQuestionsArrayLength
    ) {
      percentage = 100;
    }

    const showProgressBar =
      currentQuestionsArrayLength !== totalNumberOfQuestions;

    return (
      <>
        <TestHeader headerProps={this.props.headerProps} />
        <NavigationForward
          className="container"
          test={this.props.test}
          itemNumber={itemNumber}
          items={this.props.items}
          percentage={percentage}
          testModeSettings={testModeSettings}
          totalNumberOfQuestions={totalNumberOfQuestions}
          showProgressBar={showProgressBar}
        />
        <Page
          className="container"
          currentPage={this.props.currentPage}
          currentItem={this.props.currentItem}
          test={this.props.test}
          db={this.props.db}
          token={this.props.token}
          tokenObject={this.props.tokenObject}
          items={this.props.items}
          testModeSettings={testModeSettings}
          connectionId={this.props.connectionId}
        />
        <Sidebar sidebarProps={this.props.sidebarProps} />
        <TestButtons
          className="container"
          isFirstItem={this.props.currentPage === 0}
          isLastItem={
            this.props.currentPage === this.props.test.pages.length - 1
          }
          isFinalSession={this.props.tokenObject.ExamSettings.IsFinalSession}
          movePrevious={this.props.movePrevious}
          moveNext={this.props.moveNext}
          finish={this.props.finish}
          testModeSettings={testModeSettings}
          testAppHasScrollbar={this.props.testAppHasScrollbar}
          checkTestAppHasScrollbar={this.props.checkTestAppHasScrollbar}
          nextButtonInitiallyDisabled
        />
      </>
    );
  }
}
