import React, { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { cam, cube, disk, id } from '../../icons';
import { messages } from '../../intl';
import { Context } from '../../Proctoring';
import { isEmpty } from 'ramda';
import { NOTIF_KEY } from '../../consts';

const Welcome = ({ isActive }) => {
  const { approvalsTexts, examId, setNotification } = useContext(Context);

  useEffect(() => {
    if (isActive) {
      setNotification({
        [NOTIF_KEY.EXAM_PREPARING]: {
          intl: 'examPreparingDontRestartBrowser',
          variant: 'information',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  useEffect(() => {
    if (examId || !isEmpty(approvalsTexts)) {
      setTimeout(
        () => setNotification({ [NOTIF_KEY.EXAM_PREPARING]: null }),
        1500
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approvalsTexts, examId]);

  return (
    <div className="introCheck welcome">
      <h2 className="header2Semibold">
        <FormattedMessage {...messages.welcomeInExam} />
      </h2>
      <p className="textMdNormal">
        <FormattedMessage {...messages.testIsWatchedBePrepeared} />
        <br />
        <FormattedMessage {...messages.youGonnaNeedForExam} />:
      </p>
      <div className="displayFlex textMdMedium">
        <div>
          {id}
          <FormattedMessage {...messages.idCard} />
        </div>
        <div>
          {cam}
          <FormattedMessage {...messages.webcam} />
        </div>
        <div>
          {cube}
          <FormattedMessage {...messages.cleanTable} />
        </div>
        <div>
          {disk}
          <FormattedMessage {...messages.oneGBFreeSpaceonDisk} />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
