import log from '../utils/logging/DefaultLogger';
import beforeUnloadHelper from '../utils/BeforeUnloadHelper';

const serializeAnswersAsync = (tokenObject, db) => {
  return new Promise((resolve, reject) => {
    const answersId = tokenObject.AnswersId;
    const tran = db.transaction('Answer', 'readonly');
    const answerRequest = tran.objectStore('Answer').getAll();
    const answersPrefix = `${answersId}_`;

    answerRequest.onsuccess = (answers) => {
      const filteredAnswers = answers.target.result
        .filter((f) => f.ItemId.startsWith(answersPrefix))
        .map((m) => m.value);

      let answersNotFound = false;

      if (filteredAnswers.length === 0) {
        log.debug('Finish - No answers for serialization', tokenObject);
        answersNotFound = true;
      }

      const serializedAnswers = JSON.stringify(filteredAnswers);
      log.debug('Finish - Answers serialized', tokenObject);
      resolve({ serializedAnswers, answersNotFound });
    };

    answerRequest.onerror = (event) => {
      reject(event.target.error.message);
    };
  });
};

const saveAnswersAsync = async (
  serializedAnswers,
  eventType,
  tokenObject,
  token
) => {
  log.info('Finish - Saving answers', tokenObject);

  const response = await window.fetch(
    `${process.env.REACT_APP_TESTCORE_API_URL}/Api/TestInstance/Finish?finishModeEnum=${eventType}`,
    {
      method: 'POST',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(serializedAnswers),
    }
  );

  if (!response.ok) throw new Error('Cannot save answers');
  log.info('Finish - Answers saved', tokenObject);
};

const serializeDisplayItemLogAsync = (tokenObject, db) => {
  return new Promise((resolve, reject) => {
    const answersId = tokenObject.AnswersId;
    const tran = db.transaction('DisplayItemLog', 'readonly');
    const logRequest = tran.objectStore('DisplayItemLog').getAll();

    logRequest.onsuccess = (logs) => {
      const filteredLogs = logs.target.result.filter(
        (f) => f.answersId === answersId
      );

      const serializedItemDisplayLog = JSON.stringify(filteredLogs);
      log.debug('Finish - Display log serialized', tokenObject);
      resolve(serializedItemDisplayLog);
    };

    logRequest.onerror = (event) => {
      reject(event.target.error.message);
    };
  });
};

const saveDisplayItemLogAsync = async (
  serializedItemDisplayLog,
  tokenObject,
  token
) => {
  log.info('Finish - Saving display log', tokenObject);

  const response = await window.fetch(
    `${process.env.REACT_APP_TESTCORE_API_URL}/Api/TestInstance/SaveDisplayItemLog`,
    {
      method: 'POST',
      headers: {
        Authorization: token,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(serializedItemDisplayLog),
    }
  );

  if (!response.ok) throw new Error('Cannot save display log');
  log.info('Finish - Display log saved', tokenObject);
};

const cleanUpAnswersAsync = async (tokenObject, db) => {
  log.debug('Finish - CleanUp Answers');
  const answersId = tokenObject.AnswersId;
  cleanUpSessionStorage(answersId);
  cleanupLocalStorage(answersId);
  await cleanupAnswersFromIndexedDbAsync(answersId, db);
  log.debug('Finish - Cleanup Answers.....done');
};

const cleanupAnswersFromIndexedDbAsync = async (answersId, db) => {
  return new Promise((resolve, reject) => {
    const tran = db.transaction('Answer', 'readwrite');
    const objectStore = tran.objectStore('Answer');
    const openedCursor = objectStore.openCursor();
    openedCursor.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        if (cursor.key.startsWith(`${answersId}_`)) {
          log.debug(`Finish - Cleanup Answers.....deleting ${cursor.key}`);
          const request = cursor.delete();
          request.onsuccess = () => {
            log.debug('Finish - Cleanup Answers..........deleted');
          };
          request.onerror = (event) => {
            reject(event.target.error.message);
          };
        }
        cursor.continue();
      } else {
        resolve();
      }
    };
    openedCursor.onerror = (event) => {
      reject(event.target.error.message);
    };
  });
};

const cleanUpSessionStorage = (answersId) => {
  const keysToRemoveFromSessionStorage = [
    `${answersId}_totalSeconds`,
    `${answersId}_flaggedItems`,
  ];

  keysToRemoveFromSessionStorage.forEach((key) =>
    sessionStorage.removeItem(key)
  );
};

const cleanupLocalStorage = (answersId) => {
  const keysToRemoveFromLocalStorage = [
    `${answersId}_lastPage`,
    `${answersId}_lastItem`,
  ];

  keysToRemoveFromLocalStorage.forEach((key) => localStorage.removeItem(key));

  // Removing element while iterating is unsafe, so create an array to hold the keys that need to be removed.
  const itemKeysArray = getKeysOfDynamicLocalStorageValues(answersId);
  // remove items by keys
  for (let i = 0; i < itemKeysArray.length; i++) {
    localStorage.removeItem(itemKeysArray[i]);
  }
};

//creates array of keys created when application runs - audio/video items, notepad notes, highlighted items
const getKeysOfDynamicLocalStorageValues = (answersId) => {
  const itemKeysArray = [];
  for (let i = 0; i < localStorage.length; i++) {
    let key = '';
    try {
      key = atob(localStorage.key(i));
    } catch (err) {
      // do nothing, key is not base64 string
    }
    // check decoded key
    if (
      key !== '' &&
      (key.startsWith(`${answersId}_video_container`) ||
        key.startsWith(`${answersId}_audio_container`))
    ) {
      itemKeysArray.push(localStorage.key(i));
    }

    // notepad
    if (localStorage.key(i).startsWith(`${answersId}_notepad_page`)) {
      itemKeysArray.push(localStorage.key(i));
    }

    // highlighter
    if (localStorage.key(i).startsWith(`${answersId}_highlighter`)) {
      itemKeysArray.push(localStorage.key(i));
    }
  }
  return itemKeysArray;
};

const cleanUpDisplayItemLogAsync = (tokenObject, db) => {
  log.debug(`Finish - CleanUp DisplayItemLog`);
  const answersId = tokenObject.AnswersId;
  return new Promise((resolve, reject) => {
    const tran = db.transaction('DisplayItemLog', 'readwrite');
    const objectStore = tran.objectStore('DisplayItemLog');
    const openedCursor = objectStore.openCursor();
    openedCursor.onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        if (cursor.value.answersId === answersId) {
          log.debug(
            `Finish - CleanUp DisplayItemLog.....deleting ${cursor.key}`
          );
          const request = cursor.delete();
          request.onsuccess = () => {
            log.debug('Finish - CleanUp DisplayItemLog..........deleted');
          };
          request.onerror = (event) => {
            reject(event.target.error.message);
          };
        }
        cursor.continue();
      } else {
        log.debug('Finish - CleanUp DisplayItemLog.....done');
        resolve();
      }
    };
    openedCursor.onerror = (event) => {
      reject(event.target.error.message);
    };
  });
};

const cleanTokens = () => {
  sessionStorage.removeItem('token');
};

const FINISH_FORM_ID = 'idFinishForm';

const redirectToProfile = (tokenObject) => {
  beforeUnloadHelper.remove();
  log.info('Finish completed - redirecting back to profile app', tokenObject);

  if (tokenObject.ExamSettings.HttpGetOnlyFinish) {
    window.location.replace(tokenObject.ReturnUrl);
    return;
  }

  const form = document.getElementById(FINISH_FORM_ID);
  if (form) {
    form.submit();
  }
};

export {
  serializeAnswersAsync,
  saveAnswersAsync,
  serializeDisplayItemLogAsync,
  saveDisplayItemLogAsync,
  cleanUpAnswersAsync,
  cleanUpDisplayItemLogAsync,
  cleanTokens,
  FINISH_FORM_ID,
  redirectToProfile,
};
