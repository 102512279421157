import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect, Children, useCallback } from 'react';
import { messages } from '../intl';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { keys } from 'ramda';

const Notifications = ({ children }) => {
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const [isExam, setIsExam] = useState(false);
  const [notifications, setNotifications] = useState({});

  const setNotification = (newNotif) =>
    setNotifications((prev) => ({ ...prev, ...newNotif }));

  const action = useCallback(
    (key) => (
      <span
        onClick={() => setNotification({ [key]: null })}
        className="closeBtn"
      >
        <CloseIcon />
      </span>
    ),
    []
  );

  useEffect(() => {
    if (!isExam) {
      for (const key of keys(notifications)) {
        if (notifications[key]) {
          enqueueSnackbar(
            intl.formatMessage(
              messages[notifications[key].intl],
              notifications[key].entities || {}
            ),
            {
              variant: notifications[key].variant,
              persist: true,
              className: notifications[key].variant,
              key,
              action: action(key),
            }
          );
        } else {
          closeSnackbar(key);
        }
      }
    }
  }, [action, closeSnackbar, enqueueSnackbar, intl, isExam, notifications]);

  return Children.map(children, (child) =>
    React.cloneElement(child, {
      setNotification,
      notifications,
      setIsExam,
    })
  );
};

export default Notifications;
