import React, { Component } from 'react';
import NavigationItem from './NavigationItem';
import InstructionBox from './InstructionBox';

export default class Navigation extends Component {
  componentDidMount() {
    this.initNavigation();
  }

  componentDidUpdate() {
    this.initNavigation();
  }

  initNavigation = () => {
    window.ItemManager.UpdateNavigation(
      this.props.db,
      this.props.tokenObject.AnswersId
    );
  };

  render() {
    const { pages } = this.props.test;
    const { currentPage } = this.props;
    const { switchPage } = this.props;
    const { checkTestAppHasScrollbar } = this.props;
    const links = [];

    pages.forEach((element, pageIndex) => {
      element.itemGroups.forEach((group) => {
        group.items.forEach((item) => {
          if (item.index >= 0) {
            links.push(
              <NavigationItem
                key={item.itemId}
                itemId={item.itemId}
                pageIndex={pageIndex}
                isActivePage={pageIndex === currentPage}
                itemIndex={item.index}
                switchPage={switchPage}
                checkTestAppHasScrollbar={checkTestAppHasScrollbar}
              />
            );
          }
        });
      });
    });

    return (
      <div
        id="navigation-row"
        className={`container ${links.length > 75 ? 'mega' : ''}`}
      >
        <InstructionBox
          items={this.props.items}
          test={this.props.test}
          testModeSettings={this.props.testModeSettings}
        />
        <div id="item-links">{links.map((i) => i)}</div>
      </div>
    );
  }
}
