import * as Sentry from '@sentry/browser';
import './App.scss';
import { isEmpty, values } from 'ramda';
import React, {
  createContext,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { startRecordingChunks, startTakingSnapshots, startSocket } from './api';
import ExamWatcher from './components/ExamWatcher';
import Checks from './components/checks/Checks';
import {
  CAM_RECORDER_CONFIG,
  CHECK,
  CHUNK_LENGTH,
  CREATE_EXAM_RECONNECT_INTERVAL,
  EXAM,
  EXAM_WAS_FINISHED,
  EXAM_WAS_OPENED,
  LOCALE,
  MONITORING_APP_URL,
  SCREEN_RECORDER_CONFIG,
  SECTIONS,
  NOTIF_KEY,
  NO_INTERNET_NOTIF_DELAY,
} from './consts';
import { messages } from './intl';
import IDB from './utils/IDB';
import LSM from './utils/localStorageManager';
import { prepareCamStream, prepareScreenStream } from './utils/stream';
import useInterval from './utils/useInterval';
import Footer from './components/Footer';
import { checks as initChecks } from './utils/prepareChecks.js';
import debounce from './utils/debounce';
import ChecksTimer from './components/ChecksTimer.js';
import Loader from '../components/Loader.js';

export const Context = createContext();

let recorderCam = null;
let recorderScreen = null;

const getSection = (section) => {
  switch (section) {
    case SECTIONS.EXAM:
      return <ExamWatcher />;
    default:
      return (
        <>
          <ChecksTimer />
          <Checks />
        </>
      );
  }
};

let socketStarted = false;

// ({ event: 'start' | 'stop' })
export const touchRecorder = ({ event }) => {
  switch (event) {
    case 'start':
      if (recorderCam && recorderCam?.state !== 'recording')
        recorderCam?.start(CHUNK_LENGTH);
      if (recorderScreen && recorderScreen?.state !== 'recording')
        recorderScreen?.start(CHUNK_LENGTH);
      break;
    default:
      if (recorderCam?.state === 'recording') recorderCam?.stop();
      if (recorderScreen?.state === 'recording') recorderScreen?.stop();
      break;
  }
};

const debounced = debounce((callback) => callback(), 10000);

const Proctoring = (props) => {
  // Exam
  const {
    children,
    config = {},
    stopProctoring,
    setNotification,
    notifications,
    setIsExam,
  } = props;
  const pageHeader =
    children?.props?.children.filter(
      (prop) => prop.props.type === 'pageHeader'
    ) ?? null;
  const testApp =
    children?.props?.children.filter((prop) => prop.props.type === 'testApp') ??
    null;
  const { definitionId, externalExamId } = config;
  const [examId, setExamId] = useState(
    LSM.getExamProperty(externalExamId, EXAM.EXAM_ID)
  );
  const [socket, setSocket] = useState(null);

  // UI
  const [introChecksTimer, setIntroChecksTimer] = useState(null);
  const [allowContinue, setAllowContinue] = useState(true);
  const [section, setSection] = useState(null);
  const [activeCheckId, setActiveCheckId] = useState(0);
  const isExam = section === SECTIONS.EXAM;
  const isIntro = section === SECTIONS.INTRO;
  const monitorAppLink =
    MONITORING_APP_URL +
    examId +
    '/' +
    (config?.lang || 'cz') +
    '/' +
    (config?.stage || 'prod');
  const showWaitForServerMsg =
    !!LSM.getExamProperty(externalExamId, EXAM_WAS_OPENED) && !section;

  // Devices & stream
  const screen = useRef();
  const [isRec, setIsRec] = useState(false);
  const [webcamDeviceId, setWebcamDeviceId] = useState(
    LSM.getExamProperty(externalExamId, EXAM.WEBCAM_ID)
  );
  const [microphoneDeviceId, setMicrophoneDeviceId] = useState(
    LSM.getExamProperty(externalExamId, EXAM.MIC_ID)
  );
  const [camStream, setCamStream] = useState(null);
  const [screenStream, setScreenStream] = useState(null);

  // AI feedback
  const [aiFeedbackCam, setAiFeedbackCam] = useState(['', '']);
  const [aiFeedbackScreen, setAiFeedbackScreen] = useState(['', '']);
  const [aiFeedbackApp, setAiFeedbackApp] = useState(['', '']);

  // Checks
  // At least process analysis socket event from BE arrived
  // Timestamp of the last received processAnalysis event
  const [processAnalysisCheck, setProcessAnalysisCheck] = useState(Date.now());
  const [isProcessAnalysis, setIsProcessAnalysis] = useState(true);
  const [approvalsTexts, setApprovalsTexts] = useState({});
  const [checks, setChecks] = useState(initChecks);
  const [showDesktopNotification, setShowDesktopNotification] = useState(false);

  // Safety & cleanup
  const [showTestAppAfterCleanUp, setShowTestAppAfterCleanUp] = useState(false);
  const [showStillUploadingMessage, setShowStillUploadingMessage] =
    useState(false);
  const [
    showFailedUploadAfterExamFinishMessage,
    setShowFailedUploadAfterExamFinishMessage,
  ] = useState(false);
  const [queueUploadProgress, setQueueUploadProgress] = useState(0);

  // Test App
  const displayTestApp =
    (isExam && (!stopProctoring || showTestAppAfterCleanUp)) ||
    (!isExam && stopProctoring && showTestAppAfterCleanUp);

  // Add Sentry tags
  useEffect(() => {
    if (examId && externalExamId) {
      localStorage.setItem('externalExamId4Sentry', externalExamId);
      Sentry.setTag('externalExamId', externalExamId);
      Sentry.setTag('examId', examId);
    }
  }, [examId, externalExamId]);

  const startSocketMemoized = useCallback(() => {
    startSocket({
      config,
      definitionId,
      examId,
      externalExamId,
      setAiFeedbackApp,
      setAiFeedbackCam,
      setAiFeedbackScreen,
      setApprovalsTexts,
      setShowDesktopNotification,
      setChecks,
      setExamId,
      setIsRec,
      setShowStillUploadingMessage,
      setShowFailedUploadAfterExamFinishMessage,
      setProcessAnalysisCheck,
      setIsProcessAnalysis,
      setSection,
      setShowTestAppAfterCleanUp,
      setSocket,
      setIntroChecksTimer,
    });
  }, [config, externalExamId, definitionId, examId]);
  const intl = useIntl();

  // Notifications
  useEffect(() => {
    const _notifications = values(notifications)
      .filter(
        (notification) =>
          notification?.intl && notification?.variant === 'error'
      )
      .map((notification) => intl.formatMessage(messages[notification.intl]));

    if (!isEmpty(_notifications)) {
      debounced(() => {
        for (const notification of _notifications) {
          socket.emit('notificationReceived', {
            examId,
            externalExamId,
            notification,
          });
        }
      });
    }
  }, [examId, externalExamId, intl, notifications, socket]);

  useEffect(() => {
    setIsExam(isExam);
  }, [isExam, setIsExam]);

  // No internet
  useInterval(
    () => {
      if (window.navigator.onLine)
        LSM.setExamProperty(
          externalExamId,
          EXAM.LAST_CHUNK_RECEIVED_AT,
          Date.now()
        );

      const lastChunkReceivedAt = Number(
        LSM.getExamProperty(externalExamId, EXAM.LAST_CHUNK_RECEIVED_AT)
      );

      if (
        lastChunkReceivedAt &&
        lastChunkReceivedAt + NO_INTERNET_NOTIF_DELAY < Date.now()
      ) {
        setNotification({
          [NOTIF_KEY.NO_INTERNET]: {
            intl: isExam ? 'checkInternet' : 'noInternet',
            variant: 'error',
          },
        });
      } else {
        setNotification({ [NOTIF_KEY.NO_INTERNET]: null });
      }
    },
    3000,
    true
  );

  // Start socket
  useEffect(() => {
    if (definitionId && !socketStarted) {
      startSocketMemoized();
      socketStarted = true;
    }
  }, [definitionId, startSocketMemoized]);

  const isShareScreen = checks[activeCheckId]?.isShareScreen;

  // Start screen stream
  useEffect(() => {
    const hasExamFinished = !!LSM.getExamProperty(
      externalExamId,
      EXAM_WAS_FINISHED
    );

    if (isRec && !screenStream && !hasExamFinished && !isShareScreen) {
      const waitForUserApproval = async () =>
        await prepareScreenStream({ setNotification, setScreenStream });

      waitForUserApproval();
      recorderScreen = null;
    } else if (isRec && !recorderScreen && !hasExamFinished && screenStream) {
      if (recorderCam && recorderCam.state !== 'recording') {
        // Start cam stream recording
        startRecordingChunks({
          examId,
          recorder: recorderCam,
          event: EXAM.CAPTURED_CHUNK_CAM,
          externalExamId,
        });

        recorderCam.start(CHUNK_LENGTH);

        socket.emit('streamStarted', { examId });

        startTakingSnapshots({
          event: EXAM.CAPTURED_SNAP_CAM,
          examId,
          externalExamId,
          socket,
        });
      }

      // Start screen stream recording
      recorderScreen = new MediaRecorder(screenStream, SCREEN_RECORDER_CONFIG);
      setTimeout(() => {
        startRecordingChunks({
          examId,
          recorder: recorderScreen,
          event: EXAM.CAPTURED_CHUNK_SCREEN,
          externalExamId,
        });

        recorderScreen.start(CHUNK_LENGTH);

        startTakingSnapshots({
          event: EXAM.CAPTURED_SNAP_SCREEN,
          examId,
          externalExamId,
          socket,
        });
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenStream, isRec, isShareScreen]);

  // Start cam stream
  useEffect(() => {
    const hasExamFinished = !!LSM.getExamProperty(
      externalExamId,
      EXAM_WAS_FINISHED
    );

    if (isRec && !camStream && !hasExamFinished) {
      navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
        const videoDevices = mediaDevices.filter(
          ({ kind }) => kind === 'videoinput'
        );

        // don't continue if there is no camera
        if (isEmpty(videoDevices)) {
          setNotification({
            [NOTIF_KEY.CAM]: {
              intl: 'turnOnWebCamAndRestartBrowser',
              variant: 'error',
            },
          });
          return;
        }

        navigator.permissions
          .query({ name: 'camera' })
          .then((permissionStatus) => {
            // don't continue if the camera is not granted
            if (permissionStatus.state !== 'granted') {
              setNotification({
                [NOTIF_KEY.CAM]: {
                  intl: 'turnOnWebCamAndRestartBrowser',
                  variant: 'error',
                },
              });
              return;
            }
          });

        const waitForUserApproval = async () =>
          await prepareCamStream({
            setNotification,
            setCamStream,
            webcamDeviceId,
            microphoneDeviceId,
          });

        waitForUserApproval();
        recorderCam = null;
      });
    } else if (isRec && !hasExamFinished && !recorderCam) {
      recorderCam = new MediaRecorder(camStream, CAM_RECORDER_CONFIG);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camStream, isRec]);

  // If there was no response from BE ask to create exam again
  useInterval(
    () => startSocketMemoized(),
    CREATE_EXAM_RECONNECT_INTERVAL,
    !section
  );

  // Free disk space quota check
  useInterval(
    async () => {
      const quota = await navigator.storage.estimate();
      if (
        (activeCheckId > 0 || isExam) &&
        (quota.quota - quota.usage) / 1073741824 < 0.333
      ) {
        setNotification({
          [NOTIF_KEY.STORAGE]: {
            intl: 'storageAmountLowerWarning',
            variant: 'error',
          },
        });
      } else {
        setNotification({ [NOTIF_KEY.STORAGE]: null });
      }
    },
    10000,
    true
  );

  // Upload progress (after exam finished)
  useInterval(
    async () => setQueueUploadProgress(await IDB.getQueueStatus()),
    500,
    showStillUploadingMessage
  );

  // Assume that processAnalysis is not working if the last event arrived too long ago
  // Gets set to true when another processAnalysis socket event arrives
  useInterval(
    () => {
      setIsProcessAnalysis(false);
    },
    5000,
    Date.now() - processAnalysisCheck > 25_000
  );

  // Init screen playback - once only
  useEffect(() => {
    if (screenStream) {
      screen.current.srcObject = screenStream;
    }
  }, [screenStream]);

  const handleFinishExam = useCallback(() => {
    LSM.setExamProperty(externalExamId, EXAM_WAS_FINISHED, 'true');
    Sentry.setTag('currentUiSection', 'Finished');
    setIsRec(false);
    if (recorderCam?.state === 'recording') recorderCam?.stop();
    if (recorderScreen?.state === 'recording') recorderScreen?.stop();
    camStream?.stop();
    screenStream?.stop();
    setCamStream(null);
    setAiFeedbackCam(['', '']);
    setAiFeedbackScreen(['', '']);
    setAiFeedbackApp(['', '']);
    setShowStillUploadingMessage(true);
  }, [camStream, externalExamId, screenStream]);

  const showLoader = () => {
    if (showFailedUploadAfterExamFinishMessage) {
      return (
        <Loader
          noIcon
          message={intl.formatMessage(messages.uploadFailedWarning)}
          extra={<a href="mailto:scio@scio.cz">scio@scio.cz</a>}
        />
      );
    } else if (showStillUploadingMessage) {
      return (
        <Loader
          message={intl.formatMessage(messages.stillUploadingWarning)}
          extra={`${queueUploadProgress < 0 ? 0 : queueUploadProgress} %`}
        />
      );
    } else if (showWaitForServerMsg) {
      return (
        <Loader message={intl.formatMessage(messages.dontLeaveIfNotIntented)} />
      );
    }
  };

  // Stop proctoring - parent App call - initiates exam finish cleanup
  useEffect(() => {
    if (stopProctoring) handleFinishExam();
  }, [handleFinishExam, stopProctoring]);

  // Disable button "Next" everytime we enter another tab
  useEffect(() => {
    if (activeCheckId > 0) setAllowContinue(false);
  }, [activeCheckId]);

  return (
    <Context.Provider
      value={{
        activeCheckId,
        aiFeedbackApp,
        aiFeedbackCam,
        aiFeedbackScreen,
        allowContinue,
        approvalsTexts,
        camStream,
        checks,
        config,
        definitionId,
        examId,
        notifications,
        externalExamId,
        isProcessAnalysis,
        isRec,
        microphoneDeviceId,
        monitorAppLink,
        screenStream,
        section,
        setActiveCheckId,
        setAiFeedbackCam,
        setAllowContinue,
        setCamStream,
        setIsRec,
        setMicrophoneDeviceId,
        setProcessAnalysisCheck,
        setQueueUploadProgress,
        setSection,
        setWebcamDeviceId,
        socket,
        touchRecorder,
        webcamDeviceId,
        setNotification,
        locale: config.lang ?? LOCALE,
        showDesktopNotification,
        setScreenStream,
        isExam,
        introChecksTimer,
      }}
    >
      <div className={`${isExam ? 'showTestApp' : 'showTestBar'}`}>
        {showFailedUploadAfterExamFinishMessage ||
        showStillUploadingMessage ||
        showWaitForServerMsg
          ? showLoader()
          : null}

        {/* intro checks */}
        {isIntro && !stopProctoring && pageHeader}
        {!showWaitForServerMsg && !stopProctoring && getSection(section)}
        {!isExam && !stopProctoring && <Footer />}
        {/* Test App */}
        {displayTestApp && testApp}
      </div>

      {process.env.REACT_APP_ENV === 'Development' && (
        <section className="dev-buttons">
          {examId && checks && section !== SECTIONS.EXAM ? (
            <button
              onClick={() => {
                socket.emit('checkpointFinished', {
                  examId,
                  definitionId,
                  checkpointType: CHECK.ROOM,
                });
                setSection(SECTIONS.EXAM);
              }}
            >
              Jump to Exam
            </button>
          ) : (
            isRec && <button onClick={handleFinishExam}>Finish exam</button>
          )}
          <span>
            <br />
            examId: <strong>{examId}</strong> | definitionId:{' '}
            <strong>{definitionId}</strong>
          </span>
        </section>
      )}
      {screenStream && (
        <video
          autoPlay
          ref={screen}
          id={`exam-watcher-${EXAM.CAPTURED_CHUNK_SCREEN}`}
          muted
          className="screenPlayback"
        />
      )}
    </Context.Provider>
  );
};

export default Proctoring;
