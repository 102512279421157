import React, { Component } from 'react';
import Welcome from './Welcome';
import { handlerErrorTC } from './ErrorHandler';
import { ConfirmAlert } from '../modals/ConfirmAlertModal';
import { ResourceManager } from '../resources/ResourceManager';
import Test from './Test';
import log from '../utils/logging/DefaultLogger';

export default class InitializedTestApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showWelcomePage: !props.testInstance.testStarted,
      instructionTimerStarted: props.testInstance.instructionStarted,
      error: false,
      errorMessage: '',
      isHighlighterActive: false,
    };
  }

  componentDidMount() {
    const { instructionTimerStarted } = this.state;
    if (!instructionTimerStarted) {
      this.startInstruction();
    }
  }

  // ES6 syntax kvůli bindingu this
  startTest = () => {
    log.info(`Starting test.`, this.props.tokenObject);
    window
      .fetch(
        `${process.env.REACT_APP_TESTCORE_API_URL}/Api/TestInstance/Start`,
        {
          method: 'PUT',
          headers: {
            Authorization: this.props.token,
          },
        }
      )
      .then((response) => {
        if (response.ok) {
          log.info(`Test started.`, this.props.tokenObject);
          this.setState({ showWelcomePage: false });
        } else {
          throw response;
        }
      })
      .catch((error) => {
        handlerErrorTC(
          this,
          ResourceManager('errRetryAdvice'),
          error,
          false,
          null,
          this.props.tokenObject
        );
      });
  };

  startInstruction = () => {
    log.info(`Starting instructions.`, this.props.tokenObject);
    window
      .fetch(
        `${process.env.REACT_APP_TESTCORE_API_URL}/Api/TestInstance/StartInstruction`,
        {
          method: 'PUT',
          headers: {
            Authorization: this.props.token,
          },
        }
      )
      .then((response) => {
        if (response.ok) {
          log.info(`Instructions started.`, this.props.tokenObject);
          this.setState({ instructionTimerStarted: true });
        } else {
          throw response;
        }
      })
      .catch((error) => {
        handlerErrorTC(
          this,
          ResourceManager('errRetryAdvice'),
          error,
          false,
          null,
          this.props.tokenObject
        );
      });
  };

  toggleHighlighterActive = () => {
    const currentState = this.state.isHighlighterActive;
    this.setState({ isHighlighterActive: !currentState });
  };

  render() {
    const { error } = this.state;
    const { showWelcomePage } = this.state;

    if (error) {
      return ConfirmAlert({
        title: ResourceManager('errUnableToStartTest'),
        message: this.state.errorMessage,
        buttons: [
          {
            label: ResourceManager('btnTryAgain'),
            onClick: () => {
              this.setState({ error: false, errorMessage: '' });
              this.startTest();
            },
            className: 'btn btn-primary',
          },
        ],
      });
    }

    const items = {};
    this.props.test.items.forEach((i) => {
      items[i.itemId] = i;
    });

    let testAppClass = '';
    if (this.state.isHighlighterActive) {
      testAppClass = 'highlighter-active';
    }

    if (showWelcomePage) {
      return (
        <div id="TestApp" className={testAppClass}>
          <Welcome
            testInstance={this.props.testInstance}
            test={this.props.test}
            items={items}
            startTest={() => this.startTest()}
            tokenObject={this.props.tokenObject}
            toggleHighlighterActive={this.toggleHighlighterActive}
            isHighlighterActive={this.state.isHighlighterActive}
          />
        </div>
      );
    }

    return (
      <div id="TestApp" className={testAppClass}>
        <Test
          testInstance={this.props.testInstance}
          test={this.props.test}
          db={this.props.db}
          items={items}
          tokenObject={this.props.tokenObject}
          token={this.props.token}
          onFinish={this.props.onFinish}
          connectionId={this.props.connectionId}
          toggleHighlighterActive={this.toggleHighlighterActive}
          isHighlighterActive={this.state.isHighlighterActive}
        />
      </div>
    );
  }
}
