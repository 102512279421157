import { useState, useEffect, useRef } from 'react';
import SupportMaterialsTab from './SupportMaterialsTab';
import draggable from '../utils/draggable';
import modalOverlapping from '../utils/modalOverlapping';
import { ResourceManager } from '../resources/ResourceManager';
import base64Image from '../utils/images';

const SupportMaterialsModal = (props) => {
  const [modalWidth, setModalWidth] = useState(0);
  const modalRef = useRef(null);
  const { show, top, left } = props.modalState;

  useEffect(() => {
    if (show) {
      const width = modalRef.current.offsetWidth;
      setModalWidth(width);

      draggable(
        document.getElementById('idSupportMaterialsModal'),
        document.getElementById('idSupportMaterialsHeader')
      );

      modalOverlapping('idSupportMaterialsModal', '.sidebar-modal');
    }
  }, [show]);

  const supportMaterialsArray = props.supportMaterials;
  const { items } = props;
  const tabs = supportMaterialsArray.map((i) => (
    <SupportMaterialsTab key={i.name} name={i.name} item={items[i.itemId]} />
  ));

  if (show) {
    const modalLeftPosition = modalWidth > 0 ? left - (modalWidth + 16) : 0;

    return (
      <div
        ref={modalRef}
        className="sidebar-modal support-materials-modal"
        id="idSupportMaterialsModal"
        style={{ top, left: modalLeftPosition }}
      >
        <div id="idSupportMaterialsHeader" className="modal-sidebar-draggable">
          <div className="modal-title">
            {ResourceManager('downloadContentTitle')}
          </div>
          <button
            onClick={props.toggleSupportMaterials}
            className="btn-close"
            aria-label={ResourceManager('cancel')}
          >
            <img src={base64Image('x-close-gray700')} alt="" />
          </button>
        </div>
        <div
          className="sidebar-modal-content scrollable"
          id="idSupportMaterialsScrollable"
        >
          {tabs}
        </div>
      </div>
    );
  }

  return null;
};

export default SupportMaterialsModal;
