import { BlobServiceClient } from '@azure/storage-blob';
import { indexOf, isEmpty } from 'ramda';
import io from 'socket.io-client';
import {
  CAM_SNAPSHOT_RESOLUTION,
  CHECK,
  EXAM,
  EXAM_WAS_FINISHED,
  EXAM_WAS_OPENED,
  FAILED_REQUEST_INTERVALS,
  MAX_BLOB_SIZE,
  MAX_FAILED_REQUEST_INTERVAL,
  PROCTORING_API_URL,
  SCREEN_SNAPSHOT_RESOLUTION,
  SECTIONS,
  VIDEO_SEGMENT_LENGTH,
} from './consts';
import IDB from './utils/IDB';
import LSM from './utils/localStorageManager';
import logger from '../utils/logging/DefaultLogger';
import takeSnapshot from './utils/takeSnapshot';
import prepareChecks from './utils/prepareChecks';
import { touchRecorder } from './Proctoring';

let socket = null;
let containerClient = null;
let sendChunksInitialized = false;
const sentChunks = {
  [EXAM.CAPTURED_CHUNK_CAM]: [],
  [EXAM.CAPTURED_CHUNK_SCREEN]: [],
};

// Store last 8 snapshots in LS
const remeberSentSnap = (event, timestamp) => {
  localStorage.setItem(
    event,
    (timestamp + (localStorage.getItem(event) || '')).substr(0, 13 * 8)
  );
};

// Check if snap was sent already
const isSnapSent = (event, timestamp) =>
  (localStorage.getItem(event) || '').includes(timestamp);

const getContainerClient = ({ account, sas, container }) => {
  const blobServiceClient = new BlobServiceClient(
    `https://${account}.blob.core.windows.net?${sas}`
  );
  return blobServiceClient.getContainerClient(container);
};

export const uploadImageToBlobStorage = async ({ blobName, snapshot }) => {
  let blobPng = await fetch(snapshot);
  blobPng = await blobPng.blob();

  const blockBlobClient = containerClient.getBlockBlobClient(blobName);
  await blockBlobClient.uploadData(blobPng, {
    blobHTTPHeaders: { blobContentType: 'image/png' },
  });
};

const appendFile = async ({
  blobName,
  blob,
  timestamp,
  isParent,
  examId,
  externalExamId,
}) => {
  try {
    const appendBlobClient = containerClient.getAppendBlobClient(blobName);

    if (isParent) {
      await appendBlobClient.createIfNotExists();
      logger.info('Blob client created.', { examId, externalExamId });
    }

    // AzureBlobStorage has a limit of 5MB blobs
    if (blob.size > MAX_BLOB_SIZE) {
      logger.info(`Blob too large (${blob.size}), splitting.`, {
        examId,
        externalExamId,
      });

      for (let i = 0; i < blob.size; i += MAX_BLOB_SIZE) {
        const subBlob = blob.slice(i, i + MAX_BLOB_SIZE);
        await appendBlobClient.appendBlock(subBlob, subBlob.size);
      }
    } else await appendBlobClient.appendBlock(blob, blob.size);

    return timestamp;
  } catch (err) {
    if (err.statusCode === 409) {
      logger.info(
        `Blob was already appended and exists in storage: ${timestamp}`,
        { examId, externalExamId }
      );
      return timestamp;
    } else if (err.statusCode === 404) {
      logger.error(
        `The specified blob does not exist. Create new one with timestamp: ${timestamp}`,
        {
          examId,
          externalExamId,
          externalExamIdSentry: localStorage.getItem('externalExamId4Sentry'),
          exception: err,
        }
      );
      return appendFile({
        blob,
        blobName,
        isParent: true,
        timestamp,
        examId,
        externalExamId,
      });
    } else throw err;
  }
};

/**
 * Initiate chunks recording for both cam and screen chunks,
 * happens only once per cycle (unless browser was refreshed, or internet connection failure happened)
 */
export const startRecordingChunks = async ({
  examId,
  recorder,
  event,
  externalExamId,
}) => {
  let shouldCreateParentChunk = true;
  let parentTimestamp;

  recorder.ondataavailable = async (blob) => {
    let timestamp = Date.now();
    if (shouldCreateParentChunk) {
      logger.info('Parent chunk created.', { examId, externalExamId });
      parentTimestamp = timestamp;
    }

    const chunkData = {
      examId,
      video: blob.data,
      timestamp,
      parentTimestamp,
      isParentChunk: shouldCreateParentChunk,
    };

    if (shouldCreateParentChunk) shouldCreateParentChunk = false;

    // insert chunk into IDB everytime blob is available
    if (chunkData.video.size && chunkData.video.size > 0) {
      if (chunkData.video.size < MAX_BLOB_SIZE)
        await IDB.insert({ storeName: event, chunkData, timestamp });
      else {
        // AzureBlobStorage has blob size limit of 4MB
        logger.info(
          `Blob too large (Time: ${new Date(chunkData.timestamp)}. Bytes: ${
            chunkData.video.size
          }), splitting.`,
          {
            examId,
            externalExamId,
          }
        );

        let offset = 0;
        while (offset < blob.data.size) {
          const subBlob = new Blob(
            [blob.data.slice(offset, offset + MAX_BLOB_SIZE)],
            { type: 'video/x-matroska;codecs=avc1,opus' }
          );
          timestamp = timestamp + 1;
          await IDB.insert({
            storeName: event,
            chunkData: { ...chunkData, timestamp, video: subBlob },
            timestamp,
          });
          offset += MAX_BLOB_SIZE;
        }
      }
    }

    // Restart recorder to short video segments
    if (
      event === 'videoChunkCaptured' &&
      timestamp - parentTimestamp > VIDEO_SEGMENT_LENGTH
    ) {
      touchRecorder({ event: 'stop' });
      setTimeout(() => {
        touchRecorder({ event: 'start' });
      }, 1);
    }
  };

  recorder.onstart = () => {
    logger.info(`Started recording for event "${event}".`, {
      examId,
      externalExamId,
    });
  };

  recorder.onstop = () => {
    logger.info(`Stopped recording for event "${event}".`, {
      examId,
      externalExamId,
    });
    shouldCreateParentChunk = true;
    parentTimestamp = undefined;
  };
};

/**
 * Initiate snapshots transport for both cam and screen snapshots,
 * happens only once per cycle (unless browser was refreshed, or internet conncetion failure happened)
 *
 * isFirstTimeSnapEmitting - here, we need to emit first chunk just once,
 * to initiate events of videoSnapshotsReceived / screenSnapshotsReceived,
 * they controls fruther snapshots emitioning
 */
export const startTakingSnapshots = async ({
  event,
  examId,
  externalExamId,
  socket,
}) => {
  let isFirstTimeSnapEmitting = true;

  let snapshots = [];
  const isCam = event === EXAM.CAPTURED_SNAP_CAM;

  const interval = setInterval(async () => {
    const snapshot = await takeSnapshot({
      videoElemId:
        'exam-watcher-' +
        EXAM[isCam ? 'CAPTURED_CHUNK_CAM' : 'CAPTURED_CHUNK_SCREEN'],
      ...(isCam ? CAM_SNAPSHOT_RESOLUTION : SCREEN_SNAPSHOT_RESOLUTION),
    });

    if (snapshot) snapshots.push(snapshot);

    if (snapshots.length === 4) {
      const timestamp = Date.now();
      const chunkData = {
        examId,
        snapshots,
        timestamp,
        isFirstSnapshot: isFirstTimeSnapEmitting,
      };

      IDB.insert({ storeName: event, chunkData, timestamp });

      snapshots = [];
      isFirstTimeSnapEmitting = false;
    }

    const hasExamFinished = !!LSM.getExamProperty(
      externalExamId,
      EXAM_WAS_FINISHED
    );
    if (hasExamFinished) clearInterval(interval);
  }, 1000);
};

/**
 * Initiate snapshots transport again in case the socket drops (could happen any number of times),
 * or if exam was finished and browser refreshed.
 */
const initSnapsTransfer = async (event, socket) => {
  const followingSnapshot = await IDB.getFollowing({ storeName: event });
  if (!followingSnapshot) return;

  const { timestamp } = followingSnapshot;

  if (!isSnapSent(event, timestamp)) {
    socket.emit(event, followingSnapshot);
    remeberSentSnap(event, timestamp);
  } else {
    await IDB.removeReceived({ storeName: event, timestamp: timestamp });
    initSnapsTransfer(event, socket);
  }
};

export const startSocket = ({
  config,
  definitionId,
  examId,
  externalExamId,
  setAiFeedbackApp,
  setAiFeedbackCam,
  setAiFeedbackScreen,
  setApprovalsTexts,
  setShowDesktopNotification,
  setChecks,
  setExamId,
  setShowStillUploadingMessage,
  setShowFailedUploadAfterExamFinishMessage,
  setProcessAnalysisCheck,
  setIsProcessAnalysis,
  setSection,
  setShowTestAppAfterCleanUp,
  setSocket,
  setIntroChecksTimer,
}) => {
  if (!socket) {
    socket = io(config?.proctoringBackendUrl ?? PROCTORING_API_URL, {
      query: {
        customerId: config?.customerId,
        dateOfBirth: config?.birthDate,
        definitionId,
        examId,
        externalExamId,
        firstName: config?.firstName,
        lang: config?.lang,
        lastName: config?.lastName,
      },
      autoConnect: false,
      reconnection: true,
      reconnectionDelayMax: 5000,
      timeout: 5000,
      transports: ['websocket'],
    });

    socket.connect();
  } else socket.connect();

  const finishExamCleanUp = async () => {
    // Finish exam
    const hasExamFinished = !!LSM.getExamProperty(
      externalExamId,
      EXAM_WAS_FINISHED
    );
    if (hasExamFinished) {
      const chunksRemainingCam = await IDB.getStoreCount({
        storeName: EXAM.CAPTURED_CHUNK_CAM,
      });
      const chunksRemainingScreen = await IDB.getStoreCount({
        storeName: EXAM.CAPTURED_CHUNK_SCREEN,
      });
      const snapsRemainingCam = await IDB.getStoreCount({
        storeName: EXAM.CAPTURED_SNAP_CAM,
      });
      const snapsRemainingScreen = await IDB.getStoreCount({
        storeName: EXAM.CAPTURED_SNAP_SCREEN,
      });

      if (
        !chunksRemainingCam &&
        !chunksRemainingScreen &&
        !snapsRemainingCam &&
        !snapsRemainingScreen
      ) {
        const examId = LSM.getExamProperty(externalExamId, EXAM.EXAM_ID);

        logger.info(
          'Exam finished, no chunks or snapshots remain in the DB. Sending examFinished and disconnecting from socket.',
          { examId, externalExamId }
        );

        if (examId && !isEmpty(examId)) socket.emit('examFinished', { examId });

        LSM.deleteExam(externalExamId);
        setShowStillUploadingMessage(false);
        setShowTestAppAfterCleanUp(true);
        return true;
      }
    } else return false;
  };

  const onReceived = async (event, tsToRemove) => {
    await IDB.removeReceived({ storeName: event, timestamp: tsToRemove });

    if (await finishExamCleanUp()) return;

    const followingSnapshot = await IDB.getFollowing({ storeName: event });
    const { timestamp } = followingSnapshot;

    if (!followingSnapshot) return;

    if (!isSnapSent(event, timestamp)) {
      socket.emit(event, followingSnapshot);
      remeberSentSnap(event, timestamp);
    }
  };

  const sendChunks = (examId, definitionId, event) => {
    let failedRequestDelay = FAILED_REQUEST_INTERVALS[0];
    let failedRequestTimeout = null;
    let lastSendChunkTimestamp;

    const sendChunk = async () => {
      try {
        // Do not loop when offline, reconnection() interval will reinitialize the loop when online again (a failed request due to being offline will be caught and end the loop too)
        while (!(await finishExamCleanUp())) {
          const followingChunk = await IDB.getFollowing({ storeName: event });

          if (sentChunks[event].includes(followingChunk.timestamp)) {
            logger.warn(
              `Duplicate prevented. Timestamp: ${followingChunk.timestamp}`,
              { examId, externalExamId }
            );
            await IDB.removeReceived({
              storeName: event,
              timestamp: followingChunk.timestamp,
            });
            continue;
          }

          const blobName = `${definitionId}/${examId}/${
            event === EXAM.CAPTURED_CHUNK_CAM ? 'webcam' : 'screen'
          }/${followingChunk.parentTimestamp}.mkv`;

          lastSendChunkTimestamp = await appendFile({
            blobName,
            blob: followingChunk.video,
            timestamp: followingChunk.timestamp,
            isParent: followingChunk.isParentChunk,
            externalExamId,
            examId,
          });

          sentChunks[event] = [
            followingChunk.timestamp,
            ...sentChunks[event],
          ].slice(0, 100);

          if (lastSendChunkTimestamp)
            await IDB.removeReceived({
              storeName: event,
              timestamp: lastSendChunkTimestamp,
            });

          failedRequestDelay = FAILED_REQUEST_INTERVALS[0]; // No error was thrown, reset the counter
        }
      } catch (e) {
        const examFinished = !!LSM.getExamProperty(
          externalExamId,
          EXAM_WAS_FINISHED
        );

        logger.error(`${examId} | sendChunks error: ${e.toString()}`, {
          examId,
          externalExamId,
          externalExamIdSentry: localStorage.getItem('externalExamId4Sentry'),
          online: window.navigator.onLine,
          exception: e,
        });

        // Extending interval delay is based on the previous interval delay
        failedRequestDelay = examFinished
          ? FAILED_REQUEST_INTERVALS[
              indexOf(failedRequestDelay, FAILED_REQUEST_INTERVALS) + 1
            ] || failedRequestDelay
          : failedRequestDelay;

        logger.info(
          `Sending chunk failed on ${event}. Next trial in: ${
            failedRequestDelay / 1000
          } seconds.`,
          {
            examId,
            externalExamId,
          }
        );

        // Attempt another request with increasing offsets;
        failedRequestTimeout = setTimeout(sendChunk, failedRequestDelay);
        // If the exam is finished and the upload fails multiple times, stop trying to upload
        if (failedRequestDelay >= MAX_FAILED_REQUEST_INTERVAL && examFinished) {
          logger.info(
            `Max number of attempts to upload data after exam finished reached: ${event}`,
            { examId, externalExamId }
          );
          clearTimeout(failedRequestTimeout);
          setShowStillUploadingMessage(false);
          setShowFailedUploadAfterExamFinishMessage(true);
        }
      }

      logger.info(`sendChunk() function finished.`, { examId, externalExamId });
    };

    sendChunk();
  };

  socket.on('connect', () =>
    logger.info(`Socket (re)connected.`, { examId, externalExamId })
  );

  socket.on('disconnect', (reason) => {
    logger.error(`Socket disconnected. Reason: ${reason}`, {
      examId,
      externalExamId,
      externalExamIdSentry: localStorage.getItem('externalExamId4Sentry'),
    });
  });

  socket.io.on('error', (error) => {
    logger.error(`${examId} - Unexpected socker error: ${error}`, {
      examId,
      externalExamId,
      externalExamIdSentry: localStorage.getItem('externalExamId4Sentry'),
      exception: error,
    });
  });

  socket.io.on('reconnect_error', (error) => {
    logger.error(`${examId} - Error on socket reconnect: ${error}`, {
      examId,
      externalExamId,
      externalExamIdSentry: localStorage.getItem('externalExamId4Sentry'),
    });
  });

  socket.io.on('reconnect_attempt', (attempt) => {
    logger.info(`Attempting socket reconnection: ${attempt}`, {
      examId,
      externalExamId,
    });
  });

  socket.io.on('reconnect', (attempt) => {
    logger.info(`Socket successfully reconnected: ${attempt}`, {
      examId,
      externalExamId,
    });
  });

  socket.on('examCreated', async ({ data, errors, storageParameters }) => {
    const {
      isTimerEnabled,
      timerLimit,
      timerDisplay,
      timerWarning,
      gdprText,
      rulesText,
      checklists,
      showDesktopNotification,
      id,
    } = data;
    if (errors) {
      logger.error(
        `Errors occurred when receiving examCreated socket event: ${errors.join(
          '\n'
        )}`,
        {
          examId,
          externalExamId,
        }
      );
      return;
    }

    if (isTimerEnabled) {
      if (!LSM.getExamProperty(externalExamId, 'examStartedAt')) {
        LSM.setExamProperty(externalExamId, 'examStartedAt', +new Date());
      }
      const examStart = Number(
        LSM.getExamProperty(externalExamId, 'examStartedAt')
      );

      setIntroChecksTimer({
        timerLimit: examStart + timerLimit * 60_000,
        timerWarning: examStart + (timerLimit - timerWarning) * 60_000,
        timerDisplay: examStart + (timerLimit - timerDisplay) * 60_000,
      });
    }

    containerClient = getContainerClient(storageParameters);
    setApprovalsTexts({ gdpr: gdprText, examRules: rulesText });
    setChecks(prepareChecks(checklists));

    setShowDesktopNotification(showDesktopNotification);

    // There is an exam past the intro checks
    if (!!LSM.getExamProperty(externalExamId, EXAM_WAS_OPENED)) {
      setSection(SECTIONS.EXAM);
    } else {
      setSection(SECTIONS.INTRO);

      setExamId(id);
      LSM.setExamProperty(externalExamId, EXAM.EXAM_ID, id);
    }

    await IDB.init(externalExamId);

    initSnapsTransfer(EXAM.CAPTURED_SNAP_CAM, socket);
    initSnapsTransfer(EXAM.CAPTURED_SNAP_SCREEN, socket);

    if (!sendChunksInitialized) {
      sendChunksInitialized = true;
      sendChunks(id, definitionId, EXAM.CAPTURED_CHUNK_CAM);
      sendChunks(id, definitionId, EXAM.CAPTURED_CHUNK_SCREEN);
    }
  });

  socket.on('videoSnapshotsReceived', async ({ timestamp }) =>
    onReceived(EXAM.CAPTURED_SNAP_CAM, timestamp)
  );

  socket.on('screenSnapshotsReceived', async ({ timestamp }) =>
    onReceived(EXAM.CAPTURED_SNAP_SCREEN, timestamp)
  );

  socket.on('webcamAnalysisResult', ({ comment, objects, warning }) => {
    setAiFeedbackCam([
      comment,
      objects[0]
        ?.map(
          (object) => object.type + ' (' + object.confidence_percentage + ')'
        )
        .join(', ') ?? '',
      warning,
      CHECK.VIDEO,
    ]);
  });

  socket.on('screenAnalysisResult', ({ comment, objects, warning }) => {
    setAiFeedbackScreen([
      comment,
      objects[0]
        ?.map(
          (object) => object.type + ' (' + object.confidence_percentage + ')'
        )
        .join(', ') ?? '',
      warning,
      CHECK.SCREEN,
    ]);
  });

  socket.on('processAnalysisResult', ({ comment, objects, warning }) => {
    setAiFeedbackApp([
      JSON.parse(comment),
      objects[0]
        ?.map(
          (object) => object.type + ' (' + object.confidence_percentage + ')'
        )
        .join(', ') ?? '',
      warning,
      CHECK.PROCESS_APP,
    ]);
    // If the limit for the next event elapsed, restart it
    setIsProcessAnalysis(true);
    // Record the time of the event to perioadically check that the last such received event is fresh enough
    setProcessAnalysisCheck(Date.now());
  });

  socket.on('idAnalysisResult', ({ comment, objects, warning }) => {
    setAiFeedbackCam([
      comment + ' : ' + warning,
      objects[0]
        ?.map(
          (object) => object.type + ' (' + object.confidence_percentage + ')'
        )
        .join(', ') ?? '',
      warning,
    ]);
    socket.emit('checkpointFinished', {
      examId,
      definitionId,
      checkpointType: CHECK.ID,
    });
  });

  setSocket(socket);
};
