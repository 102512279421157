module.exports = {
  stringsCZ: {
    errHandlerPageTitle: 'Něco se nepovedlo...',
    errHandlerTitle: 'Nastala chyba',
    errHandlerDesc:
      'Zkuste to znovu. Pokud se chyba opakuje, vraťte se do seznamu zkoušek a klikněte na tlačítko Pokračovat.',
    errTokenMissing: 'Chybí autorizační token!',
    errFetchToken: 'Nelze načíst token.',
    errInitIndexedDb: 'Chyba inicializace databáze prohlížeče.',
    errUnknown: 'Nespecifikovaná chyba serveru.',
    errUnableToLoadTest: 'Nelze načíst test! ',
    errUnableToStartTest: 'Nelze spustit test! ',
    errUnableToFinishTest: 'Nepodařilo se ukončit test! ',
    errInvalidToken: 'Neplatný token',
    errInit: 'Chyba při inicializaci. ',
    errRetryAdvice: 'Zkuste opakovat akci',
    errReturnLink: 'Seznam zkoušek',
    errResilienceNoItems:
      'V tomto zařízení nejsou žádné odpovědi k nahrání. Ujistěte se, že jste v zařízení a internetovém prohlížeči, ve kterém jste řešili tuto zkoušku. V takovém případě můžete kliknout na tlačítko Ukončit zkoušku. Pokud jste v jiném zařízení nebo internetovém prohlížeči, klikněte na tlačítko Seznam zkoušek a přihlaste se do ScioLink ze správného zařízení.',
    err404: '404',
    err404Title: 'Stránka neexistuje',
    err404Desc:
      'Hledaná stránka zřejmě neexistuje, přejděte prosím na domovskou obrazovku.',
    err404Btn: 'Přejděte na domovskou obrazovku',
    loadingData: 'nahrávám data...',
    savingData: 'nahrávám data...',
    testContinuesDownwards: 'Test pokračuje směrem dolů',
    testTotalQuestions: 'Počet úloh:',
    testTimeLimit: 'Časový limit:',
    testRemainingTime: 'Zbývající čas:',
    testNoTimelimit: 'Bez časového limitu.',
    btnTestStart: 'Spustit test',
    btnPartStart: 'Spustit oddíl',
    btnTryAgain: 'Zkusit znovu',
    btnReturn: 'Seznam zkoušek',
    btnNavigationPrevious: 'Předchozí',
    btnNavigationNext: 'Další',
    btnNavigationFinishTest: 'Ukončit test',
    btnNavigationFinishPart: 'Ukončit oddíl',
    itemQuestion: 'Úloha',
    itemQuestions: 'úloh',
    sharedItem: 'Společné zadání',
    sharedItemIndexOne: 'pro úlohu',
    sharedItemIndexN: 'pro úlohy',
    implicitInstruction: 'Instrukce',
    implicitInstructionIndexOne: 'pro úlohu',
    implicitInstructionIndexN: 'pro úlohy',
    insert: 'Vložit',
    instructionCollapse: 'Sbalit instrukci',
    instructionExpand: 'Rozbalit instrukci',
    downloadContentTitle: 'Pomocné materiály',
    calculatorTitle: 'Kalkulačka',
    cancel: 'Zrušit',
    timer_estimatedTimeTest: 'Zbývající čas: ',
    timer_estimatedTimePart: 'Zbývající čas: ',
    timerTimeoutAlertTitle: 'Čas vypršel',
    timerTimeoutAlertTextTest:
      'Časový limit na vypracování vypršel. Test bude ukončen.',
    timerTimeoutAlertTextPart:
      'Časový limit na vypracování vypršel. Oddíl bude ukončen.',
    timerTimeoutAlertButton: 'OK',
    timerTimeoutConfirmTitleTest: 'Opravdu chcete skončit?',
    timerTimeoutConfirmTitlePart: 'Opravdu chcete skončit?',
    timerTimeoutConfirmTextTest:
      '<b>Ukončení testu</b> je nevratné. Po ukončení už se nebudete moci vrátit k jednotlivým úlohám.',
    timerTimeoutConfirmTextPart:
      '<b>Ukončení oddílu</b> je nevratné. Po ukončení už se nebudete moci vrátit k jednotlivým úlohám. ',
    timerTimeoutConfirmButtonConfirm: 'Ano, ukončit',
    timerTimeoutConfirmButtonCancel: 'Ne, vrátit se zpět',
    timer_hideTime_arialabel: 'Skrýt čas',
    timer_hideTime_tooltip:
      'Skrýt čas. 10 minut před koncem se vám čas automaticky zobrazí.',
    timer_showTime_tooltip: 'Zobrazit zbývající čas.',
    timer_showTime: 'Zobrazit čas',
    timer_minutes: ['minuta', 'minuty', 'minut'],
    timer_snackbar: 'Máte posledních 10 minut testu',
    timerScreenReaders: `Zbývající čas:`,
    highlighterSnackbar: 'Vypnout zvýrazňovač',
    instructionsTitle: 'Instrukce k testu',
    instructionsLegend: 'Legenda',
    instructionTitleItem: 'Úloha',
    instructionTitleItems: 'Úlohy',
    instructionTimerInfo: 'Zbývající čas na přečtení zadání: ',
    legendUreaded: 'Nepřečtená úloha',
    legendAnswered: 'Zodpovězená úloha',
    legendActive: 'Aktivní úloha, na které se zrovna nacházíte',
    legendFlagged: 'Označená úloha',
    calcDegrees: 'stupně',
    calcRadians: 'radiány',
    calcError: 'Chybná operace',
    calcDivideByZero: 'Dělení nulou',
    calcQqrtNegative: 'Sudá odmocnina ze záporného čísla není v R definovaná',
    calcMissingOperand: 'Chybí část výrazu',
    calcInvalidToken: 'Neplatný výraz',
    calcUnknownError: 'Neznámá chyba',
    flagTitle: 'Označit',
    flagTitleRemove: 'Odznačit',
    keyboardTitle: 'Pomocná klávesnice',
    keyboardInstruction:
      'Nabídka speciálních znaků se objeví po stisknutí odpovídajícího písmena, např. pro é stiskněte e.',
    showSupportMaterials: 'Zobrazit pomocný materiál',
    hideSupportMaterials: 'Skrýt pomocný materiál',
    sidebarCalculator: 'Kalkulačka',
    sidebarSupportMaterials: 'Materiály',
    sidebarNotepad: 'Poznámky',
    sidebarHighlighter: 'Zvýrazňovač',
    sidebarSupport: 'Podpora',
    supportCenter: 'Zákaznické centrum',
    supportCenterText:
      'Zákaznické centrum. Od pondělí do čtvrtka mezi 9. a 17. hodinou, v pátek mezi 9. a 16. hodinou. V den konání Národních srovnávacích zkoušek jsme k dispozici vždy v celém průběhu.',
    supportCenterEmailTitle: 'E-mail',
    supportCenterEmailSubtitle: 'Jsme tu, abychom vám pomohli',
    supportCenterEmail: 'scio@scio.cz',
    supportCenterPhoneTitle: 'Telefon',
    supportCenterPhoneSubtitle: 'Neváhejte nás kontaktovat',
    supportCenterPhone: '+420 234 705 555',
    tooltipInstructions: 'Instrukce a pokyny ke zkoušce',
    tooltipSupportMaterials: 'Pomocné materiály dostupné k testu',
    tooltipCalculator: 'Můžete používat vestavěnou kalkulačku',
    tooltipHighlighter: 'Zapnout zvýrazňovač',
    tooltipHighlighterOn: 'Vypnout zvýrazňovač',
    tooltipNotepad: 'Poznámkový blok',
    tooltipDisabled: 'Další stránka se načítá',
    proctoringTitle: 'Nastavení a kontrola údajů',
    playMedia: 'Přehrát',
    playingMediaNewPageConfirmTitle: 'Upozornění během přehrávání',
    playingMediaNewPageConfirmMessage:
      'Přechodem na jinou stránku vyčerpáte jednu možnost přehrání mediálního obsahu. Chcete přejít na jinou stránku?',
    playingMediaNewPageConfirmMessageAutoreplay:
      'Přechodem na jinou stránku vyčerpáte všechny možnosti přehrání mediálního obsahu. Chcete přejít na jinou stránku?',
    playingMediaNewPageButtonConfirm: 'Potvrzuji',
    playingMediaNewPageButtonCancel: 'Zrušit',
    maximumAttemptsTitle: 'Upozornění k přehrávání',
    maximumAttemptsMessage: 'Byl překročen maximální počet přehrání.',
    maximumAttemptsButton: 'Ok',
    mediaMessage1: 'Tato ukázka má omezený počet přehrání.', //nastaveno maxNumberOfReplays
    mediaMessage21: ' Můžete ji přehrát celkem ', //autoReplay = false
    mediaMessage22: 'x. ',
    mediaMessage23: ' Přehraje se celkem ', //autoReplay = true
    mediaMessage24: 'x. ',
    mediaMessage3: 'Po spuštění již poslech nelze pozastavit. ',
    mediaMessage4: 'Opakované přehrávání se spouští automaticky. ', //autoReplay = true
    mediaMessage51: 'Máte k dispozici ještě ', // estimated > 0
    mediaMessage52: ' přehrání.',
    mediaMessage6: ' Již nemáte k dispozici žádná přehrání.', // estimated == 0
    resilienceModeTitle: 'Obnovení odpovědí',
    resilienceModeTitleError: 'Dokončení zkoušky',
    resilienceConfirmText:
      'Odpovědi uložené při průchodu testem budou nahrány na naše servery.',
    resilienceConfirmButton: 'Nahrát odpovědi',
    resilienceErrorButtonFinish: 'Ukončit zkoušku',
    resilienceErrorButtonBack: 'Seznam zkoušek',
    percent: ' %',
    itemLoaderTextInit: 'Úloha se načítá...',
    sessionNonActiveModalTitle: 'Kontrola probíhajících zkoušek',
    sessionNonActiveModalMessage:
      'Tato zkouška již probíhá v jiném okně! Zavřete toto okno a vraťte se do původního.',
    sessionNonActiveModalButton: 'Zkontrolovat znovu',
    sessionActiveModalTitle: 'Kontrola probíhajících zkoušek',
    sessionActiveModalMessage:
      'Tato zkouška byla právě otevřena v jiném okně! Zavřete všechna ostatní okna se zkouškou a poté klikněte na tlačítko Pokračovat ve zkoušce.',
    sessionActiveModalButton: 'Pokračovat ve zkoušce',
    notepadTitle: 'Vaše poznámky',
    notepadPlaceholder: 'Prostor pro vaše poznámky k úlohám',
    notepadSave: 'Uložit a zavřít',
    ChoiceInstructions_MinOrMax_Both_Same_Low_1: 'Zaškrtněte právě ',
    ChoiceInstructions_MinOrMax_Both_Same_Low_2: ' možnosti.',
    ChoiceInstructions_MinOrMax_Both_Same_High_1: 'Zaškrtněte právě ',
    ChoiceInstructions_MinOrMax_Both_Same_High_2: ' možností.',
    ChoiceInstructions_MinOrMax_Both_MaxOne_1:
      'Zaškrtněte nejvýše jednu možnost.',
    ChoiceInstructions_MinOrMax_Both_Different_Low_1: 'Zaškrtněte ',
    ChoiceInstructions_MinOrMax_Both_Different_Low_2: ' až ',
    ChoiceInstructions_MinOrMax_Both_Different_Low_3: ' možnosti.',
    ChoiceInstructions_MinOrMax_Both_Different_High_1: 'Zaškrtněte ',
    ChoiceInstructions_MinOrMax_Both_Different_High_2: ' až ',
    ChoiceInstructions_MinOrMax_Both_Different_High_3: ' možností.',
    ChoiceInstructions_MinOrMax_Max_One_1: 'Zaškrtněte nejvýše jednu možnost.',
    ChoiceInstructions_MinOrMax_Max_Low_1: 'Zaškrtněte nejvýše ',
    ChoiceInstructions_MinOrMax_Max_Low_2: ' možnosti.',
    ChoiceInstructions_MinOrMax_Max_High_1: 'Zaškrtněte nejvýše ',
    ChoiceInstructions_MinOrMax_Max_High_2: ' možností.',
    ChoiceInstructions_MinOrMax_Min_One_1: 'Zaškrtněte alespoň 1 možnost.',
    ChoiceInstructions_MinOrMax_Min_Low_1: 'Zaškrtněte alespoň ',
    ChoiceInstructions_MinOrMax_Min_Low_2: ' možnosti.',
    ChoiceInstructions_MinOrMax_Min_High_1: 'Zaškrtněte alespoň ',
    ChoiceInstructions_MinOrMax_Min_High_2: ' možností.',
    ChoiceInstructions_NotSet_Low_1: 'Zaškrtněte 0 až ',
    ChoiceInstructions_NotSet_Low_2: ' možnosti.',
    ChoiceInstructions_NotSet_High_1: 'Zaškrtněte 0 až ',
    ChoiceInstructions_NotSet_High_2: ' možností.',
    Score: 'Správná odpověď: ',
    ScoreNegative: 'Špatná odpověď: ',
    ScoreOnePoint: ' bod',
    ScoreTwo2FourPoints: ' body',
    ScoreFive2NPoints: ' bodů',
    ScoreLessThenPoint: ' bodu',
  },
  // eslint-disable-next-line space-before-function-paren
  getResource: function (key) {
    return this.stringsCZ[key];
  },
};
