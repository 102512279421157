import shuffleMultipleChoice from './shuffleMultipleChoice';

export default function shuffleQuestion(seed, itemType, content) {
  // https://developer.mozilla.org/en-US/docs/Web/API/DOMParser
  // parse item HTML to DOM object
  const xmlParser = new DOMParser();

  // same as in xslt template because of self closing tags
  // wrap content to html/body tags
  const xmlDom = xmlParser.parseFromString(content, 'text/html');

  const item = xmlDom.childNodes[0].childNodes[1]; // html/[head|body]

  switch (itemType) {
    case 'MultipleChoiceSingleAnswer':
    case 'MultipleChoiceMultipleAnswer':
      return shuffleMultipleChoice(seed, item);
    default:
      return content;
  }
}
