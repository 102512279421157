const mirrorImage = (base64data) => {
  const canvas = document.createElement('canvas');
  const image = new Image();
  image.src = base64data;
  canvas.width = image.width;
  canvas.height = image.height;
  const context = canvas.getContext('2d');
  context.translate(image.width, 0);
  context.scale(-1, 1);
  context.drawImage(image, 0, 0);
  return canvas.toDataURL();
};

export default mirrorImage;
