import * as Sentry from '@sentry/browser';
import {
  CAM_VIDEO_CONFIG,
  SCREEN_STREAM_CONFIG,
  NOTIF_KEY,
  DEFAULT_CAM_ASPECT_RATIO,
} from '../consts';

export const checkAudioStream = ({ microphoneDeviceId, errorHandler }) =>
  new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({
        audio: { deviceId: { exact: microphoneDeviceId } },
      })
      .then((stream) => resolve(stream))
      .catch((reason) => {
        console.error(reason);
        if (errorHandler) errorHandler(reason);
      });
  });

export const checkVideoStream = ({ videoConf, webcamDeviceId, errorHandler }) =>
  new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({
        video: { ...videoConf, deviceId: { exact: webcamDeviceId } },
      })
      .then((stream) => resolve(stream))
      .catch((reason) => {
        console.error(reason);
        if (errorHandler) errorHandler(reason);
      });
  });

const getStream = ({
  microphoneDeviceId,
  videoConf,
  webcamDeviceId,
  errorHandler,
}) =>
  new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({
        video: { ...videoConf, deviceId: { exact: webcamDeviceId } },
        audio: { deviceId: { exact: microphoneDeviceId } },
      })
      .then((stream) => resolve(stream))
      .catch((reason) => {
        console.error(reason);
        if (errorHandler) errorHandler(reason);
      });
  });

export const prepareCamStream = ({
  setNotification,
  setCamStream,
  webcamDeviceId,
  microphoneDeviceId,
}) =>
  new Promise((resolve, reject) => {
    let cam = null;

    const askUser = async () => {
      cam = await getStream({
        // Prevents OverconstrainedError if the deviceId is ''
        microphoneDeviceId: microphoneDeviceId || undefined,
        videoConf: CAM_VIDEO_CONFIG,
        webcamDeviceId: webcamDeviceId || undefined,
        errorHandler: () =>
          setNotification({
            [NOTIF_KEY.CAM]: {
              intl: 'turnOnWebCamAndRestartBrowser',
              variant: 'error',
            },
          }),
      });

      if (!cam) return;

      cam.getVideoTracks()[0].onended = () => {
        setNotification({
          [NOTIF_KEY.CAM]: {
            intl: 'turnOnWebCamAndRestartBrowser',
            variant: 'error',
          },
        });

        console.log('User interrupted webcam stream.');
        Sentry.captureException(
          'Webcam stream was interrupted during the exam.',
          {
            level: Sentry.Severity.Error,
            extra: {
              externalExamId: localStorage.getItem('externalExamId4Sentry'),
            },
          }
        );

        setCamStream(null);
        const tracks = cam.getTracks();
        tracks.forEach((track) => track.stop());
        return;
      };

      localStorage.setItem(
        'aspectRatio',
        cam?.getVideoTracks()?.[0]?.getSettings()?.aspectRatio ||
          DEFAULT_CAM_ASPECT_RATIO
      );
      setCamStream(cam);
      resolve(true);
    };

    askUser();
  });

export const prepareScreenStream = ({ setNotification, setScreenStream }) =>
  new Promise((resolve, reject) => {
    let screen;

    try {
      const askUser = async () => {
        try {
          screen = await navigator.mediaDevices.getDisplayMedia(
            SCREEN_STREAM_CONFIG
          );
        } catch (err) {
          console.error(err);
          setNotification({ [NOTIF_KEY.SCREEN]: null });
          setNotification({
            [NOTIF_KEY.SCREEN]: {
              intl:
                err?.code === 0
                  ? 'systemDisallowsScreenSharing'
                  : 'errorChooseMonitor',
              variant: 'error',
            },
          });
          // Prevent infinite recursion if the stream actually was successfully selected
          // Some exams (spotted on MacOs) actually share screen normally while this error still appeared
          // https://dev.azure.com/borndigitalai/Born%20Digital/_workitems/edit/3321
          if (!screen) {
            prepareScreenStream({ setNotification, setScreenStream });
            return;
          }
        }

        if (
          screen.getVideoTracks()[0].getSettings().displaySurface !== 'monitor'
        ) {
          setNotification({ [NOTIF_KEY.SCREEN]: null });
          setNotification({
            [NOTIF_KEY.SCREEN]: {
              intl: 'errorChooseMonitor',
              variant: 'error',
            },
          });
          screen.getTracks().forEach((track) => track.stop());
          prepareScreenStream({ setNotification, setScreenStream });
          return;
        }

        screen.getVideoTracks()[0].onended = () => {
          setNotification({ [NOTIF_KEY.SCREEN]: null });
          setNotification({
            [NOTIF_KEY.SCREEN]: {
              intl: 'errorDontStopSharing',
              variant: 'error',
            },
          });

          console.log('User interrupted screen stream.');
          Sentry.captureException(
            'User interrupted screen stream during the exam.',
            {
              level: Sentry.Severity.Error,
              extra: {
                externalExamId: localStorage.getItem('externalExamId4Sentry'),
              },
            }
          );

          setScreenStream(null);
          const tracks = screen.getTracks();
          tracks.forEach((track) => track.stop());
        };

        setNotification({ [NOTIF_KEY.SCREEN]: null });
        setScreenStream(screen);
        resolve(true);
      };

      askUser();
    } catch (err) {
      Sentry.captureException(err, {
        level: Sentry.Severity.Error,
        extra: {
          externalExamId: localStorage.getItem('externalExamId4Sentry'),
        },
      });
      console.error('Error: ' + err);
      setNotification({
        [NOTIF_KEY.SCREEN]: {
          intl: 'errorChooseMonitor',
          variant: 'error',
        },
      });
      prepareScreenStream({ setNotification, setScreenStream });
      return;
    }
  });
