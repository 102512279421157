import shuffleArray from '../utils/shuffleArray';

export default function shuffleMultipleChoice(seed, item) {
  // depended on MultipleChoiceItemTemplate_nonShuffle.xslt
  let optionsDiv = [];
  item.childNodes[0].childNodes.forEach((c) => {
    if (c.className === 'options') {
      optionsDiv = c;
    }
  });

  // options to array
  const options = Array.from(optionsDiv.children);
  shuffleArray(seed, options);

  // change keys
  for (let x = 0; x < options.length; x++) {
    const letter = String.fromCharCode(x + 64 + 1);
    // option-item[x]/[input|label]/[span itemOptionKey|answer]

    let optionSpan;
    options[x].childNodes.forEach((o) => {
      if (o.className && o.className.includes('itemOptionLabel')) {
        optionSpan = o;
      }
    });
    if (optionSpan) {
      optionSpan.childNodes[0].innerText = letter;
    }
  }

  // extract html to array
  const shuffledDistractors = options.map((x) => x.outerHTML);

  // join options HTML, replace in DOM element
  optionsDiv.innerHTML = shuffledDistractors.join('');

  return item.innerHTML;
}
