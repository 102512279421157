import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { ResourceManager } from '../resources/ResourceManager';
import TestInstructionModal from '../modals/TestInstructionModal';

const InstructionBox = (props) => {
  const [instructionState, setInstructionState] = useState({
    show: false,
    top: 0,
    left: 0,
    height: 0,
  });
  const { test, items, testModeSettings } = props;

  const legendEnabled = !testModeSettings.disableLegend;

  const handleClick = (e) => {
    let rect;
    if (e) rect = e.currentTarget.getBoundingClientRect();

    setInstructionState((prevState) => ({
      show: !instructionState.show,
      top: e ? rect.top : prevState.top,
      left: e ? rect.left : prevState.left,
      height: e ? rect.height : prevState.height,
    }));
  };

  return (
    <div className="instruction-box">
      <button
        onClick={(e) => handleClick(e)}
        className="instruction-icon"
        data-tip={ResourceManager('tooltipInstructions')}
        data-for="tooltip-instructions"
        aria-label={ResourceManager('tooltipInstructions')}
      />
      <ReactTooltip id="tooltip-instructions" effect="solid" place="bottom" />
      <TestInstructionModal
        modalState={instructionState}
        toggleInstructions={handleClick}
        test={test}
        items={items}
        legendEnabled={legendEnabled}
      />
    </div>
  );
};

export default InstructionBox;
