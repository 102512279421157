import {
  ApplicationInsights,
  SeverityLevel,
} from '@microsoft/applicationinsights-web';
import LogLevel from './LogLevel';
import { flattenToken } from '../tokenHelpers';

const convertLogLevel = (logLevel) => {
  switch (logLevel) {
    case LogLevel.Debug:
      return SeverityLevel.Verbose;
    case LogLevel.Info:
      return SeverityLevel.Information;
    case LogLevel.Warn:
      return SeverityLevel.Warning;
    case LogLevel.Error:
      return SeverityLevel.Error;
    default:
      return SeverityLevel.Info;
  }
};

class AppInsightsLogger {
  appInsights;

  constructor() {
    const version = `T:${window.appVersion}`;

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey:
          process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY,
        disableAjaxTracking: true, // Ajax tracking without exam id is useless
        disableFetchTracking: true, // Fetch tracking without exam id is useless
        disableCookiesUsage: true, // cookies ai_session and ai_user disabled
      },
    });

    this.appInsights.addTelemetryInitializer((envelope) => {
      envelope.tags = envelope.tags || [];
      envelope.tags.push({ 'ai.cloud.role': process.env.REACT_APP_ENV });
      envelope.tags.push({ 'ai.cloud.roleInstance': 'TESTCORE-FE' });
      envelope.tags.push({ 'ai.application.version': version });
    });

    this.appInsights.loadAppInsights();
  }

  log = (logLevel, message, params) => {
    if (logLevel < LogLevel.Info) return;
    const properties = flattenToken(params);
    const severityLevel = convertLogLevel(logLevel);

    this.appInsights.trackTrace({
      message,
      properties: {
        ...properties,
        timestamp: new Date().toISOString(),
      },
      severityLevel,
    });
  };
}

export default AppInsightsLogger;
