import BaseOperator from "./BaseOperator";

export default class ParenthesisLeftOperator extends BaseOperator {

    getOperator() {
        return '(';
    }

    toFullString() {
        return " "+ this.getOperator();
    }
}