import { SNAPSHOT_TYPE, SNAPSHOT_QUALITY } from '../consts';

const takeSnapshot = async ({ videoElemId, width, height }) => {
  const video = document.getElementById(videoElemId);

  if (!video) return null;

  const canvas = document.createElement('canvas');
  canvas.width = width || video.videoWidth || video.clientWidth;
  canvas.height = height || video.videoHeight || video.clientHeight;

  const context = canvas.getContext('2d');
  context.drawImage(video, 0, 0, canvas.width, canvas.height);

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      async (blob) => {
        const buffer = await blob.arrayBuffer();
        resolve(buffer);
      },
      SNAPSHOT_TYPE,
      SNAPSHOT_QUALITY
    );
  });
};

export default takeSnapshot;
