import { useState, useEffect, useRef } from 'react';
import CalculatorComponent from '../calculator/component/App';
import draggable from '../utils/draggable';
import modalOverlapping from '../utils/modalOverlapping';
import { ResourceManager } from '../resources/ResourceManager';
import base64Image from '../utils/images';

const Calculator = (props) => {
  const [modalWidth, setModalWidth] = useState(0);
  const modalRef = useRef(null);
  const { show, top, left } = props.modalState;

  useEffect(() => {
    if (show) {
      const width = modalRef.current.offsetWidth;
      setModalWidth(width);

      draggable(
        document.getElementById('idCalculatorComponent'),
        document.getElementById('idCalculatorHeader')
      );

      modalOverlapping('idCalculatorComponent', '.sidebar-modal');
    }
  }, [show]);

  if (show) {
    const modalLeftPosition = modalWidth > 0 ? left - (modalWidth + 16) : 0;

    const version = props.calculatorVersion;
    let mode;

    switch (version) {
      case 1:
      default:
        mode = 'basic';
        break;
      case 2:
        mode = 'extended';
        break;
    }

    return (
      <div
        ref={modalRef}
        id="idCalculatorComponent"
        className="sidebar-modal calculator-component"
        style={{ top, left: modalLeftPosition }}
      >
        <div id="idCalculatorHeader" className="modal-sidebar-draggable">
          <div className="modal-title">
            {ResourceManager('calculatorTitle')}
          </div>
          <button
            onClick={props.toggleCalculator}
            className="btn-close"
            aria-label={ResourceManager('cancel')}
          >
            <img src={base64Image('x-close-gray700')} alt="" />
          </button>
        </div>
        <div className="sidebar-modal-content">
          <CalculatorComponent mode={mode} />
        </div>
      </div>
    );
  }

  return null;
};

export default Calculator;
