import { useState, useEffect, useRef } from 'react';
import draggable from '../utils/draggable';
import modalOverlapping from '../utils/modalOverlapping';
import { ResourceManager } from '../resources/ResourceManager';
import getStorageKey from '../utils/getStorageKey';
import base64Image from '../utils/images';

const NotepadModal = (props) => {
  const [modalWidth, setModalWidth] = useState(0);
  const [textAreaValue, setTextAreaValue] = useState('');
  const modalRef = useRef(null);
  const { show, top, left } = props.modalState;
  const answersId = props.tokenObject.AnswersId;
  const note = props.note;

  useEffect(() => {
    if (show) {
      const width = modalRef.current.offsetWidth;
      setModalWidth(width);
      setTextAreaValue(note);
      draggable(
        document.getElementById('idNotepadComponent'),
        document.getElementById('idNotepadHeader')
      );
      modalOverlapping('idNotepadComponent', '.sidebar-modal');
    }
  }, [show, answersId, note]);

  const handleTextChange = (event) => {
    setTextAreaValue(event.target.value);
  };

  const saveAndClose = (save) => {
    if (save) {
      localStorage.setItem(getStorageKey(answersId), textAreaValue);
    }

    props.toggleNotepad();
  };

  if (show) {
    const modalLeftPosition = modalWidth > 0 ? left - (modalWidth + 16) : 0;

    return (
      <div
        ref={modalRef}
        id="idNotepadComponent"
        className="sidebar-modal notepad-modal"
        style={{ top, left: modalLeftPosition }}
        role="dialog"
        aria-modal="true"
        aria-labelledby={ResourceManager('notepadTitle')}
      >
        <div id="idNotepadHeader" className="modal-sidebar-draggable">
          <div className="modal-title">{ResourceManager('notepadTitle')}</div>
          <button
            onClick={() => saveAndClose(false)}
            className="btn-close"
            aria-label={ResourceManager('cancel')}
          >
            <img src={base64Image('x-close-gray700')} alt="" />
          </button>
        </div>
        <div className="sidebar-modal-content">
          <textarea
            rows="7"
            cols="32"
            name=""
            placeholder={ResourceManager('notepadPlaceholder')}
            id="IdNotepadTextarea"
            value={textAreaValue}
            onChange={handleTextChange}
          />
          <div className="modal-button-row">
            <button
              onClick={() => saveAndClose(false)}
              className="btn btn-secondary"
            >
              {ResourceManager('cancel')}
            </button>
            <button
              onClick={() => saveAndClose(true)}
              className="btn btn-primary"
            >
              {ResourceManager('notepadSave')}
            </button>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default NotepadModal;
