import BaseOperator from "./BaseOperator";

export default class EulerOperator extends BaseOperator {

    getOperator() {
        return "e";
    }

    toFullString() {
        return this.getOperator() +" ";
    }
}