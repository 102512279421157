import React, { Component } from 'react';
import RawHtml from './RawHtml';

export default class Instruction extends Component {
  render() {
    return (
      <div className="item instruction">
        <RawHtml content={this.props.renderedItem.renderedOutput} />
      </div>
    );
  }
}
