import LogLevel from './LogLevel';

class MultipleLogger {
  initialLogLevel;

  loggers;

  constructor(initialLogLevel, loggers) {
    this.initialLogLevel = initialLogLevel;
    this.loggers = loggers;
  }

  debug(message, params) {
    this.log(LogLevel.Debug, message, params);
  }

  info(message, params) {
    this.log(LogLevel.Info, message, params);
  }

  warn(message, params) {
    this.log(LogLevel.Warn, message, params);
  }

  error(message, params) {
    this.log(LogLevel.Error, message, params);
  }

  log(logLevel, message, params) {
    if (!this.loggers && !this.loggers.forEach) return;
    if (logLevel >= this.initialLogLevel) {
      this.loggers.forEach((logger) => {
        if (logger.log) {
          logger.log(logLevel, message, params);
        }
      });
    }
  }

  extend(logger) {
    this.loggers.push(logger);
  }
}

export default MultipleLogger;
