import * as Sentry from '@sentry/browser';
import JSZip from 'jszip';
import RecordRTC from 'recordrtc';
import { EXAM } from '../consts';

let uploadCount = null;
class IDB {
  constructor(dbName) {
    this.dbName = dbName || EXAM.IDB_STORE_NAME;
    this.db = null;
  }

  init = (externalExamId) =>
    new Promise((resolve, reject) => {
      const indexedDB =
        window.indexedDB ||
        window.webkitIndexedDB ||
        window.mozIndexedDB ||
        window.OIndexedDB ||
        window.msIndexedDB;
      const request = indexedDB.open(externalExamId);

      request.onupgradeneeded = () => {
        this.db = request.result;

        // CHUNK CAM
        let store = this.db.createObjectStore(EXAM.CAPTURED_CHUNK_CAM, {
          keyPath: 'id',
          autoIncrement: true,
        });
        store.createIndex('id', 'chunkData', { unique: true });
        store.createIndex('by_timestamp', 'timestamp');

        // CHUNK SCREEN
        store = this.db.createObjectStore(EXAM.CAPTURED_CHUNK_SCREEN, {
          keyPath: 'id',
          autoIncrement: true,
        });
        store.createIndex('id', 'chunkData', { unique: true });
        store.createIndex('by_timestamp', 'timestamp');

        // SNAP CAM
        store = this.db.createObjectStore(EXAM.CAPTURED_SNAP_CAM, {
          keyPath: 'id',
          autoIncrement: true,
        });
        store.createIndex('id', 'chunkData', { unique: true });
        store.createIndex('by_timestamp', 'timestamp');

        // SNAP SCREEN
        store = this.db.createObjectStore(EXAM.CAPTURED_SNAP_SCREEN, {
          keyPath: 'id',
          autoIncrement: true,
        });
        store.createIndex('id', 'chunkData', { unique: true });
        store.createIndex('by_timestamp', 'timestamp');
      };

      request.onsuccess = () => {
        this.db = request.result;
        resolve(true);
      };
    });

  insert = ({ storeName, chunkData, timestamp }) =>
    new Promise(async (resolve, reject) => {
      let tx;

      try {
        tx = await this.db.transaction(storeName, 'readwrite');
      } catch (error) {
        Sentry.captureException(error, {
          level: Sentry.Severity.Warning,
          extra: {
            externalExamId: localStorage.getItem('externalExamId4Sentry'),
          },
        });
        console.error(error);
        return;
      }

      const store = tx.objectStore(storeName);
      const request = store.put({ chunkData, timestamp });

      request.onsuccess = () => resolve(true);
    });

  getFollowing = ({ storeName }) =>
    new Promise((resolve, reject) => {
      const getChunk = () => {
        const store =
          this.db?.transaction(storeName, 'readwrite').objectStore(storeName) ??
          null;

        store.onerror = (event) => resolve(false);
        if (!store) return;

        const request = store.get(IDBKeyRange.lowerBound(0, true));

        request.onsuccess = (e) => {
          const chunkData = e.target?.result?.chunkData;

          if (chunkData) resolve(e.target.result.chunkData);
          else setTimeout(() => getChunk(), 500);
        };
      };

      getChunk();
    });

  getStoreCount = ({ storeName }) =>
    new Promise((resolve, reject) => {
      const tx = this.db?.transaction(storeName, 'readwrite') ?? null;

      if (!tx) resolve(0);

      const store = tx.objectStore(storeName);
      const count = store.count();

      count.onsuccess = (e) => resolve(e.target?.result);
      count.onerror = () => resolve(0);
      count.onabort = () => resolve(0);
    });

  getQueueStatus = () =>
    new Promise(async (resolve, reject) => {
      const chunksRemainingCam = await this.getStoreCount({
        storeName: EXAM.CAPTURED_CHUNK_CAM,
      });
      const chunksRemainingScreen = await this.getStoreCount({
        storeName: EXAM.CAPTURED_CHUNK_SCREEN,
      });
      const snapsRemainingCam = await this.getStoreCount({
        storeName: EXAM.CAPTURED_SNAP_CAM,
      });
      const snapsRemainingScreen = await this.getStoreCount({
        storeName: EXAM.CAPTURED_SNAP_SCREEN,
      });

      const _uploadCount =
        chunksRemainingCam +
        chunksRemainingScreen +
        snapsRemainingCam +
        snapsRemainingScreen;
      if (!_uploadCount) resolve(100);

      if (!uploadCount) uploadCount = _uploadCount;

      const result = Math.round((_uploadCount / uploadCount) * 100 - 100) * -1;

      resolve(isNaN(result) ? 100 : result);
    });

  removeReceived = ({ storeName, timestamp }) =>
    new Promise((resolve, reject) => {
      let store;

      try {
        store = this.db
          .transaction(storeName, 'readwrite')
          .objectStore(storeName);
      } catch (error) {
        console.warn(error);
        return;
      }

      const timestampIndex = store.index('by_timestamp');
      const request = timestampIndex.getKey(timestamp);

      request.onsuccess = (e) => {
        const recordId = e.target.result;
        if (recordId) {
          const deleteRequest = store.delete(recordId);
          deleteRequest.onsuccess = () => resolve(true);
          deleteRequest.onerror = (e) => reject(e);
        } else {
          resolve(true);
        }
      };
    });

  exportIDB = () =>
    new Promise(async (resolve, reject) => {
      const fileName = 'export_proctoring.zip';
      const stores = [EXAM.CAPTURED_CHUNK_CAM, EXAM.CAPTURED_CHUNK_SCREEN];
      const zip = new JSZip();
      const dbs = (await indexedDB.databases()).map((db) => db.name);

      const getDbData = async (currentDbIndex) => {
        if (!dbs[currentDbIndex]) {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            RecordRTC.invokeSaveAsDialog(
              new File([content], fileName + '.zip', {
                type: 'application/zip',
              }),
              fileName + '.zip'
            );
          });

          resolve(true);
          return;
        }

        const request = await indexedDB.open(dbs[currentDbIndex]);

        request.onsuccess = async () => {
          this.db = request.result;

          // iterate stores
          for (const storeName of stores) {
            let parentTs, zipFolder, zipSubFolder;
            let tx;

            try {
              tx = await this.db.transaction(storeName, 'readonly');
            } catch (error) {
              console.warn(error);
              getDbData(currentDbIndex + 1);
              return;
            }

            const store = tx.objectStore(storeName);
            const request = await store.getAll();

            request.onsuccess = async () => {
              if (request?.result?.[0]?.chunkData) {
                let i = 0;

                // iterate chunks
                for (const _chunk of request.result) {
                  i++;
                  const chunk = _chunk.chunkData;
                  if (chunk.parentTimestamp !== parentTs) {
                    parentTs = chunk.parentTimestamp;
                    zipFolder = zip.folder(dbs[currentDbIndex]);
                    zipSubFolder = zipFolder.folder(storeName + '_' + parentTs);
                  }

                  await zipSubFolder.file(
                    chunk.timestamp + '.webm',
                    chunk.video,
                    { base64: false }
                  );
                }

                if (
                  storeName === EXAM.CAPTURED_CHUNK_SCREEN &&
                  i === request.result.length
                ) {
                  getDbData(currentDbIndex + 1);
                }
              } else if (
                storeName === EXAM.CAPTURED_CHUNK_SCREEN &&
                dbs[currentDbIndex + 1]
              ) {
                getDbData(currentDbIndex + 1);
              }
            };
          }
        };
      };

      getDbData(0);
    });

  download = (storeName, fileName) => {
    const blobs = [];
    const tx = this.db.transaction(storeName, 'readonly');
    const store = tx.objectStore(storeName);

    const iterateStore = (id) => {
      store.get(id).onsuccess = async (e) => {
        const result = e.target.result;
        if (result) {
          iterateStore(id + 1);
          blobs.push(result.blob);
        } else {
          RecordRTC.invokeSaveAsDialog(
            new File(blobs, fileName + '.webm', { type: 'video/webm' }),
            fileName + '.webm'
          );
        }
      };
    };
    iterateStore(1);
  };
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new IDB();
