import { isEmpty } from 'ramda';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CHECK } from '../../consts';
import { notOkIcon, okIcon } from '../../icons';
import { messages } from '../../intl';
import { Context } from '../../Proctoring';

const CheckProcess = ({ isActive }) => {
  const { aiFeedbackApp, config, checks, monitorAppLink, setAllowContinue } =
    useContext(Context);
  const receivedProcessAnalysis =
    !isEmpty(aiFeedbackApp[0]) || !isEmpty(aiFeedbackApp[1]);
  const checkProcess =
    checks?.filter(({ runCheck }) => runCheck === CHECK.PROCESS_APP)?.[0] ?? {};
  const turnOffDisallowedApps = aiFeedbackApp[0][0] !== 'OK';
  const intl = useIntl();
  const [proctoringInstalled, setProctoringInstalled] = useState(false);

  useEffect(() => {
    if (isActive && receivedProcessAnalysis) {
      setTimeout(() => setAllowContinue(!turnOffDisallowedApps), 1);
    }
  }, [
    isActive,
    receivedProcessAnalysis,
    setAllowContinue,
    turnOffDisallowedApps,
  ]);

  const handleDownloadApp = () => {
    window.open(
      navigator?.userAgent?.toLowerCase()?.includes('mac')
        ? config?.proctoringDesktopUrlMac ??
            process.env.REACT_APP_PROCTORING_DESKTOP_URL_MAC
        : config?.proctoringDesktopUrlWin ??
            process.env.REACT_APP_PROCTORING_DESKTOP_URL_WIN
    );
  };

  const handleStartApp = () => window.open(monitorAppLink);

  return !receivedProcessAnalysis ? (
    <div className="introCheck processApp">
      <h2 className="header2Semibold">{checkProcess?.headline}</h2>

      {/* <p
        className="width530 textMdNormal"
        dangerouslySetInnerHTML={{
          __html: checkProcess?.instructionsText,
        }}
      /> */}

      <div className="controls">
        <button
          onClick={() => setProctoringInstalled(false)}
          className={`textSmSemibold ${!proctoringInstalled && 'active'}`}
        >
          <FormattedMessage {...messages.proctoringNotInstalled} />
        </button>
        <button
          onClick={() => setProctoringInstalled(true)}
          className={`textSmSemibold ${proctoringInstalled && 'active'}`}
        >
          <FormattedMessage {...messages.proctoringInstalled} />
        </button>
      </div>

      {!proctoringInstalled ? (
        <div className="displayFlex textMdMedium">
          <div>
            {/* 1. step */}
            <p className="textXlBold">01</p>
            <span>
              <p className="textSmNormal">
                <FormattedMessage {...messages.downloadProctoringDesktop} />
              </p>
              <button
                onClick={handleDownloadApp}
                className="bgActiveReverse textSmSemibold"
              >
                <FormattedMessage {...messages.downloadProctoringDesktop} />
              </button>
            </span>
          </div>

          {/* 2. step */}
          <div>
            <p className="textXlBold">02</p>

            <p className="textSmNormal">
              <FormattedMessage {...messages.openInstallationFile} />
            </p>
          </div>

          {/* 3. step */}
          <div>
            <p className="textXlBold">03</p>
            <span>
              <p className="textSmNormal">
                <FormattedMessage {...messages.clickButtonWhenFinished} />
              </p>
              <button
                onClick={handleStartApp}
                className="bgActiveReverse textSmSemibold"
              >
                <FormattedMessage {...messages.startProctoringDesktop} />
              </button>
            </span>
          </div>

          {/* 4. step */}
          <div>
            <p className="textXlBold">04</p>
            <p className="textSmNormal">
              <FormattedMessage {...messages.makeSureAppStarted} />
            </p>
          </div>

          {/* 5. step */}
          <div>
            <p className="textXlBold">05</p>
            <p className="textSmNormal">
              <FormattedMessage {...messages.waitSuccessfulResult} />
            </p>
          </div>

          {/* 6. step */}
          <div>
            <p className="textXlBold">06</p>
            <p className="textSmNormal">
              <FormattedMessage {...messages.returnAndClickContinue} />
            </p>
          </div>
        </div>
      ) : (
        <div className="displayFlex textMdMedium">
          {/* 1. step */}
          <div>
            <p className="textXlBold">01</p>
            <span>
              <p className="textSmNormal">
                <FormattedMessage {...messages.clickOnThisButton} />
              </p>
              <button
                onClick={handleStartApp}
                className="bgActiveReverse textSmSemibold"
              >
                <FormattedMessage {...messages.startProctoringDesktop} />
              </button>
            </span>
          </div>

          {/* 2. step */}
          <div>
            <p className="textXlBold">02</p>
            <p className="textSmNormal">
              <FormattedMessage {...messages.makeSureAppStarted} />
            </p>
          </div>

          {/* 3. step */}
          <div>
            <p className="textXlBold">03</p>
            <p className="textSmNormal">
              <FormattedMessage {...messages.waitSuccessfulResult} />
            </p>
          </div>

          {/* 4. step */}
          <div>
            <p className="textXlBold">04</p>
            <p className="textSmNormal">
              <FormattedMessage {...messages.returnAndClickContinue} />
            </p>
          </div>
        </div>
      )}
    </div>
  ) : (
    <div className="introCheck processApp width682">
      <h2 className="header2Semibold">{checkProcess?.headline}</h2>

      {turnOffDisallowedApps ? notOkIcon : okIcon}
      <p className="textLgMedium">
        <FormattedMessage
          {...messages[
            turnOffDisallowedApps ? 'somethingWentWrong' : 'everythingsReady'
          ]}
        />
      </p>
      {!turnOffDisallowedApps && (
        <p className="textSmNormal">
          <FormattedMessage {...messages.procAppReady} />
        </p>
      )}
      {turnOffDisallowedApps && (
        <div
          className="error"
          dangerouslySetInnerHTML={{
            __html: `<p class='turnOffDisallowedApps textMdSemibold'>${intl.formatMessage(
              messages.closeDisallowedApps
            )}</p><ul class='textMdNormal'>
              ${aiFeedbackApp?.[0]
                .map(
                  (violation) =>
                    `<li style="line-height:30px;">${violation}</li>`
                )
                .join('')}</ul>`,
          }}
        />
      )}
    </div>
  );
};

export default CheckProcess;
