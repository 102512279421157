import React, {
  useContext,
  useEffect,
  useRef,
  useState,
  useTransition,
} from 'react';
import { Context } from '../../Proctoring';
import { FormattedMessage, useIntl } from 'react-intl';
import { messages } from '../../intl';
import useInterval from '../../utils/useInterval';
import {
  camMirror,
  photoIcon,
  playRecordingAgainIcon,
  playRecordingIcon,
  stopRecordingIcon,
} from '../../icons';
import RecordRTC from 'recordrtc';
import { STATE } from './RoomCheck';

const introCountdownInit = 4;
let recorder;

const RoomCheckVideo = ({ durationInSeconds, isActive, state, setState }) => {
  const intl = useIntl();
  const { camStream, setAllowContinue } = useContext(Context);
  const [isVideoMirrored, setIsVideoMirrored] = useState(false);
  const [introCountdown, setIntroCountdown] = useState(introCountdownInit);
  const [recordCountdown, setRecordCountdown] = useState(durationInSeconds);
  const [showReplay, setShowReplay] = useState(false);
  const [, startTransition] = useTransition();
  const playback = useRef();
  const isIntro = state === STATE.INTRO;
  const isCountingDown = introCountdown !== introCountdownInit;
  const isRecording = state === STATE.RECORDING;
  const isPlaybackReady = state === STATE.PLAYBACK_READY;
  const isPlaybackWatched = state === STATE.PLAYBACK_WATCHED;

  // Init video
  useEffect(() => {
    if (isActive && playback.current && camStream) {
      setState(STATE.INTRO);
      playback.current.srcObject = camStream;
      playback.current.controls = false;
      playback.current.onplay = () => {
        setShowReplay(false);
      };
      playback.current.onended = () => {
        setState(STATE.PLAYBACK_WATCHED);
        setShowReplay(true);
      };
    } else if (playback.current) {
      playback.current.srcObject = null;
      playback.current.src = '';
    }
  }, [camStream, durationInSeconds, isActive, setState]);

  // Allow continue
  useEffect(() => {
    if (isActive) setAllowContinue(isPlaybackReady || isPlaybackWatched);
  }, [isActive, isPlaybackReady, isPlaybackWatched, setAllowContinue]);

  // Intro countdown
  useInterval(
    () => {
      if (introCountdown === 0) {
        // Start rec
        recorder = RecordRTC(camStream, {
          type: 'video',
          mimeType: 'video/webm',
        });
        recorder.startRecording();

        startTransition(() => {
          setIntroCountdown(introCountdownInit);
          setState(STATE.RECORDING);
        });

        setRecordCountdown(durationInSeconds - 1);
      } else if (introCountdown !== 0) setIntroCountdown(introCountdown - 1);
    },
    1000,
    introCountdown !== introCountdownInit
  );

  // Record countdown
  useInterval(
    () => {
      if (recordCountdown === 0) {
        startTransition(() => {
          setRecordCountdown(durationInSeconds);
          setState(STATE.PLAYBACK_READY);
        });

        handleRoomCheck();
      } else if (recordCountdown !== 0) {
        setRecordCountdown(recordCountdown - 1);
      }
    },
    1000,
    recordCountdown !== durationInSeconds
  );

  const handleMirrorVideo = () => {
    setIsVideoMirrored(!isVideoMirrored);
  };

  const handleRoomCheck = () => {
    switch (state) {
      // Start countdown
      case STATE.INTRO:
        playback.current.srcObject = camStream;
        playback.current.src = '';
        setIntroCountdown(introCountdownInit - 1);
        break;

      // Stop recording, set playback
      case STATE.RECORDING:
        recorder.stopRecording(() => {
          const blob = recorder.getBlob();
          playback.current.srcObject = null;
          playback.current.src = URL.createObjectURL(blob);
          playback.current.controls = true;

          setRecordCountdown(durationInSeconds);
          setState(STATE.PLAYBACK_READY);
          setShowReplay(true);
        });
        break;

      // Play recording
      case STATE.PLAYBACK_READY:
      // Legacy - from when was replaying the recording required before recording it again
      // playback.current.play();
      // setState(STATE.PLAYBACK_WATCHED);
      // break;

      // Recording watched
      // eslint-disable-next-line no-fallthrough
      case STATE.PLAYBACK_WATCHED:
        if (!playback.current) {
          setTimeout(() => handleRoomCheck(), 1000);
          return;
        }

        startTransition(() => {
          setState(STATE.INTRO);
        });

        playback.current.src = '';
        playback.current.srcObject = camStream;
        setTimeout(() => setIntroCountdown(introCountdownInit - 1), 1000);
        break;

      default:
        break;
    }
  };

  return isActive ? (
    <div>
      <div className={`snapshot ${camStream && 'hideIcon'}`}>
        {/* Play recording */}
        {isPlaybackReady && showReplay && playback?.current?.paused && (
          <button onClick={() => playback.current.play()} className="play">
            {playRecordingIcon}
          </button>
        )}

        {/* Re-play recording */}
        {isPlaybackWatched && showReplay && playback?.current?.paused && (
          <button
            onClick={() => playback.current.play()}
            className="play textSmSemibold"
          >
            {playRecordingAgainIcon}
            <FormattedMessage {...messages.replayAgain} />
          </button>
        )}

        {/* Playback */}
        <video
          ref={playback}
          muted
          controls={false}
          autoPlay={isIntro}
          type="video/webm"
          className={`${isVideoMirrored && 'mirrorSnapshot'} ${
            isPlaybackWatched && 'playbackWatched'
          }`}
          disablePictureInPicture
          controlsList="nodownload noplaybackrate"
        />

        {/* Countdown */}
        {isCountingDown && (
          <div className="countdown">
            <span className={introCountdown === 3 ? 'active' : ''}>3</span>
            <span className={introCountdown === 2 ? 'active' : ''}>2</span>
            <span className={introCountdown === 1 ? 'active' : ''}>1</span>
            <span className={introCountdown === 0 ? 'active' : ''}>
              {photoIcon}
            </span>
          </div>
        )}
      </div>

      {/* Controls */}
      <div className="controls">
        {/* Mirror */}
        {!isPlaybackReady && !isPlaybackWatched && (
          <button
            aria-label={intl.formatMessage(messages.mirrorTurn)}
            className="mirror"
            onClick={handleMirrorVideo}
          >
            {camMirror}
          </button>
        )}

        {/* Start / Stop / Play / Re-play / Record again button */}
        {!isCountingDown && (
          <button
            className={`bgActive ${isRecording && 'recording'}`}
            onClick={handleRoomCheck}
          >
            {isRecording && stopRecordingIcon}
            <FormattedMessage
              {...messages[
                isIntro
                  ? 'startRecording'
                  : isRecording
                  ? 'stopRecording'
                  : 'recordAgain'
              ]}
            />
            {isRecording && <span>{recordCountdown}</span>}
          </button>
        )}
        {(isPlaybackReady || isPlaybackWatched) &&
          playback?.current?.paused && (
            <button onClick={() => playback.current.play()}>
              <FormattedMessage {...messages.playVideo} />
            </button>
          )}
      </div>
    </div>
  ) : (
    <div />
  );
};

export default RoomCheckVideo;
