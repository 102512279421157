import React, { useEffect, useState, useRef } from 'react';
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from 'notistack';
import { ResourceManager } from '../resources/ResourceManager';
import SupportMaterialsModal from '../modals/SupportMaterialsModal';
import Calculator from '../modals/Calculator';
import NotepadModal from '../modals/NotepadModal';
import Highlighter from './Highlighter';
import SidebarButton from './SidebarButton';
import getStorageKey from '../utils/getStorageKey';
import base64Image from '../utils/images';
import SupportModal from '../modals/SupportModal';

const Sidebar = (props) => {
  const [calculatorState, setCalculatorState] = useState({
    show: false,
    top: 0,
    left: 0,
  });
  const [supportMaterialsState, setSupportMaterialsState] = useState({
    show: false,
    top: 0,
    left: 0,
  });
  const [notepadState, setNotepadState] = useState({
    show: false,
    top: 0,
    left: 0,
  });
  const [supportShow, setSupportState] = useState(false);

  const {
    tokenObject,
    test,
    items,
    pageIndex,
    toggleHighlighterActive,
    isHighlighterActive,
  } = props.sidebarProps ?? {};

  const highlighterSnackbarRef = useRef(null);

  useEffect(() => {
    if (isHighlighterActive) {
      enqueueSnackbar(ResourceManager('highlighterSnackbar'), {
        autoHideDuration: null,
        action: () => (
          <button
            className="highlighter-snackbar-close"
            onClick={() => {
              toggleHighlighterActive(false);
              closeSnackbar();
            }}
          >
            <img src={base64Image('x-close-gray25')} alt="" />
          </button>
        ),
        className: 'highlighter-snackbar-custom',
      });
    } else {
      closeSnackbar();
    }
  }, [isHighlighterActive, toggleHighlighterActive]);

  const isCalculator = tokenObject?.ExamSettings?.CalculatorVersion > 0;

  const calculator = isCalculator ? (
    <>
      <SidebarButton
        condition={calculatorState.show}
        action={setCalculatorState}
        icon={'calculatorIcon'}
        text={'sidebarCalculator'}
      />
      <Calculator
        modalState={calculatorState}
        calculatorVersion={tokenObject.ExamSettings.CalculatorVersion}
        toggleCalculator={() =>
          setCalculatorState((prevState) => ({
            ...prevState,
            show: !calculatorState.show,
          }))
        }
      />
    </>
  ) : null;

  const isSupportMaterials = test?.supportMaterials?.length > 0;

  const supportMaterials = isSupportMaterials ? (
    <>
      <SidebarButton
        condition={supportMaterialsState.show}
        action={setSupportMaterialsState}
        icon={'bookOpen'}
        text={'sidebarSupportMaterials'}
      />
      <SupportMaterialsModal
        modalState={supportMaterialsState}
        items={items}
        supportMaterials={test.supportMaterials}
        toggleSupportMaterials={() =>
          setSupportMaterialsState((prevState) => ({
            ...prevState,
            show: !supportMaterialsState.show,
          }))
        }
      />
    </>
  ) : null;

  const isNotepad = tokenObject?.ExamSettings?.AllowNotepad;

  const note = isNotepad
    ? localStorage.getItem(getStorageKey(tokenObject.AnswersId))
    : '';

  const notepad = isNotepad ? (
    <>
      <SidebarButton
        condition={notepadState.show}
        action={setNotepadState}
        icon={'filePlus'}
        text={'sidebarNotepad'}
        extra={note ? 'note' : ''}
      />
      <NotepadModal
        modalState={notepadState}
        tokenObject={tokenObject}
        toggleNotepad={() =>
          setNotepadState((prevState) => ({
            ...prevState,
            show: !notepadState.show,
          }))
        }
        note={note || ''}
      />
    </>
  ) : null;

  const isHighlighter = tokenObject?.ExamSettings?.AllowHighlighter;

  const hightlighter = isHighlighter ? (
    <>
      <SidebarButton
        condition={isHighlighterActive}
        action={toggleHighlighterActive}
        icon={'pencilLine'}
        text={'sidebarHighlighter'}
      />
      <Highlighter
        answersId={tokenObject.AnswersId}
        pageIndex={pageIndex}
        isHighlighterActive={isHighlighterActive}
      />
    </>
  ) : null;

  return (
    <>
      <div id="sidebar">
        {calculator}
        {supportMaterials}
        {notepad}
        {hightlighter}
        <SidebarButton
          condition={supportShow}
          action={setSupportState}
          icon={'supportIcon'}
          text={'sidebarSupport'}
        />
      </div>
      <div id="highlighter-snackbar">
        <SnackbarProvider
          ref={highlighterSnackbarRef}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        />
      </div>
      <SupportModal
        show={supportShow}
        toggleSupport={() => setSupportState(!supportShow)}
      />
    </>
  );
};

export default Sidebar;
